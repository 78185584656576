import { apiGet, apiPost, apiPatch } from '../api';

export default {
  async checkInviteCode(code) {
    try {
      const result = await apiPost('/inviteCode', { code: code });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGroupInvite(groupId) {
    try {
      const result = await apiPost('/inviteCode/group', { groupId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTeacherInvite() {
    try {
      const result = await apiPost('/inviteCode/teacher');
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getClassInvite(teacherId) {
    try {
      const result = await apiPost('/inviteCode/class', { teacherId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getClassInvites(
    teacherId,
    role = 'student',
    action = 'personal-class',
  ) {
    try {
      const result = await apiGet('/inviteCode', {
        params: { teacherId, role, action },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getInvites(role, groupId = null, action = null) {
    try {
      const params = { role, groupId };
      const result = await apiGet('/inviteCode', { params: params });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
