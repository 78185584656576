const SET_FLASHCARDS = 'SET_FLASHCARDS';

const initialState = null;

const flashCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLASHCARDS:
      return action.flashCards;
    default:
      return state;
  }
};

export const setFlashCards = flashCards => {
  return dispatch => {
    dispatch({ type: SET_FLASHCARDS, flashCards });
  };
};

export default flashCardsReducer;
