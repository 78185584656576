/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import GroupsView from './view';
import { setUserData } from '../../store/reducers/user-reducer';
import { PersonalsService } from '../../services';
import StoreController from '../../core/storeController';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { setScroll } from '../../store/reducers/appState-reducer';

const GroupsContainer = props => {
  const personals = useSelector(state => state.personals);
  const teachers = useSelector(state => state.teachers);
  const students = useSelector(state => state.students);
  const userData = useSelector(state => state.user);

  const [selectedTeacher, setSelectedTeacher] = useState();
  const [deleteTargetClass, setDeleteTargetClass] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState(personals);
  const [showModal, setShowModal] = useState(false);
  const isAdmin = useSelector(state => state.user).role === 'admin';
  const isLoading = useSelector(state => state.appState.isLoading);
  const dispatch = useDispatch();

  const selectTeacher = id => {
    setSelectedTeacher(id);
    setData(id ? personals.filter(el => el.teacherId === id) : personals);
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  const showDeleteModal = personal => {
    setDeleteTargetClass(personal);
    setTimeout(() => setShowModal(true), 0);
  };

  const deleteClass = async () => {
    setDeleteLoading(true);
    try {
      const result = await PersonalsService.deleteClass(deleteTargetClass.id);
      const response = await StoreController.getPersonals();
      setData(response.data);
      setDeleteTargetClass(null);
      showSuccessNotification('Персональное занятие было успешно удалено');
      setShowModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setDeleteLoading(false);
  };

  return (
    <GroupsView
      showModal={showModal}
      deleteLoading={deleteLoading}
      deleteTargetClass={deleteTargetClass}
      setShowModal={setShowModal}
      showDeleteModal={showDeleteModal}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={selectTeacher}
      teachers={teachers}
      students={students}
      data={data}
      isLoading={isLoading}
      deleteClass={deleteClass}
      isAdmin={isAdmin}
    />
  );
};
export default GroupsContainer;
