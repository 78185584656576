import React from 'react';
import { useSelector } from 'react-redux';
import {
  getTeachersFormattedName,
  getGroupsFormattedName,
  getStudentsFormattedName,
} from '../../core/functions';
import Loader from '../loader/Loader';

import View from './view';

const Container = props => {
  const teachers = useSelector(state => state.teachers);
  const groups = useSelector(state => state.groups);
  const students = useSelector(state => state.students);

  const getTeacherName = id => {
    if (!teachers?.length) {
      return <Loader />;
    }
    return getTeachersFormattedName(id, teachers);
  };

  const getGroupName = id => {
    if (!groups?.length) {
      return <Loader />;
    }
    return getGroupsFormattedName(id, groups);
  };

  const getStudentName = id => {
    if (!students?.length) {
      return <Loader />;
    }
    return getStudentsFormattedName(id, students);
  };

  return (
    <View
      onPressDelete={props.onPressDelete}
      onPressChange={props.onPressChange}
      onPressNew={props.onPressNew}
      selectedTeacher={props.selectedTeacher}
      setSelectedTeacher={props.setSelectedTeacher}
      getGroupName={getGroupName}
      getTeacherName={getTeacherName}
      getStudentName={getStudentName}
      isLast={props.isLast}
      displayLoading={props.displayLoading}
      deleteStudent={props.deleteStudent}
      data={props.data}
      teachers={teachers}
      groups={groups}
      isAdmin={props.isAdmin}
    />
  );
};

export default Container;
