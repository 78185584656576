import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TestResultsView from './view';
import Icon from '../../components/icon/Icon';
import { TestService } from '../../services';
import { getId } from '../../core/functions';
import moment from 'moment';
import { setScroll } from '../../store/reducers/appState-reducer';

const TestResultsContainer = props => {
  const history = useHistory();
  const students = useSelector(store => store.students);
  const [tests, setTests] = useState(history.location.state.tests);
  const [test, setTest] = useState(null);
  const [successTestId, setSuccessTestId] = useState(
    getId(history.location.pathname),
  );
  const [successTest, setSuccessTest] = useState(
    tests.find(el => el.successTestId === successTestId),
  );
  const [studentName, setStudentName] = useState();
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [attempts, setAttempts] = useState(
    tests
      .filter(el => el.testId === successTest.testId)
      .sort(function (a, b) {
        return moment(a.changeTime) - moment(b.changeTime);
      }),
  );
  const dispatch = useDispatch();

  const getStudentName = async () => {
    const student = students.find(el => el.studentId === successTest.studentId);
    setStudentName(`${student?.name} ${student?.lastName}`);
  };

  const getTest = async testId => {
    const data = await TestService.getTest(testId);
    setTest(data);
  };

  const isTestDataDefined = () => {
    return test !== undefined && successTest !== undefined;
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  useEffect(() => {
    getTest(successTest.testId);
  }, []);

  useEffect(() => {
    getStudentName(successTest.studentId);
  }, []);

  useEffect(() => {
    setSuccessTest(attempts.find(el => el.successTestId === successTestId));
  }, [successTestId]);

  useEffect(() => {
    setDataLoaded((test && successTest && studentName) || !isTestDataDefined());
  }, [test, successTest, studentName]);

  const getAnswerStatus = (questionNumber, answerNumber) => {
    let status = '';
    const question = test.questions.find(el => el.number === questionNumber);
    const answer = question.answers.find(el => el.number === answerNumber);
    let userAnswer =
      successTest.testResult.find(el => el.questionNumber === questionNumber)
        ?.answer ||
      successTest.testResult.find(el => el.questionNumber === questionNumber)
        ?.answers;
    if (!Array.isArray(userAnswer)) {
      userAnswer = [userAnswer];
    }

    if (answer.right && userAnswer.includes(answer.number)) {
      status = 'right-chosen';
    } else if (answer.right && !userAnswer.includes(answer.number)) {
      status = 'right-not-chosen';
    } else if (!answer.right && userAnswer.includes(answer.number)) {
      status = 'wrong-chosen';
    } else {
      status = 'wrong-not-chosen';
    }

    return status;
  };

  const renderAnswerStyle = (questionNumber, answerNumber) => {
    let style = ' answer-container';
    const status = getAnswerStatus(questionNumber, answerNumber);

    if (status === 'right-chosen') {
      style += ' answer-right';
    }

    if (status === 'wrong-chosen' || status === 'right-not-chosen') {
      style += ' answer-wrong';
    }

    return style;
  };

  const renderIcon = (questionNumber, answerNumber) => {
    const status = getAnswerStatus(questionNumber, answerNumber);

    if (status === 'right-chosen') {
      return <Icon name="check" strokeWidth={3} color="#7EB608" />;
    }

    if (status === 'wrong-chosen') {
      return <Icon name="wrong" strokeWidth={3} color="#FF6F47" />;
    }

    if (status === 'right-not-chosen') {
      return <Icon name="check" strokeWidth={3} color="#FF6F47" />;
    }
  };

  return (
    <TestResultsView
      renderAnswerStyle={renderAnswerStyle}
      test={test}
      successTest={successTest}
      renderIcon={renderIcon}
      isDataLoaded={isDataLoaded}
      studentName={studentName}
      isTestDataDefined={isTestDataDefined}
      attempts={attempts}
      setSuccessTestId={setSuccessTestId}
      successTestId={successTestId}
    />
  );
};

export default TestResultsContainer;
