import React from 'react';

import View from './view';

const Container = ({
  renderEmpty = () => {},
  deleteTest = () => {},
  shareTests = () => {},
  renderControl,
  data = [],
  type,
  last = false,
  paginate = () => {},
  loading = false,
}) => {
  return (
    <View
      renderControl={renderControl}
      last={last}
      type={type}
      paginate={paginate}
      loading={loading}
      renderEmpty={renderEmpty}
      deleteTest={deleteTest}
      shareTests={shareTests}
      data={data}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default Container;
