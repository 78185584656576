const SET_MATCHUP = 'SET_MATCHUP';

const initialState = null;

const matchupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHUP:
      return action.matchup;
    default:
      return state;
  }
};

export const setMatchup = matchup => {
  return dispatch => {
    dispatch({ type: SET_MATCHUP, matchup });
  };
};

export default matchupReducer;
