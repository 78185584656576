import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';
import firebase from 'firebase';
import { deleteMaterial, getMaterials, updateMaterial } from '../../core/firebase';

export default {
  async uploadFile(file, filename, progress, sendRequest) {
    try {
      var uploadRef = await firebase
        .storage()
        .ref(`material/${new Date().getTime()}${filename}`);
      const result = uploadRef.put(file.blob, { cacheControl: 'public' }).on(
        'state_changed',
        snapshot => {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (percent === 100) {
            progress(null);
          }
          progress(percent);
        },
        error => {
          throw new Error('Ошибка');
        },
        snapshot => {
          uploadRef.getDownloadURL().then(downloadURL => {
            sendRequest(downloadURL);
          });
        },
      );
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async getMaterials(teacherId, page = 1, size = 40) {
    try {
      const result = await apiGet('/materials', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMaterial(materialId) {
    try {
      const result = await apiGet('/material', {
        params: { materialId },
      });
      return { data: result, status: result.status };
    } catch (error) {
      return error.response.data;
    }
  },
  async updateMaterial(body) {
    try {
      const result = await apiPut('/material', body);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createMaterial(body) {
    try {
      const result = await apiPost('/material', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteMaterial(materialId) {
    try {
      const result = await apiDelete('/material', { materialId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async removeMaterialFromGroup(body) {
    try {
      const result = await apiDelete('/material/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async removeMaterialFromPersonal(body) {
    try {
      const result = await apiDelete('/material/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addMaterialToGroup(body) {
    try {
      const result = await apiPost('/material/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addMaterialToPersonal(body) {
    try {
      const result = await apiPost('/material/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
