import React from 'react';

const Container = props => {
  const styles = {
    container: {
      width: '100%',
      paddingRight: 18,
      paddingLeft: 18,
      '@media (max-width: 1240)': {
        width: '1240px',
      },
      maxHeight: '100%',
      flex: 'auto 1 1',
    },
  };
  return <div style={styles.container}>{props.children}</div>;
};

export default Container;
