import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import View from './view';
import { useHistory } from 'react-router-dom';
import {
  showSuccessNotification,
  showErrorNotification,
} from '../../core/errorsController';

const registerDefaultValue = {
  name: '',
  lastName: '',
  patronymic: '',
  email: '',
  secondPassword: '',
  password: '',
  previousConviction: false,
};

const Registration = props => {
  const history = useHistory();
  const [inviteCode, setInviteCode] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const sendRestoreEmail = async () => {
    setLoading(true);

    const handlePasswordResetError = errorCode => {
      switch (errorCode) {
        case 'auth/invalid-email':
          return 'Проверьте правильность введенной почты';
        case 'auth/user-not-found':
          return 'Пользователя с таким почтовым адресом не существует';
        default:
          return 'Возникла непредвиденная ошибка, попробуйте позже';
      }
    };

    try {
      await firebase.auth().sendPasswordResetEmail(email);
      showSuccessNotification(
        `Ссылка для восстановления пароля была отправлена на почтовый адрес ${email}`,
      );
    } catch (error) {
      showErrorNotification(handlePasswordResetError(error.code));
    }
    setLoading(false);
  };

  return (
    <View
      loading={loading}
      inviteCode={inviteCode}
      setInviteCode={setInviteCode}
      sendRestoreEmail={sendRestoreEmail}
      email={email}
      setEmail={setEmail}
    />
  );
};

export default Registration;
