import React from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import './style.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/logo/Logo';

const Auth = props => (
  <div className="loginContainer">
    <div className="half left">
      <Logo />
      <div className="loginTitle">Войдите в свой аккаунт</div>
      <Input
        type="email"
        title="Email"
        className="registration-input"
        value={props.email}
        placeholder="Email"
        onChange={e => props.setEmail(e.target.value)}
      />
      <Input
        type="password"
        title="Пароль"
        className="registration-input"
        value={props.password}
        placeholder="Пароль"
        onChange={e => props.setPassword(e.target.value)}
      />
      <Button
        loading={props.loading}
        onClick={() => props.submit()}
        textButton="Войти"
      />
      <NavLink to="/registration" className="moveToRegistration">
        Регистрация по инвайт-коду
      </NavLink>
      <NavLink to="/restore" className="moveToRegistration">
        Забыли пароль?
      </NavLink>
    </div>
    <div className="half right">
      <div
        style={{
          backgroundImage: `url('${require('../../assets/pictures/authPicture.png')}')`,
        }}
        alt="Языковая студия Nota5"
      />
    </div>
  </div>
);

export default Auth;
