import React from 'react';

import { NavLink } from 'react-router-dom';
import { translate } from '../../core/functions';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      <NavLink
        to={`/${data.link || 'personal'}/${data.id}`}
        className="box box-1 number">
        {data.index + 1}
      </NavLink>
      {props.type !== 'teacher' ? (
        <NavLink
          to={`/${data.link || 'personal'}/${data.id}`}
          className="box box-3 name">
          {data.teacherName}
        </NavLink>
      ) : null}
      <NavLink
        to={`/${data.link || 'personal'}/${data.id}`}
        className="box box-4">
        {data.studentName}
      </NavLink>
      <NavLink
        to={`/${data.link || 'personal'}/${data.id}`}
        className="box box-5">
        {data?.level ? data.level : '-'}
      </NavLink>
      <NavLink
        to={`/${data.link || 'personal'}/${data.id}`}
        className="box box-6">
        {data.performance.toFixed(2)}
      </NavLink>
      <NavLink
        to={`/${data.link || 'personal'}/${data.id}`}
        className="box box-7">
        {data.fullQuestionsSuccess}/{data.fullQuestionsCount}
      </NavLink>
      <NavLink to={`teachers/${data.teacherId}`} className="box box-8">
        {moment(data.createdTime).format('DD MMMM YYYY г.')}
      </NavLink>
      {props.type !== 'teacher' ? (
        <div className="box box-9">
          {props.renderControl ? (
            props.renderControl(data)
          ) : (
            <Button
              type="secondary"
              small
              textButton="Удалить"
              onClick={() => {
                data.deleteGroup(data.id);
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );

  return (
    <div className="table-wrapper">
      <div onScroll={props.paginate} className="table classes-table">
        <div className="table-header table-line">
          <div className="box box-1">№</div>
          {props.type !== 'teacher' ? (
            <div className="box box-3">Преподаватель</div>
          ) : null}
          <div className="box box-4">Студент</div>
          <div className="box box-5">Уровень</div>
          <div className="box box-6">Успеваемость</div>
          <div className="box box-7">Прогресс</div>
          <div className="box box-8">Дата начала занятий</div>
          {props.type !== 'teacher' ? (
            <div className="box box-9">Управление</div>
          ) : null}
        </div>
        <div className="table-list">
          {props.data.length
            ? props.data.map((personal, index) => (
                <TableLine
                  link={props.link}
                  index={index}
                  key={index}
                  level={personal?.level}
                  performance={personal.performance}
                  progress={personal.progress}
                  fullQuestionsCount={personal.fullQuestionsCount}
                  fullQuestionsSuccess={personal.fullQuestionsSuccess}
                  createdTime={personal.createdTime}
                  teacherName={props.getTeacherName(personal.teacherId)}
                  studentName={props.getStudentName(personal.studentId)}
                  teacherId={personal.teacherId}
                  id={personal.classId}
                  deleteGroup={props.deleteGroup}
                />
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
    </div>
  );
};

export default UsersTableView;
