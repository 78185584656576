import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { store } from 'react-notifications-component';
import { InvitesService, TeachersService } from '../../services';
import View from './view';
import { setUserData } from '../../store/reducers/user-reducer';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorNotification } from '../../core/errorsController';
import { cloneDeep } from 'lodash';

const registerDefaultValue = {
  name: '',
  lastName: '',
  patronymic: '',
  email: '',
  secondPassword: '',
  password: '',
};

const educationItem = {
  institutionName: '',
  startYear: '',
  endYear: '',
  dyplomaSeries: '',
  dyplomaNumber: '',
  dyplomaScan: '',
};

const Registration = props => {
  const [tab, setTab] = useState(1);
  const history = useHistory();
  const [inviteCode, setInviteCode] = useState('');
  const userData = useSelector(state => state.user);
  const [inviteCodeRole, setInviteCodeRole] = useState(null);
  const [inviteCodeSuccess, setInviteCodeSuccess] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [registerData, setRegisterData] = useState(
    cloneDeep(registerDefaultValue),
  );

  const dispatch = useDispatch();

  const submit = async () => {
    try {
      setLoading(true);
      const temp = {
        ...registerData,
        education: [
          {
            dyplomaNumber: registerData.firstDyplomaNumber,
            dyplomaSeries: registerData.firstDyplomaSeries,
          },
        ],
      };
      delete temp.firstDyplomaNumber;
      delete temp.firstDyplomaSeries;
      delete temp.secondPassword;
      const res = await TeachersService.createTeacher({
        data: temp,
        code: inviteCode,
      });
      if (res) {
        const userData = await getUserData();
        userData.firebase = await firebase.auth().currentUser;
        dispatch(setUserData(userData));
        history.push('/');
        setLoading(false);
      } else {
        if (res.code === 'auth/email-already-in-use') {
          showErrorNotification('Данный email уже привязан к другой записи');
        }
        setLoading(false);
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const getUserData = async (uid = null) => {
    const res = await TeachersService.getUserData(uid);
    return res;
  };

  const handleRegisterData = (field, value) => {
    const temp = { ...registerData };
    temp[field] = value;
    setRegisterData(temp);
  };

  const handleTab = next => {
    if (next) {
      setTab(prevState => prevState + 1);
    } else {
      setTab(prevState => prevState - 1);
    }
  };

  const checkCode = async () => {
    setLoading(true);
    try {
      const res = await InvitesService.checkInviteCode(inviteCode);
      setInviteCodeSuccess(true);
      setInviteCodeRole(res.role);
      handleTab(true);
      setLoading(false);
    } catch (error) {
      let message;
      if (error.errorCode === 'missing-code') {
        message = 'Код не введен';
      }
      if (error.errorCode === 'not-found-invite-code') {
        message = 'Код не найден';
      }
      if (error.errorCode === 'not-active-invite-code') {
        message = 'Код больше не активен';
      }
      showErrorNotification(message);
      setLoading(false);
    }
  };

  const handlePasswordInput = e => {
    const keyCode = e.keyCode;
    const key = e.key;
    if (!/[a-zA-Z0-9 ]/.test(key) && keyCode !== 8) {
      e.preventDefault();
    }
  };

  const handleDyplomaInput = e => {
    const keyCode = e.keyCode;
    const key = e.key;
    if (!/[A-Za-zА-Яа-яЁё0-9 ]/.test(key) && keyCode !== 8) {
      e.preventDefault();
    }
  };

  return (
    <View
      inviteCode={inviteCode}
      setInviteCode={setInviteCode}
      inviteCodeSuccess={inviteCodeSuccess}
      checkCode={checkCode}
      handleTab={handleTab}
      registerData={registerData}
      submit={submit}
      name={name}
      handleRegisterData={handleRegisterData}
      setName={setName}
      loading={loading}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      tab={tab}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      handlePasswordInput={handlePasswordInput}
      handleDyplomaInput={handleDyplomaInput}
    />
  );
};

export default Registration;
