/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Profiler } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getId,
  copyToClipboard,
  getTeachersFormattedName,
} from '../../core/functions';
import { useHistory } from 'react-router-dom';
import View from './view';
import {
  GroupsService,
  InvitesService,
  MaterialsService,
  GamesService,
} from '../../services';
import Loader from '../../components/loader/Loader';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { cloneDeep } from 'lodash';
import { setScroll } from '../../store/reducers/appState-reducer';
import { setGroups } from '../../store/reducers/groups-reducer';

const paginationInitials = {
  students: {
    page: 1,
    last: false,
  },
  materials: {
    page: 1,
    last: false,
  },
  tests: {
    page: 1,
    last: false,
  },
  games: {
    flashcards: {
      page: 1,
      last: false,
    },
    matchup: {
      page: 1,
      last: false,
    },
    anagrams: {
      page: 1,
      last: false,
    },
    categorize: {
      page: 1,
      last: false,
    },
  },
  events: {
    page: 1,
    last: false,
  },
  payments: {
    page: 1,
    last: false,
  },
};

const TasksContainer = props => {
  const teachers = useSelector(state => state.teachers);
  const [groupData, setGroupData] = useState({});
  const [invites, setInvites] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentsLoading, setStudentsLoading] = useState(false);
  const [tabId, setTabId] = useState(1);
  const [gamesTabId, setGamesTabId] = useState(1);
  const [groupId, setGroupId] = useState(null);
  const [tests, setTests] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [flashcards, setFlashcards] = useState([]);
  const [matchup, setMatchup] = useState([]);
  const [anagrams, setAnagrams] = useState([]);
  const [categorize, setCategorize] = useState([]);
  const [events, setEvents] = useState([]);
  const [payments, setPayments] = useState([]);
  const [newPayment, setNewPayment] = useState(null);
  const [testsLoading, setTestsLoading] = useState(false);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [flashcardsLoading, setFlashcardsLoading] = useState(false);
  const [matchupLoading, setMatchupLoading] = useState(false);
  const [anagramsLoading, setAnagramsLoading] = useState(false);
  const [categorizeLoading, setCategorizeLoading] = useState(false);
  const [paginationStatus, setPaginationStatus] = useState(
    cloneDeep(paginationInitials),
  );
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createPaymentLoading, setCreatePaymentLoading] = useState(false);
  const [deleteTargetStudent, setDeleteTargetStudent] = useState(null);
  const [deleteStudentsLoading, setDeleteStudentsLoading] = useState(false);
  const [isTeacherChanged, setIsTeacherChanged] = useState(false);
  const [changeTeacherLoading, setChangeTeacherLoading] = useState(false);
  const [deletingStudents, setDeletingStudents] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showPaymentDeleteModal, setShowPaymentDeleteModal] = useState(false);
  const [paymentsList, setPaymentsList] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [selectedPaymentsList, setSelectedPaymentsList] = useState(null);
  const [deletePaymentLoading, setDeletePaymentLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(state => state.user).role === 'admin';
  const groups = useSelector(state => state.groups);

  useEffect(() => {
    dispatch(setScroll(false));
  }, []);

  const selectTeacher = teacherId => {
    setSelectedTeacher(teacherId);
    setIsTeacherChanged(!(groupData.teacherId === teacherId));
  };

  useEffect(() => {
    setGroupId(getId(history.location.pathname));
    getCodes('student', getId(history.location.pathname));
    getPaymentsList(getId(history.location.pathname));
  }, [history.location.pathname]);

  const showDeleteStudents = () => {
    setDeletingStudents(students.filter(el => !el.active));
    manipulateModal(true);
  };

  const manipulateModal = value => {
    setTimeout(() => {
      setShowModal(value);
      if (!value) {
        setDeletingStudents([]);
      }
    }, 0);
  };

  const fetchData = (_tabId = tabId, _gamesTabId = gamesTabId) => {
    switch (_tabId) {
      case 1:
        getStudents(groupId);
        break;
      case 2:
        getMaterials(groupId);
        break;
      case 3:
        getTests(groupId);
        break;
      case 4:
        switch (_gamesTabId) {
          case 1:
            getFlashcards(groupId || getId(history.location.pathname));
            break;
          case 2:
            getMatchup(groupId || getId(history.location.pathname));
            break;
          case 3:
            getAnagrams(groupId || getId(history.location.pathname));
            break;
          case 4:
            getCategorize(groupId || getId(history.location.pathname));
            break;
          default:
            return;
        }
        break;
      case 5:
        getEvents(groupId);
        break;
      case 6:
        getPayments(groupId);
        break;
      default:
        return;
    }
  };

  const selectTabId = _tabId => {
    setTabId(_tabId);
    setGamesTabId(1);
    fetchData(_tabId, 1);
  };

  const selectGamesTabId = _gamesTabId => {
    setGamesTabId(_gamesTabId);
    fetchData(tabId, _gamesTabId);
  };

  useEffect(() => {
    if (groupId) {
      getGroupData();
      setPaginationStatus(cloneDeep(paginationInitials));
      getStudents(groupId);
    }
  }, [groupId]);

  const getGroupData = async () => {
    try {
      const result = await GroupsService.getGroupData(groupId);
      setGroupData(result);
      setSelectedTeacher(result.teacherId);
      setIsTeacherChanged(false);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const rewriteTeacher = async () => {
    setChangeTeacherLoading(true);
    try {
      const result = await GroupsService.updateGroupData(groupData.groupId, {
        teacherId: selectedTeacher,
      });
      setGroupData(result);
      setSelectedTeacher(result.teacherId);
      dispatch(
        setGroups(
          groups.map((el, index) => {
            if (el.groupId === result.groupId) {
              return { ...result };
            }
            return el;
          }),
        ),
      );
      setIsTeacherChanged(false);
      showSuccessNotification('Учитель успешно изменен');
    } catch (err) {
      showErrorNotification(err.message);
    }
    setChangeTeacherLoading(false);
  };

  const handlePaginationStatus = (
    tab,
    page = 1,
    last = false,
    gameTab = false,
  ) => {
    const temp = { ...paginationStatus };
    if (gameTab) {
      temp[tab][gameTab].page = page;
      temp[tab][gameTab].last = last;
    } else {
      temp[tab].page = page;
      temp[tab].last = last;
    }
    setPaginationStatus(temp);
  };

  const getStudents = async (id = groupId) => {
    if (id && !paginationStatus.students.last && !studentsLoading) {
      setStudentsLoading(true);
      try {
        const result = await GroupsService.getStudents(
          id,
          paginationStatus.students.page,
        );
        handlePaginationStatus(
          'students',
          paginationStatus.students.page + 1,
          result.last,
        );
        setStudents([
          ...students,
          ...result.data.map(el => {
            return { ...el, has: true, active: true };
          }),
        ]);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setStudentsLoading(false);
    }
  };

  const getMaterials = async (id = groupId) => {
    if (id && !paginationStatus.materials.last && !materialsLoading) {
      setMaterialsLoading(true);
      try {
        const result = await GroupsService.getMaterials(
          id,
          paginationStatus.materials.page,
        );
        setMaterials([
          ...materials,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'materials',
          paginationStatus.materials.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMaterialsLoading(false);
    }
  };

  const getEvents = async (id = groupId) => {
    if (id && !paginationStatus.events.last && !eventsLoading) {
      setEventsLoading(true);
      try {
        const result = await GroupsService.getEvents(
          id,
          paginationStatus.events.page,
        );
        setEvents([
          ...events,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'events',
          paginationStatus.events.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setEventsLoading(false);
    }
  };

  const getPayments = async (id = groupId, force) => {
    if (id && !paginationStatus.payments.last && !paymentsLoading) {
      setPaymentsLoading(true);
      try {
        const result = await GroupsService.getPayments(
          id,
          paginationStatus.payments.page,
        );
        setPayments(
          force
            ? result.data.map(item => {
                return { ...item, has: true, active: true };
              })
            : [
                ...payments,
                ...result.data.map(item => {
                  return { ...item, has: true, active: true };
                }),
              ],
        );
        handlePaginationStatus(
          'payments',
          paginationStatus.payments.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setPaymentsLoading(false);
    }
  };

  const getTests = async (id = groupId) => {
    if (id && !paginationStatus.tests.last && !testsLoading) {
      setTestsLoading(true);
      try {
        const result = await GroupsService.getTests(
          id,
          paginationStatus.tests.page,
        );
        setTests([
          ...tests,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'tests',
          paginationStatus.tests.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setTestsLoading(false);
    }
  };

  const deleteStudents = async () => {
    setDeleteStudentsLoading(true);
    try {
      const result = await GroupsService.deleteStudents(
        groupData.groupId,
        deletingStudents.map(el => el.studentId),
      );
      setStudents(old => {
        return old.filter(
          el => !deletingStudents.find(item => item.studentId === el.studentId),
        );
      });
      showSuccessNotification('Студенты были удалены');
      setShowModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setDeleteStudentsLoading(false);
  };

  const getFlashcards = async (id = groupId) => {
    if (id && !paginationStatus.games.flashcards.last && !flashcardsLoading) {
      setFlashcardsLoading(true);
      try {
        const result = await GroupsService.getFlashCards(
          id,
          paginationStatus.games.flashcards.page,
        );
        setFlashcards([
          ...flashcards,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.flashcards.page + 1,
          result.last,
          'flashcards',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setFlashcardsLoading(false);
    }
  };

  const getAnagrams = async (id = groupId) => {
    if (id && !paginationStatus.games.anagrams.last && !anagramsLoading) {
      setAnagramsLoading(true);
      try {
        const result = await GroupsService.getAnagrams(
          id,
          paginationStatus.games.anagrams.page,
        );
        setAnagrams([
          ...anagrams,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.anagrams.page + 1,
          result.last,
          'anagrams',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setAnagramsLoading(false);
    }
  };

  const getCategorize = async (id = groupId) => {
    if (id && !paginationStatus.games.categorize.last && !categorizeLoading) {
      setCategorizeLoading(true);
      try {
        const result = await GroupsService.getCategorizes(
          id,
          paginationStatus.games.categorize.page,
        );
        setCategorize([
          ...categorize,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.categorize.page,
          result.last,
          'categorize',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setCategorizeLoading(false);
    }
  };

  const getMatchup = async (id = groupId) => {
    if (id && !paginationStatus.games.matchup.last && !matchupLoading) {
      setMatchupLoading(true);
      try {
        const result = await GroupsService.getMatchups(
          id,
          paginationStatus.games.matchup.page,
        );
        setMatchup([
          ...matchup,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.matchup.page,
          result.last,
          'matchup',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMatchupLoading(false);
    }
  };

  const getTeacherName = id => {
    if (!teachers.length) {
      return <Loader />;
    }
    return getTeachersFormattedName(id, teachers);
  };

  const shareGames = async () => {
    let removeItems;
    switch (gamesTabId) {
      case 1:
      default:
        removeItems = flashcards
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setFlashcards(old => old.filter(el => el.active));
        break;
      case 2:
        removeItems = matchup
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setMatchup(old => old.filter(el => el.active));
        break;
      case 3:
        removeItems = anagrams
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setAnagrams(old => old.filter(el => el.active));
        break;
      case 4:
        removeItems = categorize
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setCategorize(old => old.filter(el => el.active));
        break;
    }
    try {
      setDeleteLoading(true);
      if (removeItems.length) {
        const result = await GamesService.removeGames(gamesTabId, {
          groupId: groupId,
          categoryId: [...removeItems],
        });
        showSuccessNotification('Игры были успешно удалены');
        if (result.errorCode) {
          throw new Error('Не удалось удалить игры');
        }
      }
      setDeleteLoading(false);
      setShowDeleteModal(false);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };
  const shareTests = async () => {
    setTestsLoading(true);
    try {
      const removeTests = tests
        .filter(item => item.active === false)
        .map(item => item.testId);
      if (removeTests.length) {
        setDeleteLoading(true);
        const result = await GroupsService.removeTests({
          groupId: groupId,
          testId: [...removeTests],
        });
        setTests(tests.filter(el => !removeTests.includes(el.testId)));
        if (result.errorCode) {
          throw new Error('Не удалось удалить тесты');
        }
        setDeleteLoading(false);
        setShowDeleteModal(false);
        showSuccessNotification('Тесты были успешно удалены.');
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
    setTestsLoading(false);
  };

  const createPayment = async () => {
    setCreatePaymentLoading(true);
    try {
      const response = await GroupsService.requestPayment(
        groupId,
        newPayment.fromDate,
        newPayment.toDate,
        newPayment.price.endsWith('.')
          ? +newPayment.price + '0'
          : +newPayment.price,
      );
      setPayments([]);
      handlePaginationStatus('payments', 1, false);
      setShowPaymentModal(false);
      showSuccessNotification('Оплаты были успешно выставленны');
      getPaymentsList();
      setTimeout(() => getPayments(groupId, true), 1);
    } catch (e) {
      showErrorNotification(e.message);
    }
    setCreatePaymentLoading(false);
  };

  const deletePayment = async () => {
    setDeletePaymentLoading(true);
    try {
      const response = await GroupsService.deletePayment(
        groupId,
        selectedPaymentsList.fromDate,
      );
      setPayments([]);
      handlePaginationStatus('payments', 1, false);
      setShowPaymentDeleteModal(false);
      showSuccessNotification('Оплаты были удалены');
      setTimeout(() => getPayments(groupId, true), 1);
    } catch (e) {
      showErrorNotification(e.message);
    }
    setDeletePaymentLoading(false);
  };

  const getPaymentsList = async (_groupId = groupId) => {
    try {
      const response = await GroupsService.getPaymentsList(_groupId);
      setPaymentsList(response);
    } catch (e) {
      showErrorNotification(e.message);
    }
  };

  const shareMaterials = async () => {
    setMaterialsLoading(true);
    try {
      const removeMaterials = materials
        .filter(item => item.active === false)
        .map(item => item.materialId);
      if (removeMaterials.length) {
        setDeleteLoading(true);
        const result = await MaterialsService.removeMaterialFromGroup({
          groupId: groupId,
          materialId: [...removeMaterials],
        });
        setMaterials(
          materials.filter(el => !removeMaterials.includes(el.materialId)),
        );
        if (result.errorCode) {
          throw new Error('Не удалось удалить материалы');
        }
        setDeleteLoading(false);
        showSuccessNotification('Материалы были успешно удалены.');
        setShowDeleteModal(false);
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
    setMaterialsLoading(false);
  };

  const handleMaterial = (has, index, value) => {
    const temp = [...materials];
    temp[index].active = value;
    setMaterials(temp);
  };

  const handleTest = (has, index, value) => {
    const temp = [...tests];
    temp[index].active = value;
    setTests(temp);
  };

  const handleStudents = (has, index, value) => {
    const temp = [...students];
    temp[index].active = value;
    setStudents(temp);
  };

  const deleteItems = () => {
    switch (tabId) {
      case 2:
        shareMaterials();
        break;
      case 3:
        shareTests();
        break;
      case 4:
        shareGames();
        break;
    }
  };

  const handleGame = (groupHas, index, value) => {
    switch (gamesTabId) {
      case 1:
      default:
        setFlashcards(old =>
          old.map((el, idx) => (index === idx ? { ...el, active: value } : el)),
        );
        break;
      case 2:
        setMatchup(old =>
          old.map((el, idx) => (index === idx ? { ...el, active: value } : el)),
        );
        break;
      case 3:
        setAnagrams(old =>
          old.map((el, idx) => (index === idx ? { ...el, active: value } : el)),
        );
        break;
      case 4:
        setCategorize(old =>
          old.map((el, idx) => (index === idx ? { ...el, active: value } : el)),
        );
        break;
    }
  };

  const getCodes = async (role, _groupId, action) => {
    try {
      const result = await InvitesService.getInvites(role, _groupId, action);
      setInvites([...result]);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const generateCode = async () => {
    try {
      const result = await InvitesService.getGroupInvite(groupId);
      showSuccessNotification(`Сгенерирован новый инвайт код: ${result.code}`);
      copyToClipboard(result.code);
      return result;
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const addNewCode = async () => {
    try {
      const code = await generateCode();
      setInvites(prevState => [...prevState, code]);
    } catch (error) {
      showErrorNotification(error.message);
    }
  };

  const handleContainerOnBottom = async () => {
    try {
      switch (tabId) {
        case 1:
        default:
          if (paginationStatus.students.last) {
            return;
          }
          await getStudents();
          break;
        case 2:
          if (paginationStatus.materials.last) {
            return;
          }
          await getMaterials();
          break;
        case 3:
          if (paginationStatus.tests.last) {
            return;
          }
          await getTests();
          break;
        case 4:
          switch (gamesTabId) {
            case 1:
            default:
              if (paginationStatus.games.flashcards.last) {
                return;
              }
              await getFlashcards();
              break;
            case 2:
              if (paginationStatus.games.matchup.last) {
                return;
              }
              await getMatchup();
              break;
            case 3:
              if (paginationStatus.games.anagrams.last) {
                return;
              }
              await getAnagrams();
              break;
            case 4:
              if (paginationStatus.games.categorize.last) {
                return;
              }
              await getCategorize();
              break;
          }
          break;
        case 5:
          if (paginationStatus.events.last) {
            return;
          }
          await getEvents();
          break;
        case 6:
          if (paginationStatus.payments.last) {
            return;
          }
          getPayments();
          break;
      }
    } catch (error) {
      showErrorNotification('Произошла ошибка при получении данных');
    }
  };

  return (
    <View
      teachers={teachers}
      handleContainerOnBottom={handleContainerOnBottom}
      getTeacherName={getTeacherName}
      deletingStudents={deletingStudents}
      deleteStudentsLoading={deleteStudentsLoading}
      setGamesTabId={selectGamesTabId}
      setTabId={selectTabId}
      gamesTabId={gamesTabId}
      tabId={tabId}
      invites={invites}
      addNewCode={addNewCode}
      setShowModal={manipulateModal}
      showModal={showModal}
      changeTeacherLoading={changeTeacherLoading}
      setShowPaymentModal={setShowPaymentModal}
      showPaymentModal={showPaymentModal}
      deletePaymentLoading={deletePaymentLoading}
      delete={deleteItems}
      handleTest={handleTest}
      handleMaterial={handleMaterial}
      handleStudents={handleStudents}
      showDeleteStudents={showDeleteStudents}
      handleGame={handleGame}
      shareTests={shareTests}
      shareMaterials={shareMaterials}
      shareGames={shareGames}
      tests={tests}
      testsLoading={testsLoading}
      materials={materials}
      materialsLoading={materialsLoading}
      showPaymentDeleteModal={showPaymentDeleteModal}
      setShowPaymentDeleteModal={setShowPaymentDeleteModal}
      paymentsList={paymentsList}
      setPaymentsList={setPaymentsList}
      confirmDelete={confirmDelete}
      setConfirmDelete={setConfirmDelete}
      setSelectedPaymentsList={setSelectedPaymentsList}
      selectedPaymentsList={selectedPaymentsList}
      students={students}
      studentsLoading={studentsLoading}
      flashcards={flashcards}
      flashcardsLoading={flashcardsLoading}
      matchup={matchup}
      matchupLoading={matchupLoading}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      anagrams={anagrams}
      anagramsLoading={anagramsLoading}
      categorize={categorize}
      isTeacherChanged={isTeacherChanged}
      categorizeLoading={categorizeLoading}
      newPayment={newPayment}
      setNewPayment={setNewPayment}
      events={events}
      eventsLoading={eventsLoading}
      payments={payments}
      paymentsLoading={paymentsLoading}
      createPaymentLoading={createPaymentLoading}
      data={groupData}
      deleteStudents={deleteStudents}
      paginationStatus={paginationStatus}
      createPayment={createPayment}
      deleteLoading={deleteLoading}
      isAdmin={isAdmin}
      copyToClipboard={copyToClipboard}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={selectTeacher}
      rewriteTeacher={rewriteTeacher}
      deletePayment={deletePayment}
    />
  );
};

export default TasksContainer;
