import { apiGet, apiPost, apiPatch, apiPut, apiDelete } from '../api';
import firebase from 'firebase';
import { FIREBASE_CONFIG, getMaterials } from '../../core/firebase';
export default {
  async createTeacher(body) {
    try {
      const auth = await firebase
        .auth()
        .createUserWithEmailAndPassword(body.data.email, body.data.password)
        .catch(function (e) {
          if (
            e.message ===
            'The email address is already in use by another account.'
          ) {
            return e;
          }
        })
        .then(async () => {
          const result = await firebase
            .auth()
            .signInWithEmailAndPassword(body.data.email, body.data.password);
          localStorage.setItem('refreshToken', result.user.refreshToken);
        });
      if (auth && auth.code === 'auth/email-already-in-use') {
        return auth;
      }
      const token = await firebase.auth().currentUser.getIdToken();
      localStorage.setItem('token', token);
      const temp = { ...body };
      delete temp.data.password;
      const result = await apiPost('/teacher', temp);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getEvents(teacherId) {
    try {
      const result = await apiGet('/calendar/events', {
        params: { teacherId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getUserData(teacherId) {
    try {
      const result = await apiGet(
        '/teacher',
        teacherId
          ? { params: { teacherId } }
          : { params: { teacherId: firebase.auth().currentUser.uid } },
      );
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async refreshToken() {
    try {
      const result = await apiPost(
        '/token',
        {},
        {
          key: FIREBASE_CONFIG.API_KEY,
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem('refreshToken'),
        },
        { contentType: 'application/x-www-form-urlencoded' },
      );

      localStorage.setItem('token', result.data.id_token);
      localStorage.setItem('refreshToken', result.data.refresh_token);
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteTeacher(teacherId) {
    try {
      const result = await apiDelete('/teacher', { teacherId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTeacherData(teacherId) {
    try {
      const result = await apiGet('/teacher', { params: { teacherId } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTeachers() {
    try {
      const result = await apiGet('/teachers');

      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateTeacher(data, teacherId) {
    try {
      const result = await apiPut('/teacher', {
        data: data,
        teacherId: teacherId,
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMaterials(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/materials', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTests(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/tests', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGeneralTests(creatorId, page, size = 40) {
    try {
      const result = await apiGet('/test/english-level', {
        params: { creatorId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGroups(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/groups', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPersonals(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/classes', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getFlashCards(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/flashcards', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMatchup(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/matchup', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getAnagrams(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/anagrams', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCategorize(teacherId, page, size = 40) {
    try {
      const result = await apiGet('/teacher/categorize', {
        params: { teacherId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
