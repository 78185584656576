import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';

export default {
  async getAllPersonals(teacherId) {
    try {
      const result = await apiGet('/classes', { params: { teacherId } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPersonalData(classId) {
    try {
      const result = await apiGet('/class', { params: { classId } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteClass(classId) {
    try {
      const result = await apiDelete('/class', { classId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTeacherPersonals(uid) {
    try {
      const result = await apiGet(`/teacher/classs?teacherId=${uid}`);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updatePersonal(classId, data) {
    try {
      const result = await apiPut('/class', {
        classId,
        data,
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMaterials(classId, page = 1, size = 40) {
    try {
      const result = await apiGet('/class/materials', {
        params: { classId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPersonalsWithTestId(testId, teacherId = null) {
    try {
      const result = await apiGet('/test/classs', {
        params: { testId, teacherId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGeneralTests(classId, page = 1, size = 40) {
    try {
      const result = await apiGet('/class/tests/english-level', {
        params: { classId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTests(classId, page, size) {
    try {
      const result = await apiGet('/class/tests', {
        params: { classId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPersonalsWithMaterialId(materialId, teacherId = null) {
    try {
      const result = await apiGet('/material/classs', {
        params: { materialId, teacherId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedFlashcards(classId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('class/games/success', {
        params: { classId, page, size, type: 'flash_cards' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedMatchup(classId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('class/games/success', {
        params: { classId, page, size, type: 'matchUp' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedAnagrams(classId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('class/games/success', {
        params: { classId, page, size, type: 'anagram' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedCategorize(classId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('class/games/success', {
        params: { classId, page, size, type: 'categorize' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addTests(data) {
    try {
      const result = await apiPost('/test/assign', data);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async removeTests(data) {
    try {
      const result = await apiDelete('/test/assign', data);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async removeGeneralTests(data) {
    try {
      const result = await apiDelete('/class/tests/english-level', data);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getFlashCards(classId, page = 1, size = 40) {
    try {
      const result = await apiGet('/class/flashCards', {
        params: { classId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTransferedEvents(ids, page = 1, size = 40) {
    try {
      const result = await apiGet('/calendar/transfers', {
        params: { ...ids, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getEvents(ids, page = 1, size = 40) {
    try {
      const result = await apiGet('/calendar/events', {
        params: { ...ids, page, size, allTime: true },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMatchups(classId, page = 1, size = 40) {
    try {
      const result = await apiGet('/class/matchup', {
        params: { classId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getAnagrams(classId, page = 1, size = 40) {
    try {
      const result = await apiGet('/class/anagrams', {
        params: { classId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCategorizes(classId, page = 1, size = 40) {
    try {
      const result = await apiGet('/class/categorize', {
        params: { classId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedTests(classId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('/tests/success', {
        params: { classId, studentId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedGeneralTests(classId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('class/tests/english-level', {
        params: { classId, page, size, completed: true },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPersonalsWithGameId(route, categoryId) {
    try {
      const result = await apiGet(`/${route}/category/classs`, {
        params: { categoryId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
