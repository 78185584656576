import React from 'react';

import View from './view';

const Container = ({
  handleGame = () => {},
  deleteGame = () => {},
  deleteGames = () => {},
  shareGames = () => {},
  data = [],
  tabId = 1,
  loading = false,
  renderEmpty = () => {},
  renderControl,
  last = false,
  paginate = () => {},
  type,
}) => {
  return (
    <View
      type={type}
      renderControl={renderControl}
      last={last}
      deleteGame={deleteGame}
      data={data}
      tabId={tabId}
      paginate={paginate}
      deleteGames={deleteGames}
      loading={loading}
      renderEmpty={renderEmpty}
      shareGames={shareGames}
    />
  );
};

export default Container;
