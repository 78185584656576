/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/auth';
import MainView from './MainView';
import StoreController from '../../core/storeController';
import {
  addNotification,
  clearNotifications,
} from '../../store/reducers/notifications-reducer';
import { getToken } from '../../core/firebase';
import { NotificationsService, TeachersService } from '../../services';
import { setUserData } from '../../store/reducers/user-reducer';
import { setEditing, setLoading } from '../../store/reducers/appState-reducer';
import { useHistory } from 'react-router';
import {
  showErrorNotification,
  showInfoNotification,
} from '../../core/errorsController';
import { BASE_URL } from '../../constants';
import { store } from '../../store';

const MainContainer = props => {
  const [inited, setInited] = useState(false);
  const userData = useSelector(state => state.user);
  const personals = useSelector(state => state.personals);
  const teachers = useSelector(state => state.teachers);
  const groups = useSelector(state => state.groups);
  const students = useSelector(state => state.students);
  const appState = useSelector(state => state.appState);
  const notifications = useSelector(state => state.notifications);
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const userToken = localStorage.getItem('token');
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationsLength = useSelector(
    state => state.notifications.data,
  )?.length;

  const confirmRedirect = async () => {
    await dispatch(setEditing(false));
    setShowModal(false);
    history.push(nextLocation);
  };

  useEffect(() => {
    readNotifications();
    dispatch(setEditing(false));
    dispatch(clearNotifications());
    dispatch(setLoading(true));
    if (userData && personals && teachers && groups && students) {
      setInited(true);
    }

    Promise.all(StoreController.init(userData.teacherId, userData.role)).then(
      async values => {
        setInited(true);
        dispatch(setLoading(false));
        try {
          const token = await getToken();
          if (userData?.fmcToken !== token) {
            const updatedTeacher = await TeachersService.updateTeacher(
              {
                fmcToken: token,
              },
              userData.teacherId,
            );
            dispatch(setUserData(updatedTeacher));
          }
        } catch {}
      },
    );

    window.addEventListener('beforeunload', showQuitPrompt);

    document.addEventListener('visibilitychange', toggleReadedNotifications);
    return () => {
      document.removeEventListener(
        'visibilitychange',
        toggleReadedNotifications,
      );
      readNotifications();
    };
  }, []);

  const showQuitPrompt = e => {
    if (store.getState().appState.isEditing) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const readNotifications = async () => {
    try {
      await NotificationsService.readNotifications(
        notifications.readed?.map(el => el?.pushId),
      );
      dispatch(clearNotifications());
    } catch {}
  };

  const toggleReadedNotifications = async force => {
    if (document.visibilityState === 'visible' || !force) {
      dispatch(setLoading(true));
      Promise.all([
        StoreController.getUnreadNotifications(),
        StoreController.getFirstPageReadNotifications(),
      ]).then(() => {
        dispatch(setLoading(false));
      });
    } else {
      readNotifications();
    }
  };

  return (
    <MainView
      showModal={showModal}
      setShowModal={setShowModal}
      nextLocation={nextLocation}
      setNextLocation={setNextLocation}
      confirmRedirect={confirmRedirect}
      inited={inited}
      appState={appState}
    />
  );
};

export default MainContainer;
