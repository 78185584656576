/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router';

import View from './view';
import { TestService, StorageService } from '../../services';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../core/errorsController';
import { getId } from '../../core/functions';
import { isEqual } from 'lodash';
import { setEditing, setScroll } from '../../store/reducers/appState-reducer';

const getSelectionMap = questions => {
  return questions.map(el => {
    return {
      questionSelectionControl: {},
      answers: el.answers.map(item => {
        return {
          inputSelectionControl: {},
          checkboxSelectionControl: {},
        };
      }),
    };
  });
};

const getNewSelection = (toggler, color) => {
  return {
    toggler,
    color,
  };
};

const grammarTestDummy = {
  title: 'Грамматика',
  showRight: false,
  multiplie: false,
  type: 'grammar',
  questions: [
    {
      question: '',
      number: '1',
      answers: [
        {
          number: '1',
          answer: '',
          right: false,
        },
        {
          number: '1',
          answer: '',
          right: false,
        },
      ],
    },
  ],
};

const vocabularyTestDummy = {
  title: 'Лексика',
  showRight: false,
  multiplie: false,
  type: 'vocabulary',
  questions: [
    {
      question: '',
      number: '1',
      answers: [
        {
          number: '1',
          answer: '',
          right: false,
        },
        {
          number: '1',
          answer: '',
          right: false,
        },
      ],
    },
  ],
};

const listeningTestDummy = {
  title: 'Аудирование',
  showRight: false,
  multiplie: false,
  type: 'listening',
  file: {},
  questions: [
    {
      question: '',
      number: '1',
      answers: [
        {
          number: '1',
          answer: '',
          right: false,
        },
        {
          number: '1',
          answer: '',
          right: false,
        },
      ],
    },
  ],
};

const phoneticsTestDummy = {
  title: 'Фонетика',
  showRight: false,
  multiplie: false,
  type: 'phonetics',
  questions: [
    {
      question: '',
      number: '1',
      answers: [
        {
          number: '1',
          answer: '',
          right: false,
        },
        {
          number: '1',
          answer: '',
          right: false,
        },
      ],
    },
  ],
};

const translationTestDummy = {
  title: 'Перевод',
  showRight: false,
  multiplie: false,
  type: 'translation',
  questions: [
    {
      question: '',
      number: '1',
      answers: [
        {
          number: '1',
          answer: '',
          right: false,
        },
        {
          number: '1',
          answer: '',
          right: false,
        },
      ],
    },
  ],
};

const INITIALS = {
  testInfo: {
    title: '',
    description: '',
    date: new Date(),
    type: 'common test',
  },
};

const GENERAL_INITIALS = {
  types: [],
};

const TasksContainer = props => {
  const userData = useSelector(state => state.user);
  const teachers = useSelector(state => state.teachers);
  const organizers = useSelector(state => state.personals);
  const students = useSelector(state => state.students);
  const [tests, setTests] = useState([]);
  const [outlineEmptyFiles, setOutlineEmptyFiles] = useState(false);
  const [testsLoading, setTestsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [haveErrors, setHaveErrors] = useState(false);
  const [editingMode, setEditingMode] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrganizer, setSelectedOrganizer] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [attachingTestLoading, setAttachingTestLoading] = useState(false);
  const [currentTestId, setCurrentTestId] = useState();
  const [testInfo, setTestInfo] = useState(INITIALS.testInfo);
  const [subtests, setSubtests] = useState(cloneDeep(GENERAL_INITIALS.types));
  const [deletingItem, setDeletingItem] = useState(null);
  const [selectedSubtestType, setSelectedSubtestType] = useState('grammar');
  const [selectionMap, setSelectionMap] = useState(
    GENERAL_INITIALS.types.map(el => getSelectionMap(el.questions)),
  );
  const [wrongTime, setWrongTime] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(state => state.user).role === 'admin';
  const isEditing = useSelector(state => state.appState.isEditing);
  const [deleteTestLoading, setDeleteTestLoading] = useState(false);
  const [createTestLoading, setCreateTestLoading] = useState(false);
  const [updateTestLoading, setUpdateTestLoading] = useState(false);
  const [nextLinkItem, setNextLinkItem] = useState(null);

  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const id = getId(history.location.pathname);
    if (id) {
      getTestFromLink(id);
    }
    getTests();
    const unsubscribe = history.listen(({ pathname }) => {
      setTests(old => {
        openTestToEdit(getId(pathname), old);
        return old;
      });
    });
    return unsubscribe;
  }, []);

  const makeEditingMode = () => {
    if (!isEditing) {
      dispatch(setEditing(true));
    }
  };

  const getTestFromLink = async testId => {
    try {
      const result = await TestService.getEnglishTest(testId);
      selectTestToEdit(result);
    } catch (err) {
      if (err.errorCode === 'test-not-found') {
        showErrorNotification('Тест не найден. Возможно, он был удален');
      } else {
        showErrorNotification(err.message);
      }
    }
  };

  const getTestDummy = type => {
    switch (type) {
      case 'vocabulary':
        return vocabularyTestDummy;
      case 'listening':
        return listeningTestDummy;
      case 'grammar':
        return grammarTestDummy;
      case 'translation':
        return translationTestDummy;
      case 'phonetics':
        return phoneticsTestDummy;
      default:
        return null;
    }
  };

  const handleTestInfoChange = async (field, value) => {
    makeEditingMode();
    setTestInfo({
      ...testInfo,
      [field]: value,
    });
  };

  const addTestToGeneral = () => {
    makeEditingMode();
    setSubtests(old => {
      return [...old, cloneDeep(getTestDummy(selectedSubtestType))];
    });
    setSelectionMap(old => {
      return [
        ...old,
        getSelectionMap(getTestDummy(selectedSubtestType).questions),
      ];
    });
  };

  const getTests = async () => {
    if (!isLoading && !isLast) {
      setIsLoading(true);
      try {
        const result = await TestService.getEnglishTests(page);
        setIsLast(result.last);
        setTests(old => [...old, ...result.data]);
        setPage(old => old + 1);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setIsLoading(false);
    }
  };

  const selectTestToEdit = test => {
    setCurrentTestId(test.testId);
    setTestInfo({
      id: test.testId,
      title: test.title,
      teacher: test.teacher,
      creatorId: test.creatorId,
      type: test.type,
      file: test.file,
      date: test.date,
      description: test.description,
    });
    setSubtests(test.tests);
    setSelectionMap(test.tests.map(el => getSelectionMap(el.questions)));
  };

  const openTestToEdit = (testId, testsList = tests) => {
    if (testId) {
      const test = testsList.find(item => item.testId === testId);

      if (test) {
        selectTestToEdit(test);
        setEditingMode(test.id);
      }
    } else {
      clearState();
    }
  };

  const handleGeneralTestInfoChange = (index, field, value) => {
    makeEditingMode();
    const temp = [...subtests];
    temp[index][field] = value;
    setSubtests(temp);
  };

  const handleAddAnswerGeneral = (index, questionIndex) => {
    makeEditingMode();
    const temp = [...subtests];
    const subtest = temp[index];
    if (subtest.questions[questionIndex].answers.length < 4) {
      subtest.questions[questionIndex].answers.push({
        answer: '',
        right: false,
      });
      setSubtests(temp);
      setSelectionMap(old => {
        const temp = [...old];
        temp[index][questionIndex].answers = [
          ...temp[index][questionIndex].answers,
          { inputSelectionControl: {}, checkboxSelectionControl: {} },
        ];
        return temp;
      });
    } else {
      showSuccessNotification('Максимум 4 варианта ответа');
    }
  };

  const handleGeneralAnswerTextChange = (
    index,
    e,
    questionIndex,
    answerIndex,
  ) => {
    makeEditingMode();
    const temp = [...subtests];
    const subtest = temp[index];
    subtest.questions[questionIndex].answers[answerIndex].answer =
      e.target.value;
    setSelectionMap(old => {
      const temp = [...old];
      old[index][questionIndex].answers[answerIndex].inputSelectionControl =
        null;
      return temp;
    });
    setSubtests(temp);
  };

  const handleGeneralFileChange = (index, file) => {
    const temp = [...subtests];
    const subtest = temp[index];
    subtest.file = file;
    setSubtests(temp);
  };

  const handleDeleteAnswer = (
    subtestIndex = deletingItem.subtestIndex,
    index = deletingItem.index,
    subIndex = deletingItem.subIndex,
  ) => {
    setSubtests(old => {
      const tempSubtests = [...old];
      const temp = old[subtestIndex].questions;
      const tempAnswers = [...temp[index].answers];
      if (tempAnswers.length > 2) {
        const newQuestionAnswers = tempAnswers.filter(
          (el, idx) => idx !== subIndex,
        );
        const newSelectionMap = [...selectionMap];
        newSelectionMap[subtestIndex][index].answers = newSelectionMap[
          subtestIndex
        ][index].answers.filter((el, idx) => idx !== subIndex);
        setSelectionMap(newSelectionMap);
        temp[index].answers = newQuestionAnswers;
      } else {
        if (tempAnswers[subIndex]?.answer) {
          tempAnswers[subIndex].answer = '';
          tempAnswers[subIndex].right = false;
        }
        temp[index].answers = tempAnswers;
      }
      tempSubtests[subtestIndex].questions = temp;
      return tempSubtests;
    });
    manipulateDeleteModal(false);
  };

  const removeAnswerHandle = (subtestIndex, index, subIndex) => {
    makeEditingMode();
    if (!subtests[subtestIndex].questions[index].answers[subIndex].answer) {
      handleDeleteAnswer(subtestIndex, index, subIndex);
    } else {
      setModalType('delete_answer');
      setDeletingItem({ subtestIndex, index, subIndex });
      manipulateDeleteModal(true);
    }
  };

  const handleGeneralAnswerRightChange = (
    index,
    value,
    questionIndex,
    answerIndex,
  ) => {
    makeEditingMode();
    const temp = [...subtests];
    const subtest = temp[index];
    subtest.questions[questionIndex].answers[answerIndex].right = value;
    if (
      subtest.questions[questionIndex].answers.filter(el => el.right).length >
        1 &&
      !subtest.multiplie
    ) {
      subtest.multiplie = true;
      showWarningNotification('Множественный выбор был включен');
    }
    setSelectionMap(old => {
      const temp = [...old];
      temp[index][questionIndex].answers = temp[index][
        questionIndex
      ].answers.map(el => {
        return {
          ...el,
          checkboxSelectionControl: null,
        };
      });
      return temp;
    });
    setSubtests(temp);
  };

  const addGeneralQuestionHandle = index => {
    makeEditingMode();
    const temp = [...subtests];
    const subtest = temp[index];
    subtest.questions.push({
      answers: [
        {
          answer: '',
          right: false,
        },
        {
          answer: '',
          right: false,
        },
      ],
    });
    setSelectionMap(old => {
      const temp = [...old];
      temp[index] = [
        ...temp[index],
        {
          questionSelectionControl: {},
          answers: [
            {
              inputSelectionControl: {},
              checkboxSelectionControl: {},
            },
            {
              inputSelectionControl: {},
              checkboxSelectionControl: {},
            },
          ],
        },
      ];
      return temp;
    });
    setSubtests(temp);
  };

  const deleteGeneralQuestion = (
    subtestIndex = deletingItem.subtestIndex,
    index = deletingItem.index,
  ) => {
    makeEditingMode();
    const temp = [...subtests];
    const subtest = temp[subtestIndex];
    if (subtest.questions.length > 1) {
      subtest.questions = subtest.questions.filter((el, idx) => idx !== index);
      setSelectionMap(old => {
        const temp = [...old];
        temp[subtestIndex] = temp[subtestIndex].filter(
          (el, idx) => idx !== index,
        );
        return temp;
      });
    } else {
      subtest.questions = [
        {
          question: '',
          answers: [
            {
              number: '1',
              answer: '',
              right: false,
            },
            {
              number: '2',
              answer: '',
              right: false,
            },
          ],
        },
      ];
    }
    setSubtests(temp);
    manipulateDeleteModal(false);
  };

  const removeGeneralQuestionHandle = (subtestIndex, index) => {
    makeEditingMode();
    if (checkQuestionEmpty(subtests[subtestIndex].questions[index])) {
      deleteGeneralQuestion(subtestIndex, index);
    } else {
      setModalType('delete_subtest_question');
      setDeletingItem({ subtestIndex, index });
      manipulateDeleteModal(true);
    }
  };

  const checkQuestionEmpty = item => {
    return !(item?.question || item?.answers.some(el => el.answer));
  };

  const checkQuestionEmptyStrict = item => {
    return !(item?.question || item?.answers.every(el => el.answer));
  };

  const checkQuestionAnswerable = item => {
    return item?.answers.some(el => el.right);
  };

  const handleGeneralQuestionTextChange = (subtestIndex, e, index) => {
    e.persist();
    makeEditingMode();
    const temp = [...subtests];
    const subtest = temp[subtestIndex];
    subtest.questions[index].question = e?.target?.value;
    setSelectionMap(old => {
      const temp = [...old];
      old[subtestIndex][index].questionSelectionControl = null;
      return temp;
    });
    setSubtests(temp);
  };

  const swapQuestionBlockUp = index => {
    const temp = [...subtests];
    temp[index - 1] = subtests[index];
    temp[index] = subtests[index - 1];
    const mapTemp = [...selectionMap];
    mapTemp[index - 1] = selectionMap[index];
    mapTemp[index] = selectionMap[index - 1];
    setSubtests(temp);
    setSelectionMap(mapTemp);
  };

  const swapQuestionBlockDown = index => {
    const temp = [...subtests];
    temp[index + 1] = subtests[index];
    temp[index] = subtests[index + 1];
    const mapTemp = [...selectionMap];
    mapTemp[index + 1] = selectionMap[index];
    mapTemp[index] = selectionMap[index + 1];
    setSubtests(temp);
    setSelectionMap(mapTemp);
  };

  const deleteQuestionBlock = (index = deletingItem.index) => {
    makeEditingMode();
    setSubtests(old => old.filter((el, idx) => idx !== index));
    setSelectionMap(old => old.filter((el, idx) => idx !== index));
    manipulateDeleteModal(false);
  };

  const confirmDeleteQuestionBlock = index => {
    if (!testInfo.id || isAdmin || testInfo.creatorId === userData.teacherId) {
      setModalType('delete_subtest');
      setDeletingItem({ index });
      manipulateDeleteModal(true);
    }
  };

  const confirmDeleteTest = index => {
    setModalType('delete_test');
    setDeletingItem({ index });
    manipulateDeleteModal(true);
  };

  const manipulateDeleteModal = value => {
    setTimeout(() => {
      setShowDeleteModal(value);
      if (!value) {
        setModalType(null);
        setDeletingItem(null);
      }
    }, 0);
  };

  const uploadFile = async (blob, salt, addr) => {
    if (blob) {
      return await StorageService.uploadFile(blob, addr, salt);
    }
    return null;
  };

  const checkTestFilled = (test, testsSelectionMap, subtestIndex) => {
    const selectionMapTemp = [...testsSelectionMap];
    let haveErrors = false;
    test.questions.map((el, index) => {
      if (!el.question) {
        showErrorNotification(
          `Вопрос ${index + 1} в блоке ${subtestIndex + 1} не заполнен!`,
        );
        selectionMapTemp[index].questionSelectionControl = getNewSelection(
          'true',
          '#ff6f47',
        );
        haveErrors = true;
      }
      if (el.answers.filter(el => !el.answer).length) {
        showErrorNotification(
          `Вопрос ${index + 1} в блоке ${
            subtestIndex + 1
          } имеет незаполненные ответы!`,
        );
        el.answers.forEach((item, idx) => {
          if (!item.answer.length) {
            selectionMapTemp[index].answers[idx].inputSelectionControl =
              getNewSelection('true', '#ff6f47');
          }
        });
        haveErrors = true;
      }
      if (!el.answers.filter(el => el.right).length) {
        showErrorNotification(
          `Вопрос ${index + 1} в блоке ${
            subtestIndex + 1
          } не имеет правильных ответов!`,
        );
        el.answers.map((el, idx) => {
          selectionMapTemp[index].answers[idx].checkboxSelectionControl =
            getNewSelection('true', '#ff6f47');
        });
        haveErrors = true;
      }
    });
    return { haveErrors, selectionMap: selectionMapTemp };
  };

  const sendGeneralTest = async () => {
    setCreateTestLoading(true);
    const newMap = selectionMap.map((el, index) => {
      return checkTestFilled(subtests[index], el, index);
    });
    setSelectionMap(newMap.map(el => el.selectionMap));
    if (newMap.every(el => !el.haveErrors)) {
      const filesPreParsed = [];
      let haveError = false;
      subtests.forEach((el, index) => {
        if (el.type === 'listening' && typeof el.file !== 'string') {
          if (Object.keys(el.file)?.length) {
            filesPreParsed.push({ index, file: el.file });
          } else {
            setOutlineEmptyFiles(true);
            showErrorNotification(
              `Блок вопросов ${index + 1} не имеет файла аудирования`,
            );
            haveError = true;
          }
        }
      });
      if (haveError) {
        setCreateTestLoading(false);
        return;
      }
      if (filesPreParsed.length) {
        const promises = filesPreParsed.map(el => {
          return {
            ...el,
            file: uploadFile(el.file.blob, el.file.fileName, 'audition'),
          };
        });
        await Promise.all(promises.map(el => el.file))
          .then(values => {
            filesPreParsed.forEach((el, index) => {
              subtests[el.index].file = values[index];
            });
          })
          .catch(() => {
            setCreateTestLoading(false);
            showErrorNotification('Произошла ошибка при загрузке аудирования');
          });
      }
      let requestData = {
        ...testInfo,
        tests: subtests.map(el => {
          return {
            data: {
              ...el,
              questionsCount: undefined,
              questions: el.questions.map((x, idx) => {
                return {
                  ...x,
                  number: '' + (idx + 1),
                  answers: x.answers.map((k, i) => {
                    return {
                      ...k,
                      number: '' + (i + 1),
                    };
                  }),
                };
              }),
            },
          };
        }),
      };
      try {
        if (testInfo.id) {
          await TestService.updateGeneralTest({
            testId: testInfo.id,
            data: requestData,
          });
          showSuccessNotification('Тест обновлен');
          dispatch(setEditing(false));
        } else {
          const createdTest = await TestService.createGeneralTest(requestData);
          setTests(old => [createdTest, ...old]);
          showSuccessNotification('Тест загружен');
          dispatch(setEditing(false));
        }
      } catch (error) {
        showErrorNotification(error.message);
      }
    }
    setCreateTestLoading(false);
  };

  const deleteTest = async (id = deletingItem.index) => {
    setDeleteTestLoading(true);
    try {
      await TestService.deleteGeneralTest(id);
      showSuccessNotification('Тест упешно удален');
      dispatch(setEditing(false));
      setTests(old => old.filter(el => el.testId !== id));
      clearState();
      history.push('/general-tests');
      manipulateDeleteModal(false);
    } catch (err) {
      showErrorNotification(err.message);
    }
    setDeleteTestLoading(false);
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  const clearState = () => {
    setEditingMode(false);
    setTestInfo(INITIALS.testInfo);
    setSubtests(cloneDeep(GENERAL_INITIALS.types));
    setSelectionMap(
      GENERAL_INITIALS.types.map(el => getSelectionMap(el.questions)),
    );
  };

  const showAttachingTestModal = () => {
    setShowModal(true);
  };

  const attachTest = async () => {
    setAttachingTestLoading(true);
    try {
      await TestService.assignEnglishLevelTest({
        testId: currentTestId,
        classId: selectedOrganizer.id,
        date: selectedDate,
      });
      showSuccessNotification('Тест успешно привязан к персональному занятию');
      setShowModal(false);
      setAttachingTestLoading(false);
    } catch (err) {
      showErrorNotification(
        'Не удалось привязать тест к персональному занятию',
      );
    }
  };

  const renderDeleteQuestionBlockButtonStyle = () => {
    let style = 'button-icon-wrapper';
    if (
      testInfo.id &&
      !(isAdmin || testInfo.creatorId === userData.teacherId)
    ) {
      style += ' button-icon-wrapper-disabled';
    }
    return style;
  };

  const checkDisabling = () => {
    return !(isAdmin || testInfo.creatorId === userData.teacherId);
  };

  return (
    <View
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={manipulateDeleteModal}
      sendGeneralTest={sendGeneralTest}
      deleteQuestionBlock={deleteQuestionBlock}
      deleteSubtestQuestion={deleteGeneralQuestion}
      confirmDeleteQuestionBlock={confirmDeleteQuestionBlock}
      modalType={modalType}
      testInfo={testInfo}
      selectionMap={selectionMap}
      handleTestInfoChange={handleTestInfoChange}
      handleGeneralTestInfoChange={handleGeneralTestInfoChange}
      handleGeneralAnswerRightChange={handleGeneralAnswerRightChange}
      handleGeneralAnswerTextChange={handleGeneralAnswerTextChange}
      handleGeneralQuestionTextChange={handleGeneralQuestionTextChange}
      handleAddAnswerGeneral={handleAddAnswerGeneral}
      addGeneralQuestionHandle={addGeneralQuestionHandle}
      removeGeneralQuestionHandle={removeGeneralQuestionHandle}
      testsLoading={testsLoading}
      tests={tests}
      selectedSubtestType={selectedSubtestType}
      setSelectedSubtestType={setSelectedSubtestType}
      addTestToGeneral={addTestToGeneral}
      subtests={subtests}
      openTestToEdit={openTestToEdit}
      deleteTest={deleteTest}
      clearState={clearState}
      showAttachingTestModal={showAttachingTestModal}
      attachTest={attachTest}
      showModal={showModal}
      isLoading={isLoading}
      getTests={getTests}
      outlineEmptyFiles={outlineEmptyFiles}
      selectedDate={selectedDate}
      deleteTestLoading={deleteTestLoading}
      createTestLoading={createTestLoading}
      setSelectedDate={setSelectedDate}
      setShowModal={setShowModal}
      removeAnswerHandle={removeAnswerHandle}
      handleDeleteAnswer={handleDeleteAnswer}
      teachers={teachers}
      students={students}
      addTestToPersonal={addTestToGeneral}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={setSelectedTeacher}
      organizers={organizers}
      swapQuestionBlockDown={swapQuestionBlockDown}
      swapQuestionBlockUp={swapQuestionBlockUp}
      selectedOrganizer={selectedOrganizer}
      updateTest={sendGeneralTest}
      setSelectedOrganizer={setSelectedOrganizer}
      attachingTestLoading={attachingTestLoading}
      handleGeneralFileChange={handleGeneralFileChange}
      isAdmin={isAdmin}
      setNextLinkItem={setNextLinkItem}
      makeEditingMode={makeEditingMode}
      checkDisabling={checkDisabling}
      userData={userData}
      wrongTime={wrongTime}
      setWrongTime={setWrongTime}
      renderDeleteQuestionBlockButtonStyle={
        renderDeleteQuestionBlockButtonStyle
      }
      confirmDeleteTest={confirmDeleteTest}
    />
  );
};

export default TasksContainer;
