import React from 'react';
import './style.css';

const PrivacyPolicyView = props => {
  return (
    <div className="privacy-container">
      <h2 className="privacy-title">
        Политика конфиденциальности и Правила использования приложения
      </h2>
      <p className="privacy-text">
        Устанавливая и используя это приложение, Вы принимаете настоящую
        Политику конфиденциальности и Правила использования приложения, а также
        условия использования любых сторонних сайтов или программных продуктов,
        с помощью которых вы используете это приложение.
      </p>
      <h3 className="privacy-subtitle">Сбор и использование информации</h3>
      <p className="privacy-text">
        Для лучшего опыта при использовании нашего Сервиса мы можем потребовать
        от Вас предоставить нам определенную личную информацию, включая, помимо
        прочего, имя, адрес электронной почты, номер телефона, номер учетной
        записи, пароль, историю покупок, открытые экраны, используемые функции,
        какой контент Вы просматривали, идентификатор мобильного устройства, тип
        устройства, операционную систему. Приложение использует сторонние
        сервисы, которые могут собирать информацию, используемую для вашей
        идентификации.
      </p>
      <h3 className="privacy-subtitle">Cookies</h3>
      <p className="privacy-text">
        Файлы cookie - это файлы с небольшим объемом данных, которые обычно
        используются в качестве анонимных уникальных идентификаторов. Они
        отправляются в ваш браузер с веб-сайтов, которые вы посещаете, и
        хранятся во внутренней памяти вашего устройства.
      </p>
      <p className="privacy-text">
        Этот Сервис не использует cookies. Однако приложение может использовать
        сторонний код и библиотеки, которые используют файлы cookie для сбора
        информации и улучшения своих услуг. У Вас есть возможность принять или
        отклонитьэти файлы cookie и узнать, когда файл cookie отправляется на
        Ваше устройство. Если вы решите отказаться от наших файлов cookie,
        возможно, Вы не сможете использовать некоторые части этого Сервиса
      </p>
      <h3 className="privacy-subtitle">Поставщики услуг</h3>
      <p className="privacy-text">
        Мы можем нанимать сторонние компании и частных лиц по следующим
        причинам:
      </p>
      <ol>
        <li>Для оказания услуг, связанных с Сервисом;</li>
        <li>Чтобы помочь нам проанализировать, как используется наш Сервис.</li>
      </ol>
      <p className="privacy-text">
        Мы хотим проинформировать пользователей этого Сервиса о том, что эти
        третьи стороны имеют доступ к Вашей Личной информации. Причина в том,
        чтобы выполнять поставленные перед ними задачи от нашего имени. Однако
        они обязаны не раскрывать и не использовать информацию для каких-либо
        других целей.
      </p>
      <h3 className="privacy-subtitle">Безопасность</h3>
      <p className="privacy-text">
        Мы ценим Ваше доверие к предоставлению нам Вашей личной информации,
        поэтому мы стремимся использовать коммерчески приемлемые средства ее
        защиты. Но помните, что ни один метод передачи через Интернет или метод
        электронного хранения не является на 100% безопасным и надежным, и мы не
        можем гарантировать его абсолютную безопасность.
      </p>
      <h3 className="privacy-subtitle">Ссылки на сторонние сайты</h3>
      <p className="privacy-text">
        Этот Сервис может содержать ссылки на другие сайты. Если вы нажмете на
        стороннюю ссылку, Вы будете перенаправлены на этот сайт. Обратите
        внимание, что мы не управляем этими внешними сайтами. Поэтому мы
        настоятельно рекомендуемВам ознакомиться с Политикой конфиденциальности
        этих веб-сайтов. Мы не контролируем и не несем ответственности за
        контент, политику конфиденциальности или действия любых сторонних сайтов
        или служб.
      </p>
      <h3 className="privacy-subtitle">Правила использования приложения</h3>
      <p className="privacy-text">
        При загрузке или использовании приложения эти условия автоматически
        применяютсяк Вам - поэтому Вы должны внимательно прочитать их перед
        использованием приложения. Вам не разрешается каким-либо образом
        копировать или изменять приложение, любую его часть или наши товарные
        знаки. Вам не разрешается пытаться извлечь исходный код приложения, а
        также не следует пытаться переводить приложение на другие языки или
        создавать производные версии. Само приложение и все связанные с ним
        товарные знаки, авторские права, права на базы данных и другие права
        интеллектуальной собственности принадлежат Александре Томиловой.
      </p>
      <p className="privacy-text">
        Мы стремимся сделать приложение максимально полезным и эффективным. По
        этой причине мы оставляем за собой право вносить изменения в приложение
        или взимать плату за его услуги в любое время и по любой причине. Мы
        никогда не будем взимать с Вас плату за приложение или его услуги, не
        указав Вам, за что именно вы платите.Вы должны знать, что есть вещи, за
        которые Александра Томилова ответственности не берет. Для некоторых
        функций приложения потребуется, чтобы у приложения было активное
        подключение к Интернету. Соединение может быть Wi-Fi или предоставлено
        Вашим оператором мобильной связи, но Александра Томилова не может нести
        ответственность за то, что приложение не работает с полной
        функциональностью, еслиу вас нет доступа к Wi-Fi.
      </p>
      <p className="privacy-text">
        Если Вы используете приложение за пределами зоны, где есть Wi-Fi,
        помните, что условия соглашения с Вашим оператором мобильной связи
        по-прежнему будут действовать. В результате Ваш оператор мобильной связи
        может взимать с Вас плату за передачу данных в течение всего времени
        соединения при доступе к приложению. Используя приложение, Вы принимаете
        на себя ответственность за любые расходы, включая плату за передачу
        данных в роуминге. Если Вы не являетесь плательщиком счетов для
        устройства, на котором используете приложение, имейте в виду, что мы
        предполагаем, что Вы получили разрешение от плательщика счетов на
        использование приложения.
      </p>
      <p className="privacy-text">
        В какой-то момент мы можем захотеть обновить приложение. В настоящее
        время приложение доступно для Android и iOS - требования для обеих
        операционных систем (и для любых дополнительных систем, для которых мы
        решили расширить доступность приложения) могут измениться, и Вам нужно
        будет загрузить обновления,если Вы хотите продолжать использовать
        приложение. Александра Томилова не обещает, что она всегда будет
        обновлять приложение, чтобы оно было актуальным для Вас и / или работало
        с версией Android и iOS, установленной на Вашем устройстве. Однако Вы
        обещаете всегда принимать обновления приложения, когда они предлагаются
        Вам. Мы также можем прекратить предоставление приложения и можем
        прекратить его использование в любое время без уведомления Вас о
        прекращении действия. Если мы не сообщим Вам иное, при любом прекращении
        действия:
      </p>
      <ul>
        <li>
          а) Права и лицензии, предоставленные Вам в соответствии с настоящими
          условиями, прекратятся;
        </li>
        <li>
          б) Вы должны прекратить использование приложения и (при необходимости)
          удалить его со своего устройства.
        </li>
      </ul>
      <h3 className="privacy-subtitle">
        Изменения в настоящей Политике конфиденциальности и Правилах
        использования приложения
      </h3>
      <p className="privacy-text">
        Мы можем время от времени обновлять нашу Политику конфиденциальности и
        Правила использования приложения. Таким образом, Вам рекомендуется
        периодически просматривать эту страницу на предмет изменений.
      </p>
      <p className="privacy-text">
        Эта Политика конфиденциальности и Правила использования приложения
        действуют с 08.07.2021.
      </p>
      <h3 className="privacy-subtitle">Контакты</h3>
      <p className="privacy-text">
        Если у Вас есть какие-либо вопросы или предложения относительно Политики
        конфиденциальности и Правил использования приложения, не стесняйтесь
        обращаться по адресу{' '}
        <a className="privacy-link" href="mailto: eurocentre09@gmail.com">
          eurocentre09@gmail.com
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicyView;
