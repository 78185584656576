/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { TeachersService } from '../../services';
import { store } from 'react-notifications-component';

import TeachersView from './view';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { setTeachers } from '../../store/reducers/teachers-reducer';
import { setScroll } from '../../store/reducers/appState-reducer';

const GroupsContainer = props => {
  const teachers = useSelector(state => state.teachers);
  const [teachersLoading, setTeahersLoading] = useState(true);
  const [deleteTeacherLoading, setDeleteTeacherLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deletingTeacher, setDeletingTeacher] = useState(null);
  const dispatch = useDispatch();

  const confirmDelete = teacher => {
    setDeletingTeacher(teacher);
    manipulateModal(true);
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  const deleteTeacher = async teacher => {
    setDeleteTeacherLoading(true);
    try {
      await TeachersService.deleteTeacher(deletingTeacher.teacherId);
      dispatch(
        setTeachers(
          teachers.filter(el => el.teacherId !== deletingTeacher.teacherId),
        ),
      );
      showSuccessNotification('Учитель был успешно удален');
      manipulateModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setDeleteTeacherLoading(false);
  };

  const manipulateModal = value => {
    setTimeout(() => {
      setShowModal(value);
      if (!value) {
        setDeletingTeacher(false);
      }
    }, 0);
  };

  return (
    <TeachersView
      confirmDelete={confirmDelete}
      showModal={showModal}
      setShowModal={manipulateModal}
      deletingTeacher={deletingTeacher}
      teachers={teachers}
      deleteTeacher={deleteTeacher}
      deleteTeacherLoading={deleteTeacherLoading}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
