import React, { useEffect } from 'react';
import './style.css';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { showErrorNotification } from '../../core/errorsController';

const TimePicker = ({
  disabled = '',
  minDate = new Date(),
  minTime = '',
  handleWrongTime = () => {},
  wrongTime = '',
  placeholder = '',
  format = 'HH:mm',
  value = null,
  onChange = () => {},
  className = '',
  title = '',
  containerClassName = '',
}) => {
  const _value = value ? moment(value).toISOString() : null;

  const _onChange = e => {
    if (e) {
      onChange(e.format('HH:mm'));
    }
  };

  useEffect(() => {
    if (moment(_value).add(1, 'm').isBefore(moment(new Date()))) {
      handleWrongTime(true);
    } else {
      handleWrongTime(false);
    }
  }, [_value, handleWrongTime]);

  return (
    <div className={`${containerClassName}`}>
      {title && <div className="inputTitle">{title}</div>}
      <SelectableContainer
        disabled={disabled}
        className={`date-selector ${className}`}>
        <KeyboardTimePicker
          ampm={false}
          disabled={disabled}
          placeholder={placeholder}
          format={format}
          variant="inline"
          inputProps={{ className: 'date-input' }}
          value={_value}
          onChange={_onChange}
        />
      </SelectableContainer>
      <div className={`error ${wrongTime ? 'active' : 'inactive'}`}>
        Выберите будущее время
      </div>
    </div>
  );
};

export default TimePicker;
