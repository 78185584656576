import React from 'react';
import './style.css';
import Textarea from '../../components/textarea';
import Input from '../../components/input/index';
import Button from '../../components/button';
import FullscreenLoader from '../../components/FullscreenLoader/Loader';
import DialogModal from '../../components/dialogModal/DialogModal';

const AboutUsView = props => {
  return (
    <div className="about-us-page-container">
      {props.loading ? <FullscreenLoader /> : null}
      <DialogModal
        title="Обновление информации"
        show={props.showUpdateModal}
        setShow={props.setShowUpdateModal}
        loading={props.updateLoading}
        okText="Обновить"
        onDelete={props.updateAboutUs}>
        <p className="modal-message">
          Вы действительно хотите обновить информацию в разделе "О нас"? Данное
          обновление отобразится для всех учеников школы.
        </p>
      </DialogModal>
      <div className="title">О нас</div>
      <div className="about-us-inputs-container">
        <Input
          value={props.aboutUs?.students}
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                students: e.target.value,
              };
            });
          }}
          type="number"
          placeholder="Введите количество студентов"
          title="Количество студентов"
        />
        <Input
          value={props.aboutUs?.teachers}
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                teachers: e.target.value,
              };
            });
          }}
          type="number"
          placeholder="Введите количество учителей"
          title="Количество учителей"
        />
        <Input
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                languages: e.target.value,
              };
            });
          }}
          value={props.aboutUs?.languages}
          type="number"
          placeholder="Введите количество языков"
          title="Количество языков"
        />
        <Input
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                address: e.target.value,
              };
            });
          }}
          placeholder="Введите адрес"
          value={props.aboutUs?.address}
          title="Адрес"
        />
        <Textarea
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                description: e.target.value,
              };
            });
          }}
          placeholder="Введите описание школы"
          maxLength={'3000'}
          value={props.aboutUs?.description}
          containerClassName="double-size-about-us-input"
          title="Описание школы"
        />
        <Input
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                phone: e.target.value,
              };
            });
          }}
          value={props.aboutUs?.phone}
          title="Телефон"
          placeholder="Введите номер телефона"
          maxLength={100}
        />
        <Input
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                email: e.target.value,
              };
            });
          }}
          value={props.aboutUs?.email}
          placeholder="Введите почтовый адрес"
          title="Email"
          maxLength={100}
        />
        <Input
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                instagram: e.target.value,
              };
            });
          }}
          value={props.aboutUs?.instagram}
          title="Instagram"
          placeholder="Введите instagram"
          maxLength={100}
        />
        {/* <Textarea
          onChange={e => {
            props.editAboutUs(e, old => {
              return {
                ...old,
                contacts: e.target.value,
              };
            });
          }}
          maxLength={'3000'}
          value={props.aboutUs?.contacts}
          containerClassName="double-size-about-us-input"
          title="Контакты"
        /> */}
      </div>
      <Button
        className="update-about-us-button"
        textButton="Обновить"
        small
        type="primary"
        onClick={props.update}
      />
    </div>
  );
};

export default AboutUsView;
