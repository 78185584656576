/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';

import NewsView from './view';
import { NewsService, StorageService } from '../../services';
import {
  showInfoNotification,
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../core/errorsController';
import { getId } from '../../core/functions';
import { setEditing, setScroll } from '../../store/reducers/appState-reducer';

const INITIALS = {
  newNewsInfo: {
    title: '',
    description: '',
    date: '',
    time: '',
    price: '',
    poster: '',
  },
};

const NewsContainer = props => {
  const isEditing = useSelector(state => state.appState.isEditing);

  const history = useHistory();
  const dispatch = useDispatch();

  const externalRef = useRef(null);

  const [newNewsInfo, setNewNewsInfo] = useState(INITIALS.newNewsInfo);

  const [saveLoading, setSaveLoading] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [poster, setPoster] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [outlineEmptyField, setOutlineEmptyField] = useState(false);
  const [nextLinkItem, setNextLinkItem] = useState(null);
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getNews = async () => {
    if (!isLast && !isLoading) {
      setIsLoading(true);
      try {
        const response = await NewsService.getNews(page);
        setPage(old => old + 1);
        setIsLast(response.last);
        setNewsList(old => [...old, ...response.data]);
      } catch (error) {
        showErrorNotification(error);
      }
      setIsLoading(false);
    }
  };

  const getNewsFromLink = async newsId => {
    try {
      const result = await NewsService.getNewsData(newsId);
      selectNewsToEdit(result);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  useEffect(() => {
    const id = getId(history.location.pathname);

    if (id) {
      getNewsFromLink(id);
    }

    getNews();
    const unsubscribe = history.listen(({ pathname }) => {
      setNewsList(old => {
        openNewsToEdit(getId(pathname), old);
        return old;
      });
    });

    return unsubscribe;
  }, []);

  const uploadFile = async (blob, salt, addr) => {
    if (blob) {
      return await StorageService.uploadFile(blob, addr, salt);
    }
    return '';
  };

  const handleNewsChange = (field, value) => {
    makeEditingMode();
    setNewNewsInfo({
      ...newNewsInfo,
      [field]: value,
    });
  };

  const handleNewsTimeChange = (field, value) => {
    makeEditingMode();
    setNewNewsInfo({
      ...newNewsInfo,
      [field]: value,
    });
  };

  const selectNewsToEdit = newsData => {
    setNewNewsInfo({
      id: newsData.newsId,
      title: newsData.title,
      description: newsData.description,
      date: moment(newsData.eventDate, 'D MMMM YYYY, dddd'),
      time: newsData.eventTime,
      price: newsData.eventPrice,
      poster: newsData.image,
    });
  };

  const openNewsToEdit = async (id, news = newsList) => {
    externalRef.current.value = '';

    if (id) {
      const newsData = news.find(item => item.newsId === id);
      if (newsData) {
        selectNewsToEdit(newsData);
      }
    } else {
      clearState();
    }
  };

  const makeEditingMode = () => {
    if (!isEditing) {
      dispatch(setEditing(true));
    }
  };

  const clearState = () => {
    setOutlineEmptyField(false);
    setNewNewsInfo(INITIALS.newNewsInfo);
  };

  const saveNewNews = async () => {
    setSaveLoading(true);
    externalRef.current.value = '';
    const sendNews = async () => {
      if (!newNewsInfo.title.length || !newNewsInfo.description.length) {
        setOutlineEmptyField(true);
        showInfoNotification('Заполните обязательные поля');
        setSaveLoading(false);
      } else {
        let requestData = {
          image: newNewsInfo.image,
          title: newNewsInfo.title,
          eventDate:
            moment(newNewsInfo.date).format('D MMMM YYYY, dddd') ===
            'Invalid date'
              ? ''
              : moment(newNewsInfo.date).format('D MMMM YYYY, dddd'),
          eventTime:
            newNewsInfo.time === 'Invalid date' ? '' : newNewsInfo.time,
          eventPrice: newNewsInfo.price,
          description: newNewsInfo.description,
        };

        try {
          const posterUrl = uploadFile(
            poster?.blob,
            poster?.fileName,
            'poster',
          );
          new Promise((resolve, reject) => resolve(posterUrl))
            .then(async value => {
              requestData.image = value;
            })
            .then(async () => {
              const resultNews = await NewsService.createNews({
                data: requestData,
              });
              setNewsList(old => [resultNews, ...old]);
              clearState();
              showSuccessNotification('Новость загружена');
              setSaveLoading(false);
              await dispatch(setEditing(false));
            })
            .catch(err => {
              setSaveLoading(false);
              throw err;
            });
        } catch (err) {
          showErrorNotification('Не удалось загрузить новость');
        }
      }
    };
    await sendNews();
  };

  const deleteNews = async id => {
    setDeleteLoading(true);
    try {
      await NewsService.deleteNews(id);
      setNewsList(old => old.filter(el => el.newsId !== newNewsInfo.id));
      showSuccessNotification('Новость упешно удалена');
      await dispatch(setEditing(false));
      setShowModal(false);
      history.push('/news');
    } catch (err) {
      showErrorNotification('Не удалось удалить новость');
    }
    clearState();
    setDeleteLoading(false);
  };

  const updateNews = async id => {
    setSaveLoading(true);

    const sendNews = async () => {
      if (!newNewsInfo.title.length || !newNewsInfo.description.length) {
        setOutlineEmptyField(true);
        showInfoNotification('Заполните обязательные поля');
        setSaveLoading(false);
      } else {
        let requestData = {
          image: newNewsInfo.image,
          title: newNewsInfo.title,
          eventDate:
            moment(newNewsInfo.date).format('D MMMM YYYY, dddd') ===
            'Invalid date'
              ? ''
              : moment(newNewsInfo.date).format('D MMMM YYYY, dddd'),
          eventTime:
            newNewsInfo.time === 'Invalid date' ? '' : newNewsInfo.time,
          eventPrice: newNewsInfo.price,
          description: newNewsInfo.description,
        };

        try {
          const posterUrl = uploadFile(
            poster?.blob,
            poster?.fileName,
            'poster',
          );
          new Promise((resolve, reject) => resolve(posterUrl))
            .then(async value => {
              requestData.image = value;
              const response = await NewsService.updateNews({
                newsId: id,
                data: requestData,
              });
              const newNewsList = newsList.map(el =>
                el.newsId === id ? response : el,
              );
              setSaveLoading(false);
              setNewsList(newNewsList);
              showSuccessNotification('Новость успешно обновлена');
              await dispatch(setEditing(false));
            })
            .catch(err => {
              setSaveLoading(false);
              throw err;
            });
        } catch (err) {
          showErrorNotification('Не удалось обновить новость');
        }
      }
    };

    await sendNews();
  };

  const deletePoster = () => {
    setPoster(null);
    setNewNewsInfo(prev => {
      return {
        ...prev,
        poster: '',
      };
    });
  };

  const selectFile = (blob, fileName) => {
    setPoster({ blob, fileName });
    makeEditingMode();
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  return (
    <NewsView
      newsList={newsList}
      newNewsInfo={newNewsInfo}
      isLoading={isLoading}
      saveLoading={saveLoading}
      handleNewsChange={handleNewsChange}
      openNewsToEdit={openNewsToEdit}
      clearState={clearState}
      saveNewNews={saveNewNews}
      deleteNews={deleteNews}
      updateNews={updateNews}
      setPoster={setPoster}
      externalRef={externalRef}
      showModal={showModal}
      setShowModal={setShowModal}
      deleteLoading={deleteLoading}
      deletePoster={deletePoster}
      outlineEmptyField={outlineEmptyField}
      setNextLinkItem={setNextLinkItem}
      handleNewsTimeChange={handleNewsTimeChange}
      selectFile={selectFile}
      getNews={getNews}
    />
  );
};

export default NewsContainer;
