import React, { useState, useEffect } from 'react';

import GeneralResultView from './view';
import Icon from '../../components/icon/Icon';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setScroll } from '../../store/reducers/appState-reducer';

const GeneralResultContainer = props => {
  const history = useHistory();
  const [test, setTest] = useState(history.location.testAttempt);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  const getAnswerStatus = (questionNumber, answerNumber, testIndex) => {
    let status = '';
    const question = test.completedTest[testIndex].questions.find(
      el => el.number === questionNumber,
    );
    const answer = question.answers.find(el => el.number === answerNumber);
    let userAnswer =
      test.completedTest[testIndex].testResult.find(
        el => el.questionNumber === questionNumber,
      )?.answer ||
      test.completedTest[testIndex].testResult.find(
        el => el.questionNumber === questionNumber,
      )?.answers;
    if (!Array.isArray(userAnswer)) {
      userAnswer = [userAnswer];
    }

    if (answer.right && userAnswer.includes(answer.number)) {
      status = 'right-chosen';
    } else if (answer.right && !userAnswer.includes(answer.number)) {
      status = 'right-not-chosen';
    } else if (!answer.right && userAnswer.includes(answer.number)) {
      status = 'wrong-chosen';
    } else {
      status = 'wrong-not-chosen';
    }

    return status;
  };

  const renderAnswerStyle = (questionNumber, answerNumber, testIndex) => {
    let style = ' answer-container';
    const status = getAnswerStatus(questionNumber, answerNumber, testIndex);

    if (status === 'right-chosen') {
      style += ' answer-right';
    }

    if (status === 'wrong-chosen' || status === 'right-not-chosen') {
      style += ' answer-wrong';
    }

    return style;
  };

  const renderIcon = (questionNumber, answerNumber, testIndex) => {
    const status = getAnswerStatus(questionNumber, answerNumber, testIndex);

    if (status === 'right-chosen') {
      return <Icon name="check" strokeWidth={3} color="#7EB608" />;
    }

    if (status === 'wrong-chosen') {
      return <Icon name="wrong" strokeWidth={3} color="#FF6F47" />;
    }

    if (status === 'right-not-chosen') {
      return <Icon name="check" strokeWidth={3} color="#FF6F47" />;
    }
  };

  return (
    <GeneralResultView
      test={test}
      renderAnswerStyle={renderAnswerStyle}
      renderIcon={renderIcon}
    />
  );
};

export default GeneralResultContainer;
