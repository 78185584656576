import React from 'react';

import { NavLink } from 'react-router-dom';
import ClassesContainer from '../../components/classesTable';
import './style.css';
import Loader from '../../components/loader/Loader';
import Select from '../../components/select/index';
import { getTeachersFormattedName } from '../../core/functions';
import NoDataError from '../../components/noDataError/NoDataError';
import Button from '../../components/button';
import DialogModal from '../../components/dialogModal/DialogModal';

const GroupsView = props => {
  return (
    <div>
      <DialogModal
        title="Удаление занятия"
        show={props.showModal}
        setShow={props.setShowModal}
        loading={props.deleteLoading}
        onDelete={props.deleteClass}>
        {props.deleteTargetClass ? (
          <span className="add-lesson-modal-item-last">
            Вы действительно хотите удалить индивидуальное занятие{' '}
            <strong>
              {'Студент: '}
              {props.deleteTargetClass?.studentName}
              {' Учитель: '}
              {getTeachersFormattedName(
                props.deleteTargetClass?.teacherId,
                props.teachers,
              )}
            </strong>
            ?
          </span>
        ) : null}
      </DialogModal>
      {props.isAdmin && (
        <Select
          direction="bottom"
          containerClass="individual-teacher-select"
          value={
            props.selectedTeacher
              ? getTeachersFormattedName(props.selectedTeacher, props.teachers)
              : ''
          }
          width={'250px'}
          data={props.teachers}
          search={true}
          onReset={() => props.setSelectedTeacher()}
          placeholder={'Выберите учителя'}
          renderOption={el => {
            return (
              <div
                className="option"
                onMouseDown={() => {
                  props.setSelectedTeacher(el.teacherId);
                }}>
                {getTeachersFormattedName(el.teacherId, props.teachers)}
              </div>
            );
          }}
          extractSearchKey={el =>
            getTeachersFormattedName(el.teacherId, props.teachers)
          }
        />
      )}
      <ClassesContainer
        type={props.isAdmin ? 'admin' : 'teacher'}
        renderControl={el => {
          return (
            <Button
              type={'secondary'}
              small
              textButton={'Удалить'}
              onClick={() => props.showDeleteModal(el)}
            />
          );
        }}
        last={true}
        loading={props.isLoading}
        renderEmpty={() => {
          return (
            <NoDataError text="Нет доступных для просмотра индивидуальных занятий" />
          );
        }}
        data={props.data}
      />
    </div>
  );
};

export default GroupsView;
