import React from 'react';

import { connect, useSelector } from 'react-redux';
import { getTeachersFormattedName } from '../../core/functions';
import Loader from '../loader/Loader';

import View from './view';

const UsersTableContainer = ({
  renderEmpty = () => {},
  loading = false,
  data = [],
  last = false,
  renderControl,
  link = '',
  paginate = () => {},
  deleteGroup = () => {},
  type = '',
}) => {
  const teachers = useSelector(state => state.teachers);

  const getTeacherName = id => {
    if (!teachers?.length) {
      return <Loader />;
    }
    return getTeachersFormattedName(id, teachers);
  };

  return (
    <View
      type={type}
      getTeacherName={getTeacherName}
      link={link}
      loading={loading}
      data={data}
      last={last}
      paginate={paginate}
      renderControl={renderControl}
      renderEmpty={renderEmpty}
      deleteGroup={deleteGroup}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersTableContainer);
