const COLLECTIONS = {
  TEACHERS: 'teachers',
  STUDENTS: 'students',
  INVITE_CODES: 'inviteCodes',
  GROUPS: 'groups',
  TESTS: 'tests',
  MATERIALS: 'materials',
  SUCCESS_TESTS: 'successTests',
  GAMES: 'games',
  NEWS: 'newsAll',
};

export default COLLECTIONS;
export const BASE_TOKEN_URL = 'https://securetoken.googleapis.com/v1';
export const BASE_URL =
  'https://us-central1-nota-5-60b44.cloudfunctions.net/api';
