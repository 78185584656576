import React from 'react';
import { connect } from 'react-redux';
import Calendar from './Calendar';
import moment from 'moment';

const CalendarContainer = props => {
  const handleSelect = arg => {
    props.onSelect(arg.startStr, arg.endStr);
  };

  const onMonthChange = arg => {
    props.onMonthChange(
      moment(arg.startStr).format('YYYY-MM-DD'),
      moment(arg.endStr).format('YYYY-MM-DD'),
    );
  };

  const formatEvents = (data = props.data) => {
    const formattedEvents = [];
    if (data?.length) {
      data.forEach(el => {
        const elDateFormatted = moment(el.date).format('YYYY-MM-DD');
        const existingEvent = formattedEvents.find(
          item => item.start === elDateFormatted,
        );
        if (!existingEvent) {
          formattedEvents.push({
            free: el.type === 'personal-class' && !el.studentId ? 1 : '',
            all: 1,
            title: `${1}\n${
              el.type === 'personal-class' && !el.studentId ? 1 : ''
            }`,
            start: elDateFormatted,
            display: 'background',
          });
        } else {
          if (el.type === 'personal-class' && !el.studentId) {
            if (!existingEvent.free) {
              existingEvent.free = 1;
            } else {
              existingEvent.free += 1;
            }
          }
          existingEvent.all += 1;
          existingEvent.title = `${existingEvent.all}\n${existingEvent.free}`;
        }
      });
    }
    return formattedEvents;
  };

  return (
    <Calendar
      onMonthChange={onMonthChange}
      events={formatEvents()}
      handleSelect={handleSelect}
      data={props.calendarEvents}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
