import React from 'react';

import './style.css';
import { translate } from '../../core/functions';
import Button from '../button';
import Loader from '../loader/Loader';
import { ruDateTimeFormat } from '../../core/functions';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      {/* <NavLink to={`/materials/${data.id}`} className="box box-1">{data.id}</NavLink> */}
      <NavLink to={`/materials/${data.id}`} className="box box-2">
        {data.name}
      </NavLink>
      <NavLink to={`/materials/${data.id}`} className="box box-3">
        {translate(data.type, 'material')}
      </NavLink>
      <NavLink to={`/materials/${data.id}`} className="box box-4">
        {moment(data.date || data.createdTime).format('YYYY.MM.DD, HH:mm')}
      </NavLink>
      <div className="box box-5">
        {props.renderControl ? (
          props.renderControl(data)
        ) : (
          <Button
            type={data.active ? 'secondary' : 'primary'}
            small
            textButton={data.active ? 'Удалить' : 'Добавить'}
            onClick={() => {
              props.deleteMaterial(data.material);
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div onScroll={props.paginate} className="table materials-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Название</div>
          <div className="box box-3">Тип</div>
          <div className="box box-4">Дата начала</div>
          <div className="box box-5">Управление</div>
        </div>
        <div className="table-list">
          {props.data.length
            ? props.data.map((material, index) => (
                <TableLine
                  key={material.materialId}
                  index={index}
                  material={material}
                  id={material.materialId}
                  name={material.title}
                  type={material.type}
                  date={material.date}
                  createdTime={material.createdTime}
                  active={material.active}
                  has={material.has}
                  handleMaterial={props.handleMaterial}
                />
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
      {props.type !== 'teacher' && props.data?.length ? (
        <Button
          disabled={!props.data.filter(el => !el.active).length}
          type="primary"
          textButton="Удалить"
          onClick={() => {
            props.shareMaterials();
          }}
        />
      ) : null}
    </>
  );
};

export default UsersTableView;
