import React, { useState } from 'react';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import './style.css';
import Icon from '../icon/Icon';

const Input = ({
  placeholder = '',
  value = '',
  disabled = false,
  maxLength = 200,
  title = '',
  showCounter = false,
  onKeyPress = () => {},
  onChange = () => {},
  onKeyDown = () => {},
  type = 'default',
  className = '',
  containerClassName = '',
  customSelectionControl,
  selectionColor,
}) => {
  // const _onChange = e => {
  //   const text = e.target.value;
  //   onChange(text);
  // };
  const [inputType, setInputType] = useState(type);

  return (
    <div className={`inputContainer ${containerClassName}`}>
      {title && <div className="inputTitle">{title}</div>}
      <SelectableContainer
        customSelectionControl={customSelectionControl}
        selectionColor={selectionColor}
        disabled={disabled}
        className={`input-container ${className}`}>
        <input
          type={inputType}
          className={'input'}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
        />
        {type === 'password' && (
          <div
            className="icon"
            onClick={() => {
              setInputType(prev => (prev === 'password' ? 'text' : 'password'));
            }}>
            {inputType === 'password' ? <Icon name="password" /> : null}
            {inputType === 'text' ? <Icon name="passwordOff" /> : null}
          </div>
        )}
        {showCounter && maxLength && (
          <div className="input-counter">{maxLength - value.length}</div>
        )}
      </SelectableContainer>
    </div>
  );
};

export default Input;
