import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { store } from 'react-notifications-component';
import { InvitesService } from '../../services';
import { copyToClipboard } from '../../core/functions';

import View from './view';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { setScroll } from '../../store/reducers/appState-reducer';

const TasksContainer = props => {
  const [teachersInvites, setTeachersInvites] = useState([]);
  const [studentsInvites, setStudentsInvites] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getCodes('teacher');
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, [dispatch]);

  const getCodes = async (role, groupId, action) => {
    try {
      const result = await InvitesService.getInvites(role, groupId, action);
      setTeachersInvites([...result]);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const generateCode = async () => {
    try {
      const result = await InvitesService.getTeacherInvite();
      showSuccessNotification(
        `Сгенерирован новый инвайт код для учителя: ${result.code}`,
      );
      copyToClipboard(result.code);
      return result;
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const addNewCode = async role => {
    switch (role) {
      case 'teacher':
        const code = await generateCode();
        setTeachersInvites(prevState => [...prevState, code]);
        break;
      case 'student':
        setStudentsInvites(prevState => [...prevState]);
        break;
      default:
        return;
    }
  };

  return (
    <View
      teachersInvites={teachersInvites}
      studentsInvites={studentsInvites}
      copyToClipboard={copyToClipboard}
      addNewCode={addNewCode}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
