const SET_PERSONALS = 'SET_PERSONALS';

const initialState = null;

const personalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSONALS:
      return action.personals;
    default:
      return state;
  }
};

export const setPersonals = personals => {
  return dispatch => {
    dispatch({ type: SET_PERSONALS, personals });
  };
};

export default personalsReducer;
