const SET_CATEGORIZE = 'SET_CATEGORIZE';

const initialState = null;

const categorizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIZE:
      return action.categorize;
    default:
      return state;
  }
};

export const setCategorize = categorize => {
  return dispatch => {
    dispatch({ type: SET_CATEGORIZE, categorize });
  };
};

export default categorizeReducer;
