import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';
import firebase from 'firebase';
import { BASE_URL } from '../../constants';

export default {
  async getUnreadNotifications(page, size) {
    try {
      const result = await apiGet('/notifications', {
        params: { read: false },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getNotifications(page, size = 20) {
    try {
      const result = await apiGet('/notifications', {
        params: { read: true, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async readNotifications(pushes) {
    try {
      const result = await apiPut('/notifications', { pushes });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteNotifications(pushes) {
    try {
      const result = await apiDelete('/notifications', { pushes });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
