import { deleteGroup } from '../../core/firebase';
import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';

export default {
  async getGroupData(groupId) {
    try {
      const result = await apiGet('/group', { params: { groupId } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateGroupData(groupId, body) {
    try {
      const result = await apiPut('/group', {
        groupId: groupId,
        data: body,
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getAllGroups(teacherId) {
    try {
      const result = await apiGet('/groups', {
        params: { teacherId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getStudents(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/group/students', {
        params: { groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTeacherGroups(uid) {
    try {
      const result = await apiGet(`/teacher/groups?teacherId=${uid}`);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async requestPayment(groupId, fromDate, toDate, value) {
    try {
      const result = await apiPost('/payment/group', {
        groupId,
        fromDate,
        toDate,
        value,
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPaymentsList(groupId) {
    try {
      const result = await apiGet('/payments/list', {
        params: {
          groupId,
        },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createGroup(data, teacherId) {
    try {
      const result = await apiPost('/group', { data, teacherId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMaterials(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/group/materials', {
        params: { groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGroupsWithTestId(testId, teacherId = null) {
    try {
      const result = await apiGet('/test/groups', {
        params: { testId, teacherId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTests(groupId, page, size) {
    try {
      const result = await apiGet('/group/tests', {
        params: { groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPayments(groupId, page = 1, size = 40, status = '') {
    try {
      const result = await apiGet('/payments', {
        params: { groupId, page, size, status },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPaymentsCreated(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/payments', {
        params: { status: 'created', groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getStudentPayments(groupId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('/payments', {
        params: { groupId, studentId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deletePayment(groupId, fromDate) {
    try {
      const result = await apiDelete('/payment/group', {
        groupId,
        fromDate,
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedTests(groupId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('/tests/success', {
        params: { groupId, studentId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedFlashcards(groupId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('group/games/success', {
        params: { groupId, studentId, page, size, type: 'flash_cards' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedMatchup(groupId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('group/games/success', {
        params: { groupId, studentId, page, size, type: 'matchUp' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedAnagrams(groupId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('group/games/success', {
        params: { groupId, studentId, page, size, type: 'anagram' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCompletedCategorize(groupId, studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('group/games/success', {
        params: { groupId, studentId, page, size, type: 'categorize' },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getEvents(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/calendar/events', {
        params: { groupId, page, size, allTime: true },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGroupsWithMaterialId(materialId, teacherId = null) {
    try {
      const result = await apiGet('/material/groups', {
        params: { materialId, teacherId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addTests(data) {
    try {
      const result = await apiPost('/test/assign', data);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async removeTests(data) {
    try {
      const result = await apiDelete('/test/assign', data);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getFlashCards(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/group/flashCards', {
        params: { groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMatchups(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/group/matchup', {
        params: { groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getAnagrams(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/group/anagrams', {
        params: { groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCategorizes(groupId, page = 1, size = 40) {
    try {
      const result = await apiGet('/group/categorize', {
        params: { groupId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGroupsWithGameId(route, categoryId) {
    try {
      const result = await apiGet(`/${route}/category/groups`, {
        params: { categoryId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteStudents(groupId, studentId) {
    try {
      const result = await apiDelete('/group/students', { groupId, studentId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteGroup(groupId) {
    try {
      const result = await apiDelete('/group', { groupId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
