import React from 'react';
import './style.css';
import Icon from '../icon/Icon';
import PushNotification from '../pushNotification/index';
import EmptyNotification from '../emptyNotification/index';
import ButtonIcon from '../button-icon';
import Loader from '../loader/Loader';

const PushContainer = props => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;

    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.getNotifications();
    }
  };

  return (
    <div
      ref={props.containerRef}
      onClick={e => props.setIsOpen(old => !old)}
      className="push-bell-wrapper">
      <div className="push-bell-container">
        <Icon
          width={'100%'}
          height={'100%'}
          strokeWidth={0}
          name="bell"
          color="black"
        />
        {props.unseenedNotificationsAmount ? (
          <div className="push-amount-circle">
            <span className="push-amount-text">
              {props.unseenedNotificationsAmount < 10
                ? props.unseenedNotificationsAmount
                : '9+'}
            </span>
          </div>
        ) : null}
        {props.isOpen ? (
          <div onClick={e => e.stopPropagation()} className="push-wrapper">
            <div onScroll={handleDataOnBottom} className="push-container">
              {props.isLoading ? (
                <Loader className={'push-loader'} />
              ) : props.notifications.length ? (
                [
                  <div
                    onClick={props.clearNotifications}
                    className="delete-all-notifications">
                    <ButtonIcon name="close" />
                    <p className="text notification-text">
                      Очистить все уведомления
                    </p>
                  </div>,
                  props.notifications.map(el => (
                    <PushNotification
                      key={el.notificationId}
                      parentOpened={props.isOpen}
                      parentOffset={props.containerRef?.current}
                      data={el}
                    />
                  )),
                  props.isPaginationLoading ? (
                    <Loader className={'push-loader'} />
                  ) : null,
                ]
              ) : (
                <EmptyNotification />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PushContainer;
