import { apiGet, apiPost, apiPatch, apiDelete } from '../api';
import firebase from 'firebase';

export default {
  async getUsers(page, size) {
    try {
      const result = await apiGet('/students', { params: { page, size } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getUserGroups(studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('/student/groups', {
        params: { studentId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteStudent(studentId) {
    try {
      const result = await apiDelete('/student', { studentId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getUserPersonals(studentId, page = 1, size = 40) {
    try {
      const result = await apiGet('/student/classes', {
        params: { studentId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
