import React, { useEffect, useState } from 'react';
import firebase from './core/firebase';
import './App.css';
import './fonts/stylesheet.css';
import Routers from './Routers';
import { useHistory } from 'react-router';

const App = props => {
  const [loadingApp, setLoadingApp] = useState(true);
  const history = useHistory();
  useEffect(() => {
    setLoadingApp(false);
    const unsubscribe = history.listen(() => window.scroll(0, 0));
    return unsubscribe;
  }, []);

  if (loadingApp) {
    return null;
  }

  return <Routers />;
};

export default App;
