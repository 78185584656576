const SET_EDITING = 'SET_EDITING';
const SET_LOADING = 'SET_LOADING';
const SET_SCROLL = 'SET_SCROLL';

const initialState = {
  isEditing: false,
  isLoading: false,
  isScrollable: false,
};

const isSafari = window.safari !== undefined;

const appStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITING:
      return {
        ...state,
        isEditing: action.state,
      };
    case SET_SCROLL:
      return {
        ...state,
        isScrollable: action.state,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.state,
      };
    default:
      return state;
  }
};

export const setEditing = state => {
  return dispatch => {
    dispatch({ type: SET_EDITING, state });
  };
};

export const setLoading = state => {
  return dispatch => {
    dispatch({ type: SET_LOADING, state });
  };
};

export const setScroll = state => {
  return dispatch => {
    dispatch({ type: SET_SCROLL, state: !isSafari ? state : true });
  };
};

export default appStateReducer;
