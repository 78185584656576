/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import GroupsView from './view';
import { deleteUser } from '../../core/firebase';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { GroupsService } from '../../services';
import StoreController from '../../core/storeController';
import { setScroll } from '../../store/reducers/appState-reducer';

const GroupsContainer = props => {
  const groups = useSelector(state => state.groups);
  const teachers = useSelector(state => state.teachers);
  const userData = useSelector(state => state.user);
  const [modalType, setModalType] = useState('delete');
  const [deleteTargetGroup, setDeleteTargetGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [targetTeacher, setTargetTeacher] = useState(false);
  const [groupLevel, setGroupLevel] = useState(null);
  const [groupName, setGroupName] = useState('');
  const isAdmin = useSelector(state => state.user).role === 'admin';
  const isLoading = useSelector(state => state.appState.isLoading);
  const dispatch = useDispatch();

  const getGroupsToTab = () => {
    if (groups.length) {
      const groupsToTabs = groups.map(group => {
        return {
          id: group.groupId,
          name: group.name,
        };
      });
      return groupsToTabs;
    }
  };

  const confirmDeleteGroup = el => {
    setModalType('delete');
    setDeleteTargetGroup(el);
    manipulateModal(true);
  };

  const manipulateModal = value => {
    setTimeout(() => {
      setShowModal(value);
      if (!value) {
        setDeleteTargetGroup(null);
      }
    }, 0);
  };

  const openNewGroupModal = () => {
    setModalType('new');
    manipulateModal(true);
  };

  const deleteGroup = async () => {
    setDeleteLoading(true);
    try {
      const result = await GroupsService.deleteGroup(deleteTargetGroup.id);
      StoreController.getGroups(
        userData.role !== 'admin' ? userData.teacherId : null,
      );
      setDeleteTargetGroup(null);
      showSuccessNotification('Группа была успешно удалена');
      setShowModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setDeleteLoading(false);
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  const createGroup = async () => {
    setDeleteLoading(true);
    try {
      const result = await GroupsService.createGroup(
        {
          name: groupName,
          level: groupLevel,
        },
        targetTeacher,
      );
      if (result.errorCode) {
        throw result;
      }
      StoreController.getGroups(
        userData.role !== 'admin' ? userData.teacherId : null,
      );
      setDeleteTargetGroup(null);
      setGroupLevel(null);
      setTargetTeacher(null);
      setGroupName(null);
      showSuccessNotification('Группа была успешно создана');
      setShowModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setDeleteLoading(false);
  };

  return (
    <GroupsView
      createGroup={createGroup}
      groupName={groupName}
      setGroupName={setGroupName}
      openNewGroupModal={openNewGroupModal}
      groups={groups}
      teachers={teachers}
      groupLevel={groupLevel}
      setGroupLevel={setGroupLevel}
      deleteTargetGroup={deleteTargetGroup}
      deleteLoading={deleteLoading}
      setDeleteLoading={setDeleteLoading}
      confirmDeleteGroup={confirmDeleteGroup}
      deleteGroup={deleteGroup}
      modalType={modalType}
      showModal={showModal}
      setShowModal={manipulateModal}
      targetTeacher={targetTeacher}
      setTargetTeacher={setTargetTeacher}
      isAdmin={isAdmin}
      isLoading={isLoading}
    />
  );
};

export default GroupsContainer;
