import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import HeaderView from './HeaderView';

const HeaderContainer = props => {
  const userData = useSelector(state => state.user);

  return <HeaderView userData={userData} />;
};

export default HeaderContainer;
