import React from 'react';
import './style.css';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

const Container = ({
  disabled = false,
  placeholder = '',
  minDate = null,
  maxDate = null,
  format = 'DD MMMM, dd HH:mm',
  value = null,
  onChange = () => {},
  className = '',
  containerClassName = '',
  title = '',
  views = ['year', 'month', 'date'],
}) => {
  const _value = value ? moment(value).toISOString() : null;
  const _minDate = minDate ? moment(minDate).toISOString() : undefined;
  const _maxDate = maxDate ? moment(maxDate).toISOString() : undefined;
  return (
    <div className={`${containerClassName}`}>
      {title && <div className="inputTitle">{title}</div>}
      <SelectableContainer
        disabled={disabled}
        className={`date-selector ${className}`}>
        <DatePicker
          disabled={disabled}
          placeholder={placeholder}
          minDate={_minDate}
          maxDate={_maxDate}
          format={format}
          views={views}
          error={false}
          helperText=""
          customStyles={{
            dateText: {
              fontSize: 13,
              fontWeight: 400,
              color: '#234456',
            },
          }}
          variant="inline"
          inputProps={{ className: 'date-input' }}
          value={_value}
          onChange={onChange}
        />
      </SelectableContainer>
    </div>
  );
};

export default Container;
