import React from 'react';

import View from './view';

const Container = ({
  renderEmpty = () => {},
  deleteTest = () => {},
  shareTests = () => {},
  renderControl,
  data = [],
  type,
  last = false,
  loading = false,
  paginate = () => {},
}) => {
  return (
    <View
      renderControl={renderControl}
      last={last}
      type={type}
      paginate={paginate}
      loading={loading}
      renderEmpty={renderEmpty}
      deleteTest={deleteTest}
      shareTests={shareTests}
      data={data}
    />
  );
};

export default Container;
