/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { cloneDeep } from 'lodash';

import { GamesService, GroupsService, StorageService } from '../../services';
import { getGameId } from '../../core/functions';
import View from './view.js';
import { setEditing, setScroll } from '../../store/reducers/appState-reducer';

import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
  showInfoNotification,
} from '../../core/errorsController';

const FLASH_INITIALS = {
  newCardInfo: {
    title: '',
    description: '',
    showTranslateFirst: false,
  },
  words: [
    {
      number: 1,
      word: '',
      translate: '',
    },
  ],
};

const MATCHUP_INITIALS = {
  newMatchupInfo: {
    title: '',
    description: '',
  },
  words: [
    {
      number: 1,
      word: '',
      translate: '',
    },
  ],
  rounds: [
    [
      {
        number: 1,
        word: '',
        translate: '',
      },
    ],
    [
      {
        number: 2,
        word: '',
        translate: '',
      },
    ],
  ],
};

const ANAGRAM_INITIALS = {
  newAnagramInfo: {
    title: '',
    description: '',
  },
  words: [
    {
      number: 1,
      // word: '',
      hint: '',
      file: {
        name: '',
        blob: '',
      },
      translate: '',
    },
  ],
};

const CATEGORIZE_INITIALS = {
  newCategorizeInfo: {
    title: '',
    description: '',
  },
  categories: [
    {
      name: '',
      number: 1,
      words: [
        {
          number: 1,
          categoryNumber: 1,
          word: '',
        },
      ],
    },
    {
      name: '',
      number: 2,
      words: [
        {
          number: 2,
          categoryNumber: 2,
          word: '',
        },
      ],
    },
  ],
};

const paginationInitials = {
  flashcards: {
    page: 1,
    last: false,
  },
  anagrams: {
    page: 1,
    last: false,
  },
  categorize: {
    page: 1,
    last: false,
  },
  matchup: {
    page: 1,
    last: false,
  },
};

const SHOW_TRANSLATION_FIRST = [
  {
    label: 'Слово (родной язык)',
    value: false,
  },
  {
    label: 'Перевод (иностранный язык)',
    value: true,
  },
];

let flashWordsNumber = FLASH_INITIALS.words.length;
let matchupWordsNumber = MATCHUP_INITIALS.words.length;
let anagramWordsNumber = ANAGRAM_INITIALS.words.length;
let categorizeWordsNumber = 2;

const CardsContainer = props => {
  const userData = useSelector(state => state.user);
  const teachers = useSelector(state => state.teachers);
  const organizers = useSelector(state => [
    ...state.groups,
    ...state.personals,
  ]);
  const students = useSelector(state => state.students);
  const isEditing = useSelector(state => state.appState.isEditing);
  const [nextLinkItem, setNextLinkItem] = useState(null);
  const [cardsList, setCardsList] = useState([]);
  const [matchupList, setMatchupList] = useState([]);
  const [anagramsList, setAnagramsList] = useState([]);
  const [categorizeList, setCategorizeList] = useState([]);
  const [newCardInfo, setNewCardInfo] = useState(FLASH_INITIALS.newCardInfo);
  const [newMatchupInfo, setNewMatchupInfo] = useState(
    MATCHUP_INITIALS.newMatchupInfo,
  );
  const [newAnagramInfo, setNewAnagramInfo] = useState(
    ANAGRAM_INITIALS.newAnagramInfo,
  );
  const [newCategorizeInfo, setNewCategorizeInfo] = useState(
    CATEGORIZE_INITIALS.newCategorizeInfo,
  );
  const [saveLoading, setSaveLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [groupIdMap, setGroupIdMap] = useState(new Map());
  const [flashWords, setFlashWords] = useState(FLASH_INITIALS.words);
  const [matchupWords, setMatchupWords] = useState(MATCHUP_INITIALS.words);
  const [anagramWords, setAnagramWords] = useState(ANAGRAM_INITIALS.words);
  const [matchupRounds, setMatchupRounds] = useState(MATCHUP_INITIALS.rounds);
  const [categorizeCategories, setCategorizeCategories] = useState(
    CATEGORIZE_INITIALS.categories,
  );
  const [editingMode, setEditingMode] = useState(null);
  const [tabId, setTabId] = useState(1);
  const [groupsWithoutGame, setGroupsWithoutGame] = useState([]);
  const [groupsWithGame, setGroupsWithGame] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [attachingGameLoading, setAttachingGameLoading] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [selectedOrganizer, setSelectedOrganizer] = useState();
  const [currentGameId, setCurrentGameId] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [wrongTime, setWrongTime] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [outlineEmptyField, setOutlineEmptyField] = useState(false);
  const isAdmin = useSelector(state => state.user).role === 'admin';
  const history = useHistory();
  const dispatch = useDispatch();
  const [paginationStatus, setPaginationStatus] = useState(
    cloneDeep(paginationInitials),
  );
  const [flashcardsLoading, setFlashcardsLoading] = useState(false);
  const [matchupLoading, setMatchupLoading] = useState(false);
  const [anagramsLoading, setAnagramsLoading] = useState(false);
  const [categorizeLoading, setCategorizeLoading] = useState(false);

  const uploadFile = async (blob, salt, addr) => {
    if (blob) {
      return await StorageService.uploadFile(blob, addr, salt);
    }
    return '';
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  const deleteHint = index => {
    makeEditingMode();
    setAnagramWords(prev =>
      prev.map((el, id) => {
        if (id === index) {
          return {
            ...el,
            image: '',
          };
        } else {
          return el;
        }
      }),
    );
  };

  const selectFile = (blob, fileName, index) => {
    makeEditingMode();
    setAnagramWords(prev =>
      prev.map((el, id) => {
        if (id === index) {
          return {
            ...el,
            file: {
              name: fileName,
              blob: blob,
            },
          };
        } else {
          return el;
        }
      }),
    );
    makeEditingMode();
  };

  const getCategoryId = name => {
    switch (name) {
      case 'flashCards':
        return 1;
      case 'matchup':
        return 2;
      case 'anagrams':
        return 3;
      case 'categorize':
        return 4;
      default:
        return 1;
    }
  };

  const defineCategoryObject = () => {
    switch (tabId) {
      case 1:
        return newCardInfo;
      case 2:
        return newMatchupInfo;
      case 3:
        return newAnagramInfo;
      case 4:
        return newCategorizeInfo;
      default:
        return newCardInfo;
    }
  };

  const getGameFromLink = async categoryId => {
    const path = history.location.pathname;
    const tab = +getCategoryId(path.split('/')[2]);
    try {
      const result = await GamesService.getGame(tab, categoryId);
      switch (tab) {
        case 1:
          selectCardsToEdit(result.data);
          break;
        case 2:
          selectMatchupToEdit(result.data);
          break;
        case 3:
          selectAnagramsToEdit(result.data);
          break;
        case 4:
          selectCategorizeToEdit(result.data);
          break;
        default:
          selectCardsToEdit(result.data);
          break;
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const getFlashCards = async () => {
    if (!paginationStatus.flashcards.last && !flashcardsLoading) {
      setFlashcardsLoading(true);
      try {
        const result = await GamesService.getFlashCards(
          userData.creatorId,
          paginationStatus.flashcards.page,
        );
        setCardsList(old => [...old, ...result.data]);
        handlePaginationStatus(
          'flashcards',
          paginationStatus.flashcards.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setFlashcardsLoading(false);
    }
  };

  const getAnagrams = async () => {
    if (!paginationStatus.anagrams.last && !anagramsLoading) {
      setAnagramsLoading(true);
      try {
        const result = await GamesService.getAnagrams(
          userData.creatorId,
          paginationStatus.anagrams.page,
        );
        setAnagramsList(old => [...old, ...result.data]);
        handlePaginationStatus(
          'anagrams',
          paginationStatus.anagrams.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setAnagramsLoading(false);
    }
  };

  const getCategorizes = async () => {
    if (!paginationStatus.categorize.last && !categorizeLoading) {
      setCategorizeLoading(true);
      try {
        const result = await GamesService.getCategorizes(
          userData.creatorId,
          paginationStatus.categorize.page,
        );
        setCategorizeList(old => [...old, ...result.data]);
        handlePaginationStatus(
          'categorize',
          paginationStatus.categorize.page,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setCategorizeLoading(false);
    }
  };

  const getMatchups = async () => {
    if (!paginationStatus.matchup.last && !matchupLoading) {
      setMatchupLoading(true);
      try {
        const result = await GamesService.getMatchups(
          userData.creatorId,
          paginationStatus.matchup.page,
        );
        setMatchupList(old => [...old, ...result.data]);
        handlePaginationStatus(
          'matchup',
          paginationStatus.matchup.page,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMatchupLoading(false);
    }
  };

  const handleContainerOnBottom = async () => {
    try {
      switch (tabId) {
        case 1:
        default:
          if (paginationStatus.flashcards.last) {
            return;
          }
          await getFlashCards();
          break;
        case 2:
          if (paginationStatus.matchup.last) {
            return;
          }
          await getMatchups();
          break;
        case 3:
          if (paginationStatus.anagrams.last) {
            return;
          }
          await getAnagrams();
          break;
        case 4:
          if (paginationStatus.categorize.last) {
            return;
          }
          await getCategorizes();
          break;
      }
    } catch (error) {
      showErrorNotification('Произошла ошибка при получении данных');
    }
  };

  useEffect(() => {
    setPaginationStatus(cloneDeep(paginationInitials));
    const path = history.location.pathname;
    const id = getGameId(path);

    if (id) {
      getGameFromLink(id);
    }
    const tab = +getCategoryId(path.split('/')[2]);
    setTabId(tab);
    switch (tab) {
      case 2:
        getMatchups();
        break;
      case 3:
        getAnagrams();
        break;
      case 4:
        getCategorizes();
        break;
      case 1:
      default:
        getFlashCards();
        break;
    }
    const unsubscribe = history.listen(({ pathname }) => {
      setTabId(old => {
        const newTab = getCategoryId(pathname.split('/')[2]);
        switch (newTab) {
          case 2:
            setMatchupList(old_ => {
              openMatchupToEdit(getGameId(pathname), old_);
              return old_;
            });
            break;
          case 3:
            setAnagramsList(old_ => {
              openAnagramsToEdit(getGameId(pathname), old_);
              return old_;
            });
            break;
          case 4:
            setCategorizeList(old_ => {
              openCategorizeToEdit(getGameId(pathname), old_);
              return old_;
            });
            break;
          case 1:
          default:
            setCardsList(old_ => {
              openCardsToEdit(getGameId(pathname), old_);
              return old_;
            });
            break;
        }
        return newTab;
      });
    });
    return unsubscribe;
  }, []);

  const handlePaginationStatus = (tab, page = 1, last = false) => {
    setPaginationStatus(old => {
      return {
        ...old,
        [tab]: {
          page: page,
          last: last,
        },
      };
    });
  };

  const makeEditingMode = () => {
    if (!isEditing) {
      dispatch(setEditing(true));
    }
  };

  const getGroupsList = async (id = userData.teacherId) => {
    setGroupsLoading(true);
    try {
      const resAllGroups = await GroupsService.getTeacherGroups(
        userData.teacherId,
      );
      const groupList = [...resAllGroups.data];
      setGroupsList(groupList);
      const temp = [...groupList];
      setGroupsWithoutGame(
        temp.map(item => {
          const group = { ...item };
          group.active = false;
          group.has = false;
          return group;
        }),
      );
    } catch (err) {
      showErrorNotification(err.message);
    }
    setGroupsLoading(false);
  };

  const getRoute = tab => {
    switch (tab) {
      case 2:
        return 'matchup';
      case 3:
        return 'anagrams';
      case 4:
        return 'categorize';
      case 1:
      default:
        return 'flashCards';
    }
  };

  const openMatchupToEdit = (categoryId, matchups = matchupList) => {
    if (!matchups.length) {
      getMatchups();
    }
    if (categoryId) {
      const cards = matchups.find(item => item.categoryId === categoryId);

      if (cards) {
        selectMatchupToEdit(cards);
        setEditingMode(cards.id);
      }
    } else {
      clearState(2);
    }
  };

  const selectCardsToEdit = cards => {
    setCurrentGameId(cards.categoryId);
    setNewCardInfo({
      teacherId: cards.teacherId,
      categoryId: cards.categoryId,
      showTranslateFirst: cards.showTranslateFirst,
      title: cards.name,
    });

    setFlashWords(
      cards.words.map((item, index) => {
        return {
          word: item.word,
          translate: item.translate,
          number: index + 1,
        };
      }),
    );
  };

  const selectAnagramsToEdit = cards => {
    setCurrentGameId(cards.categoryId);
    setNewAnagramInfo({
      teacherId: cards.teacherId,
      categoryId: cards.categoryId,
      description: cards.description,
      title: cards.name,
    });

    const temp = cards.questions.map((item, index) => {
      const temp = {
        number: index + 1,
        // word: item.question,
        hint: item.hint,
        translate: item.answer,
      };

      item.image
        ? (temp.image = item.image)
        : (temp.file = ANAGRAM_INITIALS.words[0].file);
      return temp;
    });

    setAnagramWords(temp);
  };

  const selectCategorizeToEdit = cards => {
    setCurrentGameId(cards.categoryId);
    setNewCategorizeInfo({
      teacherId: cards.teacherId,
      categoryId: cards.categoryId,
      description: cards.description,
      title: cards.name,
    });

    const temp = [
      ...cards.categories.map(item => ({
        name: item.name,
        number: +item.number,
        words: [],
      })),
    ];

    cards.words.forEach(word => {
      temp.forEach((category, index) => {
        if (+word.categoryNumber === +category.number) {
          temp[index].words.push(word);
        }
      });
    });

    setCategorizeCategories(temp);
  };

  const selectMatchupToEdit = cards => {
    setCurrentGameId(cards.categoryId);
    setNewMatchupInfo({
      teacherId: cards.teacherId,
      categoryId: cards.categoryId,
      description: cards.description,
      title: cards.name,
    });

    setMatchupRounds(
      cards.rounds.map((item, index) => {
        return item.items.map(pair => ({
          word: pair.sentence,
          translate: pair.word,
        }));
      }),
    );
  };

  const openCardsToEdit = (categoryId, cards_ = cardsList) => {
    if (!cards_.length) {
      getFlashCards();
    }
    if (categoryId) {
      const cards = cards_.find(item => item.categoryId === categoryId);

      if (cards) {
        selectCardsToEdit(cards);
        setEditingMode(cards.id);
      }
    } else {
      clearState(1);
    }
  };

  const openAnagramsToEdit = (categoryId, anagrams = anagramsList) => {
    if (!anagrams.length) {
      getAnagrams();
    }
    if (categoryId) {
      const cards = anagrams.find(item => item.categoryId === categoryId);

      if (cards) {
        selectAnagramsToEdit(cards);
        setEditingMode(cards.id);
      }
    } else {
      clearState(3);
    }
  };

  const openCategorizeToEdit = (categoryId, categorizes = categorizeList) => {
    if (!categorizes.length) {
      getCategorizes();
    }
    if (categoryId) {
      const cards = categorizes.find(item => item.categoryId === categoryId);

      if (cards) {
        selectCategorizeToEdit(cards);
        setEditingMode(cards.id);
      }
    } else {
      clearState(4);
    }
  };

  const handleCategorizeCategories = status => {
    makeEditingMode();
    if (!status) {
      setCategorizeCategories(prevState => {
        return prevState.slice(0, 2);
      });
    } else {
      const temp = [...categorizeCategories];

      temp.push({
        name: '',
        number: 3,
        words: [
          {
            number: 3,
            categoryNumber: 3,
            word: '',
          },
        ],
      });

      setCategorizeCategories(temp);
    }
  };

  const saveNewCards = async () => {
    setSaveLoading(true);

    if (
      !newCardInfo.title.trim() ||
      !flashWords.length ||
      !flashWords.every(el => el.word.trim() && el.translate.trim())
    ) {
      setOutlineEmptyField(true);
      showInfoNotification('Заполните обязательные поля');
    } else {
      const requestData = {
        name: newCardInfo.title,
        showTranslateFirst: newCardInfo.showTranslateFirst,
        words: flashWords.map(item => {
          return { word: item.word, translate: item.translate };
        }),
        wordsLength: flashWords.length,
      };

      try {
        const resultGame = await GamesService.createFlashCard(requestData);
        setCardsList(old => [resultGame, ...old]);
        showSuccessNotification('Набор успешно добавлен');
        await dispatch(setEditing(false));
      } catch (err) {
        showErrorNotification('Не удалось сохранить, попробуйте позже');
      }
    }

    setSaveLoading(false);
  };

  const saveNewMatchups = async () => {
    setSaveLoading(true);

    if (
      !newMatchupInfo.title.trim() ||
      !matchupRounds.every(el => el.length) ||
      !matchupRounds.every(el =>
        el.every(item => item.word.trim() && item.translate.trim().length),
      )
    ) {
      setOutlineEmptyField(true);
      showInfoNotification('Заполните обязательные поля');
    } else {
      const requestData = {
        name: newMatchupInfo.title,
        description: newMatchupInfo.description,
        rounds: matchupRounds.map(item => ({
          items: item.map(pair => ({
            word: pair.translate,
            sentence: pair.word,
          })),
        })),
      };

      try {
        const resultGame = await GamesService.createMatchup(requestData);
        setMatchupList(old => [resultGame, ...old]);
        showSuccessNotification('Набор успешно добавлен');
        await dispatch(setEditing(false));
      } catch (err) {
        showErrorNotification('Не удалось сохранить, попробуйте позже');
      }
    }

    setSaveLoading(false);
  };

  const saveNewCategorize = async () => {
    setSaveLoading(true);

    if (
      !newCategorizeInfo.title.trim() ||
      categorizeCategories.length < 2 ||
      !categorizeCategories.every(el =>
        el.words.every(item => item.name?.trim()),
      ) ||
      !categorizeCategories.every(el => el.name)
    ) {
      setOutlineEmptyField(true);
      showInfoNotification('Заполните обязательные поля');
    } else if (
      categorizeCategories.length >
      categorizeCategories.map(el => el.words).flat(2).length
    ) {
      showInfoNotification('Минимальное число слов равно числу категорий');
    } else {
      const requestData = {
        name: newCategorizeInfo.title,
        description: newCategorizeInfo.description,
        categories: categorizeCategories.map(item => ({
          name: item.name,
          number: `${item.number}`,
        })),
        words: [],
      };

      categorizeCategories.forEach(item => {
        item.words.forEach(word => {
          requestData.words.push({
            name: word.name,
            categoryNumber: `${word.categoryNumber}`,
          });
        });
      });

      requestData.words = requestData.words.sort(() => 0.5 - Math.random());

      try {
        const resultGame = await GamesService.createCategorize(requestData);
        setCategorizeList(old => [resultGame, ...old]);
        showSuccessNotification('Набор успешно добавлен');
        await dispatch(setEditing(false));
      } catch (err) {
        showErrorNotification('Не удалось сохранить, попробуйте позже');
      }
    }

    setSaveLoading(false);
  };

  const saveNewAnagram = async () => {
    setSaveLoading(true);

    if (
      !newAnagramInfo.title.trim() ||
      !anagramWords.length ||
      !anagramWords.every(
        el => el.hint.trim() && el.translate.trim().length > 1,
      )
    ) {
      setOutlineEmptyField(true);
      showInfoNotification('Заполните обязательные поля');
    } else {
      const imagesForUploadPromises = [];
      anagramWords.forEach(item => {
        if (item.file?.name) {
          imagesForUploadPromises.push(
            uploadFile(item.file.blob, item.file.name, 'image'),
          );
        }
      });
      const imagesURL = await Promise.all(imagesForUploadPromises);

      const requestData = {
        name: newAnagramInfo.title,
        description: newAnagramInfo.description,
        questions: [],
      };

      requestData.questions = [
        ...anagramWords.map(item => {
          const body = {
            // question: item.word,
            hint: item.hint,
            answer: item.translate,
          };
          if (item.file.name) {
            body.image = imagesURL.shift();
          }
          return body;
        }),
      ];

      try {
        const resultGame = await GamesService.createAnagram(requestData);
        setAnagramsList(old => [resultGame, ...old]);
        showSuccessNotification('Набор успешно добавлен');
        await dispatch(setEditing(false));
      } catch (err) {
        showErrorNotification('Не удалось сохранить, попробуйте позже');
      }
    }

    setSaveLoading(false);
  };

  const updateFlashCards = async id => {
    setSaveLoading(true);

    const sendUpdates = async () => {
      if (
        !newCardInfo.title.trim() ||
        !flashWords.length ||
        !flashWords.every(el => el.word && el.translate)
      ) {
        setOutlineEmptyField(true);
        showInfoNotification('Заполните обязательные поля');
        setSaveLoading(false);
      } else {
        const requestData = {
          name: newCardInfo.title,
          showTranslateFirst: newCardInfo.showTranslateFirst,
          words: flashWords.map(item => {
            return { word: item.word, translate: item.translate };
          }),
          wordsLength: flashWords.length,
        };

        try {
          const updatedGame = await GamesService.updateGame(
            tabId,
            currentGameId,
            requestData,
          );
          const newCardsList = cardsList.map(el =>
            el.categoryId === id ? updatedGame : el,
          );
          setCardsList(newCardsList);
          showSuccessNotification('Игра успешно обновлена');
          await dispatch(setEditing(false));
        } catch (err) {
          showErrorNotification('Не удалось обновить игру');
        }
        setSaveLoading(false);
      }
    };

    sendUpdates();
  };

  const updateMatchups = async id => {
    setSaveLoading(true);

    const sendUpdates = async () => {
      if (
        !newMatchupInfo.title.trim() ||
        !matchupRounds.every(el => el.length) ||
        !matchupRounds.every(el =>
          el.every(item => item.word.trim() && item.translate.trim()),
        )
      ) {
        setOutlineEmptyField(true);
        showInfoNotification('Заполните обязательные поля');
        setSaveLoading(false);
      } else {
        const requestData = {
          name: newMatchupInfo.title,
          description: newMatchupInfo.description,
          rounds: matchupRounds.map(item => ({
            items: item.map(pair => ({
              word: pair.translate,
              sentence: pair.word,
            })),
          })),
        };

        try {
          const updatedGame = await GamesService.updateGame(
            tabId,
            currentGameId,
            requestData,
          );
          const newMatchupList = matchupList.map(el =>
            el.categoryId === id ? updatedGame : el,
          );
          setMatchupList(newMatchupList);
          showSuccessNotification('Игра успешно обновлена');
          await dispatch(setEditing(false));
        } catch (err) {
          showErrorNotification('Не удалось обновить игру');
        }

        setSaveLoading(false);
      }
    };

    sendUpdates();
  };

  const updateCategorizes = async id => {
    setSaveLoading(true);

    const sendUpdates = async () => {
      if (
        !newCategorizeInfo.title.trim() ||
        categorizeCategories.length < 2 ||
        !categorizeCategories.every(el =>
          el.words.every(item => item.name?.trim()),
        ) ||
        !categorizeCategories.every(el => el.name)
      ) {
        setOutlineEmptyField(true);
        showInfoNotification('Заполните обязательные поля');
        setSaveLoading(false);
      } else if (
        categorizeCategories.length >
        categorizeCategories.map(el => el.words).flat(2).length
      ) {
        showInfoNotification('Минимальное число слов равно числу категорий');
      } else {
        const requestData = {
          name: newCategorizeInfo.title,
          description: newCategorizeInfo.description,
          categories: categorizeCategories.map(item => ({
            name: item.name,
            number: `${item.number}`,
          })),
          words: [],
        };

        categorizeCategories.forEach(item => {
          item.words.forEach(word => {
            requestData.words.push({
              name: word.name,
              categoryNumber: `${word.categoryNumber}`,
            });
          });
        });

        requestData.words = requestData.words.sort(() => 0.5 - Math.random());

        try {
          const updatedGame = await GamesService.updateGame(
            tabId,
            currentGameId,
            requestData,
          );
          const newCategorizeList = categorizeList.map(el =>
            el.categoryId === id ? updatedGame : el,
          );
          setCategorizeList(newCategorizeList);
          showSuccessNotification('Игра успешно обновлена');
          await dispatch(setEditing(false));
        } catch (err) {
          showErrorNotification('Не удалось обновить игру');
        }

        setSaveLoading(false);
      }
    };

    sendUpdates();
  };

  const updateAnagrams = async id => {
    setSaveLoading(true);

    const sendUpdates = async () => {
      if (
        !newAnagramInfo.title.trim() ||
        !anagramWords.length ||
        !anagramWords.every(el => el.hint && el.translate.length > 1)
      ) {
        setOutlineEmptyField(true);
        showInfoNotification('Заполните обязательные поля');
        setSaveLoading(false);
      } else {
        const imagesForUploadPromises = [];
        anagramWords.forEach(item => {
          if (item.file?.name) {
            imagesForUploadPromises.push(
              uploadFile(item.file.blob, item.file.name, 'image'),
            );
          }
        });

        const imagesURL = await Promise.all(imagesForUploadPromises);

        const requestData = {
          name: newAnagramInfo.title,
          description: newAnagramInfo.description,
          questions: [],
        };

        requestData.questions = [
          ...anagramWords.map(item => {
            const body = {
              // question: item.word,
              hint: item.hint,
              answer: item.translate,
            };
            if (item.file?.name) {
              body.image = imagesURL.shift();
            }
            return body;
          }),
        ];

        try {
          const updatedGame = await GamesService.updateGame(
            tabId,
            currentGameId,
            requestData,
          );
          const newAnagramsList = anagramsList.map(el =>
            el.categoryId === id ? updatedGame : el,
          );
          setAnagramsList(newAnagramsList);
          showSuccessNotification('Игра успешно обновлена');
          await dispatch(setEditing(false));
        } catch (err) {
          showErrorNotification('Не удалось обновить игру');
        }

        setSaveLoading(false);
      }
    };

    sendUpdates();
  };

  const renderAddQuestionButtonStyle = (item, max = 0) => {
    let style = 'group-info__addQuestion';
    const collectionObject = defineCategoryObject();
    if (
      (collectionObject.categoryId &&
        !(isAdmin || collectionObject.teacherId === userData.teacherId)) ||
      (max && item.length >= max)
    ) {
      style = 'group-info__addQuestion_disabled';
    }
    return style;
  };

  const renderAddQuestionIconStyle = () => {
    let style = 'group-info__addQuestion__icon';
    const collectionObject = defineCategoryObject();
    if (
      collectionObject.categoryId &&
      !(isAdmin || collectionObject.teacherId === userData.teacherId)
    ) {
      style = 'group-info__addQuestion__icon_disabled';
    }
    return style;
  };

  const handleAddWord = (type, index) => {
    makeEditingMode();
    let temp;
    switch (type) {
      case 'flash':
        if (
          !newCardInfo.categoryId ||
          isAdmin ||
          newCardInfo.teacherId === userData.teacherId
        ) {
          flashWordsNumber += 1;
          setFlashWords([
            ...flashWords,
            {
              number: flashWordsNumber,
              word: '',
              translate: '',
            },
          ]);
        }
        break;
      case 'matchup':
        if (
          !newMatchupInfo.categoryId ||
          isAdmin ||
          newMatchupInfo.teacherId === userData.teacherId
        ) {
          matchupWordsNumber += 1;
          temp = [...matchupRounds];

          temp[index].push({
            number: matchupWordsNumber,
            word: '',
            translate: '',
          });
          if (temp[index].length === 7) {
            showWarningNotification('Количество пар не может быть больше 6');
            return;
          }
          setMatchupRounds(temp);
        }
        break;
      case 'anagram':
        if (
          !newAnagramInfo.categoryId ||
          isAdmin ||
          newAnagramInfo.teacherId === userData.teacherId
        ) {
          anagramWordsNumber += 1;
          setAnagramWords([
            ...anagramWords,
            {
              number: anagramWordsNumber,
              // word: '',
              file: { name: '', blob: '' },
              hint: '',
              translate: '',
            },
          ]);
        }
        break;
      case 'categorize':
        if (
          !newCategorizeInfo.categoryId ||
          isAdmin ||
          newCategorizeInfo.teacherId === userData.teacherId
        ) {
          if (categorizeWordsNumber >= 30) {
            return;
          }
          categorizeWordsNumber += 1;
          temp = [...categorizeCategories];
          temp[index].words.push({
            number: categorizeWordsNumber,
            categoryNumber: index + 1,
            word: '',
          });
          setCategorizeCategories(temp);
        }
        break;
      default:
        return;
    }
  };

  const handleRemoveWord = (index, type, categoryIndex) => {
    let oldWords;
    switch (type) {
      case 'flash':
        oldWords = flashWords.slice(0);
        if (oldWords.length > 1) {
          makeEditingMode();
          oldWords.splice(parseInt(index, 10), 1);
          setFlashWords(oldWords);
        }
        break;
      case 'matchup':
        oldWords = matchupRounds.slice(0);
        if (oldWords[categoryIndex].length > 1) {
          makeEditingMode();
          oldWords[categoryIndex].splice(parseInt(index, 10), 1);
          setMatchupRounds(oldWords);
        }
        break;
      case 'anagram':
        oldWords = anagramWords.slice(0);
        if (oldWords.length > 1) {
          makeEditingMode();
          oldWords.splice(parseInt(index, 10), 1);
          setAnagramWords(oldWords);
        }
        break;
      case 'categorize':
        oldWords = categorizeCategories.slice(0);
        makeEditingMode();
        oldWords[categoryIndex].words.splice(parseInt(index, 10), 1);
        setCategorizeCategories(oldWords);
        break;
      default:
        return;
    }
  };

  const onWordChange = (e, index, type, categoryIndex) => {
    makeEditingMode();
    let oldWords;
    switch (type) {
      case 'flash':
        oldWords = flashWords.slice(0);
        oldWords[index].word = e.target.value;
        setFlashWords(oldWords);
        break;

      case 'matchup':
        oldWords = matchupRounds.slice(0);

        oldWords[categoryIndex][index].word = e.target.value;

        setMatchupRounds(oldWords);
        break;

      // case 'anagram':
      //   oldWords = anagramWords.slice(0);
      //   oldWords[index].word = e.target.value;
      //   setAnagramWords(oldWords);
      //   break;

      case 'categorize':
        oldWords = categorizeCategories.slice(0);
        oldWords[categoryIndex].words[index].name = e.target.value;
        setCategorizeCategories(oldWords);
        break;

      default:
        return;
    }
  };

  const handleChangeCategoryName = (value, index) => {
    makeEditingMode();
    const temp = [...categorizeCategories];
    temp[index].name = value;
    setCategorizeCategories(temp);
  };

  const onHintChange = (e, index, type) => {
    makeEditingMode();
    let oldWords;
    switch (type) {
      case 'anagram':
        oldWords = anagramWords.slice(0);
        oldWords[index].hint = e.target.value;
        setAnagramWords(oldWords);
        break;

      default:
        return;
    }
  };

  const onTranslateChange = (e, index, type, categoryIndex) => {
    makeEditingMode();
    let oldWords;
    switch (type) {
      case 'flash':
        oldWords = flashWords.slice(0);
        oldWords[index].translate = e.target.value;
        setFlashWords(oldWords);
        break;

      case 'matchup':
        oldWords = matchupRounds.slice(0);
        oldWords[categoryIndex][index].translate = e.target.value;
        setMatchupRounds(oldWords);
        break;

      case 'anagram':
        oldWords = anagramWords.slice(0);
        const currentInput = e.target.value;
        if (
          currentInput.split('').filter(el => el !== ' ').length <= 49 &&
          currentInput.split(' ').slice(-1)[0].length <= 21
        ) {
          oldWords[index].translate = currentInput;
          setAnagramWords(oldWords);
        }
        break;

      default:
        return;
    }
  };

  const removeFile = (index, type) => {
    makeEditingMode();
    let oldWords;
    switch (type) {
      case 'anagram':
        oldWords = anagramWords.slice(0);
        oldWords[index].file = { name: '', blob: '' };
        setAnagramWords(oldWords);
        break;
      default:
        return;
    }
  };

  const handleMatchupRounds = (add, index) => {
    makeEditingMode();
    const temp = [...matchupRounds];
    if (add) {
      temp.push([
        {
          number: matchupWordsNumber,
          word: '',
          translate: '',
        },
      ]);
    } else {
      temp.pop();
    }
    if (temp.length === 0) {
      showWarningNotification('Количество раундов не может быть меньше 1');
      return;
    } else if (temp.length === 11) {
      showWarningNotification('Количество раундов не может быть больше 10');
      return;
    }
    setMatchupRounds(temp);
  };

  const handleFileChange = (e, index, type) => {
    // addChangedFields('file')
    makeEditingMode();
    const file = e.target.files[0];
    const name = file.name;
    if (file.size > 52428800) {
      showErrorNotification('Размер материала не может быть больше 50 мб');
      e.target.value = '';
      return;
    }
    const blob = new Blob([file]);
    const hint = {
      name,
      blob,
    };
    let oldWords;
    switch (type) {
      case 'anagram':
        oldWords = anagramWords.slice(0);
        oldWords[index].file = hint;
        setAnagramWords(oldWords);
        break;
      default:
        return;
    }
  };

  const shareGames = async id => {
    try {
      const addGames = groupsWithoutGame
        .filter(item => item.active === true)
        .map(item => item.groupId);
      const removeGames = groupsWithGame
        .filter(item => item.active === false)
        .map(item => item.groupId);
      await GamesService.addGames(tabId, {
        categoryId: id,
        groupId: [...addGames],
        date: selectedDate,
      });
      await GamesService.removeGames(tabId, {
        categoryId: id,
        groupId: [...removeGames],
      });
      showSuccessNotification('Набор привязан к группам');
    } catch (err) {
      showErrorNotification(
        'Набор создан, но не удалось привязать набор к группам',
      );
    }
  };

  const clearState = tabIndex => {
    setEditingMode(null);
    switch (tabIndex) {
      case 1:
        setNewCardInfo(FLASH_INITIALS.newCardInfo);
        setFlashWords(FLASH_INITIALS.words);
        break;
      case 2:
        setNewMatchupInfo(MATCHUP_INITIALS.newMatchupInfo);
        setMatchupRounds(MATCHUP_INITIALS.rounds);
        break;
      case 3:
        setNewAnagramInfo(ANAGRAM_INITIALS.newAnagramInfo);
        setAnagramWords(ANAGRAM_INITIALS.words);
        break;
      case 4:
        setNewCategorizeInfo(CATEGORIZE_INITIALS.newCategorizeInfo);
        setCategorizeCategories(CATEGORIZE_INITIALS.categories);
        break;
      default:
        return;
    }
  };

  const getNewSelection = (toggler, color) => {
    return {
      toggler,
      color,
    };
  };

  const deleteGame = async id => {
    setDeleteLoading(true);
    try {
      await GamesService.deleteGames(tabId, currentGameId);
      switch (tabId) {
        case 1:
          const newCardsList = cardsList.filter(el => el.categoryId !== id);
          setCardsList(newCardsList);
          break;
        case 2:
          const newMatchupList = matchupList.filter(el => el.categoryId !== id);
          setMatchupList(newMatchupList);
          break;
        case 3:
          const newAnagramsList = anagramsList.filter(
            el => el.categoryId !== id,
          );
          setAnagramsList(newAnagramsList);
          break;
        case 4:
          const newCategorizeList = categorizeList.filter(
            el => el.categoryId !== id,
          );
          setCategorizeList(newCategorizeList);
          break;
        default:
          return;
      }
      showSuccessNotification('Набор упешно удален');
      await dispatch(setEditing(false));
      setShowDeleteModal(false);
      history.push(`/games/${getRoute(tabId)}`);
    } catch (err) {
      showErrorNotification('Не удалось удалить набор');
    }
    clearState(tabId);
    setDeleteLoading(false);
  };

  const handleTab = () => {
    setOutlineEmptyField(false);
    setNextLinkItem(null);
  };

  const handleChangeCard = (type, field, value) => {
    makeEditingMode();
    switch (type) {
      case 'flash':
        setNewCardInfo({
          ...newCardInfo,
          [field]: value,
        });
        break;

      case 'matchup':
        setNewMatchupInfo({
          ...newMatchupInfo,
          [field]: value,
        });
        break;

      case 'anagram':
        setNewAnagramInfo({
          ...newAnagramInfo,
          [field]: value,
        });
        break;

      case 'categorize':
        setNewCategorizeInfo({
          ...newCategorizeInfo,
          [field]: value,
        });
        break;
      default:
        return;
    }
  };

  const handleShowTranslateFirst = value => {
    makeEditingMode();
    setNewCardInfo({
      ...newCardInfo,
      showTranslateFirst: value,
    });
  };

  const showAttachingGameModal = () => {
    setShowModal(true);
  };

  const attachFlashCards = async () => {
    setAttachingGameLoading(true);

    if (selectedOrganizer.type === 'group') {
      try {
        await GamesService.addFlashCardsToGroup({
          categoryId: currentGameId,
          groupId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification('Игра успешно привязан к группе');
        setShowModal(false);
      } catch (err) {
        showErrorNotification('Не удалось привязать игру к группе');
      }
      setAttachingGameLoading(false);
    } else {
      try {
        await GamesService.addFlashCardsToPersonal({
          categoryId: currentGameId,
          classId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification(
          'Игра успешно привязана к персональному занятию',
        );
        setShowModal(false);
      } catch (err) {
        showErrorNotification(
          'Не удалось привязать игру к персональному занятию',
        );
      }
      setAttachingGameLoading(false);
    }
  };

  const attachMatchUp = async () => {
    setAttachingGameLoading(true);

    if (selectedOrganizer.type === 'group') {
      try {
        await GamesService.addMatchUpToGroup({
          categoryId: currentGameId,
          groupId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification('Игра успешно привязан к группе');
        setShowModal(false);
      } catch (err) {
        showErrorNotification('Не удалось привязать игру к группе');
      }
      setAttachingGameLoading(false);
    } else {
      try {
        await GamesService.addMatchUpToPersonal({
          categoryId: currentGameId,
          classId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification(
          'Игра успешно привязана к персональному занятию',
        );
        setShowModal(false);
      } catch (err) {
        showErrorNotification(
          'Не удалось привязать игру к персональному занятию',
        );
      }
      setAttachingGameLoading(false);
    }
  };

  const attachAnagrams = async () => {
    setAttachingGameLoading(true);

    if (selectedOrganizer.type === 'group') {
      try {
        await GamesService.addAnagramsToGroup({
          categoryId: currentGameId,
          groupId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification('Игра успешно привязан к группе');
        setShowModal(false);
      } catch (err) {
        showErrorNotification('Не удалось привязать игру к группе');
      }
      setAttachingGameLoading(false);
    } else {
      try {
        await GamesService.addAnagramsToPersonal({
          categoryId: currentGameId,
          classId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification(
          'Игра успешно привязана к персональному занятию',
        );
        setShowModal(false);
      } catch (err) {
        showErrorNotification(
          'Не удалось привязать игру к персональному занятию',
        );
      }
      setAttachingGameLoading(false);
    }
  };

  const attachCategorize = async () => {
    setAttachingGameLoading(true);
    if (selectedOrganizer.type === 'group') {
      try {
        await GamesService.addCategorizeToGroup({
          categoryId: currentGameId,
          groupId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification('Игра успешно привязан к группе');
        setShowModal(false);
      } catch (err) {
        showErrorNotification('Не удалось привязать игру к группе');
      }
      setAttachingGameLoading(false);
    } else {
      try {
        await GamesService.addCategorizeToPersonal({
          categoryId: currentGameId,
          classId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification(
          'Игра успешно привязана к персональному занятию',
        );
        setShowModal(false);
      } catch (err) {
        showErrorNotification(
          'Не удалось привязать игру к персональному занятию',
        );
      }
      setAttachingGameLoading(false);
    }
  };

  const attachGame = async () => {
    switch (tabId) {
      case 1:
        return attachFlashCards();
      case 2:
        return attachMatchUp();
      case 3:
        return attachAnagrams();
      case 4:
        return attachCategorize();
    }
  };

  const checkDisabling = () => {
    switch (tabId) {
      case 1:
        return !(isAdmin || newCardInfo.teacherId === userData.teacherId);
      case 2:
        return !(isAdmin || newMatchupInfo.teacherId === userData.teacherId);
      case 3:
        return !(isAdmin || newAnagramInfo.teacherId === userData.teacherId);
      case 4:
        return !(isAdmin || newCategorizeInfo.teacherId === userData.teacherId);
    }
  };

  return (
    <View
      cardsList={cardsList}
      newCardInfo={newCardInfo}
      saveLoading={saveLoading}
      saveNewCards={saveNewCards}
      groupsList={groupsList}
      groupIdMap={groupIdMap}
      flashWords={flashWords}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      onWordChange={onWordChange}
      onTranslateChange={onTranslateChange}
      handleAddWord={handleAddWord}
      handleRemoveWord={handleRemoveWord}
      openCardsToEdit={openCardsToEdit}
      clearState={clearState}
      deleteGame={deleteGame}
      saveNewMatchups={saveNewMatchups}
      matchupList={matchupList}
      handleMatchupRounds={handleMatchupRounds}
      matchupRounds={matchupRounds}
      openAnagramsToEdit={openAnagramsToEdit}
      anagramsList={anagramsList}
      saveNewAnagram={saveNewAnagram}
      openCategorizeToEdit={openCategorizeToEdit}
      openMatchupToEdit={openMatchupToEdit}
      categorizeList={categorizeList}
      saveNewCategorize={saveNewCategorize}
      handleCategorizeCategories={handleCategorizeCategories}
      handleChangeCategoryName={handleChangeCategoryName}
      removeFile={removeFile}
      handleFileChange={handleFileChange}
      matchupWords={matchupWords}
      categorizeCategories={categorizeCategories}
      anagramWords={anagramWords}
      newAnagramInfo={newAnagramInfo}
      newCategorizeInfo={newCategorizeInfo}
      newMatchupInfo={newMatchupInfo}
      wrongTime={wrongTime}
      setWrongTime={setWrongTime}
      handleChangeCard={handleChangeCard}
      handleTab={handleTab}
      onHintChange={onHintChange}
      tabId={tabId}
      SHOW_TRANSLATION_FIRST={SHOW_TRANSLATION_FIRST}
      handleShowTranslateFirst={handleShowTranslateFirst}
      showModal={showModal}
      setShowModal={setShowModal}
      attachingGameLoading={attachingGameLoading}
      showAttachingGameModal={showAttachingGameModal}
      teachers={teachers}
      students={students}
      organizers={organizers}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={setSelectedTeacher}
      selectedOrganizer={selectedOrganizer}
      setSelectedOrganizer={setSelectedOrganizer}
      attachGame={attachGame}
      deleteLoading={deleteLoading}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      updateFlashCards={updateFlashCards}
      updateMatchups={updateMatchups}
      updateAnagrams={updateAnagrams}
      updateCategorizes={updateCategorizes}
      getRoute={getRoute}
      isAdmin={isAdmin}
      checkDisabling={checkDisabling}
      userData={userData}
      renderAddQuestionButtonStyle={renderAddQuestionButtonStyle}
      renderAddQuestionIconStyle={renderAddQuestionIconStyle}
      defineCategoryObject={defineCategoryObject}
      outlineEmptyField={outlineEmptyField}
      setNextLinkItem={setNextLinkItem}
      selectFile={selectFile}
      deleteHint={deleteHint}
      handleContainerOnBottom={handleContainerOnBottom}
      flashcardsLoading={flashcardsLoading}
      anagramsLoading={anagramsLoading}
      categorizeLoading={categorizeLoading}
      matchupLoading={matchupLoading}
      getNewSelection={getNewSelection}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CardsContainer);
