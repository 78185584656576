import React from 'react';

import { useSelector } from 'react-redux';
import {
  getStudentsFormattedName,
  getTeachersFormattedName,
} from '../../core/functions';
import Loader from '../loader/Loader';

import View from './view';

const ClassesContainer = ({
  renderEmpty = () => {},
  loading = false,
  data = [],
  last = false,
  renderControl,
  type = 'teacher',
  paginate = () => {},
  isAdmin,
}) => {
  const teachers = useSelector(state => state.teachers);
  const students = useSelector(state => state.students);

  const getTeacherName = id => {
    if (!teachers?.length) {
      return <Loader />;
    }
    return getTeachersFormattedName(id, teachers);
  };

  const getStudentName = id => {
    if (!teachers?.length) {
      return <Loader />;
    }
    return getStudentsFormattedName(id, students);
  };

  return (
    <View
      isAdmin={isAdmin}
      type={type}
      paginate={paginate}
      getTeacherName={getTeacherName}
      getStudentName={getStudentName}
      data={data}
      loading={loading}
      renderEmpty={renderEmpty}
      last={last}
      renderControl={renderControl}
    />
  );
};

export default ClassesContainer;
