import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import moment from 'moment';
import 'moment/locale/ru';
import Select from '../select/index';
import { getGroupTeacherId } from '../../core/functions';
moment.locale('ru');

const UsersTableView = props => {
  const TableLine = data => {
    const fromDate = moment(data.date);
    const tillDate = moment(data.date).add(data.duration, 'm');
    const sourcePath = data.type === 'group' ? 'groups/' : 'users/';
    const isPast = moment(new Date()).isAfter(data.event.date);
    const isFree =
      data.event.type === 'personal-class' && !data.event.studentId === true;
    const isGroup = data.event.type === 'group';
    return (
      <div
        className={`table-line ${
          data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
        }`}>
        <div className="box box-1">
          <div>{fromDate.format('DD MMMM, dd')}</div>
          <div>
            {fromDate.format('HH:mm')} - {tillDate.format('HH:mm')}
          </div>
        </div>
        {props.isAdmin ? (
          <NavLink to={data.teacherLink} className="box box-2">
            <div>{data.teacher}</div>
          </NavLink>
        ) : null}
        <NavLink
          to={data.studentId ? sourcePath + data.studentId : 'calendar'}
          className="box box-3">
          {data.student}
        </NavLink>
        <div className="box box-4">
          <Button
            type="primary"
            className="lessons-table-change"
            small
            textButton="Просмотр"
            onMouseUp={() => {
              props.onPressChange(data.event);
            }}
          />
          <Button
            type="secondary"
            small
            disabled={isGroup ? isPast : !isFree}
            textButton="Удаление"
            onMouseUp={() => {
              props.onPressDelete(data.event);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="table lessons-table">
      <div className="table-header table-line">
        <div className="box box-1">Дата</div>
        {props.isAdmin ? <div className="box box-2">Учитель</div> : null}
        <div className="box box-3">Обучаемый</div>
        <div className="box box-4" />
      </div>
      <div className="table-list">
        {props.loading ? (
          <Loader />
        ) : props.data?.length ? (
          props.data.map((el, index) => {
            const type = el.type;
            const teacherId =
              type === 'group'
                ? getGroupTeacherId(el.groupId, props.groups)
                : el.teacherId;
            const teacherName = props.getTeacherName(teacherId);
            const studentId = type === 'group' ? el.groupId : el.studentId;
            const student =
              type === 'group'
                ? props.getGroupName(el.groupId)
                : studentId
                ? props.getStudentName(el.studentId)
                : 'Свободно';
            return (
              <TableLine
                event={el}
                key={index}
                index={index}
                date={el.date}
                type={type}
                duration={el.duration}
                student={student}
                studentId={studentId}
                teacher={teacherName}
                teacherLink={teacherId ? `teachers/${teacherId}` : 'calendar'}
              />
            );
          })
        ) : null}
      </div>
      {!props.data?.length ? (
        <div className="lessons-no-data-error">
          На выбранную дату нет занятий
        </div>
      ) : null}
      <div className="lessons-table-bottom-bar">
        <div className="lessons-table-button-container">
          <Button
            type="primary"
            small
            onClick={props.onPressNew}
            textButton="Новое занятие"
          />
        </div>
        {props.displayLoading ? <Loader /> : null}
        {props.isLast ? <div>Были загружены последние занятия.</div> : null}
        {props.isAdmin ? (
          <Select
            value={
              props.selectedTeacher
                ? props.getTeacherName(props.selectedTeacher)
                : ''
            }
            data={props.teachers}
            search={true}
            onReset={() => props.setSelectedTeacher()}
            placeholder={'Выберите учителя'}
            renderOption={el => {
              return (
                <div
                  className="option"
                  onMouseDown={() => {
                    props.setSelectedTeacher(el.teacherId);
                  }}>
                  {props.getTeacherName(el.teacherId)}
                </div>
              );
            }}
            extractSearchKey={el => props.getTeacherName(el.teacherId)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UsersTableView;
