/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getDate, getTime } from '../../core/functions';
import MaterialsView from './view';
import {
  GroupsService,
  MaterialsService,
  StorageService,
} from '../../services';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
  showInfoNotification,
} from '../../core/errorsController';
import { getId } from '../../core/functions';
import { setEditing, setScroll } from '../../store/reducers/appState-reducer';

const INITIALS = {
  newMaterialInfo: {
    title: '',
    source: '',
    description: '',
    markdown: '',
    date: '',
    time: '',
    type: '',
    video: '',
  },
};

const MaterialsContainer = props => {
  const userData = useSelector(state => state.user);
  const organizers = useSelector(state => [
    ...state.groups,
    ...state.personals,
  ]);
  const teachers = useSelector(state => state.teachers);
  const students = useSelector(state => state.students);
  const isEditing = useSelector(state => state.appState.isEditing);
  const [materialsList, setMaterialsList] = useState([]);
  const [attachingMaterialLoading, setAttachingMaterialLoading] =
    useState(false);
  const [currentMaterialId, setCurrentMaterialId] = useState();
  // const [groupIdMap, setGroupIdMap] = useState(new Map());
  const [fileLoading, setFileLoading] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [poster, setPoster] = useState(null);
  const [audio, setAudio] = useState(null);
  const [file, setFile] = useState(null);
  // const [changedFields, setChangedFields] = useState([])
  const [newMaterialInfo, setNewMaterialInfo] = useState(
    INITIALS.newMaterialInfo,
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [outlineEmptyField, setOutlineEmptyField] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrganizer, setSelectedOrganizer] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isAdmin = useSelector(state => state.user).role === 'admin';
  const history = useHistory();
  const dispatch = useDispatch();
  const externalPosterRef = useRef(null);
  const externalAudioRef = useRef(null);
  const externalFileRef = useRef(null);
  const [nextLinkItem, setNextLinkItem] = useState(null);
  const [wrongTime, setWrongTime] = useState(false);

  //Pagination
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const id = getId(history.location.pathname);
    if (id) {
      getMaterialFromLink(id);
    }
    getMaterials();
    const unsubscribe = history.listen(({ pathname }) => {
      setMaterialsList(old => {
        openMaterialToEdit(getId(pathname), old);
        return old;
      });
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  const uploadFile = async (blob, salt, addr) => {
    if (blob) {
      return await StorageService.uploadFile(blob, addr, salt);
    }
    return '';
  };

  const clearExternalRefs = () => {
    if (externalFileRef.current) {
      externalFileRef.current.value = '';
    }
    if (externalPosterRef.current) {
      externalPosterRef.current.value = '';
    }
    if (externalAudioRef.current) {
      externalAudioRef.current.value = '';
    }
  };

  const getMaterialFromLink = async materialId => {
    try {
      const result = await MaterialsService.getMaterial(materialId);
      selectMaterialToEdit(result.data.data);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const getMaterials = async () => {
    if (!isLast && !isLoading) {
      setIsLoading(true);
      try {
        const result = await MaterialsService.getMaterials(
          userData.teacherId,
          page,
        );
        setIsLast(result.last);
        setPage(old => old + 1);
        setMaterialsList(old => [...old, ...result.data]);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setIsLoading(false);
    }
  };

  const selectMaterialToEdit = material => {
    setCurrentMaterialId(material.materialId);
    setNewMaterialInfo({
      title: material.title,
      teacherId: material.teacherId,
      source: material.source,
      date: getDate(material.createdTime),
      time: getTime(material.createdTime),
      type: material.type,
      description: material.description,
      poster: material.poster,
      video: material.video || '',
      markdown: material.markdown || '',
      audio: material.audio || '',
      file: material.file || '',
      id: material.materialId,
    });
  };

  const openMaterialToEdit = async (id, materials = materialsList) => {
    clearExternalRefs();

    if (id) {
      const material = materials.find(item => item.materialId === id);
      if (material) {
        selectMaterialToEdit(material);
      }
    } else {
      clearState();
    }
  };

  const makeEditingMode = () => {
    if (!isEditing) {
      dispatch(setEditing(true));
    }
  };

  const handleMaterialChange = (field, value, e) => {
    // addChangedFields(field)
    makeEditingMode();
    const temp = { ...newMaterialInfo };
    temp[field] = value;
    setNewMaterialInfo(temp);
  };

  const handleMaterialSourceChange = value => {
    if (value.length > 300) {
      showErrorNotification('Недопустимая длина ссылки на ресурс');
    } else {
      setNewMaterialInfo({
        ...newMaterialInfo,
        source: value,
      });
    }
  };

  const handleMaterialMarkdownChange = async markup => {
    // addChangedFields('markdown')
    // if is used to escape making editing mode on 1st render of EditorView
    if (newMaterialInfo.markdown !== markup) {
      makeEditingMode();
    }
    setNewMaterialInfo({
      ...newMaterialInfo,
      markdown: markup,
    });
  };

  const handleMaterialDescriptionChange = async e => {
    makeEditingMode();
    setNewMaterialInfo({
      ...newMaterialInfo,
      description: e,
    });
  };

  const handleMaterialStartDateChange = async e => {
    makeEditingMode();
    setNewMaterialInfo({
      ...newMaterialInfo,
      date: e.target.value,
    });
  };
  const handleMaterialStartTimeChange = async e => {
    makeEditingMode();
    setNewMaterialInfo({
      ...newMaterialInfo,
      time: e.target.value,
    });
  };
  const handleMaterialVideoLinkChange = async e => {
    makeEditingMode();
    setNewMaterialInfo({
      ...newMaterialInfo,
      video: e.target.value,
    });
  };
  const handleMaterialTypeChange = async type => {
    makeEditingMode();
    handleMaterialDescriptionChange('');
    setOutlineEmptyField(false);
    setNewMaterialInfo({
      ...newMaterialInfo,
      type,
    });
  };

  const saveNewMaterial = async () => {
    setSaveLoading(true);

    const sendMaterial = async () => {
      if (
        !newMaterialInfo.title ||
        !newMaterialInfo.type ||
        !(newMaterialInfo.description || newMaterialInfo.markdown) ||
        (newMaterialInfo.type !== 'standart' &&
          !(newMaterialInfo.type === 'audio' && audio) &&
          !(newMaterialInfo.type === 'file' && file) &&
          !(newMaterialInfo.type === 'video' && newMaterialInfo.video))
      ) {
        setOutlineEmptyField(true);
        showInfoNotification('Заполните обязательные поля');
        setSaveLoading(false);
      } else {
        let requestData = {
          poster: newMaterialInfo.poster,
          title: newMaterialInfo.title,
          source: newMaterialInfo.source,
          description: newMaterialInfo.description,
          type: newMaterialInfo.type,
        };

        if (requestData.type === 'video') {
          requestData.video = newMaterialInfo.video;
        }

        if (requestData.type === 'standart') {
          requestData.markdown = newMaterialInfo.markdown;
          requestData.description = new DOMParser().parseFromString(
            newMaterialInfo.markdown,
            'text/html',
          ).documentElement.textContent;
        }

        try {
          const posterUrl = uploadFile(
            poster?.blob,
            poster?.fileName,
            'poster',
          );
          const audioUrl = uploadFile(audio?.blob, audio?.fileName, 'audio');
          const fileUrl = uploadFile(file?.blob, file?.fileName, 'file');

          const posterPromise = new Promise((resolve, reject) =>
            resolve(posterUrl),
          );
          const audioPromise = new Promise((resolve, reject) =>
            resolve(audioUrl),
          );
          const filePromise = new Promise((resolve, reject) =>
            resolve(fileUrl),
          );

          Promise.all([posterPromise, audioPromise, filePromise])
            .then(async result => {
              if (result[0]) {
                requestData.poster = result[0];
              }
              if (result[1]) {
                requestData.audio = result[1];
              }
              if (result[2]) {
                requestData.file = result[2];
              }
            })
            .then(async () => {
              const resultMaterial = await MaterialsService.createMaterial({
                data: requestData,
              });
              setMaterialsList(old => [resultMaterial, ...old]);
              clearState();
              showSuccessNotification('Материал загружен');
              setSaveLoading(false);
              await dispatch(setEditing(false));
            })
            .catch(err => {
              setSaveLoading(false);
              throw err;
            });
        } catch (err) {
          showErrorNotification('Не удалось добавить материал');
        }
      }
    };
    if (
      newMaterialInfo.type === 'video' &&
      !(
        newMaterialInfo.video.substr(0, 17) === 'https://youtu.be/' ||
        newMaterialInfo.video.substr(0, 32) ===
          'https://www.youtube.com/watch?v='
      )
    ) {
      showWarningNotification('Можно использовать только ссылку с youtube');
      setSaveLoading(false);
    } else {
      sendMaterial();
      clearExternalRefs();
    }
  };

  const updateMaterial = async id => {
    setSaveLoading(true);

    const sendMaterial = async () => {
      if (
        !newMaterialInfo.title ||
        !newMaterialInfo.type ||
        !(newMaterialInfo.description || newMaterialInfo.markdown) ||
        (newMaterialInfo.type !== 'standart' &&
          !(
            newMaterialInfo.type === 'audio' &&
            (newMaterialInfo.audio || audio)
          ) &&
          !(
            newMaterialInfo.type === 'file' &&
            (newMaterialInfo.file || file)
          ) &&
          !(newMaterialInfo.type === 'video' && newMaterialInfo.video))
      ) {
        showInfoNotification('Заполните обязательные поля');
        setOutlineEmptyField(true);
        setSaveLoading(false);
      } else {
        let requestData = {
          poster: newMaterialInfo.poster,
          title: newMaterialInfo.title,
          source: newMaterialInfo.source,
          description: newMaterialInfo.description,
          type: newMaterialInfo.type,
        };

        if (requestData.type === 'video') {
          requestData.video = newMaterialInfo.video;
        }

        if (requestData.type === 'standart') {
          requestData.markdown = newMaterialInfo.markdown;
          requestData.description = new DOMParser().parseFromString(
            newMaterialInfo.markdown,
            'text/html',
          ).documentElement.textContent;
        }

        try {
          const posterUrl = uploadFile(
            poster?.blob,
            poster?.fileName,
            'poster',
          );
          const audioUrl = uploadFile(audio?.blob, audio?.fileName, 'audio');
          const fileUrl = uploadFile(file?.blob, file?.fileName, 'file');

          const posterPromise = new Promise((resolve, reject) =>
            resolve(posterUrl),
          );
          const audioPromise = new Promise((resolve, reject) =>
            resolve(audioUrl),
          );
          const filePromise = new Promise((resolve, reject) =>
            resolve(fileUrl),
          );

          Promise.all([posterPromise, audioPromise, filePromise])
            .then(async result => {
              if (result[0]) {
                requestData.poster = result[0];
              }
              if (result[1]) {
                requestData.audio = result[1];
              }
              if (result[2]) {
                requestData.file = result[2];
              }
            })
            .then(async () => {
              const response = await MaterialsService.updateMaterial({
                materialId: id,
                data: requestData,
              });
              const newMaterialsList = materialsList.map(el =>
                el.materialId === id ? response : el,
              );
              setMaterialsList(newMaterialsList);
              setSaveLoading(false);
              showSuccessNotification('Материал успешно обновлен');
              await dispatch(setEditing(false));
            })
            .catch(err => {
              setSaveLoading(false);
              throw err;
            });
        } catch (err) {
          showErrorNotification('Не удалось обновить материал');
        }
      }
    };

    if (
      newMaterialInfo.type === 'video' &&
      !(
        newMaterialInfo.video.substr(0, 17) === 'https://youtu.be/' ||
        newMaterialInfo.video.substr(0, 32) ===
          'https://www.youtube.com/watch?v='
      )
    ) {
      showWarningNotification('Можно использовать только ссылку с youtube');
      setSaveLoading(false);
    } else {
      sendMaterial();
    }
  };

  const deleteMaterial = async id => {
    setDeleteLoading(true);
    try {
      await MaterialsService.deleteMaterial(id);
      setMaterialsList(old =>
        old.filter(el => el.materialId !== newMaterialInfo.id),
      );
      showSuccessNotification('Материал упешно удален');
      await dispatch(setEditing(false));
      setShowDeleteModal(false);
      history.push('/materials');
    } catch (err) {
      showErrorNotification('Не удалось удалить материал');
    }
    clearState();
    setDeleteLoading(false);
  };

  const clearState = () => {
    // setGroupIdMap(new Map());
    setOutlineEmptyField(false);
    setPoster(null);
    setAudio(null);
    setFile(null);
    setNewMaterialInfo(INITIALS.newMaterialInfo);
  };

  const showAttachingMaterialModal = () => {
    setShowModal(true);
  };

  const attachMaterial = async () => {
    setAttachingMaterialLoading(true);

    if (selectedOrganizer.type === 'group') {
      try {
        await MaterialsService.addMaterialToGroup({
          materialId: currentMaterialId,
          groupId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification('Материал успешно привязан к группе');
        setShowModal(false);
      } catch (err) {
        showErrorNotification('Не удалось привязать материал к группе');
      }
      setAttachingMaterialLoading(false);
    } else {
      try {
        await MaterialsService.addMaterialToPersonal({
          materialId: currentMaterialId,
          classId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification(
          'Материал успешно привязан к персональному занятию',
        );
        setShowModal(false);
      } catch (err) {
        showErrorNotification(
          'Не удалось привязать материал к персональному занятию',
        );
      }
      setAttachingMaterialLoading(false);
    }
  };

  const deleteTagsFromMarkdown = text => {
    return text.replace(/<\/?[a-z][^>]*(>|$)/gi, '').replace(/\r?\n/g, '');
  };

  const checkDisabling = () => {
    return !(isAdmin || newMaterialInfo.teacherId === userData.teacherId);
  };

  const deleteFile = name => {
    setPoster(null);
    setNewMaterialInfo(prev => {
      return {
        ...prev,
        [name]: '',
      };
    });
  };

  const selectFile = (blob, fileName) => {
    setPoster({ blob, fileName });
    makeEditingMode();
  };

  return (
    <MaterialsView
      saveLoading={saveLoading}
      attachingMaterialLoading={attachingMaterialLoading}
      organizers={organizers}
      selectedOrganizer={selectedOrganizer}
      setSelectedOrganizer={setSelectedOrganizer}
      teachers={teachers}
      students={students}
      getMaterials={getMaterials}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={setSelectedTeacher}
      newMaterialInfo={newMaterialInfo}
      updateMaterial={updateMaterial}
      handleMaterialMarkdownChange={handleMaterialMarkdownChange}
      handleMaterialDescriptionChange={handleMaterialDescriptionChange}
      handleMaterialStartDateChange={handleMaterialStartDateChange}
      handleMaterialStartTimeChange={handleMaterialStartTimeChange}
      handleMaterialVideoLinkChange={handleMaterialVideoLinkChange}
      handleMaterialChange={handleMaterialChange}
      handleMaterialTypeChange={handleMaterialTypeChange}
      handleMaterialSourceChange={handleMaterialSourceChange}
      outlineEmptyField={outlineEmptyField}
      fileLoading={fileLoading}
      isLoading={isLoading}
      materialsList={materialsList}
      openMaterialToEdit={openMaterialToEdit}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      deleteMaterial={deleteMaterial}
      clearState={clearState}
      poster={poster}
      saveNewMaterial={saveNewMaterial}
      showModal={showModal}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      setShowModal={setShowModal}
      attachMaterial={attachMaterial}
      showAttachingMaterialModal={showAttachingMaterialModal}
      isAdmin={isAdmin}
      deleteTagsFromMarkdown={deleteTagsFromMarkdown}
      deleteLoading={deleteLoading}
      userData={userData}
      checkDisabling={checkDisabling}
      externalPosterRef={externalPosterRef}
      externalAudioRef={externalAudioRef}
      externalFileRef={externalFileRef}
      deleteFile={deleteFile}
      setPoster={setPoster}
      audio={audio}
      wrongTime={wrongTime}
      setWrongTime={setWrongTime}
      setAudio={setAudio}
      setFile={setFile}
      file={file}
      setNextLinkItem={setNextLinkItem}
      selectFile={selectFile}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialsContainer);
