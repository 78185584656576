import { apiGet, apiPost, apiPut, apiDelete } from '../api';
export default {
  async createTest(body) {
    try {
      const result = await apiPost('/test', body);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createGeneralTest(body) {
    try {
      const result = await apiPost('/test/english-level', body);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getEnglishTests(page = 1, size = 40) {
    try {
      const result = await apiGet('/test/english-level', {
        params: { page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getEnglishTest(testId) {
    try {
      const result = await apiGet(`/test/english-level/${testId}`);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteTest(testId) {
    try {
      const result = await apiDelete('/test', { testId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteGeneralTest(id) {
    try {
      const result = await apiDelete('/test/english-level', { id });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateGeneralTest(body) {
    try {
      const result = await apiPut('/test/english-level', body);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateTest(body) {
    try {
      const result = await apiPut('/test', body);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTests(page = 1, size = 40) {
    try {
      const result = await apiGet('/tests', { params: { page, size } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async removeTestFromGroup(body) {
    try {
      const result = await apiDelete('/test/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async assignEnglishLevelTest(body) {
    try {
      const result = await apiPost('class/tests/english-level', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addTestToGroup(body) {
    try {
      const result = await apiPost('/test/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addTestToPersonal(body) {
    try {
      const result = await apiPost('/test/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getTest(testId) {
    try {
      const result = await apiGet('/test', { params: { testId } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
