import React from 'react';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import './style.css';

const Input = ({
  placeholder = '',
  value = '',
  disabled = false,
  maxLength = 200,
  title = '',
  onChange = () => {},
  type = 'default',
  className = '',
  showCounter,
  containerClassName = '',
}) => {
  // const _onChange = e => {
  //   const text = e.target.value;
  //   onChange(text);
  // };

  return (
    <div className={`${containerClassName}`}>
      {title && <div className="inputTitle">{title}</div>}
      <SelectableContainer
        disabled={disabled}
        className={`${className} textarea-container`}>
        <textarea
          className="textarea input"
          type={type}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {showCounter && maxLength && (
          <div className="input-counter">{maxLength - value.length}</div>
        )}
      </SelectableContainer>
    </div>
  );
};

export default Input;
