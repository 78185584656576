import React from 'react';
import { Checkbox } from '..';
import './style.css';

const LabeledCheckbox = props => {
  return (
    <div className={`checkbox-aligner ${props.containerClass || ''}`}>
      <div onClick={props.onClick} className="component__checkbox__container">
        <label for={props.id}>{props.title}</label>
        <Checkbox id={props.id} disabled={props.disabled} value={props.value} />
      </div>
    </div>
  );
};

export default LabeledCheckbox;
