import React from 'react';
import './style.css';

const renderItems = (array = [], onClick = () => {}) =>
  array.map(item => (
    <div
      className={`component__list-container-item-button ${
        item.isActive && 'active'
      }`}
      onClick={() => onClick(item.code)}>
      {item.code}
    </div>
  ));

const ListContainer = props => {
  return (
    <div className="component__list-container">
      <div className="component__list-container-title">{props.title}</div>
      <div className="component__list-container-items-container invites">
        {renderItems(props.list, props.onClick)}
      </div>
    </div>
  );
};

export default ListContainer;
