import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import moment from 'moment';

const UsersTableView = props => {
  switch (props.tabId) {
    case 1:
    default:
      return (
        <>
          <div
            onScroll={props.paginate}
            className="table group-flashcards-table">
            <div className="table-header table-line">
              {/* <div className="box box-1">ID</div> */}
              <div className="box box-2">Название</div>
              <div className="box box-3">Перевод первый</div>
              <div className="box box-4">Количество карточек</div>
              <div className="box box-5">Дата начала</div>
              <div className="box box-6">Управление</div>
            </div>
            <div className="table-list">
              {props.data?.length
                ? props.data.map((game, index) => (
                    <div
                      className={`table-line ${
                        index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
                      }`}>
                      {/* <NavLink to={`users/${game?.categoryId}`} className="box box-1">{game?.categoryId}</NavLink> */}
                      <NavLink
                        to={`/games/flashCards/${game?.categoryId}`}
                        className="box box-2">
                        {game?.name}
                      </NavLink>
                      <NavLink
                        to={`/games/flashCards/${game?.categoryId}`}
                        className="box box-3">
                        {game?.showTranslateFirst ? 'да' : 'нет'}
                      </NavLink>
                      <NavLink
                        to={`/games/flashCards/${game?.categoryId}`}
                        className="box box-4">
                        {game?.wordsLength}
                      </NavLink>
                      <NavLink
                        to={`/games/flashCards/${game?.categoryId}`}
                        className="box box-5">
                        {moment(game?.date || game?.createdTime).format(
                          'YYYY.MM.DD, HH:mm',
                        )}
                      </NavLink>
                      <div className="box box-6">
                        {props.renderControl ? (
                          props.renderControl({ ...game, index: index })
                        ) : (
                          <Button
                            type={game?.active ? 'secondary' : 'primary'}
                            small
                            textButton={game?.active ? 'Удалить' : 'Добавить'}
                            onClick={() => {
                              props.deleteGame(game);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))
                : null}
              {props.last && !props.data?.length ? props.renderEmpty() : null}
              {props.loading ? <Loader className="table-loader" /> : null}
            </div>
          </div>
          {props.type !== 'teacher' && props.data?.length ? (
            <Button
              disabled={!props.data.filter(el => !el.active).length}
              type="primary"
              textButton="Удалить"
              loading={props.groupGamesUpdating}
              onClick={() => {
                props.shareGames();
              }}
            />
          ) : null}
        </>
      );
    case 2:
      return (
        <>
          <div onScroll={props.paginate} className="table group-matchup-table">
            <div className="table-header table-line">
              {/* <div className="box box-1">ID</div> */}
              <div className="box box-2">Название</div>
              <div className="box box-3">Количество слов</div>
              <div className="box box-4">Количество раундов</div>
              <div className="box box-5">Дата начала</div>
              <div className="box box-6">Управление</div>
            </div>
            <div className="table-list">
              {props.data.length
                ? props.data.map((game, index) => (
                    <div
                      className={`table-line ${
                        index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
                      }`}>
                      {/* <NavLink to={`users/${game?.categoryId}`} className="box box-1">{game?.categoryId}</NavLink> */}
                      <NavLink
                        to={`/games/matchup/${game?.categoryId}`}
                        className="box box-2">
                        {game?.name}
                      </NavLink>
                      <NavLink
                        to={`/games/matchup/${game?.categoryId}`}
                        className="box box-3">
                        {game?.wordsLength}
                      </NavLink>
                      <NavLink
                        to={`/games/matchup/${game?.categoryId}`}
                        className="box box-4">
                        {game?.roundsLength}
                      </NavLink>
                      <NavLink
                        to={`/games/matchup/${game?.categoryId}`}
                        className="box box-5">
                        {moment(game?.date || game?.createdTime).format(
                          'YYYY.MM.DD, HH:mm',
                        )}
                      </NavLink>
                      <div className="box box-6">
                        {props.renderControl ? (
                          props.renderControl({ ...game, index: index })
                        ) : (
                          <Button
                            type={game?.active ? 'secondary' : 'primary'}
                            small
                            textButton={game?.active ? 'Удалить' : 'Добавить'}
                            onClick={() => {
                              props.deleteGame(game);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))
                : null}
              {props.last && !props.data?.length ? props.renderEmpty() : null}
              {props.loading ? <Loader className="table-loader" /> : null}
            </div>
          </div>
          {props.type !== 'teacher' && props.data?.length ? (
            <Button
              disabled={!props.data.filter(el => !el.active).length}
              type="primary"
              textButton="Удалить"
              loading={props.groupGamesUpdating}
              onClick={() => {
                props.shareGames();
              }}
            />
          ) : null}
        </>
      );
    case 3:
      return (
        <>
          <div onScroll={props.paginate} className="table group-anagram-table">
            <div className="table-header table-line">
              {/* <div className="box box-1">ID</div> */}
              <div className="box box-2">Название</div>
              <div className="box box-3">Количество вопросов</div>
              <div className="box box-4">Дата начала</div>
              <div className="box box-5">Управление</div>
            </div>
            <div className="table-list">
              {props.data?.length
                ? props.data.map((game, index) => (
                    <div
                      className={`table-line ${
                        index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
                      }`}>
                      {/* <NavLink to={`users/${game?.categoryId}`} className="box box-1">{game?.categoryId}</NavLink> */}
                      <NavLink
                        to={`/games/anagrams/${game?.categoryId}`}
                        className="box box-2">
                        {game?.name}
                      </NavLink>
                      <NavLink
                        to={`/games/anagrams/${game?.categoryId}`}
                        className="box box-3">
                        {game?.questionsLength}
                      </NavLink>
                      <NavLink
                        to={`/games/anagrams/${game?.categoryId}`}
                        className="box box-4">
                        {moment(game?.date || game?.createdTime).format(
                          'YYYY.MM.DD, HH:mm',
                        )}
                      </NavLink>
                      <div className="box box-5">
                        {props.renderControl ? (
                          props.renderControl({ ...game, index: index })
                        ) : (
                          <Button
                            type={game?.active ? 'secondary' : 'primary'}
                            small
                            textButton={game?.active ? 'Удалить' : 'Добавить'}
                            onClick={() => {
                              props.deleteGame(game);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))
                : null}
              {props.last && !props.data?.length ? props.renderEmpty() : null}
              {props.loading ? <Loader className="table-loader" /> : null}
            </div>
          </div>
          {props.type !== 'teacher' && props.data?.length ? (
            <Button
              disabled={!props.data.filter(el => !el.active).length}
              type="primary"
              textButton="Удалить"
              loading={props.groupGamesUpdating}
              onClick={() => {
                props.shareGames();
              }}
            />
          ) : null}
        </>
      );
    case 4:
      return (
        <>
          <div
            onScroll={props.paginate}
            className="table group-categorize-table">
            <div className="table-header table-line">
              {/* <div className="box box-1">ID</div> */}
              <div className="box box-2">Название</div>
              <div className="box box-3">Количество категорий</div>
              <div className="box box-4">Количество слов</div>
              <div className="box box-5">Дата начала</div>
              <div className="box box-6">Управление</div>
            </div>
            <div className="table-list">
              {props.data.length
                ? props.data.map((game, index) => (
                    <div
                      className={`table-line ${
                        index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
                      }`}>
                      {/* <NavLink to={`users/${game?.categoryId}`} className="box box-1">{game?.categoryId}</NavLink> */}
                      <NavLink
                        to={`/games/categorize/${game?.categoryId}`}
                        className="box box-2">
                        {game?.name}
                      </NavLink>
                      <NavLink
                        to={`/games/categorize/${game?.categoryId}`}
                        className="box box-3">
                        {game?.categories?.length}
                      </NavLink>
                      <NavLink
                        to={`/games/categorize/${game?.categoryId}`}
                        className="box box-4">
                        {game?.wordsLength}
                      </NavLink>
                      <NavLink
                        to={`/games/categorize/${game?.categoryId}`}
                        className="box box-5">
                        {moment(game?.date || game?.createdTime).format(
                          'YYYY.MM.DD, HH:mm',
                        )}
                      </NavLink>
                      <div className="box box-6">
                        {props.renderControl ? (
                          props.renderControl({ ...game, index: index })
                        ) : (
                          <Button
                            type={game?.active ? 'secondary' : 'primary'}
                            small
                            textButton={game?.active ? 'Удалить' : 'Добавить'}
                            onClick={() => {
                              props.deleteGame(game);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))
                : null}
              {props.last && !props.data?.length ? props.renderEmpty() : null}
              {props.loading ? <Loader className="table-loader" /> : null}
            </div>
          </div>
          {props.type !== 'teacher' && props.data?.length ? (
            <Button
              disabled={!props.data.filter(el => !el.active).length}
              type="primary"
              textButton="Удалить"
              loading={props.groupGamesUpdating}
              onClick={() => {
                props.shareGames();
              }}
            />
          ) : null}
        </>
      );
  }
};

export default UsersTableView;
