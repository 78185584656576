import React from 'react';

import './style.css';

import Button from '../../components/button';
import { ListContainer } from '../../components';

const View = props => {
  return (
    <div className="invites-page">
      <div className="invites-page__header">
        <div className="invites-page__header__button-container">
          <Button
            small
            type="info"
            textButton="Сгенерировать код для преподавателя"
            onClick={() => {
              props.addNewCode('teacher');
            }}
          />
        </div>
      </div>
      <div className="invites-page__main">
        <ListContainer
          title="Коды для преподавателей"
          list={props.teachersInvites}
          onClick={props.copyToClipboard}
        />
        {/* <ListContainer title="Коды для учеников" list={props.studentsInvites} /> */}
      </div>
    </div>
  );
};

export default View;
