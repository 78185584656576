import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';

export default {
  async getNews(page = 1, size = 40) {
    try {
      const result = await apiGet('/interesting/news/all', {
        params: { page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getNewsData(newsId) {
    try {
      const result = await apiGet('/interesting/news', { params: { newsId } });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async createNews(body) {
    try {
      const result = await apiPost('/interesting/news', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateNews(body) {
    try {
      const result = await apiPut('/interesting/news', body);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteNews(newsId) {
    try {
      const result = await apiDelete(`/interesting/news?newsId=${newsId}`);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
