import React from 'react';
import Dropdown from 'react-dropdown';
import TestType from '../../components/create-test/test-type';

import {
  LabeledCheckbox,
  Modal,
  Checkbox,
  GamesTable,
  GroupTests,
} from '../../components';
import Tabs from '../../components/tabs/Tabs';
import GeneralTestsTeacherTable from '../../components/generalTestsTeacherTable/index';
import Input from '../../components/input';
import Textarea from '../../components/textarea';
import Button from '../../components/button';
import GroupsTableContainer from '../../components/groupsTable';
import './style.css';
import Icon from '../../components/icon/Icon';
import AvatarControls from '../../components/avatarControls/AvatarControls';
import Avatar from 'react-avatar-edit';
import Loader from '../../components/loader/Loader';
import { MaterialsTable } from '../../components';
import { getTeacherBirthBoundry } from '../../core/functions';
import DatePicker from '../../components/datePicker/DatePicker';
import FileInput from '../../components/fileInput/FileInput';
import NoDataError from '../../components/noDataError/NoDataError';
import ClassesContainer from '../../components/classesTable';
import DialogModal from '../../components/dialogModal/DialogModal';
import moment from 'moment';
import { ListContainer } from '../../components';
import { AVAILABLE_AVATAR_PICTURE_EXTENSIONS } from '../../core/config';

const View = props => {
  const mainTabs = [
    { name: 'Подробная информация', id: 1 },
    { name: 'Группы', id: 2 },
    { name: 'Индивидуальные', id: 3 },
    { name: 'Материалы', id: 4 },
    { name: 'Тесты', id: 5 },
    { name: 'Placement tests', id: 6 },
    { name: 'Игры', id: 7 },
    props.isAdmin ? { name: 'Инвайт коды', id: 8 } : null,
  ].filter(el => el);

  const gameTabs = [
    { name: 'Flash cards', id: 1 },
    { name: 'Match Up', id: 2 },
    { name: 'Anagrams', id: 3 },
    { name: 'Categorize', id: 4 },
  ];

  if (!props.data) {
    return <Loader />;
  }

  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;
    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.handleContainerOnBottom();
    }
  };

  const getModalDeleteText = () => {
    if (props.deletingItem) {
      switch (props.tabId) {
        case 4:
          return {
            title: 'Удаление материала',
            before: 'Вы действительно хотите удалить учебный материал?',
            bold: `Название: ${props.deletingItem.title}\nТип: ${props.deletingItem?.type}\nОписание: ${props.deletingItem?.description}\n`,
            warning:
              'Внимание! Удаление материала приведет к его полному удалению из приложения.',
          };
        case 5:
          return {
            title: 'Удаление теста',
            before: 'Вы действительно хотите удалить тест?',
            bold: `Название: ${props.deletingItem?.title}\nТип: ${props.deletingItem?.type}\nОписание: ${props.deletingItem?.description}\n`,
            warning:
              'Внимание! Удаление теста приведет к его полному удалению из приложения.',
          };
        case 6: {
          return {
            title: 'Удаление общего теста',
            before: 'Вы действительно хотите удалить общий тест?',
            bold: `Название: ${props.deletingItem?.title}\nТип: ${props.deletingItem?.type}\nОписание: ${props.deletingItem?.description}\n`,
            warning:
              'Внимание! Удаление теста приведет к его полному удалению из приложения.',
          };
        }
        case 7:
          switch (props.gamesTabId) {
            case 1:
              return {
                title: 'Удаление флешкард',
                before: 'Вы действительно хотите удалить флэшкардс?',
                bold: `Название: ${props.deletingItem?.name}\nКоличество слов: ${props.deletingItem?.wordsLength}\n`,
                warning:
                  'Внимание! Удаление флэшкардс приведет к его полному удалению из приложения.',
              };
            case 2:
              return {
                title: 'Удаление мэтчап',
                before: 'Вы действительно хотите удалить мэтчап?',
                bold: `Название: ${props.deletingItem?.name}\nОписание: ${props.deletingItem?.description}\nКоличество раундов: ${props.deletingItem?.roundsLength}\nКоличество слов: ${props.deletingItem?.wordsLength}\n`,
                warning:
                  'Внимание! Удаление мэтчап приведет к его полному удалению из приложения.',
              };
            case 3:
              return {
                title: 'Удаление анаграмм',
                before: 'Вы действительно хотите удалить анаграммы?',
                bold: `Название: ${props.deletingItem?.name}\nОписание: ${props.deletingItem?.description}\nКоличество анаграм: ${props.deletingItem?.questionsLength}\n`,
                warning:
                  'Внимание! Удаление анаграмм приведет к их полному удалению из приложения.',
              };
            case 4:
              return {
                title: 'Удаление категорайз',
                before: 'Вы действительно хотите удалить категорайз?',
                bold: `Название: ${props.deletingItem?.name}\nОписание: ${props.deletingItem?.description}\nКоличество слов: ${props.deletingItem?.wordsLength}\n`,
                warning:
                  'Внимание! Удаление категорайз приведет к его полному удалению из приложения.',
              };
          }
      }
    }
    return null;
  };

  const renderGames = (tab, props) => {
    let data;
    let last;
    let loading;
    let noDataText;
    switch (tab) {
      case 1:
        data = props.flashcards;
        last = props.paginationStatus.flashcards.last;
        loading = props.flashcardsLoading;
        noDataText = 'Учитель еще не добавил Flashcards';
        break;
      case 2:
        data = props.matchup;
        last = props.paginationStatus.matchup.last;
        loading = props.matchupLoading;
        noDataText = 'Учитель еще не добавил Match Up';
        break;
      case 3:
        data = props.anagrams;
        last = props.paginationStatus.anagrams.last;
        loading = props.anagramsLoading;
        noDataText = 'Учитель еще не добавил Anagrams';
        break;
      case 4:
        data = props.categorize;
        last = props.paginationStatus.categorize.last;
        loading = props.categorizeLoading;
        noDataText = 'Учитель еще не добавил Categorize';
        break;
      default:
        return;
    }
    return (
      <GamesTable
        renderEmpty={() => {
          return <NoDataError text={noDataText} />;
        }}
        deleteGame={game => {
          props.confirmDelete(game);
        }}
        type={'teacher'}
        tabId={tab}
        last={last}
        loading={loading}
        data={data}
      />
    );
  };

  const renderEducationTab = (index, data) => {
    return (
      <>
        <Input
          type="text"
          title="Учреждение образования"
          class="new-teacher-name"
          placeholder="Введите название учреждения образования"
          disabled={!props.editMode}
          onChange={e =>
            props.handleEducationChange(
              'institutionName',
              index,
              e.target.value,
            )
          }
          value={data?.institutionName}
        />
        <DatePicker
          disableToolbar={false}
          title="Дата начала обучения"
          className="new-teacher-name"
          views={['year']}
          format={'yyyy'}
          maxDate={new Date()}
          placeholder="Выберите дату начала обучения"
          disabled={!props.editMode}
          onChange={e => {
            props.handleEducationChange('startYear', index, +e.format('YYYY'));
          }}
          value={moment(data?.startYear, 'YYYY').toISOString()}
        />
        <DatePicker
          disableToolbar={false}
          title="Дата окончания обучения"
          className="new-teacher-name"
          views={['year']}
          format={'yyyy'}
          placeholder="Выберите дату окончания обучения"
          disabled={!props.editMode}
          onChange={e => {
            props.handleEducationChange('endYear', index, +e.format('YYYY'));
          }}
          value={moment(data?.endYear, 'YYYY').toISOString()}
        />
        <Input
          type="text"
          title="Серия"
          class="new-teacher-name"
          placeholder="Введите серию диплома"
          maxLength={20}
          disabled={!props.editMode}
          onChange={e =>
            props.handleEducationChange('dyplomaSeries', index, e.target.value)
          }
          value={data?.dyplomaSeries}
        />
        <Input
          type="text"
          title="Номер"
          class="new-teacher-name"
          maxLength={20}
          placeholder="Введите номер диплома"
          disabled={!props.editMode}
          onChange={e =>
            props.handleEducationChange('dyplomaNumber', index, e.target.value)
          }
          value={data?.dyplomaNumber}
        />
        <FileInput
          type="text"
          title="Файл"
          maxSize={10}
          class="new-teacher-name"
          placeholder="Выберите файл диплома"
          disabled={!props.editMode}
          handleFileDelete={() => {
            props.handleEducationChange('dyplomaScan', index, '');
            props.handleEducationDelete('dyplomaScan', index, '');
          }}
          handleFileSelect={(blob, fileName) =>
            props.handleEducationChange('dyplomaScan', index, {
              blob,
              fileName,
            })
          }
          fileAddr={
            typeof data?.dyplomaScan === 'string' ? data?.dyplomaScan : null
          }
        />
      </>
    );
  };

  const renderTeacherTab = tab => {
    switch (tab) {
      default:
      case 1:
        return (
          <div className="teacher-page_info-tab">
            <div className="teacher-page_info-tab_ruler title first">
              Профиль
            </div>
            <Input
              type="text"
              title="Имя"
              maxLength={25}
              class="new-teacher-name"
              placeholder="Имя"
              disabled={!props.editMode}
              onChange={e => props.handleTeacherChange('name', e.target.value)}
              value={props.data?.name}
            />
            <Input
              type="text"
              title="Фамилия"
              maxLength={25}
              class="new-teacher-name"
              placeholder="Фамилия"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('lastName', e.target.value)
              }
              value={props.data?.lastName}
            />
            <Input
              type="text"
              title="Отчество"
              maxLength={25}
              class="new-teacher-name"
              placeholder="Отчество"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('patronymic', e.target.value)
              }
              value={props.data?.patronymic}
            />
            <DatePicker
              disableToolbar={false}
              maxDate={getTeacherBirthBoundry()}
              title="Дата рождения"
              className="new-teacher-name"
              views={['year', 'month', 'date']}
              format={'DD/MM/yyyy'}
              placeholder="Дата рождения"
              disabled={!props.editMode}
              onChange={e => {
                props.handleTeacherChange('birthday', e.toISOString());
              }}
              value={props.data?.birthday}
            />
            <div className="teacher-page_info-tab_ruler title">
              Паспорт и документы
            </div>
            <Input
              type="text"
              title="Серия"
              class="new-teacher-name"
              placeholder="Введите серию"
              maxLength={15}
              disabled={!props.editMode}
              onChange={e => {
                props.handleTeacherChange('passportSeries', e.target.value);
              }}
              value={props.data?.passportSeries}
            />
            <Input
              type="text"
              title="Номер"
              maxLength={15}
              class="new-teacher-name"
              placeholder="Введите номер"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('passportNumber', e.target.value)
              }
              value={props.data?.passportNumber}
            />
            <Input
              type="text"
              title="Кем выдан"
              class="new-teacher-name"
              placeholder="Введите кем выдан"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange(
                  'passportRecievedFrom',
                  e.target.value,
                )
              }
              value={props.data?.passportRecievedFrom}
            />
            <DatePicker
              disableToolbar={false}
              title="Когда выдан"
              maxDate={new Date()}
              className="new-teacher-name"
              views={['year', 'month', 'date']}
              format={'DD.MM.yyyy'}
              placeholder="Выберите когда выдан"
              disabled={!props.editMode}
              onChange={e => {
                props.handleTeacherChange(
                  'passportRecievedWhen',
                  e.toISOString(),
                );
              }}
              value={props.data?.passportRecievedWhen}
            />
            <FileInput
              title="Скан"
              extensions={['jpg', 'png', 'jpeg', 'svg']}
              maxSize={10}
              class="new-teacher-name"
              placeholder="Добавьте скан паспорта"
              disabled={!props.editMode}
              handleFileDelete={() =>
                props.handleTeacherChange('passportScan', '')
              }
              handleFileSelect={(blob, fileName) =>
                props.setPassportScan({ blob, fileName })
              }
              fileAddr={props.data?.passportScan}
            />
            <div className="teacher-page_info-tab_ruler" />
            <Input
              type="text"
              title="Регистрация"
              class="new-teacher-name"
              placeholder="Регистрация"
              maxLength={200}
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('registrationAddress', e.target.value)
              }
              value={props.data?.registrationAddress}
            />
            <FileInput
              type="text"
              title="Скан"
              maxSize={10}
              class="new-teacher-name"
              placeholder="Добавьте скан регистрации"
              disabled={!props.editMode}
              handleFileDelete={() =>
                props.handleTeacherChange('registrationScan', '')
              }
              handleFileSelect={(blob, fileName) =>
                props.setRegistrationScan({ blob, fileName })
              }
              fileAddr={props.data?.registrationScan}
            />
            <div className="teacher-page_info-tab_ruler title">
              Отсутствие судимости
            </div>
            <FileInput
              type="text"
              maxSize={10}
              title="Справка об отсутсвии судимости"
              class="new-teacher-name"
              placeholder="Добавьте скан справки"
              disabled={!props.editMode}
              handleFileDelete={() =>
                props.handleTeacherChange('convinienceScan', '')
              }
              handleFileSelect={(blob, fileName) =>
                props.setConvinienceScan({ blob, fileName })
              }
              fileAddr={props.data?.convinienceScan}
            />
            <div className="teacher-page_info-tab_ruler title">
              Образование 1
            </div>
            {renderEducationTab(0, props.education?.[0])}
            <div className="teacher-page_info-tab_ruler title">
              Образование 2
            </div>
            {renderEducationTab(1, props.education?.[1])}
            <div className="teacher-page_info-tab_ruler title">
              Образование 3
            </div>
            {renderEducationTab(2, props.education?.[2])}
            <div className="teacher-page_info-tab_ruler title">
              Образование 4
            </div>
            {renderEducationTab(3, props.education?.[3])}
            <div className="teacher-page_info-tab_ruler title">
              Образование 5
            </div>
            {renderEducationTab(4, props.education?.[4])}
            {/* <Input
              type="text"
              title="Серия"
              class="new-teacher-name"
              placeholder="Введите серию диплома"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('secondDyplomaSeries', e.target.value)
              }
              value={props.data?.secondDyplomaSeries}
            />
            <Input
              type="text"
              title="Номер"
              class="new-teacher-name"
              placeholder="Введите номер диплома"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('secondDyplomaNumber', e.target.value)
              }
              value={props.data?.secondDyplomaNumber}
            />
            <FileInput
              type="text"
              title="Файл"
              maxSize={10}
              class="new-teacher-name"
              placeholder="Выберите файл диплома"
              disabled={!props.editMode}
              handleFileDelete={() =>
                props.handleTeacherChange('secondDyplomaScan', '')
              }
              handleFileSelect={(blob, fileName) =>
                props.setSecondDyplomaScan({ blob, fileName })
              }
              onChange={e =>
                props.handleTeacherChange('patronymic', e.target.value)
              }
              fileAddr={props.data?.secondDyplomaScan}
            /> */}

            <div className="teacher-page_info-tab_ruler title">
              Информация для студентов
            </div>
            <Textarea
              containerClassName="double-size"
              type="text"
              title="О себе"
              class="new-teacher-name"
              maxLength={1500}
              placeholder="Расскажите студентам приложения о себе"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('aboutMyself', e.target.value)
              }
              value={props.data?.aboutMyself}
            />
            <Input
              type="text"
              title="Педагогический опыт (лет)"
              class="new-teacher-name"
              placeholder="Введите число"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange('teachingExperience', e.target.value)
              }
              value={props.data?.teachingExperience}
            />
            <Input
              type="text"
              title="Переводческий опыт (лет)"
              class="new-teacher-name"
              placeholder="Введите число"
              disabled={!props.editMode}
              onChange={e =>
                props.handleTeacherChange(
                  'translationExperience',
                  e.target.value,
                )
              }
              value={props.data?.translationExperience}
            />
            <div className="teacher-page_info-tab_ruler title">
              Администрирование
            </div>
            <Input
              type="text"
              title="Цена 60 мин. занятия (рублей)"
              class="new-teacher-name"
              placeholder="Введите число"
              disabled={!props.adminEdit}
              onChange={e =>
                props.handleTeacherChange('shortClassPrice', e.target.value)
              }
              value={props.data?.shortClassPrice}
            />
            <Input
              disabled={!props.adminEdit}
              type="text"
              title="Цена 80 мин. занятия (рублей)"
              class="new-teacher-name"
              placeholder="Введите число"
              onChange={e =>
                props.handleTeacherChange('longClassPrice', e.target.value)
              }
              value={props.data?.longClassPrice}
            />
            <LabeledCheckbox
              id="showInTeachersList"
              type="text"
              title="Видимость в приложении"
              containerClass="teacher-checkbox"
              disabled={!props.adminEdit}
              onClick={e =>
                props.handleTeacherChange(
                  'showInTeachersList',
                  !props.data.showInTeachersList,
                )
              }
              value={props.data?.showInTeachersList}
            />
            <Button
              onClick={() => props.updateTeacher()}
              disabled={!props.editMode}
              textButton="Обновить данные"
              loading={props.updateLoading}
            />
          </div>
        );
      case 2:
        return (
          <GroupsTableContainer
            last={props.paginationStatus.groups.last}
            type="teacher"
            renderEmpty={() => {
              return (
                <NoDataError text="За данным учителем не закреплено групп" />
              );
            }}
            renderControl={el => {
              return (
                <Checkbox
                  // value={!el.active}
                  onClick={() => {
                    props.handleGroup(el.has, el.index, !el.active);
                  }}
                />
              );
            }}
            paginate={handleDataOnBottom}
            data={props.groups}
            loading={props.groupsLoading}
          />
        );
      case 3:
        return (
          <ClassesContainer
            type="teacher"
            paginate={handleDataOnBottom}
            renderControl={el => {
              return (
                <Button
                  type={'secondary'}
                  small
                  textButton={'Удалить'}
                  onClick={() => props.showDeleteModal(el)}
                />
              );
            }}
            last={props.paginationStatus.personals.last}
            renderEmpty={() => {
              return (
                <NoDataError text="Нет доступных для просмотра индивидуальных занятий" />
              );
            }}
            data={props.personals}
            loading={props.personalsLoading}
          />
        );
      case 4:
        return (
          <MaterialsTable
            deleteMaterial={material => {
              props.confirmDelete(material);
            }}
            type="teacher"
            last={props.paginationStatus.materials.last}
            renderEmpty={() => {
              return (
                <NoDataError text="Данный учитель еще не добавил материалов" />
              );
            }}
            paginate={handleDataOnBottom}
            data={props.materials}
            loading={props.materialsLoading}
          />
        );
      case 5:
        return (
          <GroupTests
            deleteTest={test => {
              props.confirmDelete(test);
            }}
            type="teacher"
            last={props.paginationStatus.tests.last}
            renderEmpty={() => {
              return (
                <NoDataError text="Данный учитель еще не добавил материалов" />
              );
            }}
            paginate={handleDataOnBottom}
            data={props.tests}
            loading={props.testsLoading}
          />
        );
      case 6:
        return (
          <GeneralTestsTeacherTable
            type={'teacher'}
            deleteTest={test => {
              props.confirmDelete(test);
            }}
            last={props.paginationStatus.generalTests.last}
            renderEmpty={() => {
              return (
                <NoDataError text="Учитель еще не добавил placement tests" />
              );
            }}
            paginate={handleDataOnBottom}
            loading={props.generalTestsLoading}
            data={props.generalTests}
          />
        );
      case 7:
        return (
          <>
            <Tabs
              tabId={props.gamesTabId}
              tabs={gameTabs}
              handleChangeGroup={props.setGamesTabId}
            />
            {renderGames(props.gamesTabId, props)}
          </>
        );
      case 8:
        return (
          <div className="group-page__code-tab">
            <div className="group-page__code-tab__invite-code-button">
              <Button
                onClick={() => props.generateCode()}
                type="info"
                textButton="Сгенерировать инвайт-код"
              />
            </div>
            {props.invites?.length ? (
              <div className="invites-page__main">
                <ListContainer
                  title="Коды для персональных занятий с учителем"
                  list={props.invites}
                  onClick={props.copyToClipboard}
                />
              </div>
            ) : null}
          </div>
        );
    }
  };

  const modalText = getModalDeleteText();

  return (
    <div className="teacher-page">
      <DialogModal
        title={modalText?.title}
        show={props.showDeleteModal}
        setShow={props.setShowDeleteModal}
        onLoading={props.deleteLoading}
        onDelete={props.deleteItem}>
        {props.deletingItem ? (
          <span className="add-lesson-modal-item-last">
            {modalText?.before}
            <br />
            <strong style={{ whiteSpace: 'pre-wrap' }}>
              {modalText?.bold}
            </strong>
            <br />
            <em>*{modalText?.warning}</em>
          </span>
        ) : null}
      </DialogModal>
      <Modal
        show={props.showModal}
        setShow={props.setShowModal}
        title="Отредактируйте изображение">
        <div className="modal-column-last-item">
          <Avatar
            exportAsSquare={true}
            width={'390px'}
            height={295}
            onCrop={blob => {
              props.setCroppedImage(blob);
            }}
            onClose={() => props.setShowModal(false)}
            src={props.newAvatar}
          />
        </div>
        <div className="delete-modal-controls-wrapper">
          <Button
            type="text"
            small
            className="delete-modal-go-back-button"
            textButton="Назад"
            onClick={() => {
              props.setNewAvatar(false);
              props.setCroppedImage(false);
              props.setShowModal(false);
            }}
          />
          <Button
            loading={props.avatarUploading}
            type="primary"
            disabled={!props.croppedImage}
            small
            textButton="Загрузить"
            onClick={props.onUploadNewAvatar}
          />
        </div>
      </Modal>
      <div className="teacher-page__header">
        {props.data?.avatar ? (
          <div className="teacher-page__avatar">
            <AvatarControls
              extensions={AVAILABLE_AVATAR_PICTURE_EXTENSIONS}
              deleteLoading={props.deleteLoading}
              handleDelete={props.onDeleteAvatar}
              handleFileSelect={file => {
                props.setNewAvatar(file);
                props.setShowModal(true);
              }}
            />
            <img className="user-avatar" alt="avatar" src={props.data.avatar} />
          </div>
        ) : (
          <div className="teacher-page__no-avatar">
            <div className="user-avatar">
              <Icon name="avatar" width={'100%'} height={'100%'} />
            </div>
            <AvatarControls
              deleteLoading={props.deleteLoading}
              disableDelete={true}
              handleDelete={props.onDeleteAvatar}
              handleFileSelect={file => {
                props.setNewAvatar(file);
                props.setShowModal(true);
              }}
            />
          </div>
        )}
        <div className="teacher-page__info">
          <h2 className="teacher-page__info-name">{`${props.data?.name} ${
            props.data?.lastName
          } ${props.data.patronymic || ''}`}</h2>
          <p>{`Email: ${props.data.email}`}</p>
          <p>{`Присоединился: ${moment(props.data.createdTime).format(
            'DD MMMM YYYY г.',
          )}`}</p>
        </div>
      </div>
      <div className="teacher-content-container">
        <Tabs
          tabId={props.tabId}
          tabs={mainTabs}
          handleChangeGroup={props.handleTab}
        />
        {renderTeacherTab(props.tabId)}
      </div>
    </div>
  );
};

export default View;
