import { store } from 'react-notifications-component';

export const showErrorNotification = (
  message = 'Возникла ошибка, попробуйте позже',
  title = 'Ошибка',
) => {
  store.addNotification({
    title: title,
    message: message,
    type: 'danger',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const showSuccessNotification = (
  message = 'Операция завершена успешно',
  title = 'Успешно',
) => {
  store.addNotification({
    title: title,
    message: message,
    type: 'success',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const showWarningNotification = (message, title = 'Внимание') => {
  store.addNotification({
    title: title,
    message: message,
    type: 'warning',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const showInfoNotification = (message, title = 'Внимание') => {
  store.addNotification({
    title: title,
    message: message,
    type: 'info',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};
