import React from 'react';
import { ReactComponent as ArrowBottomIcon } from '../../assets/icons/arrowBottom.svg';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as Games } from '../../assets/icons/games.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as Groups } from '../../assets/icons/groups.svg';
import { ReactComponent as Materials } from '../../assets/icons/materials.svg';
import { ReactComponent as PersonalClasses } from '../../assets/icons/personals.svg';
import { ReactComponent as Users } from '../../assets/icons/users.svg';
import { ReactComponent as Tasks } from '../../assets/icons/tasks.svg';
import { ReactComponent as Teachers } from '../../assets/icons/teachers.svg';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import { ReactComponent as News } from '../../assets/icons/news.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Wrong } from '../../assets/icons/wrong.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as Bell } from '../../assets/icons/bell.svg';
import { ReactComponent as ArrowTop } from '../../assets/icons/arrowTop.svg';
import { ReactComponent as GeneralTests } from '../../assets/icons/generalTests.svg';
import { ReactComponent as Headphones } from '../../assets/icons/headphones.svg';
import { ReactComponent as File } from '../../assets/icons/file.svg';
import { ReactComponent as Text } from '../../assets/icons/text.svg';
import { ReactComponent as Video } from '../../assets/icons/video.svg';
import { ReactComponent as Password } from '../../assets/icons/password.svg';
import { ReactComponent as PasswordOff } from '../../assets/icons/passwordOff.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';

const Icon = ({
  name = '',
  width = 24,
  height = 24,
  color = '#A0A5B9',
  strokeWidth = 1.2,
}) => {
  switch (name) {
    case 'arrow-bottom':
      return (
        <ArrowBottomIcon
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'arrow-top':
      return (
        <ArrowTop
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'avatar':
      return (
        <Avatar
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'bell':
      return (
        <Bell
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'audio':
      return (
        <Headphones
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'info':
      return (
        <Info
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          fill={'transparent'}
          strokeWidth={1}
        />
      );
    case 'video':
      return (
        <Video
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'standart':
      return (
        <Text
          width={width}
          height={height}
          currentColor={color}
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'file':
      return (
        <File
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'success':
      return (
        <Success
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'minus':
      return (
        <Minus
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'download':
      return (
        <Download
          width={width}
          height={height}
          stroke={color}
          strokeWidth={1}
        />
      );
    case 'upload':
      return (
        <Upload width={width} height={height} stroke={color} strokeWidth={1} />
      );
    case 'games':
      return (
        <Games width={width} height={height} stroke={color} strokeWidth={1} />
      );
    case 'calendar':
      return (
        <Calendar
          width={width}
          height={height}
          stroke={color}
          strokeWidth={1}
        />
      );
    case 'search':
      return (
        <Search
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'close':
      return (
        <Close
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'trash':
      return (
        <Trash
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'dashboard':
      return (
        <Dashboard
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'groups':
      return (
        <Groups
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'materials':
      return (
        <Materials
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'personal-classes':
      return (
        <PersonalClasses
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'users':
      return (
        <Users
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'tasks':
      return (
        <Tasks
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'general-tests':
      return (
        <GeneralTests
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'teachers':
      return (
        <Teachers
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'news':
      return (
        <News
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'check':
      return (
        <Check
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'wrong':
      return (
        <Wrong
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'password':
      return (
        <Password
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    case 'passwordOff':
      return (
        <PasswordOff
          width={width}
          height={height}
          currentColor={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      );
    default:
      return null;
  }
};

export default Icon;
