/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import ResetPassword from './resetPassword';
import { useHistory } from 'react-router-dom';
import {
  showSuccessNotification,
  showErrorNotification,
} from '../../core/errorsController';

const Registration = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get('mode');
  const verificationCode = urlParams.get('oobCode');
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const auth = firebase.auth();

  useEffect(() => {
    switch (mode) {
      case 'resetPassword':
        verifyResetPassCode(verificationCode);
        break;
      default:
        verifyResetPassCode(verificationCode);
    }
  }, []);

  const handleResetPassVerificationError = code => {
    switch (code) {
      case 'auth/expired-action-code':
        return 'Ссылка для восстановления пароля более не действительна';
      case 'auth/invalid-action-code':
        return 'Неправильный код ссылки';
      case 'auth/user-disabled':
        return 'Пользователь заблокирован';
      case 'auth/user-not-found':
        return 'Пользователь удален';
      case 'auth/weak-password':
        return 'Слишком легкий пароль';
      default:
        return 'Возникла непредвиденная ошибка, попробуйте позже';
    }
  };

  const verifyResetPassCode = async code => {
    try {
      const response = await auth.verifyPasswordResetCode(code);
      return true;
    } catch (error) {
      const errorText = handleResetPassVerificationError(error.code);
      showErrorNotification(errorText);
      history.push('/auth');
      return false;
    }
  };

  const changePassword = async () => {
    setLoading(true);
    try {
      if (true && verifyResetPassCode(verificationCode)) {
        await auth.confirmPasswordReset(verificationCode, newPassword);
        showSuccessNotification('Вы успешно изменили пароль');
      }
      history.push('/auth');
    } catch (e) {
      showErrorNotification(e.message);
    }
    setLoading(false);
  };

  switch (mode) {
    case 'resetPassword':
      return (
        <ResetPassword
          changePassword={changePassword}
          loading={loading}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          repeatedPassword={repeatedPassword}
          setRepeatedPassword={setRepeatedPassword}
        />
      );
    default:
      return (
        <ResetPassword
          changePassword={changePassword}
          loading={loading}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          repeatedPassword={repeatedPassword}
          setRepeatedPassword={setRepeatedPassword}
        />
      );
  }
};

export default Registration;
