/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { store } from 'react-notifications-component';
import { cloneDeep } from 'lodash';
import {
  getId,
  removeIntersection,
  ruDateTimeFormat,
  copyToClipboard,
} from '../../core/functions';
import { useHistory } from 'react-router-dom';
import View from './view';
import {
  PersonalsService,
  MaterialsService,
  GamesService,
} from '../../services';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { setScroll } from '../../store/reducers/appState-reducer';
import { setPersonals } from '../../store/reducers/personals-reducer';

const paginationInitials = {
  materials: {
    page: 1,
    last: false,
  },
  tests: {
    page: 1,
    last: false,
  },
  generalTests: {
    page: 1,
    last: false,
  },
  games: {
    flashcards: {
      page: 1,
      last: false,
    },
    matchup: {
      page: 1,
      last: false,
    },
    anagrams: {
      page: 1,
      last: false,
    },
    categorize: {
      page: 1,
      last: false,
    },
  },
  events: {
    page: 1,
    last: false,
  },
};

const TasksContainer = props => {
  const teachers = useSelector(state => state.teachers);
  const students = useSelector(state => state.students);
  const history = useHistory();
  const [personalData, setPersonalData] = useState({});
  const [materials, setMaterials] = useState([]);
  const [tests, setTests] = useState([]);
  const [generalTests, setGeneralTests] = useState([]);
  const [events, setEvents] = useState([]);
  const [flashcards, setFlashcards] = useState([]);
  const [matchup, setMatchup] = useState([]);
  const [anagrams, setAnagrams] = useState([]);
  const [categorize, setCategorize] = useState([]);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [testsLoading, setTestsLoading] = useState(false);
  const [generalTestsLoading, setGeneralTestsLoading] = useState(false);
  const [flashcardsLoading, setFlashcardsLoading] = useState(false);
  const [matchupLoading, setMatchupLoading] = useState(false);
  const [anagramsLoading, setAnagramsLoading] = useState(false);
  const [categorizeLoading, setCategorizeLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [paginationStatus, setPaginationStatus] = useState(
    cloneDeep(paginationInitials),
  );
  const [handlingMaterials, setHandlingMaterials] = useState(false);
  const [tabId, setTabId] = useState(1);
  const [gamesTabId, setGamesTabId] = useState(1);
  const [teacherId, setTeacherId] = useState(null);
  const [personalId, setPersonalId] = useState(
    getId(history.location.pathname),
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [langLevel, setLangLevel] = useState(null);
  const [isLangLevelChanged, setIsLangLevelChanged] = useState(false);
  const [updateLanguageLoading, setUpdateLanguageLoading] = useState(false);
  const dispatch = useDispatch();
  const personals = useSelector(state => state.personals);

  useEffect(() => {
    dispatch(setScroll(false));
  }, []);

  const selectTabId = _tabId => {
    setTabId(_tabId);
    setGamesTabId(1);
    fetchData(_tabId, 1);
  };

  const selectGamesTabId = _gamesTabId => {
    setGamesTabId(_gamesTabId);
    fetchData(tabId, _gamesTabId);
  };

  const fetchData = (_tabId = tabId, _gamesTabId = gamesTabId) => {
    switch (_tabId) {
      case 1:
        getMaterials(personalId);
        break;
      case 2:
        getTests(personalId);
        break;
      case 3:
        getGeneralTests(personalId);
        break;
      case 4:
        switch (_gamesTabId) {
          case 1:
            getFlashcards(personalId || getId(history.location.pathname));
            break;
          case 2:
            getMatchup(personalId || getId(history.location.pathname));
            break;
          case 3:
            getAnagrams(personalId || getId(history.location.pathname));
            break;
          case 4:
            getCategorize(personalId || getId(history.location.pathname));
            break;
          default:
            return;
        }
        break;
      case 5:
        getEvents(personalId);
        break;
      default:
        return;
    }
  };

  const handlePaginationStatus = (
    tab,
    page = 1,
    last = false,
    gameTab = false,
  ) => {
    const temp = { ...paginationStatus };
    if (gameTab) {
      temp[tab][gameTab].page = page;
      temp[tab][gameTab].last = last;
    } else {
      temp[tab].page = page;
      temp[tab].last = last;
    }
    setPaginationStatus(temp);
  };

  useEffect(() => {
    if (personalId) {
      getPersonalData();
      fetchData(1);
    }
  }, [personalId]);

  const getPersonalData = async () => {
    try {
      const result = await PersonalsService.getPersonalData(personalId);
      setLangLevel(result?.level);
      setPersonalData(result);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const getMaterials = async (id = personalId) => {
    if (id && !paginationStatus.materials.last && !materialsLoading) {
      setMaterialsLoading(true);
      try {
        const result = await PersonalsService.getMaterials(
          id,
          paginationStatus.materials.page,
        );
        setMaterials([
          ...materials,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'materials',
          paginationStatus.materials.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMaterialsLoading(false);
    }
  };

  const getGeneralTests = async (id = personalId) => {
    if (id && !paginationStatus.generalTests.last && !generalTestsLoading) {
      setGeneralTestsLoading(true);
      try {
        const result = await PersonalsService.getGeneralTests(
          id,
          paginationStatus.generalTests.page,
        );
        setGeneralTests([
          ...generalTests,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'generalTests',
          paginationStatus.generalTests.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setGeneralTestsLoading(false);
    }
  };

  const getEvents = async (id = personalId) => {
    if (id && !paginationStatus.events.last && !eventsLoading) {
      setEventsLoading(true);
      try {
        const result = await PersonalsService.getEvents(
          {
            teacherId: personalData.teacherId,
            studentId: personalData.studentId,
          },
          paginationStatus.events.page,
        );
        setEvents([
          ...events,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'events',
          paginationStatus.events.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setEventsLoading(false);
    }
  };

  const handleMaterial = (has, index, value) => {
    const temp = [...materials];
    temp[index].active = value;
    setMaterials(temp);
  };

  const handleGame = (groupHas, index, value) => {
    switch (gamesTabId) {
      case 1:
      default:
        setFlashcards(
          flashcards.map((el, idx) =>
            index === idx ? { ...el, active: value } : el,
          ),
        );
        break;
      case 2:
        setMatchup(
          matchup.map((el, idx) =>
            index === idx ? { ...el, active: value } : el,
          ),
        );
        break;
      case 3:
        setAnagrams(
          anagrams.map((el, idx) =>
            index === idx ? { ...el, active: value } : el,
          ),
        );
        break;
      case 4:
        setCategorize(
          categorize.map((el, idx) =>
            index === idx ? { ...el, active: value } : el,
          ),
        );
        break;
    }
  };

  const shareGames = async () => {
    let removeItems;
    switch (gamesTabId) {
      case 1:
      default:
        removeItems = flashcards
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setFlashcards(old => old.filter(el => el.active));
        break;
      case 2:
        removeItems = matchup
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setMatchup(old => old.filter(el => el.active));
        break;
      case 3:
        removeItems = anagrams
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setAnagrams(old => old.filter(el => el.active));
        break;
      case 4:
        removeItems = categorize
          .filter(item => item.active === false)
          .map(item => item.categoryId);
        setCategorize(old => old.filter(el => el.active));
        break;
    }
    try {
      if (removeItems.length) {
        setDeleteLoading(true);
        const result = await GamesService.removeGames(gamesTabId, {
          classId: personalId,
          categoryId: [...removeItems],
        });
        if (result.errorCode) {
          throw new Error('Не удалось удалить игры');
        }
        setDeleteLoading(false);
        setShowDeleteModal(false);
      }
      showSuccessNotification('Игры были успешно удалены');
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const getTests = async (id = personalId) => {
    if (id && !paginationStatus.tests.last && !testsLoading) {
      setTestsLoading(true);
      try {
        setDeleteLoading(true);
        const result = await PersonalsService.getTests(
          id,
          paginationStatus.tests.page,
        );
        setTests([
          ...tests,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'tests',
          paginationStatus.tests.page + 1,
          result.last,
        );
        setDeleteLoading(false);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setTestsLoading(false);
    }
  };

  const deleteItems = () => {
    switch (tabId) {
      case 1:
        shareMaterials();
        break;
      case 2:
        shareTests();
        break;
      case 3:
        shareGeneralTests();
        break;
      case 4:
        shareGames();
        break;
    }
  };

  const shareTests = async () => {
    setTestsLoading(true);
    try {
      setDeleteLoading(true);
      const removeTests = tests
        .filter(item => item.active === false)
        .map(item => item.testId);
      if (removeTests.length) {
        const result = await PersonalsService.removeTests({
          classId: personalId,
          testId: [...removeTests],
        });
        setTests(tests.filter(el => !removeTests.includes(el.testId)));
        if (result.errorCode) {
          throw new Error('Не удалось удалить тесты');
        }
        setDeleteLoading(false);
        setShowDeleteModal(false);
        showSuccessNotification('Тесты были успешно удалены.');
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
    setTestsLoading(false);
  };

  const shareGeneralTests = async () => {
    setGeneralTestsLoading(true);
    try {
      setDeleteLoading(true);
      const removeGeneralTests = generalTests
        .filter(item => item.active === false)
        .map(item => item.testId);
      if (removeGeneralTests.length) {
        const result = await PersonalsService.removeGeneralTests({
          classId: personalId,
          testId: [...removeGeneralTests],
        });
        setGeneralTests(
          generalTests.filter(el => !removeGeneralTests.includes(el.testId)),
        );
        if (result.errorCode) {
          throw new Error('Не удалось удалить тесты');
        }
        setDeleteLoading(false);
        setShowDeleteModal(false);
        showSuccessNotification('Тесты были успешно удалены.');
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
    setGeneralTestsLoading(false);
  };

  const shareMaterials = async () => {
    setMaterialsLoading(true);
    try {
      setDeleteLoading(true);
      const removeMaterials = materials
        .filter(item => item.active === false)
        .map(item => item.materialId);
      if (removeMaterials.length) {
        const result = await MaterialsService.removeMaterialFromGroup({
          classId: personalId,
          materialId: [...removeMaterials],
        });
        setMaterials(
          materials.filter(el => !removeMaterials.includes(el.materialId)),
        );
        if (result.errorCode) {
          throw new Error('Не удалось удалить материалы');
        }
        setDeleteLoading(false);
        setShowDeleteModal(false);
        showSuccessNotification('Материалы были успешно удалены.');
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
    setMaterialsLoading(false);
  };

  const handleTest = (has, index, value) => {
    const temp = [...tests];
    temp[index].active = value;
    setTests(temp);
  };

  const handleGeneralTests = (has, index, value) => {
    const temp = [...generalTests];
    temp[index].active = value;
    setGeneralTests(temp);
  };

  const getFlashcards = async (id = personalId) => {
    if (id && !paginationStatus.games.flashcards.last && !flashcardsLoading) {
      setFlashcardsLoading(true);
      try {
        const result = await PersonalsService.getFlashCards(
          id,
          paginationStatus.games.flashcards.page,
        );
        setFlashcards([
          ...flashcards,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.flashcards.page + 1,
          result.last,
          'flashcards',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setFlashcardsLoading(false);
    }
  };

  const getAnagrams = async (id = personalId) => {
    if (id && !paginationStatus.games.anagrams.last && !anagramsLoading) {
      setAnagramsLoading(true);
      try {
        const result = await PersonalsService.getAnagrams(
          id,
          paginationStatus.games.anagrams.page,
        );
        setAnagrams([
          ...anagrams,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.anagrams.page + 1,
          result.last,
          'anagrams',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setAnagramsLoading(false);
    }
  };

  const getCategorize = async (id = personalId) => {
    if (id && !paginationStatus.games.categorize.last && !categorizeLoading) {
      setCategorizeLoading(true);
      try {
        const result = await PersonalsService.getCategorizes(
          id,
          paginationStatus.games.categorize.page,
        );
        setCategorize([
          ...categorize,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.categorize.page,
          result.last,
          'categorize',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setCategorizeLoading(false);
    }
  };

  const updateLanguageLevel = async () => {
    setUpdateLanguageLoading(true);
    try {
      const response = await PersonalsService.updatePersonal(
        personalData.classId,
        {
          level: langLevel,
        },
      );
      dispatch(
        setPersonals(
          personals.map((el, index) => {
            if (el.classId === response.classId) {
              return { ...response };
            }
            return el;
          }),
        ),
      );
      setPersonalData(response);
      showSuccessNotification('Уровень языка успешно изменен');
    } catch (error) {
      showErrorNotification(error.message);
    }
    setUpdateLanguageLoading(false);
  };

  const getMatchup = async (id = personalId) => {
    if (id && !paginationStatus.games.matchup.last && !matchupLoading) {
      setMatchupLoading(true);
      try {
        const result = await PersonalsService.getMatchups(
          id,
          paginationStatus.games.matchup.page,
        );
        setMatchup([
          ...matchup,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'games',
          paginationStatus.games.matchup.page,
          result.last,
          'matchup',
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMatchupLoading(false);
    }
  };

  return (
    <View
      teachers={teachers}
      students={students}
      data={personalData}
      setGamesTabId={selectGamesTabId}
      setTabId={selectTabId}
      tabId={tabId}
      langLevel={langLevel}
      updateLanguageLevel={updateLanguageLevel}
      setLangLevel={setLangLevel}
      gamesTabId={gamesTabId}
      handleTest={handleTest}
      handleGeneralTests={handleGeneralTests}
      handleMaterial={handleMaterial}
      handleGame={handleGame}
      shareTests={shareTests}
      shareGeneralTests={shareGeneralTests}
      shareMaterials={shareMaterials}
      shareGames={shareGames}
      generalTests={generalTests}
      generalTestsLoading={generalTestsLoading}
      isLangLevelChanged={isLangLevelChanged}
      setIsLangLevelChanged={setIsLangLevelChanged}
      updateLanguageLoading={updateLanguageLoading}
      tests={tests}
      testsLoading={testsLoading}
      materials={materials}
      materialsLoading={materialsLoading}
      flashcards={flashcards}
      flashcardsLoading={flashcardsLoading}
      matchup={matchup}
      matchupLoading={matchupLoading}
      anagrams={anagrams}
      anagramsLoading={anagramsLoading}
      categorize={categorize}
      categorizeLoading={categorizeLoading}
      handleContainerOnBottom={fetchData}
      events={events}
      eventsLoading={eventsLoading}
      paginationStatus={paginationStatus}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      deleteLoading={deleteLoading}
      delete={deleteItems}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
