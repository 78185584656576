import React, { useEffect, useRef } from 'react';
import Icon from '../icon/Icon';
import './style.css';
import PushContainer from '../pushContainer';

const UserBlockView = props => {
  const { name, lastName, teacherId, avatar } = props.userData || {
    name: '',
    lastName: '',
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleMenu(false);
          // alert('asda')
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="userBlock-container">
      <PushContainer />
      <div className="user-info" onClick={() => props.handleMenu(true)}>
        {avatar ? (
          <img src={avatar} className="avatar" alt="account" />
        ) : (
          <div className="avatar">
            <Icon name="avatar" width={32} height={32} />
          </div>
        )}
        <div className="name">
          {name} {lastName} <Icon name="arrow-bottom" width={24} height={24} />
        </div>
        {props.displayUserMenu && (
          <div ref={wrapperRef} class="user-menu">
            <div
              className="submenu-item"
              onClick={() => props.navigateTo(teacherId)}>
              Профиль
            </div>
            <div className="submenu-item" onClick={props.logOut}>
              Выход
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserBlockView;
