import React, { useRef } from 'react';
import { AVAILABLE_AVATAR_PICTURE_EXTENSIONS } from '../../core/config';
import { showErrorNotification } from '../../core/errorsController';
import Icon from '../icon/Icon';
import Loader from '../loader/Loader';
import './style.css';

const AvatarControls = ({
  handleFileSelect = () => {},
  handleDelete = () => {},
  disableDelete = false,
  disableUpload = false,
  deleteLoading = false,
  extensions = [],
}) => {
  const uploader = useRef(null);

  const toggleUploader = () => {
    uploader.current.click();
  };

  const getBase64 = file => {
    const passedExtenstionCheck = checkExtensions(file.name);
    if (passedExtenstionCheck) {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        uploader.current.value = '';
        handleFileSelect(fileReader.result);
      };

      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
  };

  const unsetFileSelector = () => {
    uploader.current.value = '';
  };

  const checkExtensions = fileName => {
    if (extensions.length) {
      const fileExtension = fileName.split('.').pop();
      if (!fileExtension) {
        showErrorNotification(
          'Произошла ошибка, проверьте расширение загружаемого файла',
        );
        unsetFileSelector();
        return false;
      }
      if (!extensions.includes(fileExtension)) {
        showErrorNotification(
          'Пожалуйста, загрузите файл требуемого расширения, доступные расширения: ' +
            extensions.join(', '),
        );
        unsetFileSelector();
        return false;
      }
    }
    return true;
  };

  return (
    <div className="avatar-controls-container">
      <div className="avatar-controls-wrapper">
        {!disableDelete ? (
          <div
            className="avatar-controls-button"
            onClick={!deleteLoading ? handleDelete : null}>
            {deleteLoading ? (
              <Loader className="loader-default" color="#FFFFFF" />
            ) : (
              <Icon name="trash" color="white" width={36} height={36} />
            )}
          </div>
        ) : null}
        {!(disableDelete || disableUpload) ? (
          <div className="avatar-controls-ruler" />
        ) : null}
        {!disableUpload ? (
          <div onClick={toggleUploader} className="avatar-controls-button">
            <input
              ref={uploader}
              type="file"
              accept={
                extensions.length
                  ? extensions.map(el => '.' + el).join(',')
                  : null
              }
              className="file-input"
              onChange={e => getBase64(e.target.files[0])}
            />
            <Icon name="download" color="white" width={36} height={36} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AvatarControls;
