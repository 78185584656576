import React from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import './style.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import { LabeledCheckbox } from '../../components';
import { getTeacherBirthBoundry } from '../../core/functions';
import DatePicker from '../../components/datePicker/DatePicker.jsx';

const Registration = props => {
  return (
    <div className="loginContainer">
      <div className="half left">
        <Logo />
        <div className="registration__form__container">
          <div className="loginTitle">Введите новый пароль</div>
          <Input
            type="password"
            title="Введите пароль"
            className="registration-input"
            value={props.newPassword}
            placeholder="Введите новый пароль"
            onChange={e => props.setNewPassword(e.target.value)}
          />
          <div className="repeat-password-container">
            <Input
              type="password"
              title="Повторите пароль"
              className="registration-input"
              value={props.repeatedPassword}
              placeholder="Повторите новый пароль"
              onChange={e => props.setRepeatedPassword(e.target.value)}
            />
            {!(props.newPassword === props.repeatedPassword) && (
              <div className="password-warning">Пароли не совпадают</div>
            )}
          </div>
          <Button
            loading={props.loading}
            disabled={
              props.newPassword
                ? props.newPassword !== props.repeatedPassword
                : true
            }
            className="centered"
            onClick={() => props.changePassword()}
            textButton="Изменить пароль"
          />
        </div>
      </div>
      <div className="half right">
        <div
          style={{
            backgroundImage: `url('${require('../../assets/pictures/authPicture.png')}')`,
          }}
          alt="Языковая студия Nota5"
        />
      </div>
    </div>
  );
};

export default Registration;
