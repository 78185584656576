import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import AppInfoService from '../../services/appInfo';
import { setEditing } from '../../store/reducers/appState-reducer';

import AboutUsView from './AboutUsView';

const AboutUsContainer = props => {
  const [aboutUs, setAboutUs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEditing(false));
    getData();
  }, []);

  const editAboutUs = (e, callback) => {
    e.persist();
    dispatch(setEditing(true));
    setAboutUs(callback);
  };

  const getData = async () => {
    try {
      const aboutUsData = await AppInfoService.getAboutUs();
      setAboutUs(aboutUsData);
      setLoading(false);
    } catch (e) {
      showErrorNotification('Не удалось получить данные');
    }
    setLoading(false);
  };

  const updateAboutUs = async () => {
    setUpdateLoading(true);
    try {
      const aboutUsData = await AppInfoService.updateAboutUs(aboutUs);
      dispatch(setEditing(false));
      setLoading(false);
      setShowUpdateModal(false);
      showSuccessNotification('Данные успешно обновлены');
    } catch (e) {
      showErrorNotification('Не удалось обновить данные');
    }
    setUpdateLoading(false);
  };

  return (
    <AboutUsView
      editAboutUs={editAboutUs}
      aboutUs={aboutUs}
      setAboutUs={setAboutUs}
      loading={loading}
      updateAboutUs={updateAboutUs}
      updateLoading={updateLoading}
      setUpdateLoading={setUpdateLoading}
      showUpdateModal={showUpdateModal}
      setShowUpdateModal={setShowUpdateModal}
      update={() => setShowUpdateModal(true)}
    />
  );
};

export default AboutUsContainer;
