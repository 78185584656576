import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import { ruDateTimeFormat } from '../../core/functions';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      {/* <NavLink to={`users/${data.id}`} className="box box-1">{data.id}</NavLink> */}
      <NavLink
        to={`/general-tests/${data.testObj.testId}`}
        className="box box-2">
        {data.title}
      </NavLink>
      <NavLink
        to={`/general-tests/${data.testObj.testId}`}
        className="box box-3">
        {moment(data.createdTime).format('DD MMMM YYYY г.')}
      </NavLink>
      <NavLink
        to={`/general-tests/${data.testObj.testId}`}
        className="box box-6 center">
        {props.renderControl ? (
          props.renderControl(data.testObj)
        ) : (
          <Button
            type={'secondary'}
            small
            textButton={'Удалить'}
            onClick={() => {
              props.deleteTest(data.testObj);
            }}
          />
        )}
      </NavLink>
    </div>
  );

  return (
    <>
      <div
        onScroll={props.paginate}
        className="table general-teacher-tests-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Название</div>
          <div className="box box-3">Дата начала</div>
          <div className="box box-6">Управление</div>
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((test, index) => (
                <TableLine
                  index={index}
                  title={test.title}
                  createdTime={test.createdTime}
                  testObj={test}
                />
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
      {props.type !== 'teacher' && props.data?.length ? (
        <Button
          type="primary"
          textButton="Удалить"
          onClick={() => {
            props.shareTests();
          }}
        />
      ) : null}
    </>
  );
};

export default UsersTableView;
