/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getNotificationData } from '../../core/notificationsConfig';
import { NotificationsService } from '../../services';
import { deleteNotification } from '../../store/reducers/notifications-reducer';

import View from './view';

const Container = ({ data = null }) => {
  const notificationContent = getNotificationData(data);
  const [wasUnseen] = useState(data?.read === false);
  const dispatch = useDispatch();
  const notificationRef = useRef(null);

  const onDelete = () => {
    NotificationsService.deleteNotifications(data.pushId);
    dispatch(deleteNotification(data.pushId));
  };

  return (
    <View
      date={data.date}
      notificationRef={notificationRef}
      unseen={wasUnseen}
      onDelete={onDelete}
      {...notificationContent}
    />
  );
};

export default Container;
