import React from 'react';

import View from './view';

const Container = ({
  renderEmpty = () => {},
  handleTest = () => {},
  shareTests = () => {},
  renderControl,
  data = [],
  last = false,
  paginate = () => {},
  loading = false,
}) => {
  return (
    <View
      renderControl={renderControl}
      last={last}
      loading={loading}
      paginate={paginate}
      renderEmpty={renderEmpty}
      handleTest={handleTest}
      shareTests={shareTests}
      data={data}
    />
  );
};


export default Container;
