export const languageLevels = [
  'Beginner',
  'Elementary',
  'Pre-intermediate',
  'Intermediate',
  'Upper-intermediate',
  'Advanced',
  'Proficiency',
];

export const AVAILABLE_MOBILE_APP_AUDIO_EXTENSIONS = [
  'aac',
  'wave',
  'wav',
  'wma',
  'ac3',
  'aif',
  'amr',
  'aud',
  'flac',
  'iff',
  'm3u',
  'm3u8',
  'm4a',
  'm4b',
  'm4r',
  'mid',
  'midi',
  'mod',
  'mp3',
  'mpa',
  'ogg',
  'ra',
  'ram',
  'sib',
];

export const AVAILABLE_AVATAR_PICTURE_EXTENSIONS = [
  'png',
  'jpeg',
  'jpg',
  'heic',
];
