import React from 'react';

import { NavLink } from 'react-router-dom';
import { translate } from '../../core/functions';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      <NavLink
        to={`/${data.link || 'groups'}/${data.id}`}
        className="box box-1 number">
        {data.number}
      </NavLink>
      <NavLink
        to={`/${data.link || 'groups'}/${data.id}`}
        className="box box-2 name">
        {data.name}
      </NavLink>
      <NavLink
        to={`/${data.link || 'groups'}/${data.id}`}
        className="box box-3">
        {data.level}
      </NavLink>
      <NavLink
        to={`/${data.link || 'groups'}/${data.id}`}
        className="box box-4">
        {data.studentsLength}
      </NavLink>
      {props.type !== 'teacher' ? (
        <NavLink to={`teachers/${data.teacherId}`} className="box box-5">
          {data.teacherName}
        </NavLink>
      ) : null}
      {props.type !== 'teacher' ? (
        <div className="box box-6">
          {props.renderControl ? (
            props.renderControl(props.group)
          ) : (
            <Button
              type="secondary"
              small
              textButton="Удалить"
              onClick={() => {
                data.deleteGroup(data);
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );

  return (
    <div className="table-wrapper">
      <div onScroll={props.paginate} className="table groups-table">
        <div className="table-header table-line">
          <div className="box box-1">№</div>
          <div className="box box-2">Название</div>
          <div className="box box-3">Уровень</div>
          <div className="box box-4">Число учащихся</div>
          {props.type !== 'teacher' ? (
            <div className="box box-5">Преподаватель</div>
          ) : null}
          {props.type !== 'teacher' ? (
            <div className="box box-6">Управление</div>
          ) : null}
        </div>
        <div className="table-list">
          {props.data.length
            ? props.data.map((group, index) => (
                <TableLine
                  link={props.link}
                  index={index}
                  key={index}
                  group={group}
                  number={index + 1}
                  name={group.name}
                  level={group.level}
                  studentsLength={group.studentsLength}
                  teacherName={props.getTeacherName(group.teacherId)}
                  teacherId={group.teacherId}
                  id={group.groupId}
                  deleteGroup={props.deleteGroup}
                />
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
    </div>
  );
};

export default UsersTableView;
