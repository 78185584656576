/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CalendarService from '../../services/calendar';
import { cloneDeep } from 'lodash';
import CalendarView from './CalendarView';
import { store } from 'react-notifications-component';
import { getGroupTeacherId } from '../../core/functions';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { setScroll } from '../../store/reducers/appState-reducer';

const today = moment(new Date());
const defaultFromDate = today.subtract(1, 'M').format('YYYY-MM-21');
const defaultToDate = today.add(2, 'M').format('YYYY-MM-08');

const CalendarContainer = props => {
  const teachers = useSelector(state => state.teachers);
  const groups = useSelector(state => state.groups);
  const students = useSelector(state => state.students);
  const userData = useSelector(state => state.user);

  const [lastFetchOptions, setLastFetchOptions] = useState(null);
  const [lastDaySortOptions, setLastDaySortOptions] = useState({
    from: today.format('YYYY-MM-DD'),
    to: today.format('YYYY-MM-DD'),
  });

  const [modalType, setModalType] = useState('new');
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [filteredByDays, setFilteredByDays] = useState(null);
  const [filteredByDaysAndTeacher, setFilteredByDaysAndTeacher] =
    useState(null);
  const [filteredByTeacher, setFilteredByTeacher] = useState();
  const [createLoading, setCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [targetEvent, setTargetEvent] = useState();
  const [eventOnChange, setEventOnChange] = useState();
  const [wrongTime, setWrongTime] = useState(false);
  const isAdmin = userData.role === 'admin';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScroll(false));
  }, []);

  const getEvents = async (
    fromDate = defaultFromDate,
    toDate = defaultToDate,
  ) => {
    setIsLast(false);
    setIsLoading(true);
    let _data = data;
    try {
      const response = await CalendarService.getEvents(
        fromDate,
        toDate,
        isAdmin ? null : userData.teacherId,
      );
      setFilteredByTeacher(filterByTeacher(selectedTeacher, response.data));
      setLastFetchOptions({ fromDate, toDate });
      setData(response.data);
      setIsLast(false); // захардкожено до тех пор пока фаербейс не выдает нормально ласта
      _data = response.data;
    } catch (error) {
      showErrorNotification(error.message);
    }
    setIsLoading(false);
    return _data;
  };

  const updateEvent = async () => {
    setUpdateLoading(true);
    try {
      const _targetEvent = { ...targetEvent };
      delete _targetEvent.type;
      delete _targetEvent.eventId;
      delete _targetEvent.createdTime;
      delete _targetEvent.timeEnd;
      delete _targetEvent.timeStart;
      delete _targetEvent.originalDate;
      delete _targetEvent.price;
      delete _targetEvent.paid;
      const response = await CalendarService.updateEvent(
        targetEvent.eventId,
        _targetEvent,
      );
      showSuccessNotification('Событие обновлено');
      const fetchedData = await getEvents(
        lastFetchOptions.fromDate,
        lastFetchOptions.toDate,
      );
      await filterByDay(
        lastDaySortOptions.from,
        lastDaySortOptions.to,
        fetchedData,
      );
      manipulateModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setUpdateLoading(false);
  };

  const deleteEvent = async () => {
    setDeleteLoading(true);
    try {
      const response = await CalendarService.deleteEvent(targetEvent.eventId);
      showSuccessNotification('Событие удалено');
      const fetchedData = await getEvents(
        lastFetchOptions.fromDate,
        lastFetchOptions.toDate,
      );
      await filterByDay(
        lastDaySortOptions.from,
        lastDaySortOptions.to,
        fetchedData,
      );
      manipulateModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setDeleteLoading(false);
  };

  const createEvent = async () => {
    setCreateLoading(true);
    try {
      if (targetEvent.groupId) {
        const response = await CalendarService.createGroupEvent(
          targetEvent.date,
          targetEvent.groupId,
          targetEvent.duration,
        );
      } else {
        const targetTeacher = teachers.find(
          el => el.teacherId === targetEvent.teacherId,
        );
        const price =
          targetEvent.duration === '60'
            ? targetTeacher.shortClassPrice
            : targetTeacher.longClassPrice;
        if (!price) {
          throw new Error(
            'Для выбранного учителя не определена цена индивидуального занятия',
          );
        }
        const response = await CalendarService.createClassEvent(
          targetEvent.date,
          targetEvent.teacherId,
          targetEvent.duration,
          price,
        );
      }
      await getEvents(lastFetchOptions.fromDate, lastFetchOptions.toDate);
      showSuccessNotification('Событие было создано');
      const fetchedData = await getEvents(
        lastFetchOptions.fromDate,
        lastFetchOptions.toDate,
      );
      await filterByDay(
        lastDaySortOptions.from,
        lastDaySortOptions.to,
        fetchedData,
      );
      manipulateModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setCreateLoading(false);
  };
  const setTargetEventGroup = el => {
    setTargetEvent(old => {
      return { ...old, groupId: el.groupId, teacherId: el.teacherId };
    });
  };

  const setTargetEventTeacher = el => {
    setTargetEvent(old => {
      return { ...old, groupId: '', teacherId: el.teacherId };
    });
  };

  const selectTeacher = id => {
    setSelectedTeacher(id);
    setFilteredByDaysAndTeacher(filterByTeacher(id, filteredByDays));
    setFilteredByTeacher(filterByTeacher(id));
  };

  const filterByTeacher = (teacherId, _data = data) => {
    let filteredByTeacher;
    if (teacherId && _data?.length) {
      filteredByTeacher = _data.filter(el => {
        if (el.teacherId) {
          return el.teacherId === teacherId;
        } else {
          return getGroupTeacherId(el.groupId, groups) === teacherId;
        }
      });
    } else {
      filteredByTeacher = _data;
    }
    return filteredByTeacher;
  };

  const filterByDay = (
    _minDate = lastDaySortOptions.from,
    _maxDate = lastDaySortOptions.to,
    _data = data,
    _selectedTeacher = selectTeacher,
  ) => {
    const _filteredByDays = _data?.filter(el => {
      return (
        moment(el.date).isAfter(moment(_minDate)) &&
        moment(el.date).isBefore(moment(_maxDate))
      );
    });
    setLastDaySortOptions({
      from: _minDate,
      to: _maxDate,
    });
    setFilteredByDaysAndTeacher(
      filterByTeacher(selectedTeacher, _filteredByDays),
    );
    setFilteredByDays(cloneDeep(_filteredByDays));
  };

  const onPressChange = event => {
    setTargetEvent(cloneDeep(event));
    setModalType('change');
    setEventOnChange(cloneDeep(event));
    setTimeout(() => manipulateModal(true), 0);
  };

  const onPressDelete = event => {
    setTargetEvent(cloneDeep(event));
    setModalType('delete');
    setTimeout(() => manipulateModal(true), 0);
  };

  const onPressNew = () => {
    setTargetEvent(isAdmin ? {} : { teacherId: userData.teacherId });
    setModalType('new');
    setTimeout(() => manipulateModal(true), 0);
  };

  const manipulateModal = show => {
    if (show) {
      setShowModal(true);
    } else {
      setShowModal(false);
      setModalType(null);
      setTargetEvent(null);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <CalendarView
      updateEvent={updateEvent}
      deleteEvent={deleteEvent}
      createEvent={createEvent}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      createLoading={createLoading}
      modalType={modalType}
      eventOnChange={eventOnChange}
      onPressDelete={onPressDelete}
      onPressChange={onPressChange}
      onPressNew={onPressNew}
      setTargetEventGroup={setTargetEventGroup}
      setTargetEventTeacher={setTargetEventTeacher}
      showModal={showModal}
      setShowModal={manipulateModal}
      setTargetEvent={setTargetEvent}
      targetEvent={targetEvent}
      teachers={teachers}
      groups={groups}
      students={students}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={selectTeacher}
      onMonthChange={getEvents}
      onSelect={filterByDay}
      data={filteredByTeacher}
      wrongTime={wrongTime}
      setWrongTime={setWrongTime}
      filteredData={filteredByDaysAndTeacher}
      isLoading={isLoading}
      isLast={isLast}
      isAdmin={isAdmin}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
