import moment from 'moment';
import { store } from '../store/index';
import {
  getTeachersFormattedName,
  getGroupsFormattedName,
  getStudentsFormattedName,
} from './functions';

let students;
let teachers;
let groups;
let user;
let isDataFromStoreRecieved = false;

const getAvatar = (array, data, idField) => {
  return array.find(el => el[idField] === data[idField])?.avatar;
};

const nameGetter = {
  teacher: teacherId => getTeachersFormattedName(teacherId, teachers),
  student: studentId => getStudentsFormattedName(studentId, students),
  group: groupId => getGroupsFormattedName(groupId, groups),
};

const getDataFromPersistedStore = () => {
  if (!isDataFromStoreRecieved) {
    const _store = store.getState();
    students = _store.students;
    teachers = _store.teachers;
    groups = _store.groups;
    user = _store.user;
    isDataFromStoreRecieved = true;
  }
};

export const getNotificationData = data => {
  getDataFromPersistedStore();
  switch (data.type) {
    case 'event-transfer':
      if (user.role === 'admin') {
        return {
          avatar: getAvatar(students, data, 'studentId'),
          title: 'Перенос занятия',
          text: `Студент ${nameGetter.student(
            data.studentId,
          )} перенес занятие у учителя ${nameGetter.teacher(
            data.oldTeacherId,
          )} от ${moment(data.oldEventDate).format('DD MMMM, HH:mm')} на
          ${moment(data.newEventDate).format(
            'DD MMMM, HH:mm',
          )} у учителя ${nameGetter.teacher(data.newTeacherId)}`,
          data: data.date,
        };
      }
      if (user.teacherId === data.oldTeacherId) {
        if (data.oldTeacherId === data.newTeacherId) {
          return {
            avatar: getAvatar(students, data, 'studentId'),
            title: 'Перенос занятия',
            text: `Студент ${nameGetter.student(
              data.studentId,
            )} перенес ваше занятие с ${moment(data.oldEventDate).format(
              'DD MMMM, HH:mm',
            )} на
            ${moment(data.newEventDate).format('DD MMMM, HH:mm')}`,
            data: data.date,
          };
        }
        return {
          avatar: getAvatar(students, data, 'studentId'),
          title: 'Перенос занятия',
          text: `Студент ${nameGetter.student(
            data.studentId,
          )} перенес ваше занятие с ${moment(data.oldEventDate).format(
            'DD MMMM, HH:mm',
          )} к другому учителю`,
          data: data.date,
        };
      }
      if (user.teacherId === data.newTeacherId) {
        return {
          avatar: getAvatar(students, data, 'studentId'),
          title: 'Перенос занятия',
          text: `Студент ${nameGetter.student(
            data.studentId,
          )} перенес к вам занятие на ${moment(data.newEventDate).format(
            'DD MMMM, HH:mm',
          )}`,
          data: data.date,
        };
      }
      break;
    case 'event-appointment':
      return {
        avatar: getAvatar(students, data, 'studentId'),
        title: 'Запись на занятие',
        text: `Студент ${nameGetter.student(
          data.studentId,
        )} записался на занятие у учителя ${nameGetter.teacher(
          data.teacherId,
        )}`,
        data: data.date,
      };
    case 'personal-event-creation':
      return {
        avatar: getAvatar(teachers, data, 'teacherId'),
        title: 'Новое персональное занятие',
        text: `Учитель ${nameGetter.teacher(
          data.teacherId,
        )} создал новое свободное персональное занятие.`,
        data: data.date,
      };
    case 'group-event-creation':
      return {
        avatar: getAvatar(teachers, data, 'teacherId'),
        title: 'Новое групповое занятие',
        text: `Учитель ${nameGetter.teacher(
          data.teacherId,
        )} создал новое занятие с группой ${nameGetter.group(data.groupId)}.`,
        data: data.date,
      };
  }
};
