import React from 'react';
import './style.css';
import ButtonIcon from '../button-icon/index';
import SelectableContainer from '../selectableContainer/SelectableContainer';

const Select = props => {
  const getFilteredData = () => {
    if (props.data?.length) {
      if (props.search) {
        if (props.searchFor) {
          const regexp = new RegExp(props.searchFor, 'i');
          return props.data.filter(el => {
            return regexp.test(props.extractSearchKey(el));
          });
        }
      }
    }
    return props.data;
  };
  const filtered = getFilteredData();

  const getDirection = (direction, offset) => {
    switch (direction) {
      case 'bottom':
        return { top: offset };
      case 'top':
        return { bottom: offset };
      default:
        return { top: 0 };
    }
  };

  return (
    <div className={`${props.containerClass} select-container`}>
      {props.label ? <div className="inputTitle">{props.label}</div> : null}
      <SelectableContainer
        disabled={props.disabled}
        style={{ width: `calc(${props.width}` }}
        selectionColor={'rgb(248, 249, 251)'}
        className={`select-wrapper ${
          props.showOptions ? 'select-active' : null
        } `}
        customSelectionControl={props.showOptions}>
        {props.showOptions ? (
          <div
            className="options-wrapper"
            style={{
              width: `calc(${props.width} + 2px)`,
              ...getDirection(props.invertedDirection, 10),
            }}>
            <div
              style={{
                width: `calc(${props.width})`,
                ...getDirection(props.direction, 0),
              }}
              className="options-container"
              onMouseUp={props.onBlur}>
              {props.renderBefore ? props.renderBefore() : null}
              {filtered?.length
                ? filtered.map(el => props.renderOption(el))
                : props.renderEmpty()}
              {props.renderAfter ? props.renderAfter() : null}
            </div>
          </div>
        ) : null}
        <div
          className={`controls-wrapper ${
            props.disabled ? 'controls-disabled' : ''
          }`}>
          <input
            disabled={props.disabled}
            style={!props.search ? { caretColor: 'transparent' } : null}
            type="text"
            className="select-input input"
            onChange={e =>
              props.search ? props.setSearchFor(e.target.value) : null
            }
            placeholder={props.placeholder}
            value={props.searchMode ? props.searchFor : props.value}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
          />
          {!props.disabled ? (
            !props.value || props.noReset ? (
              <ButtonIcon
                onClick={() => {
                  props.showOptions ? props.onBlur() : props.onFocus();
                }}
                name="search"
              />
            ) : (
              <ButtonIcon onClick={props.onReset} name="close" />
            )
          ) : null}
        </div>
      </SelectableContainer>
    </div>
  );
};

export default Select;
