import React from 'react';
import Button from '../button';
import Modal from '../modal';
import './style.css';

const DialogModal = ({
  show,
  setShow,
  children,
  loading = false,
  onDelete = () => {},
  title = 'Удаление',
  okText = 'Удалить',
  okDisabled = false,
}) => {
  const stopEvent = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const blockActions = async () => {
    document.addEventListener('click', stopEvent, true);
  };

  const unblockActions = () => {
    document.removeEventListener('click', stopEvent, true);
  };

  const onPressDelete = async () => {
    blockActions();
    await onDelete();
    unblockActions();
  };

  return (
    <Modal title={title} show={show} setShow={setShow}>
      {children ? (
        <div className="delete-lesson-modal">
          {children}
          <div className="delete-modal-controls-wrapper">
            <Button
              type="text"
              small
              className="delete-modal-go-back-button"
              textButton="Назад"
              onClick={() => setShow(false)}
            />
            <Button
              loading={loading}
              type="primary"
              small
              disabled={okDisabled}
              textButton={okText}
              onClick={onPressDelete}
            />
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export default DialogModal;
