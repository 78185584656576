import { store } from 'react-notifications-component';
import moment from 'moment';
import teachersReducer from '../store/reducers/teachers-reducer';
import Loader from '../components/loader/Loader';
import { showSuccessNotification } from './errorsController';

export const prettyWord = (counter, oneWord, noWord, aLotOfWord) => {
  if (counter % 10 === 1 && counter !== 11) {
    return oneWord;
  } else if (
    [2, 3, 4].some(el => el === counter % 10) &&
    ![12, 13, 14].some(el => el === counter)
  ) {
    return noWord;
  } else {
    return aLotOfWord;
  }
};

export const prettyLangLevel = level => {
  const languageLevels = [
    'Beginner',
    'Elementary',
    'Pre-intermediate',
    'Intermediate',
    'Upper-intermediate',
    'Advanced',
    'Proficiency',
  ];
};

export const getTeacherBirthBoundry = () => {
  const today = moment(new Date());
  const limited = today.subtract(16, 'y');
  return limited.format('YYYY-MM-DD');
};

export const getTeachersFormattedName = (id, teachers) => {
  const teacher = teachers.find(el => el.teacherId === id);
  return teacher
    ? `${teacher.lastName} ${teacher.name[0]}. ${
        teacher.patronymic ? teacher.patronymic[0] + '.' : ''
      }`
    : 'Ошибка';
};

export const getStudentsFormattedName = (id, students) => {
  const student = students.find(el => el.studentId === id);
  return student
    ? `${student.lastName} ${student.name[0]}. ${
        student.patronymic ? student.patronymic[0] + '.' : ''
      }`
    : 'Ошибка';
};

export const getGroupTeacherId = (groupId, groups) => {
  const teacherId = groups.find(el => el.groupId === groupId)?.teacherId;
  return teacherId;
};

export const getGroupsFormattedName = (id, groups) => {
  const group = groups.find(el => el.groupId === id);
  return group ? group.name : 'Ошибка';
};

export const getDate = string => {
  if (!string) {
    return null;
  }

  return string.substring(0, 10);
};

export const getTime = string => {
  if (!string) {
    return null;
  }

  return string.substring(11, 16);
};
export const ruDateTimeFormat = date => {
  if (!!date === false) {
    return '';
  }

  const temp = date.split(/[^0-9]/);

  const result = new Date(
    Date.UTC(
      temp[0],
      temp[1] - 1,
      temp[2],
      temp[3] || null,
      temp[4] || null,
      temp[5] || null,
    ),
  );
  return result.toLocaleString('ru-RU');
};
export const getId = string => {
  if (!string) {
    return null;
  }

  return string.split('/')[2];
};
export const getGameId = string => {
  if (!string) {
    return null;
  }

  return string.split('/')[3];
};
export const removeIntersection = (array1, array2, field) => {
  if (!array2.length) {
    return array1;
  }
  const ids = array2.map(item => item[field]);
  let result = array1.filter(item => ids.indexOf(item[field]) < 0);
  return result;
};

export const copyToClipboard = async text => {
  try {
    await navigator.clipboard.writeText(text);
    showSuccessNotification(`Код ${text} скопирован в буфер обмена`);
  } catch (e) {}
};

export const translate = (string, type) => {
  switch (type) {
    case 'level':
      switch (string) {
        case 'a1':
          return 'Elementary';
        case 'a2':
          return 'Pre-Intermediate';
        case 'b1':
          return 'Intermediate';
        case 'b2':
          return 'Upper-Intermediate';
        case 'c1':
          return 'Advanced';
        case 'c2':
          return 'Fluent';
        default:
          return '';
      }
    case 'material':
      switch (string) {
        case 'standart':
          return 'Текстовый';
        case 'audio':
          return 'Аудио';
        case 'video':
          return 'Видео';
        case 'file':
          return 'Файл';
        default:
          return '';
      }
    default:
      return '';
  }
};
