import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import localForage from 'localforage';
import reducers from './reducers';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage: localForage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware, logger),
);

export const persistor = persistStore(store);

// persistor.purge();

//delete persisted state
