import axios from 'axios';
import { BASE_URL, BASE_TOKEN_URL } from '../constants';
import { TeachersService } from '.';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

const axiosInstanceToken = axios.create({
  baseURL: BASE_TOKEN_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(async config => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `${token}`;
  if (config.url === '/auth') {
    delete config.headers.Authorization;
  }

  return config;
});

axiosInstanceToken.interceptors.request.use(async config => {
  if (config.url === '/auth') {
    delete config.headers.Authorization;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      error.response.data.message === 'Invalid token!' ||
      error.response.data.errorCode === 'auth/id-token-expired'
    ) {
      originalRequest._retry = true;
      localStorage.removeItem('token');
      await TeachersService.refreshToken();
      return axiosInstance(originalRequest);
    }
    if (error.response.data.code === 401) {
    }
    return Promise.reject(error);
  },
);

axiosInstanceToken.interceptors.response.use(
  response => response,
  async error => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return Promise.reject(error);
  },
);

export const apiPost = (path, body, params = {}, configOutside) => {
  if (path === '/token') {
    axiosInstanceToken.interceptors.request.use(config => {
      if (configOutside && configOutside.contentType) {
        config.headers['Content-Type'] = configOutside.contentType;
      }
      return config;
    });
    if (configOutside && configOutside.contentType) {
      return axiosInstanceToken.post(path, body, { params });
    }
    return axiosInstanceToken.post(path, JSON.stringify(body), { params });
  }
  axiosInstance.interceptors.request.use(config => {
    if (configOutside && configOutside.contentType) {
      config.headers['Content-Type'] = configOutside.contentType;
    }
    return config;
  });
  if (configOutside && configOutside.contentType) {
    return axiosInstance.post(path, body, { params });
  }

  return axiosInstance.post(path, JSON.stringify(body), { params });
};

export const apiGet = (path, params, configOutside) => {
  axiosInstance.interceptors.request.use(config => {
    if (configOutside && configOutside.token) {
      config.headers.Authorization = `jwt ${configOutside.token}`;
    }
    return config;
  });

  return axiosInstance.get(path, params);
};

export const apiPut = (path, body, configOutside) => {
  axiosInstance.interceptors.request.use(config => {
    if (configOutside && configOutside.contentType) {
      config.headers['Content-Type'] = configOutside.contentType;
    }
    return config;
  });

  if (configOutside && configOutside.contentType) {
    return axiosInstance.patch(path, body);
  }

  return axiosInstance.put(path, JSON.stringify(body));
};

export const apiPatch = (path, body, configOutside) => {
  axiosInstance.interceptors.request.use(config => {
    if (configOutside && configOutside.contentType) {
      config.headers['Content-Type'] = configOutside.contentType;
    }
    return config;
  });

  if (configOutside && configOutside.contentType) {
    return axiosInstance.patch(path, body);
  }

  return axiosInstance.patch(path, JSON.stringify(body));
};

export const apiDelete = (path, data) => {
  return axiosInstance.delete(path, { data });
};
