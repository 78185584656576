import React from 'react';
import './style.css';
import Container from '../../components/container';
import Logo from '../../components/logo/Logo';
import UserBlockContainer from '../../components/userBlock/UserBlockContainer';
import { Link } from 'react-router-dom';

const HeaderView = props => {
  return (
    <div className="header-background">
      <div className="header-container">
        <Container>
          <div className="header-wrapper">
            <Link to="/groups">
              <Logo />
            </Link>
            <UserBlockContainer />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default HeaderView;
