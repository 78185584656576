/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import './App.css';
import Container from './components/container';
import { Route, Redirect, useHistory, Switch } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import AuthContainer from './pages/auth/AuthContainer';
import AuthActionsContainer from './pages/authActions';
import RegistrationContainer from './pages/registration';
import RestoreContainer from './pages/restore';
import MainContainer from './pages/main/MainContainer';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicyContainer';
import 'react-notifications-component/dist/theme.css';
import { TeachersService } from './services';
import Loader from './components/loader/Loader';

const Routers = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(history.location.pathname);
  const userData = useSelector(state => state.user);
  const appState = useSelector(state => state.appState);

  if (loading) {
    return <Loader />;
  }

  if (
    localStorage.getItem('refreshToken') &&
    localStorage.getItem('token') &&
    userData.teacherId
  ) {
    return (
      <div
        className={`App app-container ${
          appState.isScrollable ? '' : 'center-main-window'
        }`}>
        <Switch>
          <Route path="/privacy" render={() => <PrivacyPolicy />} />
          <Route
            path="/"
            render={() => (
              <MainContainer
                path={!redirect || redirect === '/' ? '/groups' : redirect}
              />
            )}
          />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    );
  } else {
    return (
      <div className="App app-container">
        <Container>
          <Switch>
            <Route path="/privacy" render={() => <PrivacyPolicy />} />
            <Route path="/auth" render={() => <AuthContainer />} />
            <Route
              exact
              path="/registration"
              render={() => <RegistrationContainer />}
            />
            <Route path="/restore" render={() => <RestoreContainer />} />
            <Route
              exact
              path="/authentication"
              render={() => <AuthActionsContainer />}
            />
            <Route>
              <Redirect to="/auth" />
            </Route>
          </Switch>
        </Container>
        <ReactNotification />
      </div>
    );
  }
};

export default Routers;
