import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import { ruDateTimeFormat } from '../../core/functions';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      {/* <NavLink to={`users/${data.id}`} className="box box-1">{data.id}</NavLink> */}
      <div className="box box-2">
        {data.title && data.type ? data.title : 'Тест удален'}
      </div>
      <div className="box box-3">{data.type}</div>
      <div className="box box-4">{`${(
        (data.progress * data.questionCount) /
        100
      ).toFixed(0)}/${data.questionCount}`}</div>
      <div className="box box-5">{(data.performance * 10).toFixed(0)}%</div>
      <div className="box box-6">
        {moment(data.changeTime).format('DD MMMM, HH:mm')}
      </div>
      <div className="box box-7">
        {props.renderControl ? (
          props.renderControl(data)
        ) : (
          <Button
            type={data.active ? 'secondary' : 'primary'}
            small
            textButton={data.active ? 'Удалить' : 'Добавить'}
            onClick={() => {
              data.handleTest(data.has, data.index, !data.active);
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div onScroll={props.paginate} className="table success-tests-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          {/* <div className="box box-1">Название</div> */}
          <div className="box box-2">Название</div>
          <div className="box box-3">Тип</div>
          <div className="box box-4">Пройдено</div>
          <div className="box box-5">Отвечено правильно</div>
          <div className="box box-6">Дата прохождения</div>
          <div className="box box-7">Управление</div>
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((test, index) => (
                <TableLine
                  key={index}
                  successTestId={test.successTestId}
                  index={index}
                  id={test.testId}
                  progress={test.progress}
                  performance={test.performance}
                  questionSuccess={test.questionsSuccess}
                  questionCount={test.questionsCount}
                  title={test.title}
                  type={test.type}
                  changeTime={test.changeTime}
                  date={test.date}
                  handleTest={props.handleTest}
                  testObj={test}
                />
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
    </>
  );
};

export default UsersTableView;
