import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
import Icon from '../icon/Icon';
import { useSelector } from 'react-redux';

const Menu = props => {
  const isAdmin = useSelector(state => state.user).role === 'admin';

  return (
    <div className="menu-wrapper">
      <div className="menu-container">
        <NavLink
          activeClassName="menu-item_active"
          to="/groups"
          className="menu-item">
          <Icon name="groups" />
          <div className="menu-item__text">Группы</div>
        </NavLink>
        <NavLink
          activeClassName="menu-item_active"
          to="/personal"
          className="menu-item">
          <Icon name="personal-classes" />
          <div className="menu-item__text">Индивидуальные</div>
        </NavLink>
        <NavLink
          activeClassName="menu-item_active"
          to="/calendar"
          className="menu-item">
          <Icon name="calendar" />
          <div className="menu-item__text">Календарь</div>
        </NavLink>
        <NavLink
          activeClassName="menu-item_active"
          to="/materials"
          className="menu-item">
          <Icon name="materials" />
          <div className="menu-item__text">Материалы</div>
        </NavLink>
        <NavLink
          activeClassName="menu-item_active"
          to="/games"
          className="menu-item">
          <Icon name="games" />
          <div className="menu-item__text">Игры</div>
        </NavLink>
        <NavLink
          activeClassName="menu-item_active"
          to="/tests"
          className="menu-item">
          <Icon name="tasks" />
          <div className="menu-item__text">Тесты</div>
        </NavLink>
        <NavLink
          activeClassName="menu-item_active"
          to="/general-tests"
          className="menu-item">
          <Icon name="general-tests" />
          <div className="menu-item__text">Placement tests</div>
        </NavLink>
        {isAdmin && (
          <NavLink
            activeClassName="menu-item_active"
            to="/news"
            className="menu-item">
            <Icon name="news" />
            <div className="menu-item__text">Новости</div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            activeClassName="menu-item_active"
            to="/dashboard"
            className="menu-item">
            <Icon name="dashboard" />
            <div className="menu-item__text">Статистика</div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            activeClassName="menu-item_active"
            to="/about-us"
            className="menu-item">
            <Icon name="info" />
            <div className="menu-item__text">О нас</div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            activeClassName="menu-item_active"
            to="/users"
            className="menu-item">
            <Icon name="users" />
            <div className="menu-item__text">Пользователи</div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            activeClassName="menu-item_active"
            to="/teachers"
            className="menu-item">
            <Icon name="teachers" />
            <div className="menu-item__text">Учителя</div>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default Menu;
