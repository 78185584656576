import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import Icon from '../icon/Icon';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      <NavLink to={`/users/${data.id}`} className="box box-1">
        {data.index + 1}
      </NavLink>
      <NavLink to={`/users/${data.id}`} className="box box-2">
        {data.avatar ? (
          <div
            className="table-line__avatar"
            style={{ backgroundImage: `url(${data.avatar})` }}
          />
        ) : (
          <Icon name="avatar" width={32} height={32} />
        )}
      </NavLink>
      <NavLink to={`/users/${data.id}`} className="box box-3">
        {data.name}
      </NavLink>
      <NavLink to={`/users/${data.id}`} className="box box-4">
        {data.lastName}
      </NavLink>
      <NavLink to={`/users/${data.id}`} className="box box-5">
        {data.patronymic ? data.patronymic : '-'}
      </NavLink>
      {props.type !== 'general' ? (
        <NavLink to={`/users/${data.id}`} className="box box-6">
          {data.performance ? data.performance : '-'}
        </NavLink>
      ) : null}
      {props.type !== 'general' ? (
        <NavLink to={`/users/${data.id}`} className="box box-7">
          {data.successTestsCount ? data.successTestsCount : '-'}
        </NavLink>
      ) : null}
      {props.type === 'general' ? (
        <NavLink to={`/users/${data.id}`} className="box box-6">
          {data.timeInApplication > 360
            ? moment.duration(data.timeInApplication).asHours().toFixed(1) +
              'ч.'
            : '-'}
        </NavLink>
      ) : null}
      {props.type !== 'teacher' ? (
        <div className="box box-8">
          {props.renderControl ? (
            props.renderControl({ ...data.user, index: data.index })
          ) : (
            <Button
              type="secondary"
              small
              textButton="Удалить"
              onClick={() => props.deleteUser(data.id)}
            />
          )}
        </div>
      ) : null}
    </div>
  );

  return (
    <div onScroll={props.paginate} className="table group-students-table">
      <div className="table-header table-line">
        <div className="box box-1">№</div>
        <div className="box box-2" />
        <div className="box box-3">Имя</div>
        <div className="box box-4">Фамилия</div>
        <div className="box box-5">Отчество</div>
        {props.type !== 'general' ? (
          <div className="box box-6">Успеваемость</div>
        ) : null}
        {props.type !== 'general' ? (
          <div className="box box-7">Количество пройденных тестов</div>
        ) : null}
        {props.type === 'general' ? (
          <div className="box box-6">Время в приложении</div>
        ) : null}
        {props.type !== 'teacher' ? (
          <div className="box box-8">Управление</div>
        ) : null}
      </div>
      <div className="table-list">
        {props.data.length
          ? props.data.map((user, index) => (
              <TableLine
                setCurrentAction={props.setCurrentAction}
                key={index}
                user={user}
                index={index}
                id={user.studentId}
                name={user.name}
                lastName={user.lastName}
                patronymic={user.patronymic}
                avatar={user.avatar}
                performance={user?.performance?.toFixed(2)}
                successTestsCount={user.successTestsCount}
                email={user.email}
                timeInApplication={user.timeInApplication}
                setIdRemovingUser={props.setIdRemovingUser}
                setModalOpen={props.setModalOpen}
              />
            ))
          : null}
        {props.last && !props.data?.length ? props.renderEmpty() : null}
        {props.loading ? <Loader className="table-loader" /> : null}
      </div>
      {!['teacher', 'general'].includes(props.type) && props.data?.length ? (
        <Button
          disabled={!props.data.some(el => !el.active)}
          type="primary"
          textButton="Удалить"
          onClick={() => {
            props.shareStudents();
          }}
        />
      ) : null}
    </div>
  );
};

export default UsersTableView;
