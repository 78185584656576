import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import './style.css';
import Button from '../../components/button';
import { List, DatePicker, TimeInput } from '../../components';
import FileInput from '../../components/fileInput/FileInput';
import Textarea from '../../components/textarea/index';
import Input from '../../components/input';
import DialogModal from '../../components/dialogModal/DialogModal';

const NewsView = props => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;

    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.getNews();
    }
  };

  return (
    <div className="news-container">
      <DialogModal
        title="Удаление новости"
        show={props.showModal}
        setShow={props.setShowModal}
        loading={props.deleteLoading}
        onDelete={() => props.deleteNews(props.newNewsInfo.id)}>
        <p className="modal-message">
          Вы действительно хотите удалить новость?
        </p>
      </DialogModal>
      <List
        renderItem={item => {
          return (
            <NavLink to={`/news/${item.newsId}`}>
              <div
                className="item-list-container"
                onClick={() => {
                  props.setNextLinkItem(item);
                }}>
                {item.title}
              </div>
            </NavLink>
          );
        }}
        onScroll={handleDataOnBottom}
        renderHeader={() => {
          return (
            <NavLink to="/news">
              <div className="add-new-item-button-container">
                <Button
                  small
                  textButton="Добавить новость"
                  onClick={() => props.setNextLinkItem(null)}
                />
              </div>
            </NavLink>
          );
        }}
        list={props.newsList}
        loading={props.isLoading}
        emptySign="На данный момент нет новостей"
      />
      <div className="new-news-container">
        <div className="new-news-header">
          <div className="new-news-header-left">
            <div className="new-news-title-container">
              <Input
                type="text"
                className={`new-news-title ${
                  props.outlineEmptyField && !props.newNewsInfo.title.length
                    ? 'new-news-title-invalid'
                    : ''
                }`}
                placeholder="Новая новость"
                maxLength={100}
                showCounter={true}
                onChange={e => props.handleNewsChange('title', e.target.value)}
                value={props.newNewsInfo?.title}
              />
            </div>
            <div className="new-news-description-container">
              <Textarea
                className={`new-news-description ${
                  props.outlineEmptyField && !props.newNewsInfo.description
                    ? 'new-news-description-invalid'
                    : ''
                }`}
                showCounter={true}
                placeholder="Описание"
                onChange={e =>
                  props.handleNewsChange('description', e.target.value)
                }
                value={props.newNewsInfo?.description}
                maxLength={10000}
              />
            </div>
          </div>
          <div className="new-news-header-right">
            {!props.newNewsInfo?.id && (
              <div className="new-news-save-container">
                <Button
                  small
                  textButton="Сохранить"
                  loading={props.saveLoading}
                  disabled={props.saveLoading}
                  onClick={props.saveNewNews}
                />
              </div>
            )}
            {props.newNewsInfo?.id && (
              <div className="new-news-delete-container">
                <Button
                  small
                  type="secondary"
                  textButton="Удалить"
                  disabled={props.saveLoading}
                  onClick={() => props.setShowModal(true)}
                />
              </div>
            )}
            {props.newNewsInfo?.id && (
              <div className="new-news-update-container">
                <Button
                  small
                  textButton="Обновить"
                  disabled={props.saveLoading}
                  onClick={() => props.updateNews(props.newNewsInfo.id)}
                />
              </div>
            )}
          </div>
        </div>

        <div className="new-news-content-container">
          <div className="new-news-datetime-container">
            <div className="new-news-date-container">
              <DatePicker
                className="new-news-date"
                placeholder="Дата начала"
                onChange={e => props.handleNewsTimeChange('date', e)}
                value={moment(props.newNewsInfo?.date, 'D MMMM YYYY, dddd')}
                format="D MMMM YYYY, dddd"
                title="Дата начала"
              />
            </div>
            <div className="new-news-time-container">
              <TimeInput
                title="Время начала"
                className="new-news-time"
                onChange={e => props.handleNewsTimeChange('time', e)}
                value={moment(props.newNewsInfo?.time, 'HH:mm')}
                format="HH:mm"
              />
            </div>
          </div>
        </div>

        <div className="new-news-content-container">
          <div className="new-news-price-container">
            <Input
              type="text"
              placeholder="Цена"
              maxLength={100}
              showCounter={true}
              onChange={e => props.handleNewsChange('price', e.target.value)}
              value={props.newNewsInfo?.price}
            />
          </div>
        </div>

        <div className="new-news-content-container">
          <div className="new-news-type-list-container">
            <div className="new-news-file-container">
              <FileInput
                type="text"
                title="Постер"
                externalRef={props.externalRef}
                extensions={['jpg', 'png', 'jpeg', 'svg']}
                maxSize={10}
                placeholder="Выбрать файл"
                handleFileDelete={props.deletePoster}
                handleFileSelect={(blob, fileName) =>
                  props.selectFile(blob, fileName)
                }
                fileAddr={props.newNewsInfo?.poster}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsView;
