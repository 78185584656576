import React from 'react';

import View from './view';

const Container = ({
  renderEmpty = () => {},
  loading = false,
  data = [],
  last = false,
  deleteMaterial = () => {},
  shareMaterials = () => {},
  handleMaterial = () => {},
  paginate = () => {},
  renderControl,
  type,
}) => {
  return (
    <View
      renderControl={renderControl}
      renderEmpty={renderEmpty}
      loading={loading}
      data={data}
      last={last}
      type={type}
      paginate={paginate}
      shareMaterials={shareMaterials}
      deleteMaterial={deleteMaterial}
      handleMaterial={handleMaterial}
    />
  );
};

export default Container;
