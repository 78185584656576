import React, { useRef } from 'react';
import Icon from '../icon/Icon';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import './style.css';

const FileInput = ({
  placeholder = '',
  disabled = false,
  title = '',
  className = '',
  fileAddr = '',
}) => {
  const uploader = useRef(null);

  return (
    <div>
      {title && <div className="inputTitle">{title}</div>}
      <SelectableContainer
        disabled={disabled}
        className={`input-container ${className}`}>
        <div className={`upload-area ${!disabled ? 'pointer' : ''}`}>
          <a className="file-name input" href={fileAddr}>
            {uploader.current?.files?.[0]?.name ? (
              <span>{uploader.current.files[0].name}</span>
            ) : fileAddr ? (
              <span>Доступно для скачивания</span>
            ) : disabled ? (
              <span className="file-input-placeholder">Нет данных</span>
            ) : (
              <span className="file-input-placeholder">{placeholder}</span>
            )}
          </a>
        </div>
        <input ref={uploader} type="file" className="file-input" />
        {fileAddr ? (
          <a href={fileAddr}>
            <div className="file-control-wrapper">
              <div className="file-control">
                <Icon name="download" color="black" width={24} height={24} />
              </div>
            </div>
          </a>
        ) : null}
      </SelectableContainer>
    </div>
  );
};

export default FileInput;
