const CONCAT_TO_END = 'CONCAT_TO_END';
const CONCAT_TO_START = 'CONCAT_TO_START';
const INCREMENT_PAGE = 'INCREMENT_PAGE';
const DECREMENT_PAGE = 'DECREMENT_PAGE';
const READ_ALL = 'READ_ALL';
const UNSHIFT_NOTIFICATION = 'UNSHIFT_NOTIFICATION';
const CHANGE_NOTIFICATION = 'CHANGE_NOTIFICATION';
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

const initialState = {
  data: [],
  readed: [],
  page: 1,
};

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case DECREMENT_PAGE:
      return {
        state,
        page: state.page - 1,
      };
    case CONCAT_TO_END:
      return {
        ...state,
        data: [...state.data, ...action.notifications],
      };
    case CONCAT_TO_START:
      return {
        ...state,
        data: [...action.notifications, ...state.data],
      };
    case UNSHIFT_NOTIFICATION:
      return {
        ...state,
        data: [action.notification, ...state.data],
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        data: state.data.filter(el => el.pushId !== action.pushId),
      };
    case CHANGE_NOTIFICATION:
      return {
        ...state,
        data: state.data.map(el => {
          if (el.pushId === action.pushId) {
            return action.notification;
          }
          return el;
        }),
      };
    case READ_ALL:
      const temp = state.readed;
      return {
        ...state,
        data: state.data.map(el => {
          if (el.read === false) {
            temp.push(el);
          }
          return { ...el, read: true };
        }),
        readed: temp,
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default groupsReducer;

export const addNotification = _notification => {
  return dispatch => {
    dispatch({
      type: UNSHIFT_NOTIFICATION,
      notification: { ..._notification, read: false },
    });
  };
};

export const incrementNotificationsPage = () => {
  return dispatch => {
    dispatch({
      type: INCREMENT_PAGE,
    });
  };
};

export const concatToStart = _notifications => {
  return dispatch => {
    dispatch({
      type: CONCAT_TO_START,
      notifications: _notifications,
    });
  };
};

export const concatToEnd = _notifications => {
  return dispatch => {
    dispatch({
      type: CONCAT_TO_END,
      notifications: _notifications,
    });
  };
};

export const deleteNotification = pushId => {
  return dispatch => {
    dispatch({ type: DELETE_NOTIFICATION, pushId });
  };
};

export const readAll = () => {
  return dispatch => {
    dispatch({ type: READ_ALL });
  };
};

export const changeNotification = (notificationId, notification) => {
  return dispatch => {
    dispatch({ type: CHANGE_NOTIFICATION, notificationId, notification });
  };
};

export const clearNotifications = () => {
  return dispatch => {
    dispatch({ type: CLEAR_NOTIFICATIONS });
  };
};
