import React from 'react';
import './style.css';
import Input from '../../../input/index';
import Checkbox from '../../../checkbox/Checkbox';
import Icon from '../../../icon/Icon';

const QuestionInput = ({
  title,
  placeholder = '',
  withoutCheckbox = false,
  onChange = () => {},
  onRightChange = () => {},
  value = '',
  checked = false,
  maxlength = null,
  id = null,
  fileName = '',
  onDelete,
  removeFile = () => {},
  type = 'text',
  disabled = false,
  handleDeleteAnswer,
  checkboxSelectionControl,
  inputSelectionControl,
  className = '',
}) => {
  if (type === 'file') {
    return (
      <div
        className={`question-input-container ${
          disabled && 'question-input-container_disabled'
        }`}>
        <label htmlFor={id}>{placeholder}</label>
        <Input
          id={id}
          value={value}
          type={type}
          maxlength={maxlength}
          showCounter={true}
          className="question-input"
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
        {fileName && (
          <div className="question-filename">
            {fileName}
            {/* <TrashIcon onClick={removeFile} /> */}
          </div>
        )}
        {!withoutCheckbox && (
          <Input
            checked={checked}
            type="checkbox"
            className="question-checkbox"
            onChange={e => {
              onRightChange(e);
            }}
            disabled={disabled}
          />
        )}
      </div>
    );
  }

  return (
    <div className="question-input-container">
      {onDelete && (
        <div className="delete-question-answer" onClick={onDelete}>
          <Icon name="minus" width={36} height={36} color={'#ff6f47'} />
        </div>
      )}
      <Input
        title={title}
        value={value}
        type={type}
        customSelectionControl={inputSelectionControl?.toggler}
        selectionColor={inputSelectionControl?.color}
        maxLength={maxlength}
        showCounter={true}
        containerClassName={
          className ? `question-input ${className}` : 'question-input'
        }
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {!withoutCheckbox && (
        <Checkbox
          value={checked}
          customSelectionControl={checkboxSelectionControl?.toggler}
          selectionColor={checkboxSelectionControl?.color}
          type="checkbox"
          width={'100%'}
          height={'100%'}
          containerClassName="question-checkbox"
          onClick={e => {
            onRightChange(!checked);
          }}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default QuestionInput;
