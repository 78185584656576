import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import moment from 'moment';

const UsersTableView = props => {
  return (
    <>
      <div className="table group-flashcards-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Название</div>
          <div className="box box-3">Дата прохождения</div>
          {props.type !== 'flashCards' ? (
            <div className="box box-4">Прогресс</div>
          ) : null}
          {props.type !== 'flashCards' ? (
            <div className="box box-5">% правильных</div>
          ) : null}
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((game, index) => (
                <div
                  className={`table-line ${
                    index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
                  }`}>
                  {/* <NavLink to={`users/${game.categoryId}`} className="box box-1">{game.categoryId}</NavLink> */}
                  <NavLink
                    to={`/games/${props.type}/${game.categoryId}`}
                    className="box box-2">
                    {game.name}
                  </NavLink>
                  <NavLink
                    to={`/games/${props.type}/${game.categoryId}`}
                    className="box box-3">
                    {moment(new Date(game.date?._seconds * 1000)).format(
                      'DD MMMM, HH:mm',
                    )}
                  </NavLink>
                  {props.type !== 'flashCards' ? (
                    <NavLink
                      to={`/games/${props.type}/${game.categoryId}`}
                      className="box box-4">
                      {game.solvedLevelsQuantity}
                      {'/'}
                      {game.levelsQuantity}
                    </NavLink>
                  ) : null}
                  {props.type !== 'flashCards' ? (
                    <NavLink
                      to={`/games/${props.type}/${game.categoryId}`}
                      className="box box-5">
                      {(game.result * 100).toFixed(1)}
                      {'%'}
                    </NavLink>
                  ) : null}
                </div>
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
    </>
  );
};

export default UsersTableView;
