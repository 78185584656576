import React from 'react';
import moment from 'moment';

import './style.css';
import Loader from '../../components/loader/Loader';
import FileDownload from '../../components/fileDownload/FileDownload';
import Select from '../../components/select/index';

const TestResultsView = props => {
  return (
    <div>
      {!props.isDataLoaded && <Loader />}
      {props.isDataLoaded &&
        (!props.isTestDataDefined() ? (
          'Не удалось загрузить данные'
        ) : (
          <>
            <div className="questions-title">{props.test?.title || 'Teст'}</div>
            {props.test?.type && (
              <div className="questions-subtitle">
                <span className="questions-subtitle-indicator">Тип: </span>
                <span className="questions-subtitle-value">
                  {props.test?.type}
                </span>
              </div>
            )}
            {props.attempts.length > 1 && (
              <Select
                direction="bottom"
                value={`${props.successTest.title} ${moment(props.successTest.changeTime).format('DD MMMM, HH:mm')}`}
                data={props.attempts}
                search={true}
                noReset={true}
                placeholder={'Выберите попытку'}
                renderOption={el => {
                  return (
                    <div
                      className="option"
                      onMouseDown={() => {
                        props.setSuccessTestId(el.successTestId);
                      }}>
                      {`${props.successTest.title} ${moment(el.changeTime).format('DD MMMM, HH:mm')}`}
                    </div>
                  );
                }}
                extractSearchKey={el => el.successTestId}
              />
            )}
            <div className="questions-subtitle">
              <span className="questions-subtitle-indicator">Студент: </span>
              <span className="questions-subtitle-value">
                {props.studentName}
              </span>
            </div>
            <div className="questions-subtitle">
              <span className="questions-subtitle-indicator">Пройдено: </span>
              <span className="questions-subtitle-value">
                {props.successTest.questionsSuccess}/
                {props.successTest.questionsCount}
              </span>
            </div>
            <div className="questions-subtitle">
              <span className="questions-subtitle-indicator">
                Отвечено правильно:{' '}
              </span>
              <span className="questions-subtitle-value">
                {+(props.successTest.performance * 10).toFixed(1)}%
              </span>
            </div>
            <div className="questions-subtitle">
              <span className="questions-subtitle-indicator">Оценка: </span>
              <span className="questions-subtitle-value">
                {+props.successTest.performance.toFixed(1)}
              </span>
            </div>
            <div className="questions-subtitle">
              <span className="questions-subtitle-indicator">
                Дата прохождения:{' '}
              </span>
              <span className="questions-subtitle-value">
                {moment(props.successTest.changeTime).format('DD MMMM, HH:mm')}
              </span>
            </div>
            {props.test.file && (
              <div className="questions-subtitle">
                <FileDownload
                  type="text"
                  title="Файл"
                  placeholder="Выбрать файл"
                  fileAddr={props.test.file}
                />
              </div>
            )}
            <div className="questions-container">
              {props.test?.questions.map(el => {
                return (
                  <div className="question-container">
                    <div className="question-name">
                      {el.number}. {el.question}
                    </div>
                    {el.answers.map(answer => (
                      <div
                        key={answer.number}
                        className={props.renderAnswerStyle(
                          el.number,
                          answer.number,
                        )}>
                        {answer.number}. {answer.answer}
                        {props.renderIcon(el.number, answer.number)}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </>
        ))}
    </div>
  );
};

export default TestResultsView;
