import React from 'react';
import './style.css';
import Icon from '../icon/Icon';

const PushContainer = props => {
  return (
    <div className={'push-notification-wrapper empty'}>
      <div className="notification-avatar">
        <Icon name={'bell'} width={'100%'} height={'100%'} strokeWidth={0} />
      </div>
      <div className="notification-content">
        <div className="notification-content-header">
          <p className="text notification-title">Нет новых уведомлений</p>
        </div>
        <p className="text notification-text">
          С момента вашего последнего появления в приложении ничего не произошло
          :(
        </p>
      </div>
    </div>
  );
};

export default PushContainer;
