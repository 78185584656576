import React from 'react';

import { NavLink } from 'react-router-dom';

import './style.css';
import GroupsTableContainer from '../../components/groupsTable';
import Loader from '../../components/loader/Loader';
import {
  getTeachersFormattedName,
  prettyLangLevel,
} from '../../core/functions';
import { Modal } from '../../components';
import Button from '../../components/button';
import Select from '../../components/select/index';
import Input from '../../components/input';
import NoDataError from '../../components/noDataError/NoDataError';
import DialogModal from '../../components/dialogModal/DialogModal';
import { languageLevels } from '../../core/config';

const GroupsView = props => {
  if (!props.groups) {
    return <Loader />;
  }

  const getModalTitle = type => {
    switch (type) {
      case 'delete':
        return 'Удаление группы';
      case 'new':
        return 'Создание группы';
      default:
        return 'Ошибка';
    }
  };

  const renderModalContent = type => {
    switch (type) {
      case 'delete':
        if (!props.deleteTargetGroup) {
          return null;
        }
        return (
          <DialogModal
            title={getModalTitle(props.modalType)}
            show={props.showModal}
            setShow={props.setShowModal}
            loading={props.deleteLoading}
            onDelete={props.deleteGroup}>
            <span className="add-lesson-modal-item-last">
              Вы действительно хотите удалить группу{' '}
              <strong>
                {props.deleteTargetGroup.name}
                {' • ' + props.deleteTargetGroup.level}
                {', '}
                {'Учитель: '}
                {getTeachersFormattedName(
                  props.deleteTargetGroup.teacherId,
                  props.teachers,
                )}
              </strong>
              ?
            </span>
          </DialogModal>
        );
      case 'new':
        return (
          <Modal
            title={getModalTitle(props.modalType)}
            show={props.showModal}
            setShow={props.setShowModal}>
            <div>
              <Select
                label="Учитель"
                containerClass="add-lesson-modal-item"
                direction="bottom"
                value={
                  props.targetTeacher
                    ? getTeachersFormattedName(
                        props.targetTeacher,
                        props.teachers,
                      )
                    : ''
                }
                width={'275px'}
                data={props.teachers}
                search={true}
                onReset={() => props.setTargetTeacher(null)}
                placeholder={'Выберите учителя'}
                renderOption={el => {
                  return (
                    <div
                      className="option"
                      onMouseDown={() => {
                        props.setTargetTeacher(el.teacherId);
                      }}>
                      {getTeachersFormattedName(el.teacherId, props.teachers)}
                    </div>
                  );
                }}
                extractSearchKey={el => {
                  return getTeachersFormattedName(el.teacherId, props.teachers);
                }}
              />
              <Select
                label="Уровень языка"
                containerClass="add-lesson-modal-item"
                direction="bottom"
                value={props.groupLevel ? props.groupLevel : ''}
                width={'275px'}
                data={languageLevels}
                onReset={() => props.setGroupLevel(null)}
                placeholder="Выберите языковой уровень"
                renderOption={el => {
                  return (
                    <div
                      className="option"
                      onMouseDown={() => {
                        props.setGroupLevel(el);
                      }}>
                      {el}
                    </div>
                  );
                }}
              />
              <Input
                maxLength={100}
                type="text"
                title="Название"
                value={props.groupName}
                placeholder="Введите название группы"
                onChange={e => props.setGroupName(e.target.value)}
              />
              <Button
                disabled={
                  !(
                    props.groupLevel &&
                    props.groupName &&
                    props.targetTeacher
                  ) || props.deleteLoading
                }
                type="primary"
                small
                loading={props.deleteLoading}
                textButton="Создать"
                className="add-lesson-modal-item"
                onClick={() => {
                  props.createGroup();
                }}
              />
              <Button
                type="text"
                small
                textButton="Назад"
                onClick={() => props.setShowModal(false)}
              />
            </div>
          </Modal>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="groups-header">
        {props.isAdmin && (
          <Button
            className="button-add-group"
            textButton="Новая группа"
            small
            onClick={props.openNewGroupModal}
          />
        )}
      </div>

      {renderModalContent(props.modalType)}
      <GroupsTableContainer
        renderEmpty={() => {
          return <NoDataError text="Нет групп доступных для просмотра" />;
        }}
        type={props.isAdmin ? 'admin' : 'teacher'}
        last={true}
        loading={props.isLoading}
        data={props.groups}
        deleteGroup={props.confirmDeleteGroup}
      />
    </div>
  );
};

export default GroupsView;
