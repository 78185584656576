const SET_TEACHERS = 'SET_TEACHERS';

const initialState = null;

const teachersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEACHERS:
      return action.teachers;
    default:
      return state;
  }
};

export const setTeachers = teachers => {
  return dispatch => {
    dispatch({ type: SET_TEACHERS, teachers });
  };
};

export default teachersReducer;
