import React, { useState, useEffect } from 'react';
import './style.css';
//  tabs example = [
//   {
//      id: '123',
//      name: 'Главная'
//   }
// ]
const Tabs = ({ tabId, tabs = null, handleChangeGroup = () => {} }) => {
  if (!tabs.find(el => el.id === tabId)) {
    handleChangeGroup(1);
  }
  return (
    <div className="tabs-container">
      <div className="tabs-container_scroll">
        {tabs &&
          tabs.length > 0 &&
          tabs.map(tab => (
            <div
              onClick={() => {
                if (!tab.disabled) {
                  if (tabId !== tab.id) {
                    handleChangeGroup(tab.id);
                  }
                }
              }}
              key={tab?.id}
              className={`tabs-item ${
                tabId === tab.id ? 'tabs-item_active' : ''
              } ${tab.disabled ? 'tabs-item_disabled' : ''}`}>
              {tab.name}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Tabs;
