import React from 'react';
import { NavLink } from 'react-router-dom';

import Dropdown from 'react-dropdown';
import './style.css';
import Input from '../../components/input';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';
import LinkTabs from '../../components/linkTabs/linkTabs';
import TestType from '../../components/create-test/test-type';
import QuestionInput from '../../components/create-test/add-question/question-input';
import { List, Modal } from '../../components';
import DialogModal from '../../components/dialogModal/DialogModal';
import Select from '../../components/select';
import FileInput from '../../components/fileInput/FileInput';
import Textarea from '../../components/textarea/index';
import {
  getGroupsFormattedName,
  getStudentsFormattedName,
  getTeachersFormattedName,
} from '../../core/functions';
import DateTimePicker from '../../components/dateTimePicker/DateTimePicker';

const tabs = [
  { name: 'Flash cards', id: 1, link: '/games/flashCards' },
  { name: 'Match up', id: 2, link: '/games/matchup' },
  { name: 'Anagram', id: 3, link: '/games/anagrams' },
  { name: 'Categorize', id: 4, link: '/games/categorize' },
];

const renderCards = props =>
  props.flashWords.map((item, index) => (
    <div className="word-container">
      <div className="add-word-first-container">
        <div className="add-question-id">{index + 1}</div>
        <Button
          small
          type="secondary"
          textButton="-"
          onClick={() => {
            props.handleRemoveWord(index, 'flash');
          }}
          disabled={props.newCardInfo.categoryId && props.checkDisabling()}
        />
      </div>

      <div className="add-word-second-container">
        <QuestionInput
          withoutCheckbox
          value={props.flashWords[index].word}
          placeholder="Введите слово (родной язык)"
          onChange={e => {
            props.onWordChange(e, index, 'flash');
          }}
          disabled={props.newCardInfo.categoryId && props.checkDisabling()}
          inputSelectionControl={
            props.outlineEmptyField &&
            !props.flashWords[index].word &&
            props.getNewSelection('true', '#ff6f47')
          }
          maxlength="100"
        />
        <QuestionInput
          withoutCheckbox
          value={props.flashWords[index].translate}
          placeholder="Введите перевод (иностранный язык)"
          onChange={e => {
            props.onTranslateChange(e, index, 'flash');
          }}
          disabled={props.newCardInfo.categoryId && props.checkDisabling()}
          inputSelectionControl={
            props.outlineEmptyField &&
            !props.flashWords[index].translate &&
            props.getNewSelection('true', '#ff6f47')
          }
          maxlength="100"
        />
      </div>
    </div>
  ));

const renderCategorize = (element, categoryIndex, props) =>
  element.words.map((item, index) => (
    <div className="word-container">
      <div className="add-word-first-container">
        <div className="add-question-id">{index + 1}</div>
        <Button
          small
          type="secondary"
          textButton="-"
          onClick={() => {
            props.handleRemoveWord(index, 'categorize', categoryIndex);
          }}
          disabled={
            props.newCategorizeInfo.categoryId && props.checkDisabling()
          }
        />
      </div>
      <div className="add-word-second-container-categorize">
        <QuestionInput
          withoutCheckbox
          value={item.name}
          placeholder="Введите слово"
          onChange={e => {
            props.onWordChange(e, index, 'categorize', categoryIndex);
          }}
          maxlength="15"
          disabled={
            props.newCategorizeInfo.categoryId && props.checkDisabling()
          }
          inputSelectionControl={
            props.outlineEmptyField &&
            !item.name &&
            props.getNewSelection('true', '#ff6f47')
          }
        />
      </div>
    </div>
  ));

const renderMatchups = (round, roundIndex, props) =>
  round.map((pair, index) => (
    <div className="word-container">
      <div className="add-word-first-container">
        <div className="add-question-id">{index + 1}</div>
        <Button
          small
          type="secondary"
          textButton="-"
          onClick={() => {
            props.handleRemoveWord(index, 'matchup', roundIndex);
          }}
          disabled={props.newMatchupInfo.categoryId && props.checkDisabling()}
        />
      </div>

      <div className="add-word-second-container">
        <QuestionInput
          withoutCheckbox
          value={pair.word}
          placeholder="Введите слово/фразу"
          maxlength="40"
          onChange={e => {
            props.onWordChange(e, index, 'matchup', roundIndex);
          }}
          disabled={props.newMatchupInfo.categoryId && props.checkDisabling()}
          inputSelectionControl={
            props.outlineEmptyField &&
            !pair.word &&
            props.getNewSelection('true', '#ff6f47')
          }
        />
        <QuestionInput
          withoutCheckbox
          maxlength="77"
          value={pair.translate}
          placeholder="Введите пару"
          onChange={e => {
            props.onTranslateChange(e, index, 'matchup', roundIndex);
          }}
          disabled={props.newMatchupInfo.categoryId && props.checkDisabling()}
          inputSelectionControl={
            props.outlineEmptyField &&
            !pair.translate &&
            props.getNewSelection('true', '#ff6f47')
          }
        />
      </div>
    </div>
  ));

const renderAnagrams = props =>
  props.anagramWords.map((item, index) => (
    <div className="word-container">
      <div className="add-word-first-container">
        <div className="add-question-id">{index + 1}</div>
        <Button
          small
          type="secondary"
          textButton="-"
          onClick={() => {
            props.handleRemoveWord(index, 'anagram');
          }}
          disabled={props.newAnagramInfo.categoryId && props.checkDisabling()}
        />
      </div>
      <div className="add-word-second-container">
        {/* <QuestionInput
          withoutCheckbox
          value={props.anagramWords[index].word}
          placeholder="Введите вопрос"
          onChange={e => {
            props.onWordChange(e, index, 'anagram');
          }}
        /> */}
        <QuestionInput
          withoutCheckbox
          value={props.anagramWords[index].hint}
          placeholder="Введите подсказку"
          maxlength="75"
          onChange={e => {
            props.onHintChange(e, index, 'anagram');
          }}
          disabled={props.newAnagramInfo.categoryId && props.checkDisabling()}
          inputSelectionControl={
            props.outlineEmptyField &&
            !props.anagramWords[index].hint &&
            props.getNewSelection('true', '#ff6f47')
          }
        />
        <QuestionInput
          withoutCheckbox
          value={props.anagramWords[index].translate}
          placeholder="Введите ответ (минимум 2 символа)"
          showCounter={true}
          onChange={e => {
            props.onTranslateChange(e, index, 'anagram');
          }}
          disabled={props.newAnagramInfo.categoryId && props.checkDisabling()}
          inputSelectionControl={
            props.outlineEmptyField &&
            props.anagramWords[index].translate?.length < 2 &&
            props.getNewSelection('true', '#ff6f47')
          }
          maxlength={49}
        />
        <FileInput
          type="test"
          title="Добавить картинку"
          extensions={['jpg', 'png', 'jpeg', 'svg']}
          maxSize={10}
          placeholder="Выбрать файл"
          handleFileDelete={() => props.deleteHint(index)}
          handleFileSelect={(blob, fileName) =>
            props.selectFile(blob, fileName, index)
          }
          fileAddr={props.anagramWords[index].image}
          disabled={props.newAnagramInfo.categoryId && props.checkDisabling()}
          key={props.newAnagramInfo.categoryId}
        />
      </div>
    </div>
  ));

const renderModal = props => (
  <Modal
    show={props.showModal}
    setShow={props.setShowModal}
    title="Привяжите игру">
    {props.isAdmin && (
      <Select
        label="Учитель"
        direction="bottom"
        containerClass="add-lesson-modal-item"
        value={
          props.selectedTeacher
            ? getTeachersFormattedName(props.selectedTeacher, props.teachers)
            : ''
        }
        data={props.teachers}
        search={true}
        width={'100%'}
        renderEmpty={() => (
          <div className="option option-empty">Нет доступных учителей</div>
        )}
        onReset={() => props.setSelectedTeacher()}
        placeholder={'Выберите учителя'}
        renderOption={el => {
          return (
            <div
              className="option"
              onMouseDown={() => {
                props.setSelectedTeacher(el.teacherId);
                props.setSelectedOrganizer();
              }}>
              {getTeachersFormattedName(el.teacherId, props.teachers)}
            </div>
          );
        }}
        extractSearchKey={el =>
          getTeachersFormattedName(el.teacherId, props.teachers)
        }
      />
    )}
    <Select
      label="Группы/занятия"
      direction="bottom"
      containerClass="add-lesson-modal-item"
      value={
        props.selectedOrganizer
          ? `${
              props.selectedOrganizer.type === 'group'
                ? getGroupsFormattedName(
                    props.selectedOrganizer.id,
                    props.organizers,
                  )
                : getStudentsFormattedName(
                    props.selectedOrganizer.studentId,
                    props.students,
                  )
            } (Учитель: ${getTeachersFormattedName(
              props.selectedOrganizer.teacherId,
              props.teachers,
            )})`
          : ''
      }
      data={
        props.selectedTeacher
          ? props.organizers.filter(
              el => el.teacherId === props.selectedTeacher,
            )
          : props.organizers
      }
      search={true}
      width={'100%'}
      renderEmpty={() => (
        <div className="option option-empty">
          Нет доступных групп и персональных занятий
        </div>
      )}
      onReset={() => props.setSelectedOrganizer()}
      placeholder="Выберите группу или персональное занятие"
      renderOption={el => {
        return (
          <div
            className="option"
            onMouseDown={() => {
              props.setSelectedOrganizer({
                id: el.groupId || el.classId,
                type: el.groupId ? 'group' : 'class',
                studentId: el.studentId,
                teacherId: el.teacherId,
              });
              props.setSelectedTeacher(el.teacherId);
            }}>
            {`${
              el.groupId
                ? getGroupsFormattedName(el.groupId, props.organizers)
                : getStudentsFormattedName(el.studentId, props.students)
            } (Учитель: ${getTeachersFormattedName(
              el.teacherId,
              props.teachers,
            )})`}
          </div>
        );
      }}
      extractSearchKey={el =>
        el.groupId
          ? getGroupsFormattedName(el.groupId, props.organizers)
          : getStudentsFormattedName(el.studentId, props.students)
      }
    />
    <DateTimePicker
      className="add-lesson-modal-item-last"
      title="Дата открытия игры"
      placeholder="Выберите дату открытия"
      minDate={new Date()}
      format={'DD MMMM, dd HH:mm'}
      variant="inline"
      wrongTime={props.wrongTime}
      setWrongTime={props.setWrongTime}
      inputProps={{ className: 'date-input' }}
      value={props.selectedDate}
      onChange={date => {
        props.setSelectedDate(date);
      }}
    />
    <Button
      small
      textButton="Привязать игру"
      onClick={props.attachGame}
      disabled={!props.selectedOrganizer || props.wrongTime}
      loading={props.attachingGameLoading}
    />
  </Modal>
);

const renderGame = (id, props) => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;
    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.handleContainerOnBottom();
    }
  };
  switch (id) {
    case 1:
    default:
      return (
        <div className="cards-container">
          {renderModal(props)}
          <List
            renderItem={item => {
              return (
                <NavLink to={`/games/flashCards/${item.categoryId}`}>
                  <div
                    className="item-list-container"
                    onClick={() => {
                      props.setNextLinkItem(item);
                    }}>
                    <div>{item.name}</div>
                    <div className="item-creator">
                      {getTeachersFormattedName(item.teacherId, props.teachers)}
                    </div>
                  </div>
                </NavLink>
              );
            }}
            renderHeader={() => {
              return (
                <NavLink to="/games/flashCards">
                  <div className="add-new-item-button-container">
                    <Button
                      small
                      textButton="Добавить набор"
                      onClick={() => props.setNextLinkItem(null)}
                    />
                  </div>
                </NavLink>
              );
            }}
            onScroll={handleDataOnBottom}
            list={props.cardsList}
            loading={props.flashcardsLoading}
            emptySign="На данный момент нет Flash cards"
          />
          <div className="new-card-container">
            <div className="new-test-header-tab">
              <LinkTabs
                tabId={props.tabId}
                tabs={tabs}
                handleChangeGroup={props.handleTab}
              />
            </div>
            <div className="new-test-header">
              <div className="new-test-header-left">
                <div className="new-test-title-container">
                  <Input
                    type="text"
                    className={`new-game-title ${
                      props.outlineEmptyField && !props.newCardInfo.title
                        ? 'new-game-title-invalid'
                        : ''
                    }`}
                    placeholder="Тема игры"
                    maxLength={100}
                    showCounter={true}
                    onChange={e =>
                      props.handleChangeCard('flash', 'title', e.target.value)
                    }
                    value={props.newCardInfo.title}
                    disabled={
                      props.newCardInfo.categoryId && props.checkDisabling()
                    }
                  />
                </div>
                <Select
                  direction="bottom"
                  containerClass="new-test-type-container"
                  value={
                    props.SHOW_TRANSLATION_FIRST.find(
                      el => el.value === props.newCardInfo.showTranslateFirst,
                    ).label
                  }
                  data={props.SHOW_TRANSLATION_FIRST}
                  search={false}
                  onReset={() => props.handleShowTranslateFirst('')}
                  placeholder="Первым отображать"
                  disabled={
                    props.newCardInfo.categoryId && props.checkDisabling()
                  }
                  renderOption={el => {
                    return (
                      <div
                        className="option"
                        onMouseDown={() => {
                          props.handleShowTranslateFirst(el.value);
                        }}>
                        {el.label}
                      </div>
                    );
                  }}
                />
              </div>
              <div className="new-test-header-right">
                {!props.newCardInfo.categoryId && (
                  <div className="new-test-save-container">
                    <Button
                      small
                      textButton="Сохранить"
                      onClick={props.saveNewCards}
                      disabled={props.saveLoading}
                    />
                  </div>
                )}
                {props.newCardInfo.categoryId &&
                  (props.isAdmin ||
                    props.newCardInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-cancel-container">
                      <Button
                        type="secondary"
                        small
                        textButton="Удалить набор"
                        disabled={props.deleteLoading}
                        onClick={() => props.setShowDeleteModal(true)}
                      />
                    </div>
                  )}
                {props.newCardInfo.categoryId &&
                  (props.isAdmin ||
                    props.newCardInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-save-container">
                      <Button
                        small
                        textButton="Обновить"
                        onClick={() =>
                          props.updateFlashCards(props.newCardInfo.categoryId)
                        }
                        disabled={props.saveLoading}
                      />
                    </div>
                  )}
                {props.newCardInfo.categoryId && (
                  <div className="new-test-attach-container">
                    <Button
                      small
                      textButton="Привязать игру"
                      loading={props.attachingGameLoading}
                      onClick={props.showAttachingGameModal}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* <div className="new-test-description-container">
            <textarea
              class="new-test-description"
              placeholder="Описание"
              onChange={(e) => props.handleChangeCard('flash', 'description', e.target.value)}
              value={props.newCardInfo.description} />
          </div> */}
            <div className="new-test-questions-container">
              <div className="new-test-questions-header">
                <div className="new-test-questions-header-left">
                  <div className="new-test-questions-title">Карточки слов</div>
                </div>
                <div
                  onClick={() => props.handleAddWord('flash')}
                  className={props.renderAddQuestionButtonStyle()}>
                  <img
                    className={props.renderAddQuestionIconStyle()}
                    src={require('../../assets/pictures/plus.svg')}
                    alt="Новая карточка"
                  />
                </div>
              </div>
              {renderCards(props)}
            </div>
          </div>
        </div>
      );
    case 2:
      return (
        <div className="cards-container">
          {renderModal(props)}
          <List
            renderItem={item => {
              return (
                <NavLink to={`/games/matchup/${item.categoryId}`}>
                  <div
                    className="item-list-container"
                    onClick={() => {
                      props.setNextLinkItem(item);
                    }}>
                    <div>{item.name}</div>
                    <div className="item-creator">
                      {getTeachersFormattedName(item.teacherId, props.teachers)}
                    </div>
                  </div>
                </NavLink>
              );
            }}
            renderHeader={() => {
              return (
                <NavLink to="/games/matchup">
                  <div className="add-new-item-button-container">
                    <Button
                      small
                      textButton="Добавить набор"
                      onClick={() => props.setNextLinkItem(null)}
                    />
                  </div>
                </NavLink>
              );
            }}
            onScroll={handleDataOnBottom}
            list={props.matchupList}
            loading={props.matchupLoading}
            emptySign="На данный момент нет Match up"
          />
          <div className="new-card-container">
            <div className="new-test-header-tab">
              <LinkTabs
                tabId={props.tabId}
                tabs={tabs}
                handleChangeGroup={props.handleTab}
              />
            </div>
            <div className="new-test-header">
              <div className="new-test-header-left">
                <div className="new-test-title-container">
                  <Input
                    type="text"
                    className={`new-game-title ${
                      props.outlineEmptyField && !props.newMatchupInfo.title
                        ? 'new-game-title-invalid'
                        : ''
                    }`}
                    placeholder="Тема игры"
                    maxLength={100}
                    showCounter={true}
                    onChange={e =>
                      props.handleChangeCard('matchup', 'title', e.target.value)
                    }
                    value={props.newMatchupInfo.title}
                    disabled={
                      props.newMatchupInfo.categoryId && props.checkDisabling()
                    }
                  />
                </div>
                <div className="new-test-description-container">
                  <Textarea
                    className="new-game-description"
                    placeholder="Описание"
                    showCounter={true}
                    maxLength={100}
                    onChange={e =>
                      props.handleChangeCard(
                        'matchup',
                        'description',
                        e.target.value,
                      )
                    }
                    value={props.newMatchupInfo.description}
                    disabled={
                      props.newMatchupInfo.categoryId && props.checkDisabling()
                    }
                  />
                </div>
              </div>
              <div className="new-test-header-right">
                {!props.newMatchupInfo.categoryId && (
                  <div className="new-test-save-container">
                    <Button
                      small
                      textButton="Сохранить"
                      onClick={props.saveNewMatchups}
                      disabled={props.saveLoading}
                    />
                  </div>
                )}
                {props.newMatchupInfo.categoryId &&
                  (props.isAdmin ||
                    props.newMatchupInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-cancel-container">
                      <Button
                        type="secondary"
                        small
                        textButton="Удалить набор"
                        disabled={props.deleteLoading}
                        onClick={() => props.setShowDeleteModal(true)}
                      />
                    </div>
                  )}
                {props.newMatchupInfo.categoryId &&
                  (props.isAdmin ||
                    props.newMatchupInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-save-container">
                      <Button
                        small
                        textButton="Обновить"
                        onClick={() =>
                          props.updateMatchups(props.newMatchupInfo.categoryId)
                        }
                        disabled={props.saveLoading}
                      />
                    </div>
                  )}
                {props.newMatchupInfo.categoryId && (
                  <div className="new-test-attach-container">
                    <Button
                      small
                      textButton="Привязать игру"
                      loading={props.attachingGameLoading}
                      onClick={props.showAttachingGameModal}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="new-matchup-rounds-counter-container">
              <div className="new-cards-groups-title">Количество раундов</div>
              <div className="new-matchup-round-counter">
                <div className="add-word-first-container">
                  <Button
                    small
                    type="secondary"
                    textButton="-"
                    onClick={() => {
                      (!props.newMatchupInfo.categoryId ||
                        props.isAdmin ||
                        props.newMatchupInfo.teacherId ===
                          props.userData.teacherId) &&
                        props.handleMatchupRounds(false);
                    }}
                    disabled={
                      props.newMatchupInfo.categoryId && props.checkDisabling()
                    }
                  />
                </div>
                <h3>{props.matchupRounds.length}</h3>
                <div
                  onClick={() => {
                    (!props.newMatchupInfo.categoryId ||
                      props.isAdmin ||
                      props.newMatchupInfo.teacherId ===
                        props.userData.teacherId) &&
                      props.handleMatchupRounds(true);
                  }}
                  className={props.renderAddQuestionButtonStyle()}>
                  <img
                    className={props.renderAddQuestionIconStyle()}
                    src={require('../../assets/pictures/plus.svg')}
                    alt="Новая карточка"
                  />
                </div>
              </div>
            </div>
            {props.matchupRounds.map((item, index) => (
              <div className="new-test-questions-container">
                <div className="new-test-questions-header">
                  <div className="new-test-questions-header-left">
                    <div className="new-test-questions-title">
                      Раунд {index + 1}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      if (item.length < 5) {
                        props.handleAddWord('matchup', index);
                      }
                    }}
                    className={props.renderAddQuestionButtonStyle(item, 5)}>
                    <img
                      className={props.renderAddQuestionIconStyle()}
                      src={require('../../assets/pictures/plus.svg')}
                      alt="Новая карточка"
                    />
                  </div>
                </div>
                {renderMatchups(item, index, props)}
              </div>
            ))}
          </div>
        </div>
      );
    case 3:
      return (
        <div className="cards-container">
          {renderModal(props)}
          <List
            renderItem={item => {
              return (
                <NavLink to={`/games/anagrams/${item.categoryId}`}>
                  <div
                    className="item-list-container"
                    onClick={() => {
                      props.setNextLinkItem(item);
                    }}>
                    <div>{item.name}</div>
                    <div className="item-creator">
                      {getTeachersFormattedName(item.teacherId, props.teachers)}
                    </div>
                  </div>
                </NavLink>
              );
            }}
            renderHeader={() => {
              return (
                <NavLink to="/games/anagrams">
                  <div className="add-new-item-button-container">
                    <Button
                      small
                      textButton="Добавить набор"
                      onClick={() => props.setNextLinkItem(null)}
                    />
                  </div>
                </NavLink>
              );
            }}
            onScroll={handleDataOnBottom}
            list={props.anagramsList}
            loading={props.anagramsLoading}
            emptySign="На данный момент нет Anagrams"
          />
          <div className="new-card-container">
            <div className="new-test-header-tab">
              <LinkTabs
                tabId={props.tabId}
                tabs={tabs}
                handleChangeGroup={props.handleTab}
              />
            </div>
            <div className="new-test-header">
              <div className="new-test-header-left">
                <div className="new-test-title-container">
                  <Input
                    type="text"
                    className={`new-game-title ${
                      props.outlineEmptyField && !props.newAnagramInfo.title
                        ? 'new-game-title-invalid'
                        : ''
                    }`}
                    placeholder="Тема игры"
                    maxLength={100}
                    showCounter={true}
                    onChange={e =>
                      props.handleChangeCard('anagram', 'title', e.target.value)
                    }
                    value={props.newAnagramInfo.title}
                    disabled={
                      props.newAnagramInfo.categoryId && props.checkDisabling()
                    }
                  />
                </div>
                <div className="new-test-description-container">
                  <Textarea
                    className="new-game-description"
                    placeholder="Описание"
                    showCounter={true}
                    maxLength={100}
                    onChange={e =>
                      props.handleChangeCard(
                        'anagram',
                        'description',
                        e.target.value,
                      )
                    }
                    value={props.newAnagramInfo.description}
                    disabled={
                      props.newAnagramInfo.categoryId && props.checkDisabling()
                    }
                  />
                </div>
              </div>
              <div className="new-test-header-right">
                {!props.newAnagramInfo.categoryId && (
                  <div className="new-test-save-container">
                    <Button
                      small
                      textButton="Сохранить"
                      onClick={props.saveNewAnagram}
                      disabled={props.saveLoading}
                    />
                  </div>
                )}
                {props.newAnagramInfo.categoryId &&
                  (props.isAdmin ||
                    props.newAnagramInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-cancel-container">
                      <Button
                        type="secondary"
                        small
                        textButton="Удалить набор"
                        disabled={props.deleteLoading}
                        onClick={() => props.setShowDeleteModal(true)}
                      />
                    </div>
                  )}
                {props.newAnagramInfo.categoryId &&
                  (props.isAdmin ||
                    props.newAnagramInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-save-container">
                      <Button
                        small
                        textButton="Обновить"
                        onClick={() =>
                          props.updateAnagrams(props.newAnagramInfo.categoryId)
                        }
                        disabled={props.saveLoading}
                      />
                    </div>
                  )}
                {props.newAnagramInfo.categoryId && (
                  <div className="new-test-attach-container">
                    <Button
                      small
                      textButton="Привязать игру"
                      loading={props.attachingGameLoading}
                      onClick={props.showAttachingGameModal}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="new-test-questions-container">
              <div className="new-test-questions-header">
                <div className="new-test-questions-header-left">
                  <div className="new-test-questions-title">Anagrams</div>
                </div>
                <div
                  onClick={() => props.handleAddWord('anagram')}
                  className={props.renderAddQuestionButtonStyle()}>
                  <img
                    className={props.renderAddQuestionIconStyle()}
                    src={require('../../assets/pictures/plus.svg')}
                    alt="Новая карточка"
                  />
                </div>
              </div>
              {renderAnagrams(props)}
            </div>
          </div>
        </div>
      );
    case 4:
      return (
        <div className="cards-container">
          {renderModal(props)}
          <List
            renderItem={item => {
              return (
                <NavLink to={`/games/categorize/${item.categoryId}`}>
                  <div
                    className="item-list-container"
                    onClick={() => {
                      props.setNextLinkItem(item);
                    }}>
                    <div>{item.name}</div>
                    <div className="item-creator">
                      {getTeachersFormattedName(item.teacherId, props.teachers)}
                    </div>
                  </div>
                </NavLink>
              );
            }}
            renderHeader={() => {
              return (
                <NavLink to="/games/categorize">
                  <div className="add-new-item-button-container">
                    <Button
                      small
                      textButton="Добавить набор"
                      onClick={() => props.setNextLinkItem(null)}
                    />
                  </div>
                </NavLink>
              );
            }}
            onScroll={handleDataOnBottom}
            list={props.categorizeList}
            loading={props.categorizeLoading}
            emptySign="На данный момент нет Categorize"
          />
          <div className="new-card-container">
            <div className="new-test-header-tab">
              <LinkTabs
                tabId={props.tabId}
                tabs={tabs}
                handleChangeGroup={props.handleTab}
              />
            </div>
            <div className="new-test-header">
              <div className="new-test-header-left">
                <div className="new-test-title-container">
                  <Input
                    type="text"
                    className={`new-game-title ${
                      props.outlineEmptyField && !props.newCategorizeInfo.title
                        ? 'new-game-title-invalid'
                        : ''
                    }`}
                    placeholder="Тема игры"
                    maxLength={100}
                    showCounter={true}
                    onChange={e =>
                      props.handleChangeCard(
                        'categorize',
                        'title',
                        e.target.value,
                      )
                    }
                    value={props.newCategorizeInfo.title}
                    disabled={
                      props.newCategorizeInfo.categoryId &&
                      props.checkDisabling()
                    }
                  />
                </div>
                <div className="new-test-description-container">
                  <Textarea
                    className="new-game-description"
                    placeholder="Описание"
                    showCounter={true}
                    maxLength={100}
                    onChange={e =>
                      props.handleChangeCard(
                        'categorize',
                        'description',
                        e.target.value,
                      )
                    }
                    value={props.newCategorizeInfo.description}
                    disabled={
                      props.newCategorizeInfo.categoryId &&
                      props.checkDisabling()
                    }
                  />
                </div>
              </div>
              <div className="new-test-header-right">
                {!props.newCategorizeInfo.categoryId && (
                  <div className="new-test-save-container">
                    <Button
                      small
                      textButton="Сохранить"
                      onClick={props.saveNewCategorize}
                      disabled={props.saveLoading}
                    />
                  </div>
                )}
                {props.newCategorizeInfo.categoryId &&
                  (props.isAdmin ||
                    props.newCategorizeInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-cancel-container">
                      <Button
                        type="secondary"
                        small
                        textButton="Удалить набор"
                        disabled={props.deleteLoading}
                        onClick={() => props.setShowDeleteModal(true)}
                      />
                    </div>
                  )}
                {props.newCategorizeInfo.categoryId &&
                  (props.isAdmin ||
                    props.newCategorizeInfo.teacherId ===
                      props.userData.teacherId) && (
                    <div className="new-test-save-container">
                      <Button
                        small
                        textButton="Обновить"
                        onClick={() =>
                          props.updateCategorizes(
                            props.newCategorizeInfo.categoryId,
                          )
                        }
                        disabled={props.saveLoading}
                      />
                    </div>
                  )}
                {props.newCategorizeInfo.categoryId && (
                  <div className="new-test-attach-container">
                    <Button
                      small
                      textButton="Привязать игру"
                      loading={props.attachingGameLoading}
                      onClick={props.showAttachingGameModal}
                    />
                  </div>
                )}
              </div>
            </div>
            {props.categorizeCategories.map((item, index) => (
              <div className="new-test-categorize-name-container">
                <Input
                  type="text"
                  className={`new-game-title ${
                    props.outlineEmptyField &&
                    !props.categorizeCategories[index].name
                      ? 'new-game-title-invalid'
                      : ''
                  }`}
                  placeholder={`Категория ${item.number}`}
                  maxLength={27}
                  onChange={e =>
                    props.handleChangeCategoryName(e.target.value, index)
                  }
                  value={item.name}
                  disabled={
                    props.newCategorizeInfo.categoryId && props.checkDisabling()
                  }
                  showCounter={true}
                />
              </div>
            ))}
            {props.categorizeCategories.length === 2 ? (
              <div className="new-test-categorize-add-container">
                <Button
                  small
                  textButton="Добавить категорию"
                  onClick={() => props.handleCategorizeCategories(true)}
                  disabled={
                    props.newCategorizeInfo.categoryId && props.checkDisabling()
                  }
                />
              </div>
            ) : (
              <div className="new-test-categorize-add-container">
                <Button
                  small
                  textButton="Убрать категорию"
                  onClick={() => props.handleCategorizeCategories(false)}
                  disabled={
                    props.newCategorizeInfo.categoryId && props.checkDisabling()
                  }
                />
              </div>
            )}
            {props.categorizeCategories.map((item, categoryIndex) => (
              <div className="new-test-questions-container">
                <div className="new-test-questions-header">
                  <div className="new-test-questions-header-left">
                    <div className="new-test-questions-title">
                      {item.name || `Категория ${categoryIndex + 1}`}
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      props.handleAddWord('categorize', categoryIndex)
                    }
                    className={props.renderAddQuestionButtonStyle()}>
                    <img
                      className={props.renderAddQuestionIconStyle()}
                      src={require('../../assets/pictures/plus.svg')}
                      alt="Новая карточка"
                    />
                  </div>
                </div>
                {renderCategorize(item, categoryIndex, props)}
              </div>
            ))}
          </div>
        </div>
      );
    case 5:
      return <h2>5</h2>;
  }
};

const CardsView = props => {
  return (
    <div className="games-page">
      <DialogModal
        title="Удаление набора"
        show={props.showDeleteModal}
        setShow={props.setShowDeleteModal}
        loading={props.deleteLoading}
        onDelete={() =>
          props.deleteGame(props.defineCategoryObject().categoryId)
        }>
        <p className="modal-message">Вы действительно хотите удалить набор?</p>
      </DialogModal>
      {renderGame(props.tabId, props)}
    </div>
  );
};

export default CardsView;
