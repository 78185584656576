import React from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import './style.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import { LabeledCheckbox } from '../../components';
import { getTeacherBirthBoundry } from '../../core/functions';
import DatePicker from '../../components/datePicker/DatePicker.jsx';

const Registration = props => {
  const renderTab = tab => {
    switch (tab) {
      default:
      case 1:
        return (
          <div key="1">
            <div className="registration__tab-title">Введите инвайт-код</div>
            <Input
              type="name"
              title="Инвайт код"
              className="registration-input"
              value={props.inviteCode}
              placeholder="Код"
              onChange={e => props.setInviteCode(e.target.value)}
            />
            <Button
              loading={props.loading}
              disabled={props.inviteCode === ''}
              className="centered"
              onClick={() => props.checkCode()}
              textButton="Применить"
            />
            <NavLink to="/auth" className="moveToRegistration">
              Вернуться к авторизации
            </NavLink>
          </div>
        );
      case 2:
        return (
          <>
            <div className="registration__tab-title">
              Введите почту и пароль
            </div>
            <Input
              type="email"
              title="Email"
              className="registration-input"
              value={props.registerData.email}
              placeholder="Email"
              onChange={e => props.handleRegisterData('email', e.target.value)}
            />
            <Input
              type="password"
              maxLength="15"
              title="Пароль (мин 6 символов)"
              className="registration-input"
              value={props.registerData.password}
              placeholder="Пароль"
              onChange={e =>
                props.handleRegisterData('password', e.target.value)
              }
            />
            <div className="repeat-password-container">
              <Input
                type="password"
                maxLength="15"
                title="Подтвердите пароль"
                className="registration-input"
                value={props.registerData.secondPassword}
                placeholder="Подтвердите пароль"
                onChange={e =>
                  props.handleRegisterData('secondPassword', e.target.value)
                }
              />
              {!(
                props.registerData.password ===
                props.registerData.secondPassword
              ) &&
                props.registerData.secondPassword && (
                  <div className="password-warning">Пароли не совпадают</div>
                )}
            </div>
            <Button
              onClick={() => props.handleTab(true)}
              disabled={
                props.registerData.password !==
                  props.registerData.secondPassword ||
                props.registerData.password === '' ||
                props.registerData.password.length < 6
              }
              textButton="Следующий шаг"
            />
            <NavLink to="/auth" className="moveToRegistration">
              Авторизоваться
            </NavLink>
          </>
        );
      case 3:
        return (
          <div key="3">
            <div className="registration__tab-title">Введите данные о себе</div>
            <Input
              maxLength="25"
              title="Имя"
              className="registration-input"
              value={props.registerData?.name}
              placeholder="Имя"
              onChange={e => props.handleRegisterData('name', e.target.value)}
            />
            <Input
              maxLength="25"
              title="Фамилия"
              className="registration-input"
              value={props.registerData?.lastName}
              placeholder="Фамилия"
              onChange={e =>
                props.handleRegisterData('lastName', e.target.value)
              }
            />
            <DatePicker
              title="Дата рождения"
              className="registration-date-picker"
              maxDate={getTeacherBirthBoundry()}
              placeholder="Выберите дату рождения"
              format="DD/MM/YYYY"
              id="registration-birthday"
              value={props.registerData?.birthday}
              onChange={e =>
                props.handleRegisterData('birthday', e.toISOString())
              }
            />
            <div className="flexbox-container">
              <Input
                maxLength="15"
                containerClassName="fl-1 margined-rigth"
                title="Паспорт"
                value={props.registerData?.passportSeries}
                placeholder="Серия"
                onChange={e =>
                  props.handleRegisterData('passportSeries', e.target.value)
                }
                onKeyDown={e => props.handlePasswordInput(e)}
              />
              <Input
                maxLength="15"
                containerClassName="fl-2"
                title="⠀"
                value={props.registerData?.passportNumber}
                placeholder="Номер"
                onChange={e =>
                  props.handleRegisterData('passportNumber', e.target.value)
                }
                onKeyDown={e => props.handlePasswordInput(e)}
              />
            </div>
            <Input
              maxLength="200"
              title="Регистрация"
              className="registration-input"
              value={props.registerData?.registrationAddress}
              placeholder="Регистрация"
              onChange={e =>
                props.handleRegisterData('registrationAddress', e.target.value)
              }
            />
            <div className="flexbox-container">
              <Input
                maxLength="20"
                containerClassName="fl-1 margined-rigth"
                title="Диплом"
                value={props.registerData?.firstDyplomaSeries}
                placeholder="Серия"
                onChange={e =>
                  props.handleRegisterData('firstDyplomaSeries', e.target.value)
                }
                onKeyDown={e => props.handleDyplomaInput(e)}
              />
              <Input
                maxLength="20"
                containerClassName="fl-2"
                title="⠀"
                value={props.registerData?.firstDyplomaNumber}
                placeholder="Номер"
                onChange={e =>
                  props.handleRegisterData('firstDyplomaNumber', e.target.value)
                }
                onKeyDown={e => props.handleDyplomaInput(e)}
              />
            </div>
            <div className="flexbox-container">
              <Button
                className="fl-1 margined-right"
                onClick={() => props.handleTab(false)}
                textButton="Назад"
              />
              <Button
                className="fl-2"
                loading={props.loading}
                disabled={
                  !(
                    props.registerData?.birthday &&
                    props.registerData?.lastName &&
                    props.registerData?.firstDyplomaNumber &&
                    props.registerData?.firstDyplomaSeries &&
                    props.registerData?.registrationAddress &&
                    props.registerData?.passportNumber &&
                    props.registerData?.passportSeries &&
                    props.registerData?.name
                  )
                }
                onClick={() => props.submit()}
                textButton="Зарегистрироваться"
              />
            </div>
            <NavLink to="/auth" className="moveToRegistration">
              Авторизоваться
            </NavLink>
          </div>
        );
    };
  };
  return (
    <div className="loginContainer">
      <div className="half left">
        <Logo />
        <ol className="registration__tabs">
          <li
            className={`registration__list ${
              props.tab === 1 ? ' active' : ' success'
            }`}
          />
          <li
            className={`registration__list ${
              props.tab === 1 ? '' : props.tab === 2 ? ' active' : ' success'
            }`}
          />
          <li
            className={`registration__list ${props.tab === 3 ? ' active' : ''}`}
          />
        </ol>
        <div className="registration__form__container">
          {renderTab(props.tab)}
        </div>
      </div>
      <div className="half right">
        <div
          style={{
            backgroundImage: `url('${require('../../assets/pictures/authPicture.png')}')`,
          }}
          alt="Языковая студия Nota5"
        />
      </div>
    </div>
  );
};

export default Registration;
