import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import Icon from '../icon/Icon';

const TableLine = data => (
  <div
    className={`table-line ${
      data.number % 2 === 0 ? 'table-line_white' : 'table-line_gray'
    }`}>
    <NavLink to={`teachers/${data.teacherId}`} className="box box-1">
      {data.number}
    </NavLink>
    <NavLink to={`teachers/${data.teacherId}`} className="box box-2">
      {data.avatar ? (
        <div
          className="table-line__avatar"
          style={{ backgroundImage: `url(${data.avatar})` }}
        />
      ) : (
        <div className="table-line__avatar">
          <Icon name="avatar" width={'32px'} height={'32px'} />
        </div>
      )}
    </NavLink>
    <NavLink to={`teachers/${data.teacherId}`} className="box box-3">
      {`${data.name} ${data.lastName} ${data.patronymic}`}
    </NavLink>
    <NavLink to={`teachers/${data.teacherId}`} className="box box-4">
      {data.email}
    </NavLink>
    <NavLink to={`teachers/${data.teacherId}`} className="box box-5 complete">
      {(data.firstDyplomaSeries || '') + ' ' + (data.firstDyplomaNumber || '')}
    </NavLink>
    <NavLink to={`teachers/${data.teacherId}`} className="box box-6 complete">
      {(data.passportSeries || '') + ' ' + (data.passportNumber || '')}
    </NavLink>
    <NavLink to={`teachers/${data.teacherId}`} className="box box-7 complete">
      {data.registrationAddress || ''}
    </NavLink>
    <div className="box box-9">
      <Button
        type="secondary"
        small
        textButton="Удалить"
        onClick={data.deleteTeacher}
      />
    </div>
  </div>
);

const UsersTableView = props => {
  return (
    <div className="table-wrapper">
      <div className="table teachers_table">
        <div className="table-header table-line">
          <div className="box box-1">№</div>
          <div className="box box-2" />
          <div className="box box-3">ФИО</div>
          <div className="box box-4">E-mail</div>
          <div className="box box-5">Диплом</div>
          <div className="box box-6">Паспортные данные</div>
          <div className="box box-7">Регистрация</div>
          <div className="box box-9">Управление</div>
        </div>
        <div className="table-list">
          {props.loading ? (
            <Loader />
          ) : (
            props.data &&
            props.data.map((user, index) => (
              <TableLine
                {...user}
                key={index}
                number={index + 1}
                deleteTeacher={() => props.deleteTeacher(user)}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersTableView;
