import React from 'react';

import { NavLink } from 'react-router-dom';

import './style.css';
import ButtonIcon from '../../components/button-icon';
import Tabs from '../../components/tabs/Tabs';
import UsersTableContainer from '../../components/usersTable/UsersTableContainer';
import Loader from '../../components/loader/Loader';
import Input from '../../components/input';
import { prettyWord } from '../../core/functions';
import { Modal, StudentsTable } from '../../components';
import NoDataError from '../../components/noDataError/NoDataError';
import Button from '../../components/button';
import DialogModal from '../../components/dialogModal/DialogModal';

const UsersView = props => {
  return (
    <div className="users-page">
      <DialogModal
        title="Удаление студента"
        show={props.showModal}
        setShow={props.setShowModal}
        onLoading={props.deleteLoading}
        onDelete={props.deleteUser}>
        {props.deleteTargetUser ? (
          <span className="add-lesson-modal-item-last">
            Вы действительно хотите удалить студента{' '}
            <strong>
              {props.deleteTargetUser?.name} {props.deleteTargetUser?.lastName}
            </strong>
            ?
            <br />
            <br />
            <em>
              *Удаление приведет к потере всей информации об этом студенте,
              включая данные о платежах, пройденных тестах, играх и т.д.
            </em>
          </span>
        ) : null}
      </DialogModal>
      <div className="users-page__header__search-container">
        <Input
          onChange={e => props.setSearchFor(e.target.value)}
          placeholder="Поиск"
          value={props.searchFor}
        />
      </div>
      <StudentsTable
        data={props.users}
        last={true}
        type={'general'}
        renderEmpty={() => <NoDataError text="Нет студентов для отображения" />}
        renderControl={user => (
          <Button
            type="secondary"
            small
            textButton="Удалить"
            onClick={() => props.showDeleteUserModal(user)}
          />
        )}
      />
    </div>
  );
};

export default UsersView;
