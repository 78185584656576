const SET_NEWS = 'SET_NEWS';

const initialState = null;

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS:
      return action.news;
    default:
      return state;
  }
};

export const setNews = news => {
  return dispatch => {
    dispatch({ type: SET_NEWS, news });
  };
};

export default newsReducer;
