import React from 'react';

import { connect } from 'react-redux';

import TeachersTableView from './TeachersTableView';

const TeachersTableContainer = props => {
  return (
    <TeachersTableView
      deleteTeacher={props.deleteTeacher}
      data={props.teachers}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeachersTableContainer);
