import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import AppInfoService from '../../services/appInfo';
import { showErrorNotification } from '../../core/errorsController';

import { getStudents } from '../../core/firebase';
import DashboardView from './DashboardView';
import { setLoading, setScroll } from '../../store/reducers/appState-reducer';

const DashboardContainer = props => {
  const [counts, setCounts] = useState(null);
  const [progress, setProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getCounts = async () => {
    try {
      const response = await AppInfoService.getCounts();
      setCounts(response);
    } catch (error) {
      showErrorNotification(
        'Произошла ошибка при получении данных, попробуйте позже',
      );
    }
  };

  const getProgress = async () => {
    try {
      const response = await AppInfoService.getProgress();
      setProgress(response);
    } catch (error) {
      showErrorNotification(
        'Произошла ошибка при получении данных, попробуйте позже',
      );
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, [dispatch]);

  useEffect(() => {
    Promise.all([getCounts(), getProgress()]).then(() => setIsLoading(false));
  }, []);

  return (
    <DashboardView counts={counts} isLoading={isLoading} progress={progress} />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
