import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import {
  getGroupTeacherId,
  getTeachersFormattedName,
  ruDateTimeFormat,
} from '../../core/functions';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => {
    const fromDate = moment(new Date(data.date._seconds * 1000));
    return (
      <div
        className={`table-line ${
          data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
        }`}>
        <div className="box box-2">
          {fromDate.format('DD MMMM YYYY г., dd')}
        </div>
        <div className="box box-3">
          <div>
            {getTeachersFormattedName(data.oldTeacherId, props.teachers)}
          </div>
        </div>
        <div className="box box-4">
          {moment(data.oldEventDate).format('DD MMMM, HH:mm, dd')}
        </div>
        <div className="box box-5">
          {getTeachersFormattedName(data.newTeacherId, props.teachers)}
        </div>
        <div className="box box-6">
          {moment(data.newEventDate).format('DD MMMM, HH:mm, dd')}
        </div>
      </div>
    );
  };

  return (
    <>
      <div onScroll={props.paginate} className="table transfers-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Дата переноса</div>
          <div className="box box-3">Предыдущий учитель</div>
          <div className="box box-4">Предыдущая дата</div>
          <div className="box box-5">Новый учитель</div>
          <div className="box box-6">Новый дата</div>
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((event, index) => {
                return <TableLine {...event} key={index} index={index} />;
              })
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
    </>
  );
};

export default UsersTableView;
