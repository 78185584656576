import React from 'react';
import Loader from '../../components/loader/Loader';
import './style.css';
import ProgressCard from '../../components/progressCard/ProgressCard';
const DashboardView = props => {
  if (props.loading) {
    return <Loader />;
  }

  return (
    <div className="dashboard">
      <ProgressCard
        amount={props.counts?.students}
        loading={props.isLoading}
        description="Студентов"
        size="full"
      />
      <ProgressCard
        amount={props.progress?.performance?.toFixed(2) || 0}
        loading={props.isLoading}
        description="Общая успеваемость"
        size="half"
      />
      <ProgressCard
        amount={props.progress?.progress?.toFixed(2) || 0}
        loading={props.isLoading}
        postfix={'%'}
        description="Общий прогресс"
        size="half"
      />
      <ProgressCard
        amount={props.counts?.teachers}
        loading={props.isLoading}
        description="Учителей"
        size="third"
      />
      <ProgressCard
        amount={props.counts?.groups}
        loading={props.isLoading}
        description="Групп"
        size="third"
      />
      <ProgressCard
        amount={props.counts?.classes}
        loading={props.isLoading}
        description="Индивидуальных занятий"
        size="third"
      />
    </div>
  );
};

export default DashboardView;
