import React, { useState } from 'react';

import { connect } from 'react-redux';
import Select from './Select';

const SelectContainer = ({
  containerClass = '',
  onReset = () => {},
  placeholder = '',
  value = '',
  data,
  disabled = false,
  search = false,
  extractSearchKey,
  renderOption,
  handleOption,
  width = '100%',
  renderBefore = () => {},
  renderAfter = () => {},
  renderEmpty = () => {},
  direction = 'top',
  noReset = false,
  label,
}) => {
  const [searchFor, setSearchFor] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [searchMode, setSearchMode] = useState(false);

  const onFocus = () => {
    if (!disabled) {
      if (search) {
        setSearchMode(true);
      }
      setShowOptions(true);
    }
  };

  const onBlur = () => {
    if (!disabled) {
      if (search) {
        setSearchMode(false);
      }
      setSearchFor('');
      setShowOptions(false);
    }
  };

  const invertedDirection = direction === 'top' ? 'bottom' : 'top';

  return (
    <Select
      label={label}
      noReset={noReset}
      disabled={disabled}
      containerClass={containerClass}
      invertedDirection={invertedDirection}
      direction={direction}
      onReset={onReset}
      placeholder={placeholder}
      value={value}
      data={data}
      showOptions={showOptions}
      setShowOptions={setShowOptions}
      search={search}
      searchFor={searchFor}
      searchMode={searchMode}
      setSearchFor={setSearchFor}
      onFocus={onFocus}
      onBlur={onBlur}
      width={width}
      extractSearchKey={extractSearchKey}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
      renderOption={renderOption}
      renderEmpty={renderEmpty}
      handleOption={handleOption}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectContainer);
