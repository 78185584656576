import React, { useState, useEffect } from 'react';
import './style.css';

const TestType = ({
  text = '',
  active = false,
  type = '',
  onStateChange = () => {},
  withState = true,
  onClick = null,
  disabled = false,
}) => {
  const [isActive, setActive] = useState(!!active);

  useEffect(() => {
    setActive(active);
  }, [active]);

  if (!withState) {
    return (
      <button
        className="test-type-container test-type-active"
        disabled={disabled}
        onClick={() => {
          onClick();
        }}>
        {text}
      </button>
    );
  }

  return (
    <button
      className={`test-type-container ${isActive && 'test-type-active'}`}
      disabled={disabled}
      onClick={() => {
        setActive(!isActive);
        onStateChange(type, !isActive);
      }}>
      {text}
    </button>
  );
};

export default TestType;
