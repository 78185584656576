import React from 'react';
import './style.css';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import moment from 'moment';
import TimeInput from '../timeInput/TimeInput';
import DatePicker from '../datePicker/DatePicker';

const DateTimePicker = ({
  disabled = '',
  minDate = '',
  maxDate = '',
  format = 'DD MMMM, dd HH:mm',
  title = '',
  value = new Date(),
  onChange = () => {},
  wrongTime,
  setWrongTime = () => {},
  className = '',
}) => {
  const _value = value ? moment(value).toISOString() : undefined;
  const _date = value ? moment(_value).toISOString() : undefined;
  const _time = value ? moment(_value).toISOString() : undefined;
  const _onChangeDate = date => {
    onChange(
      moment(
        date.format('DD MMMM YYYY ') + moment(_time).format('HH:mm'),
        'DD MMMM YYYY HH:mm',
      ),
    );
  };

  const _onChangeTime = time => {
    onChange(
      moment(
        moment(_date || minDate).format('DD MMMM YYYY ') + time,
        'DD MMMM YYYY HH:mm',
      ),
    );
  };

  return (
    <div>
      {title && <div className="inputTitle">{title}</div>}
      <div className={`date-time-picker-wrapper ${className}`}>
        <DatePicker
          containerClassName={'date-time-picker-date'}
          placeholder="Дата"
          views={['year', 'month', 'date']}
          format={'DD MMMM yyyy'}
          value={_value}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          onChange={_onChangeDate}
        />
        <TimeInput
          placeholder="Время"
          onChange={_onChangeTime}
          value={_value}
          wrongTime={wrongTime}
          handleWrongTime={setWrongTime}
          minTime={new Date()}
          disabled={disabled}
          containerClassName={'date-time-picker-time'}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
