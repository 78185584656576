import React, { useEffect, useState } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import ButtonIcon from '../button-icon';
import './style.css';

const Modal = React.memo(
  ({
    title = '',
    show = true,
    setShow = () => {},
    renderTitle,
    children = '',
  }) => {
    useEffect(() => {
      if (show) {
        document.body.style.overflow = 'hidden';
      }
      return () => (document.body.style.overflow = 'unset');
    }, [show]);

    return (
      <CSSTransitionGroup
        transitionName="modal-wrapper"
        transitionAppear={true}
        transitionAppearTimeout={300}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}>
        {show ? (
          <div
            onClick={e => {
              if (e.target === e.currentTarget) {
                setShow(false);
              }
            }}
            className="modal-wrapper">
            <div className="modal-container">
              {renderTitle ? (
                renderTitle()
              ) : (
                <div className="modal-title">
                  {title}
                  <ButtonIcon onClick={() => setShow(false)} name="close" />
                </div>
              )}
              {children}
            </div>
          </div>
        ) : null}
      </CSSTransitionGroup>
    );
    // return null;
  },
);

export default Modal;
