import React from 'react';
import { NavLink } from 'react-router-dom';

import './style.css';
import Button from '../../components/button';
import TestType from '../../components/create-test/test-type';
import EditorView from '../../components/editor/Editor';
import { List, Modal } from '../../components';
import Select from '../../components/select';
import FileInput from '../../components/fileInput/FileInput';
import DialogModal from '../../components/dialogModal/DialogModal';
import {
  getGroupsFormattedName,
  getStudentsFormattedName,
  getTeachersFormattedName,
} from '../../core/functions';
import Textarea from '../../components/textarea/index';
import DateTimePicker from '../../components/dateTimePicker/DateTimePicker';
import Icon from '../../components/icon/Icon';
import Input from '../../components/input';
import { AVAILABLE_MOBILE_APP_AUDIO_EXTENSIONS } from '../../core/config';

const MaterialsView = props => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;

    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.getMaterials();
    }
  };

  return (
    <div className="materials-container">
      <DialogModal
        title="Удаление материала"
        show={props.showDeleteModal}
        setShow={props.setShowDeleteModal}
        loading={props.deleteLoading}
        onDelete={() => {
          props.deleteMaterial(props.newMaterialInfo.id);
        }}>
        <p className="modal-message">
          Вы действительно хотите удалить материал?
        </p>
      </DialogModal>
      <Modal
        show={props.showModal}
        setShow={props.setShowModal}
        title="Привяжите материал">
        {props.isAdmin && (
          <Select
            label="Учитель"
            direction="bottom"
            containerClass="add-lesson-modal-item"
            value={
              props.selectedTeacher
                ? getTeachersFormattedName(
                    props.selectedTeacher,
                    props.teachers,
                  )
                : ''
            }
            data={props.teachers}
            width={'100%'}
            search={true}
            renderEmpty={() => (
              <div className="option option-empty">Нет доступных учителей</div>
            )}
            onReset={() => props.setSelectedTeacher()}
            placeholder={'Выберите учителя'}
            renderOption={el => {
              return (
                <div
                  className="option"
                  onMouseDown={() => {
                    props.setSelectedTeacher(el.teacherId);
                    props.setSelectedOrganizer();
                  }}>
                  {getTeachersFormattedName(el.teacherId, props.teachers)}
                </div>
              );
            }}
            extractSearchKey={el =>
              getTeachersFormattedName(el.teacherId, props.teachers)
            }
          />
        )}
        <Select
          label="Группа/занятие"
          direction="bottom"
          width={'100%'}
          containerClass="add-lesson-modal-item"
          value={
            props.selectedOrganizer
              ? `${
                  props.selectedOrganizer.type === 'group'
                    ? getGroupsFormattedName(
                        props.selectedOrganizer.id,
                        props.organizers,
                      )
                    : getStudentsFormattedName(
                        props.selectedOrganizer.studentId,
                        props.students,
                      )
                } (Учитель: ${getTeachersFormattedName(
                  props.selectedOrganizer.teacherId,
                  props.teachers,
                )})`
              : ''
          }
          data={
            props.selectedTeacher
              ? props.organizers.filter(
                  el => el.teacherId === props.selectedTeacher,
                )
              : props.organizers
          }
          search={true}
          renderEmpty={() => (
            <div className="option option-empty">
              Нет доступных групп и персональных занятий
            </div>
          )}
          onReset={() => props.setSelectedOrganizer()}
          placeholder="Выберите группу или занятие"
          renderOption={el => {
            return (
              <div
                className="option"
                onMouseDown={() => {
                  props.setSelectedOrganizer({
                    id: el.groupId || el.classId,
                    type: el.groupId ? 'group' : 'class',
                    studentId: el.studentId,
                    teacherId: el.teacherId,
                  });
                  props.setSelectedTeacher(el.teacherId);
                }}>
                {`${
                  el.groupId
                    ? getGroupsFormattedName(el.groupId, props.organizers)
                    : getStudentsFormattedName(el.studentId, props.students)
                } (Учитель: ${getTeachersFormattedName(
                  el.teacherId,
                  props.teachers,
                )})`}
              </div>
            );
          }}
          extractSearchKey={el =>
            el.groupId
              ? getGroupsFormattedName(el.groupId, props.organizers)
              : getStudentsFormattedName(el.studentId, props.students)
          }
        />
        <DateTimePicker
          className="add-lesson-modal-item-last"
          title="Дата открытия материала"
          placeholder="Выберите дату открытия"
          minDate={new Date()}
          format={'DD MMMM, dd HH:mm'}
          variant="inline"
          wrongTime={props.wrongTime}
          setWrongTime={props.setWrongTime}
          inputProps={{ className: 'date-input' }}
          value={props.selectedDate ? props.selectedDate : null}
          onChange={date => {
            props.setSelectedDate(date);
          }}
        />
        <Button
          small
          textButton="Привязать материал"
          onClick={props.attachMaterial}
          disabled={
            !(props.selectedOrganizer && props.selectedDate) || props.wrongTime
          }
          loading={props.attachingMaterialLoading}
        />
      </Modal>
      <List
        renderItem={item => {
          return (
            <NavLink to={`/materials/${item.materialId}`}>
              <div
                className="item-list-container"
                onClick={() => {
                  props.setNextLinkItem(item);
                }}>
                <Icon
                  name={item.type}
                  width={16}
                  height={16}
                  color={'black'}
                  storkeWidth={0}
                />
                <div>{item.title} </div>
                <div className="item-creator">
                  {getTeachersFormattedName(item.teacherId, props.teachers)}
                </div>
              </div>
            </NavLink>
          );
        }}
        renderHeader={() => {
          return (
            <NavLink to="/materials">
              <div className="add-new-item-button-container">
                <Button
                  small
                  textButton="Добавить материал"
                  onClick={() => props.setNextLinkItem(null)}
                />
              </div>
            </NavLink>
          );
        }}
        onScroll={handleDataOnBottom}
        list={props.materialsList}
        loading={props.isLoading}
        emptySign="На данный момент нет материалов"
      />
      <div className="new-material-container">
        <div className="new-material-header">
          <div className="new-material-header-left">
            <div className="new-material-title-container">
              <Input
                type="text"
                className={`new-material-title ${
                  props.outlineEmptyField && !props.newMaterialInfo.title
                    ? 'new-material-title-invalid'
                    : ''
                }`}
                placeholder="Новый материал"
                maxLength={200}
                showCounter={true}
                onChange={e =>
                  props.handleMaterialChange('title', e.target.value)
                }
                value={props.newMaterialInfo.title}
                disabled={props.newMaterialInfo.id && props.checkDisabling()}
              />
            </div>
            <div className="new-test-source-container">
              <Input
                type="text"
                class={`new-test-title ${
                  props.outlineEmptyField && !props.newMaterialInfo.source
                    ? 'new-test-title-invalid'
                    : ''
                }`}
                placeholder="Автор или ссылка на ресурс"
                onChange={e => props.handleMaterialSourceChange(e.target.value)}
                value={props.newMaterialInfo.source || ''}
                disabled={props.newMaterialInfo.id && props.checkDisabling()}
              />
            </div>
            {props.newMaterialInfo.type === 'standart' && (
              <div className="new-material-markdown-length-counter">
                {}
                {`Длина материала ${
                  props.deleteTagsFromMarkdown(props.newMaterialInfo.markdown)
                    .length
                }/10000`}
              </div>
            )}
            <div
              className={
                props.newMaterialInfo.type === 'standart'
                  ? `new-material-description-container ${
                      props.outlineEmptyField &&
                      !props.deleteTagsFromMarkdown(
                        props.newMaterialInfo.markdown,
                      )
                        ? 'new-material-description-invalid'
                        : ''
                    }`
                  : 'new-test-description-container'
              }>
              {props.newMaterialInfo.type === 'standart' ? (
                <EditorView
                  value={props.newMaterialInfo.markdown}
                  onChange={props.handleMaterialMarkdownChange}
                  disabled={props.newMaterialInfo.id && props.checkDisabling()}
                />
              ) : (
                <Textarea
                  className={`new-test-description ${
                    props.outlineEmptyField &&
                    !props.newMaterialInfo.description
                      ? 'new-material-description-invalid'
                      : ''
                  }`}
                  showCounter={true}
                  placeholder="Описание"
                  onChange={e =>
                    props.handleMaterialChange('description', e.target.value)
                  }
                  value={props.newMaterialInfo.description}
                  maxLength={1000}
                  disabled={props.newMaterialInfo.id && props.checkDisabling()}
                />
              )}
              {/* <Editor
                editorState={editorState}
                onChange={editor => setEditorState(editor)}
              /> */}
            </div>
          </div>
          <div className="new-material-header-right">
            {props.newMaterialInfo.id &&
              (props.isAdmin ||
                props.newMaterialInfo.teacherId ===
                  props.userData.teacherId) && (
                <div className="new-material-cancel-container">
                  <Button
                    type="secondary"
                    small
                    textButton="Удалить материал"
                    disabled={props.saveLoading}
                    onClick={() => props.setShowDeleteModal(true)}
                  />
                </div>
              )}
            <div className="new-material-save-container">
              {props.newMaterialInfo.id &&
              (props.isAdmin ||
                props.newMaterialInfo.teacherId ===
                  props.userData.teacherId) ? (
                <Button
                  small
                  textButton="Обновить"
                  onClick={() => props.updateMaterial(props.newMaterialInfo.id)}
                  disabled={props.saveLoading}
                />
              ) : (
                !props.newMaterialInfo.id && (
                  <Button
                    small
                    textButton="Сохранить"
                    onClick={props.saveNewMaterial}
                    disabled={props.saveLoading}
                  />
                )
              )}
              {props.saveLoading && (
                <div
                  className="uploading-progress"
                  style={{ width: `${props.fileLoading}%` }}
                />
              )}
            </div>
            {props.newMaterialInfo.id && (
              <div className="new-material-attach-container">
                <Button
                  small
                  textButton="Привязать материал"
                  onClick={props.showAttachingMaterialModal}
                />
              </div>
            )}
          </div>
        </div>
        <div className="new-material-type-container">
          <div className="new-material-type-title">Постер</div>
          <div className="new-material-type-list-container">
            <div className="new-material-file-container">
              <FileInput
                type="text"
                externalRef={props.externalPosterRef}
                extensions={['jpg', 'png', 'jpeg', 'heic']}
                maxSize={5}
                placeholder="Выбрать файл"
                handleFileDelete={() => props.deleteFile('poster')}
                handleFileSelect={(blob, fileName) =>
                  props.selectFile(blob, fileName)
                }
                fileAddr={props.newMaterialInfo?.poster}
                disabled={props.newMaterialInfo.id && props.checkDisabling()}
              />
            </div>
          </div>
        </div>

        <div className="new-material-type-container">
          <div className="new-material-type-title">Тип материала</div>
          <div
            className={`new-material-type-list-container ${
              props.outlineEmptyField && !props.newMaterialInfo.type
                ? 'new-material-type-list-container-invalid'
                : ''
            }`}>
            <TestType
              text="Текстовый"
              type="standart"
              disabled={props.newMaterialInfo.id}
              active={props.newMaterialInfo.type === 'standart'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
            <TestType
              text="Аудио"
              type="audio"
              disabled={props.newMaterialInfo.id}
              active={props.newMaterialInfo.type === 'audio'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
            <TestType
              text="Видео"
              type="video"
              disabled={props.newMaterialInfo.id}
              active={props.newMaterialInfo.type === 'video'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
            <TestType
              text="Файл (pdf)"
              type="file"
              disabled={props.newMaterialInfo.id}
              active={props.newMaterialInfo.type === 'file'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
          </div>
        </div>

        {props.newMaterialInfo.type === 'audio' && (
          <div className="new-material-type-container">
            <div className="new-material-type-title">
              Загрузите аудио файл (макс 50мб)
            </div>
            <div
              className={`new-material-type-list-container ${
                props.outlineEmptyField && !props.audio
                  ? 'new-material-type-list-container-invalid'
                  : ''
              }`}>
              <div className="new-material-file-container">
                <FileInput
                  type="text"
                  externalRef={props.externalAudioRef}
                  maxSize={50}
                  placeholder="Добавьте аудиозапись"
                  extensions={AVAILABLE_MOBILE_APP_AUDIO_EXTENSIONS}
                  disabledText="Недоступно для данного типа"
                  handleFileDelete={() => props.deleteFile('audio')}
                  handleFileSelect={(blob, fileName) =>
                    props.setAudio({ blob, fileName })
                  }
                  fileAddr={props.newMaterialInfo?.audio}
                  disabled={props.newMaterialInfo.id && props.checkDisabling()}
                />
              </div>
            </div>
          </div>
        )}

        {props.newMaterialInfo.type === 'file' && (
          <div className="new-material-type-container">
            <div className="new-material-type-title">
              Загрузите PDF файл (макс 50мб)
            </div>
            <div
              className={`new-material-type-list-container ${
                props.outlineEmptyField && !props.file
                  ? 'new-material-type-list-container-invalid'
                  : ''
              }`}>
              <div className="new-material-file-container">
                <FileInput
                  externalRef={props.externalFileRef}
                  key={props.newMaterialInfo.type}
                  maxSize={50}
                  placeholder="Добавьте файл материала"
                  disabledText="Недоступно для данного типа"
                  handleFileDelete={() => props.deleteFile('file')}
                  handleFileSelect={(blob, fileName) =>
                    props.setFile({ blob, fileName })
                  }
                  extensions={['pdf']}
                  fileAddr={props.newMaterialInfo?.file}
                  disabled={props.newMaterialInfo.id && props.checkDisabling()}
                />
              </div>
            </div>
          </div>
        )}

        {props.newMaterialInfo.type === 'video' && (
          <div className="new-material-content-container">
            <div className="new-material-date-container">
              <label
                for="material-video-link"
                className="new-material-date-title">
                Ссылка на видео
              </label>
              <input
                id="material-video-link"
                type="text"
                placeholder="https://www.youtube.com/watch?v=..."
                className={`new-material-title ${
                  props.outlineEmptyField && !props.newMaterialInfo.video
                    ? 'new-material-title-invalid'
                    : ''
                }`}
                onChange={props.handleMaterialVideoLinkChange}
                value={props.newMaterialInfo.video}
                disabled={props.newMaterialInfo.id && props.checkDisabling()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MaterialsView;
