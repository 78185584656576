import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';
import firebase from 'firebase';

export default {
  async createFlashCard(body) {
    try {
      const result = await apiPost('/flashCards/category', { data: body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getFlashCards(creatorId, page = 1, size = 40) {
    try {
      const result = await apiGet('/flashCards/categories', {
        params: { creatorId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createAnagram(body) {
    try {
      const result = await apiPost('/anagrams/category', { data: body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getAnagrams(creatorId, page = 1, size = 40) {
    try {
      const result = await apiGet('/anagrams/categories', {
        params: { creatorId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createMatchup(body) {
    try {
      const result = await apiPost('/matchup/category', { data: body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getMatchups(creatorId, page = 1, size = 40) {
    try {
      const result = await apiGet('/matchup/categories', {
        params: { creatorId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createCategorize(body) {
    try {
      const result = await apiPost('/categorize/category', { data: body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCategorizes(creatorId, page = 1, size = 40) {
    try {
      const result = await apiGet('/categorize/categories', {
        params: { creatorId, page, size },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async uploadFile(file, filename) {
    try {
      var uploadRef = await firebase
        .storage()
        .ref(`material/${new Date().getTime()}${filename}`);

      const result = uploadRef
        .put(file.blob, { cacheControl: 'public' })
        .then(snapshot => snapshot.ref.getDownloadURL());

      return result;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async addGames(gameId, data) {
    let route;
    switch (gameId) {
      case 1:
      default:
        route = 'flashCards';
        break;
      case 2:
        route = 'matchup';
        break;
      case 3:
        route = 'anagrams';
        break;
      case 4:
        route = 'categorize';
        break;
    }
    try {
      const result = await apiPost(`/${route}/category/assign`, data);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteGames(gameId, categoryId) {
    let route;
    switch (gameId) {
      case 1:
      default:
        route = 'flashCards';
        break;
      case 2:
        route = 'matchup';
        break;
      case 3:
        route = 'anagrams';
        break;
      case 4:
        route = 'categorize';
        break;
    }
    try {
      const result = await apiDelete(`/${route}/category`, { categoryId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async removeGames(gameId, data) {
    let route;
    switch (gameId) {
      case 1:
      default:
        route = 'flashCards';
        break;
      case 2:
        route = 'matchup';
        break;
      case 3:
        route = 'anagrams';
        break;
      case 4:
        route = 'categorize';
        break;
    }
    try {
      const result = await apiDelete(`/${route}/category/assign`, data);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addFlashCardsToGroup(body) {
    try {
      const result = await apiPost('flashCards/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addFlashCardsToPersonal(body) {
    try {
      const result = await apiPost('flashCards/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addMatchUpToGroup(body) {
    try {
      const result = await apiPost('/matchup/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addMatchUpToPersonal(body) {
    try {
      const result = await apiPost('/matchup/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addAnagramsToGroup(body) {
    try {
      const result = await apiPost('/anagrams/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addAnagramsToPersonal(body) {
    try {
      const result = await apiPost('/anagrams/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addCategorizeToGroup(body) {
    try {
      const result = await apiPost('/categorize/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addCategorizeToPersonal(body) {
    try {
      const result = await apiPost('/categorize/category/assign', { ...body });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateGame(gameId, categoryId, data) {
    let route;
    switch (gameId) {
      case 1:
      default:
        route = 'flashCards';
        break;
      case 2:
        route = 'matchup';
        break;
      case 3:
        route = 'anagrams';
        break;
      case 4:
        route = 'categorize';
        break;
    }
    try {
      const result = await apiPut(`/${route}/category`, { data, categoryId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getGame(gameId, categoryId) {
    let route;
    switch (gameId) {
      case 1:
      default:
        route = 'flashCards';
        break;
      case 2:
        route = 'matchup';
        break;
      case 3:
        route = 'anagrams';
        break;
      case 4:
        route = 'categorize';
        break;
    }
    try {
      const result = await apiGet(`/${route}/category`, { params: { categoryId } });
      return { data: result.data, status: result.status };
    } catch (error) {
      return error.response.data;
    }
  },
};
