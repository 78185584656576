import React from 'react';
import './style.css';
import Loader from '../loader/Loader';
const FullscreenLoader = ({ className = '', color = 'white' }) => {
  return (
    <div
      style={{ background: color }}
      className={`fullscreen-loader-wrapper ${className}`}>
      <Loader className={'fullscreen-loader'} />
    </div>
  );
};

export default FullscreenLoader;
