import React from 'react';

import Tabs from '../../components/tabs/Tabs';
import {
  StudentsTable,
  MaterialsTable,
  GroupTests,
  GamesTable,
  Modal,
  Checkbox,
  LabeledCheckbox,
} from '../../components';
import Select from '../../components/select/index';
import PaymentsTable from '../../components/PaymentsTable';
import './style.css';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';
import { NavLink } from 'react-router-dom';
import { ListContainer } from '../../components';
import NoDataError from '../../components/noDataError/NoDataError';
import DialogModal from '../../components/dialogModal/DialogModal';
import EventsTable from '../../components/eventsTable/index';
import DatePicker from '../../components/datePicker/DatePicker';
import Input from '../../components/input/index';
import {
  getTeachersFormattedName,
  prettyLangLevel,
} from '../../core/functions';
import moment from 'moment';

const View = props => {
  const mainTabs = [
    { name: 'Студенты', id: 1 },
    { name: 'Материалы', id: 2 },
    { name: 'Тесты', id: 3 },
    { name: 'Игры', id: 4 },
    { name: 'Занятия', id: 5 },
    props.isAdmin ? { name: 'Платежи', id: 6 } : null,
    props.isAdmin ? { name: 'Инвайт коды', id: 7 } : null,
  ].filter(el => el);

  const gameTabs = [
    { name: 'Flash cards', id: 1 },
    { name: 'Match Up', id: 2 },
    { name: 'Anagrams', id: 3 },
    { name: 'Categorize', id: 4 },
  ];

  const renderGames = (tab, props) => {
    let data;
    let last;
    let loading;
    let noDataText;
    switch (tab) {
      case 1:
        data = props.flashcards;
        last = props.paginationStatus.games.flashcards.last;
        loading = props.flashcardsLoading;
        noDataText = 'В этой группе еще нет Flashcards';
        break;
      case 2:
        data = props.matchup;
        last = props.paginationStatus.games.matchup.last;
        loading = props.matchupLoading;
        noDataText = 'В этой группе еще нет Match Up';
        break;
      case 3:
        data = props.anagrams;
        last = props.paginationStatus.games.anagrams.last;
        loading = props.anagramsLoading;
        noDataText = 'В этой группе еще нет Anagrams';
        break;
      case 4:
        data = props.categorize;
        last = props.paginationStatus.games.categorize.last;
        loading = props.categorizeLoading;
        noDataText = 'В этой группе еще нет Categorize';
        break;
      default:
        return;
    }
    return (
      <GamesTable
        renderEmpty={() => {
          return <NoDataError text={noDataText} />;
        }}
        renderControl={el => {
          return (
            <Checkbox
              value={!el.active}
              onClick={() => {
                props.handleGame(el.has, el.index, !el.active);
              }}
            />
          );
        }}
        tabId={tab}
        last={last}
        loading={loading}
        handleGame={props.handleGame}
        shareGames={() => props.setShowDeleteModal(true)}
        data={data}
      />
    );
  };

  const renderGroupTab = (tab, props) => {
    switch (tab) {
      default:
      case 1:
        return (
          <StudentsTable
            paginate={e => handleDataOnBottom(e)}
            renderEmpty={() => {
              return <NoDataError text="В этой группе еще нет студентов" />;
            }}
            renderControl={el => {
              return (
                <Checkbox
                  value={!el.active}
                  onClick={() => {
                    props.handleStudents(el.has, el.index, !el.active);
                  }}
                />
              );
            }}
            loading={props.studentsLoading}
            data={props.students}
            last={props.paginationStatus.students.last}
            type={props.isAdmin ? 'admin' : 'teacher'}
            shareStudents={props.showDeleteStudents}
          />
        );
      case 2:
        return (
          <MaterialsTable
            paginate={e => handleDataOnBottom(e)}
            last={props.paginationStatus.materials.last}
            renderEmpty={() => {
              return <NoDataError text="В этой группе еще нет материалов" />;
            }}
            renderControl={el => {
              return (
                <Checkbox
                  value={!el.active}
                  onClick={() => {
                    props.handleMaterial(el.has, el.index, !el.active);
                  }}
                />
              );
            }}
            loading={props.materialsLoading}
            data={props.materials}
            shareMaterials={() => props.setShowDeleteModal(true)}
            handleMaterial={props.handleMaterial}
          />
        );
      case 3:
        return (
          <GroupTests
            paginate={e => handleDataOnBottom(e)}
            renderControl={el => {
              return (
                <Checkbox
                  value={!el.active}
                  onClick={() => {
                    props.handleTest(el.has, el.index, !el.active);
                  }}
                />
              );
            }}
            last={props.paginationStatus.tests.last}
            renderEmpty={() => {
              return <NoDataError text="В этой группе еще нет тестов" />;
            }}
            loading={props.testsLoading}
            data={props.tests}
            shareTests={() => props.setShowDeleteModal(true)}
            handleTest={props.handleTest}
          />
        );
      case 4:
        return (
          <>
            <Tabs
              tabId={props.gamesTabId}
              tabs={gameTabs}
              handleChangeGroup={props.setGamesTabId}
            />
            {renderGames(props.gamesTabId, props)}
          </>
        );
      case 5:
        return (
          <EventsTable
            paginate={e => handleDataOnBottom(e)}
            type={'group'}
            renderEmpty={() => {
              return (
                <NoDataError text={'Нет доступных для просмотра занятий'} />
              );
            }}
            last={props.paginationStatus.events.last}
            loading={props.eventsLoading}
            data={props.events}
          />
        );
      case 7:
        return (
          <div className="group-page__code-tab">
            <div className="group-page__code-tab__invite-code-button">
              <Button
                onClick={() => props.addNewCode()}
                type="info"
                textButton="Сгенерировать инвайт-код"
              />
            </div>
            {props.invites.length ? (
              <div className="invites-page__main">
                <ListContainer
                  title="Коды для группы"
                  list={props.invites}
                  onClick={props.copyToClipboard}
                />
              </div>
            ) : null}
          </div>
        );
      case 6:
        return (
          <PaymentsTable
            paginate={e => handleDataOnBottom(e)}
            renderEmpty={() => {
              return <NoDataError text={'Нет доступных для просмотра '} />;
            }}
            last={props.paginationStatus.payments.last}
            loading={props.paymentsLoading}
            data={props.payments}
            deletePayment={() => props.setShowPaymentDeleteModal(true)}
            newPayment={() => props.setShowPaymentModal(true)}
          />
        );
    }
  };

  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;

    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.handleContainerOnBottom();
    }
  };

  const getModalData = () => {
    switch (props.tabId) {
      case 2:
        return {
          title: 'Удаление материалов',
          text: 'Вы действительно хотите удалить материалы из группы?',
        };
      case 3:
        return {
          title: 'Удаление тестов',
          text: 'Вы действительно хотите удалить тесты из группы?',
        };
      case 4:
        return {
          title: 'Удаление набора игр',
          text: 'Вы действительно хотите удалить наборы для игры из группы?',
        };
    }
  };

  const modalData = getModalData();

  const getPaymentListDescription = item => {
    return `${item.value} RUB от ${moment(item.originalDate).format(
      'DD.MM HH:mm',
    )}, Период: ${moment(item.fromDate).format('DD.MM')} - ${moment(
      item.toDate,
    ).format('DD.MM')}`;
  };

  return (
    <div className="group-page">
      <DialogModal
        title={modalData?.title}
        show={props.showDeleteModal}
        setShow={props.setShowDeleteModal}
        loading={props.deleteLoading}
        onDelete={props.delete}>
        <span className="add-lesson-modal-item-last">{modalData?.text}</span>
      </DialogModal>
      <DialogModal
        title="Удаление студентов"
        show={props.showModal}
        setShow={props.setShowModal}
        loading={props.deleteStudentsLoading}
        onDelete={props.deleteStudents}>
        {props.deletingStudents.length ? (
          <span className="add-lesson-modal-item-last">
            Вы действительно хотите удалить следующих студентов из группы{' '}
            {props.data.name + ' ' + props.data.level} ?
            <br />
            {props.deletingStudents.map(el => {
              return (
                <>
                  <br />
                  <strong>
                    {el.lastName} {el.name}
                  </strong>
                </>
              );
            })}
          </span>
        ) : null}
      </DialogModal>
      <DialogModal
        title="Удаление оплаты"
        show={props.showPaymentDeleteModal}
        setShow={props.setShowPaymentDeleteModal}
        loading={props.deletePaymentLoading}
        okDisabled={!(props.selectedPaymentsList && props.confirmDelete)}
        okText={'Удалить'}
        onDelete={props.deletePayment}>
        <Select
          direction="bottom"
          value={
            props.selectedPaymentsList
              ? getPaymentListDescription(props.selectedPaymentsList)
              : ''
          }
          data={props.paymentsList}
          search={true}
          width={'100%'}
          containerClass="add-lesson-modal-item"
          renderEmpty={() => (
            <div className="option option-empty">Нет оплат</div>
          )}
          onReset={() => props.setSelectedPaymentsList(null)}
          placeholder={'Выберите оплату'}
          renderOption={el => {
            return (
              <div
                className="option"
                onMouseDown={() => {
                  props.setSelectedPaymentsList(el);
                }}>
                {getPaymentListDescription(el)}
              </div>
            );
          }}
          extractSearchKey={el => getPaymentListDescription(el)}
        />
        <em className="attention-text add-lesson-modal-item">
          *Внимание! Удалить можно только оплаты со статусом "не оплачено".
          Оплаты в статусе "оплачено" недоступны для удаления.
        </em>
        <LabeledCheckbox
          containerClass="add-lesson-modal-item"
          title="Я подтверждаю удаление оплаты"
          value={props.confirmDelete}
          onClick={() => {
            props.setConfirmDelete(!props.confirmDelete);
          }}
        />
      </DialogModal>
      <DialogModal
        title="Выставление оплаты"
        show={props.showPaymentModal}
        setShow={props.setShowPaymentModal}
        loading={props.createPaymentLoading}
        okDisabled={
          !(
            props.newPayment?.fromDate &&
            props.newPayment?.toDate &&
            props.newPayment?.price
          )
        }
        okText={'Выставить'}
        onDelete={props.createPayment}>
        <DatePicker
          disableToolbar={false}
          title="Начала периода"
          maxDate={
            props.newPayment?.toDate
              ? moment(props.newPayment?.toDate).toISOString()
              : null
          }
          className="add-lesson-modal-item"
          views={['year', 'month', 'date']}
          format={'DD MMMM yyyy'}
          placeholder="Выберите дату"
          onChange={e => {
            props.setNewPayment(old => {
              return {
                ...old,
                fromDate: e.toISOString(),
              };
            });
          }}
          value={props.newPayment?.fromDate}
        />
        <DatePicker
          disableToolbar={false}
          title="Окончание периода"
          className="add-lesson-modal-item"
          views={['year', 'month', 'date']}
          minDate={
            props.newPayment?.fromDate
              ? moment(props.newPayment?.fromDate).toISOString()
              : null
          }
          format={'DD MMMM yyyy'}
          placeholder="Выберите дату"
          onChange={e => {
            props.setNewPayment(old => {
              return {
                ...old,
                toDate: e.toISOString(),
              };
            });
          }}
          value={props.newPayment?.toDate}
        />
        <Input
          type="text"
          title="Размер платежа (RUB)"
          className="add-lesson-modal-item-last"
          value={props.newPayment?.price}
          placeholder="Введите размер платежа"
          onChange={e => {
            if (
              (e.target.value >= 1 &&
                /^[0-9]{1,7}(?:\.[0-9]{0,2})?$/.test(e.target.value)) ||
              e.target.value === ''
            ) {
              e.persist();
              props.setNewPayment(old => {
                return {
                  ...old,
                  price: e.target.value,
                };
              });
            }
          }}
        />
      </DialogModal>
      {props.data.name && (
        <div key={props.data.groupId} className="group-page-content">
          <div className="group-page__header">
            <div className="group-page__info">
              <h2 className="group-page__info-name">{props.data.name}</h2>
              <p>{`Уровень: ${props.data?.level ? props.data?.level : '-'}`}</p>
              <div className="inline-select">
                <div className="lang-level-label">Учитель:</div>
                <Select
                  direction="bottom"
                  value={
                    props.selectedTeacher
                      ? getTeachersFormattedName(
                          props?.selectedTeacher,
                          props.teachers,
                        )
                      : ''
                  }
                  data={props.teachers}
                  search={true}
                  width={'100%'}
                  renderEmpty={() => (
                    <div className="option option-empty">
                      Нет доступных учителей
                    </div>
                  )}
                  noReset={!props.isTeacherChanged}
                  onReset={() => props.setSelectedTeacher(props.data.teacherId)}
                  placeholder={'Выберите учителя'}
                  renderOption={el => {
                    return (
                      <div
                        className="option"
                        onMouseDown={() => {
                          props.setSelectedTeacher(el.teacherId);
                        }}>
                        {getTeachersFormattedName(el.teacherId, props.teachers)}
                      </div>
                    );
                  }}
                  extractSearchKey={el =>
                    getTeachersFormattedName(el.teacherId, props.teachers)
                  }
                />
                <Button
                  type="primary"
                  small
                  className="lang-level-button"
                  loading={props.changeTeacherLoading}
                  disabled={!props.isTeacherChanged}
                  textButton="Изменить учителя"
                  onClick={() => props.rewriteTeacher()}
                />
              </div>
            </div>
          </div>
          <div className={'group-page__main'}>
            <Tabs
              tabId={props.tabId}
              tabs={mainTabs}
              handleChangeGroup={props.setTabId}
            />
            <div className="group-tab-wrapper">
              {renderGroupTab(props.tabId, props)}
            </div>
          </div>
        </div>
      )}
      {!props.data.name && <Loader />}
    </div>
  );
};

export default View;
