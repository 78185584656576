import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import {
  getGroupTeacherId,
  getTeachersFormattedName,
  ruDateTimeFormat,
} from '../../core/functions';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => {
    const fromDate = moment(data.date);
    const tillDate = moment(data.date).add(data.duration, 'm');
    return (
      <div
        className={`table-line ${
          data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
        }`}>
        {/* <NavLink to={`users/${data.id}`} className="box box-1">{data.id}</NavLink> */}
        <div className="box box-2">
          <div>{fromDate.format('DD MMMM, dd')}&nbsp;</div>
          <div>
            {fromDate.format('HH:mm')} - {tillDate.format('HH:mm')}
          </div>
        </div>
        <div className="box box-3">
          {getTeachersFormattedName(data.teacherId, props.teachers)}
        </div>
        <div className="box box-4">{data.duration + ' мин'}</div>
        {props.type === 'class' ? (
          <div className="box box-5">{data.price}</div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div onScroll={props.paginate} className="table events-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Дата</div>
          <div className="box box-3">Учитель</div>
          <div className="box box-4">Длительность</div>
          {props.type === 'class' ? (
            <div className="box box-5">Цена</div>
          ) : null}
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((event, index) => {
                const teacherId =
                  event.type === 'group'
                    ? getGroupTeacherId(event.groupId, props.groups)
                    : event.teacherId;
                return (
                  <TableLine
                    price={event.price}
                    key={index}
                    index={index}
                    teacherId={teacherId}
                    id={event.eventId}
                    duration={event.duration}
                    date={event.date}
                  />
                );
              })
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
    </>
  );
};

export default UsersTableView;
