/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { store } from 'react-notifications-component';
import { UsersService } from '../../services';
import View from './view';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { setScroll } from '../../store/reducers/appState-reducer';

const GroupsContainer = props => {
  const users = useSelector(state => state.students);
  const [searchFor, setSearchFor] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [deleteTargetUser, setDeleteTargetUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const showDeleteUserModal = user => {
    setDeleteTargetUser(user);
    manipulateModal(true);
  };

  useEffect(() => {
    dispatch(setScroll(true));
  }, []);

  const manipulateModal = value => {
    setTimeout(() => {
      setShowModal(value);
      if (!value) {
        setDeleteTargetUser(true);
      }
    }, 0);
  };

  const deleteStudent = async () => {
    setDeleteLoading(true);
    try {
      const result = await UsersService.deleteStudent(
        deleteTargetUser.studentId,
      );
      if (result.errorCode) {
        throw result;
      }
      setFilteredUsers(old =>
        old.filter(el => el.studentId !== deleteTargetUser.studentId),
      );
      setDeleteTargetUser(null);
      showSuccessNotification('Студент был удален');
      setShowModal(false);
    } catch (error) {
      showErrorNotification(error.message);
    }
    setDeleteLoading(false);
  };

  const searchUser = value => {
    setSearchFor(value);
    const regexp = new RegExp(value, 'i');
    setFilteredUsers(() => {
      return users.filter(el => {
        return regexp.test(
          `${el.name} ${el.lastName} ${el.patronymic} ${el.phone} ${el.email}`,
        );
      });
    });
  };

  return (
    <View
      users={filteredUsers}
      showModal={showModal}
      deleteLoading={deleteLoading}
      setShowModal={manipulateModal}
      deleteTargetUser={deleteTargetUser}
      showDeleteUserModal={showDeleteUserModal}
      deleteUser={deleteStudent}
      setSearchFor={searchUser}
      searchFor={searchFor}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
