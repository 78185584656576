import React, { useState } from 'react';
import './style.css';

const SelectableContainer = ({
  className,
  style,
  onFocus = () => {},
  onBlur = () => {},
  onClick = () => {},
  customSelectionControl,
  selectionColor = '#7EB608',
  children,
  disabled = false,
}) => {
  const [selected, setSelected] = useState(false);
  const withoutCustom = typeof customSelectionControl === 'undefined';

  const defOnFocus = () => {
    setSelected(true);
  };

  const defOnBlur = () => {
    setSelected(false);
  };

  const isSelected = withoutCustom ? selected : customSelectionControl;

  return (
    <div
      className={`selection-container ${className ? className : ''} ${
        isSelected && !disabled ? 'selection-active' : ''
      } ${disabled ? 'selection-disabled' : ''}`}
      style={{
        ...style,
        ...(isSelected && !disabled ? { borderColor: selectionColor } : null),
      }}
      onFocus={withoutCustom ? defOnFocus : onFocus}
      onBlur={withoutCustom ? defOnBlur : onBlur}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default SelectableContainer;
