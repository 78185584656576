import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';

export default {
  async getProgress(teacherId) {
    try {
      const result = await apiGet('/appInfo/progress');
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getCounts(classId) {
    try {
      const result = await apiGet('/appInfo/counts');
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getAboutUs() {
    try {
      const result = await apiGet('/interesting/about');
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateAboutUs(body) {
    try {
      const result = await apiPut('/interesting/about', body);
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
