import React from 'react';

import Tabs from '../../components/tabs/Tabs';
import {
  MaterialsTable,
  GroupTests,
  GamesTable,
  Checkbox,
} from '../../components';
import './style.css';
import { NavLink } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import {
  getStudentsFormattedName,
  getTeachersFormattedName,
  prettyLangLevel,
} from '../../core/functions';
import NoDataError from '../../components/noDataError/NoDataError';
import Select from '../../components/select/index';
import moment from 'moment';
import EventsTable from '../../components/eventsTable/index';
import GeneralTestsTable from '../../components/generalTestsTable/index';
import Button from '../../components/button/index';
import DialogModal from '../../components/dialogModal/DialogModal';
import { languageLevels } from '../../core/config';

const mainTabs = [
  { name: 'Материалы', id: 1 },
  { name: 'Тесты', id: 2 },
  { name: 'Placement tests', id: 3 },
  { name: 'Игры', id: 4 },
  { name: 'Занятия', id: 5 },
];

const gameTabs = [
  { name: 'Flash cards', id: 1 },
  { name: 'Match Up', id: 2 },
  { name: 'Anagrams', id: 3 },
  { name: 'Categorize', id: 4 },
];

const View = props => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;
    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.handleContainerOnBottom();
    }
  };

  const renderGames = (tab, props) => {
    let data;
    let last;
    let loading;
    let noDataText;
    switch (tab) {
      case 1:
        data = props.flashcards;
        last = props.paginationStatus.games.flashcards.last;
        loading = props.flashcardsLoading;
        noDataText = 'В этом персональном занятии нет Flashcards';
        break;
      case 2:
        data = props.matchup;
        last = props.paginationStatus.games.matchup.last;
        loading = props.matchupLoading;
        noDataText = 'В этом персональном занятии нет Match Up';
        break;
      case 3:
        data = props.anagrams;
        last = props.paginationStatus.games.anagrams.last;
        loading = props.anagramsLoading;
        noDataText = 'В этом персональном занятии нет Anagrams';
        break;
      case 4:
        data = props.categorize;
        last = props.paginationStatus.games.categorize.last;
        loading = props.categorizeLoading;
        noDataText = 'В этом персональном занятии нет Categorize';
        break;
      default:
        return;
    }
    return (
      <GamesTable
        renderEmpty={() => {
          return <NoDataError text={noDataText} />;
        }}
        renderControl={el => {
          return (
            <Checkbox
              value={!el.active}
              onClick={() => {
                props.handleGame(el.has, el.index, !el.active);
              }}
            />
          );
        }}
        tabId={tab}
        last={last}
        paginate={e => handleDataOnBottom(e)}
        loading={loading}
        handleGame={props.handleGameAdding}
        shareGames={() => props.setShowDeleteModal(true)}
        data={data}
      />
    );
  };

  const renderGroupTab = (tab, props) => {
    switch (tab) {
      case 1:
      default:
        if (props.personalMaterialsLoading) {
          return <Loader />;
        }
        return (
          <MaterialsTable
            paginate={e => handleDataOnBottom(e)}
            last={props.paginationStatus.materials.last}
            renderEmpty={() => {
              return (
                <NoDataError text="В этом персональном занятии еще нет материалов" />
              );
            }}
            renderControl={el => {
              return (
                <Checkbox
                  value={!el.active}
                  onClick={() => {
                    props.handleMaterial(el.has, el.index, !el.active);
                  }}
                />
              );
            }}
            loading={props.materialsLoading}
            data={props.materials}
            shareMaterials={() => props.setShowDeleteModal(true)}
            handleMaterial={props.handleMaterial}
          />
        );
      case 2:
        return (
          <GroupTests
            paginate={e => handleDataOnBottom(e)}
            renderControl={el => {
              return (
                <Checkbox
                  value={!el.active}
                  onClick={() => {
                    props.handleTest(el.has, el.index, !el.active);
                  }}
                />
              );
            }}
            last={props.paginationStatus.tests.last}
            renderEmpty={() => {
              return (
                <NoDataError text="В этом персональном занятии еще нет тестов" />
              );
            }}
            loading={props.testsLoading}
            data={props.tests}
            shareTests={() => props.setShowDeleteModal(true)}
            handleTest={props.handleTest}
          />
        );
      case 3:
        return (
          <GeneralTestsTable
            renderControl={el => {
              return (
                <Checkbox
                  value={!el.testObj.active}
                  onClick={() => {
                    props.handleGeneralTests(
                      el.has,
                      el.index,
                      !el.testObj.active,
                    );
                  }}
                />
              );
            }}
            last={props.paginationStatus.generalTests.last}
            renderEmpty={() => {
              return (
                <NoDataError text="В этом персональном занятии еще нет общих тестов" />
              );
            }}
            paginate={e => handleDataOnBottom(e)}
            loading={props.generalTestsLoading}
            data={props.generalTests}
            shareTests={() => props.setShowDeleteModal(true)}
            handleTest={props.handleTest}
          />
        );
      case 4:
        return (
          <>
            <Tabs
              tabId={props.gamesTabId}
              tabs={gameTabs}
              handleChangeGroup={props.setGamesTabId}
            />
            {renderGames(props.gamesTabId, props)}
          </>
        );
      case 5:
        return (
          <EventsTable
            paginate={e => handleDataOnBottom(e)}
            type={'class'}
            renderEmpty={() => {
              return (
                <NoDataError text={'Нет доступных для просмотра занятий'} />
              );
            }}
            last={props.paginationStatus.events.last}
            loading={props.eventsLoading}
            data={props.events}
          />
        );
    }
  };

  const getModalData = () => {
    switch (props.tabId) {
      case 1:
        return {
          title: 'Удаление материалов',
          text: 'Вы действительно хотите удалить материалы из группы?',
        };
      case 2:
        return {
          title: 'Удаление тестов',
          text: 'Вы действительно хотите удалить тесты из группы?',
        };
      case 3:
        return {
          title: 'Удаление тестов',
          text: 'Вы действительно хотите удалить тесты из группы?',
        };
      case 4:
        return {
          title: 'Удаление набора игр',
          text: 'Вы действительно хотите удалить наборы для игры из группы?',
        };
    }
  };

  const modalData = getModalData();

  return (
    <div className="group-page">
      <DialogModal
        title={modalData?.title}
        show={props.showDeleteModal}
        setShow={props.setShowDeleteModal}
        loading={props.deleteLoading}
        onDelete={props.delete}>
        <span className="add-lesson-modal-item-last">{modalData?.text}</span>
      </DialogModal>
      {props.data.classId ? (
        <>
          <div className="group-page__header">
            <div className="group-page__info class">
              <NavLink
                className="text link"
                to={`/teachers/${props.data.teacherId}`}>{`Преподаватель: ${getTeachersFormattedName(
                props.data.teacherId,
                props.teachers,
              )}`}</NavLink>
              <NavLink
                className="text link"
                to={`/users/${props.data.studentId}`}>{`Ученик: ${getStudentsFormattedName(
                props.data.studentId,
                props.students,
              )}`}</NavLink>
              <div className="text">
                Дата начала занятий:{' '}
                {moment(props.data.createdTime).format('DD MMMM YYYY г.')}
              </div>
              <div className="inline-select">
                <div className="lang-level-label">Уровень языка: </div>
                <Select
                  // containerClass="add-lesson-modal-item"
                  direction="bottom"
                  value={props.langLevel ? props.langLevel : ''}
                  noReset={props.langLevel === props.data.level}
                  width={'275px'}
                  data={languageLevels}
                  onReset={() => props.setLangLevel(props.data.level)}
                  placeholder="Выберите языковой уровень"
                  renderOption={el => {
                    return (
                      <div
                        className="option"
                        onMouseDown={() => {
                          props.setLangLevel(el);
                        }}>
                        {el}
                      </div>
                    );
                  }}
                />
                <Button
                  type="primary"
                  small
                  className="lang-level-button"
                  loading={props.updateLanguageLoading}
                  disabled={props.langLevel === props.data.level}
                  textButton="Изменить уровень"
                  onClick={() => props.updateLanguageLevel()}
                />
              </div>
            </div>
          </div>
          <Tabs
            tabId={props.tabId}
            tabs={mainTabs}
            handleChangeGroup={props.setTabId}
          />
          <div className="personal-class-tab-container">
            {renderGroupTab(props.tabId, props)}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default View;
