import React from 'react';

import './style.css';
import Button from '../../button';
import QuestionInput from './question-input';

const renderAnswers = (
  answers,
  questionId,
  handleAnswerTextChange,
  handleAnswerRightChange,
  handleDeleteAnswer,
  disabled,
  selectionMap,
) =>
  answers.map((item, index) => (
    <QuestionInput
      title={`Ответ ${index + 1}`}
      placeholder="Введите вариант ответа..."
      value={answers[index].answer}
      checked={answers[index].right}
      maxlength={256}
      inputSelectionControl={selectionMap?.[index]?.inputSelectionControl}
      checkboxSelectionControl={selectionMap?.[index]?.checkboxSelectionControl}
      onChange={e => {
        handleAnswerTextChange(e, questionId, index);
      }}
      onDelete={() => handleDeleteAnswer(questionId, index)}
      onRightChange={value => {
        handleAnswerRightChange(value, questionId, index);
      }}
      disabled={disabled}
    />
  ));

const AddQuestion = ({
  id = '',
  removeHandle = () => {},
  handleQuestionTextChange = () => {},
  question = {},
  answers = [],
  handleAddAnswer = () => {},
  handleAnswerTextChange = () => {},
  handleAnswerRightChange = () => {},
  handleDeleteAnswer,
  disabled = false,
  selectionMap,
}) => {
  return (
    <div className="add-question-container">
      <div className="add-question-first-container">
        <div className="add-question-id">{id + 1}</div>
        <Button
          small
          type="secondary"
          textButton="-"
          onClick={() => {
            removeHandle(id);
          }}
          disabled={disabled}
        />
      </div>
      <div className="add-question-second-container">
        <div className="add-question-title-container">
          <QuestionInput
            title="Вопрос"
            value={question.question}
            withoutCheckbox
            maxlength={256}
            inputSelectionControl={selectionMap?.questionSelectionControl}
            placeholder="Введите текст вопроса"
            onChange={e => {
              handleQuestionTextChange(e, id);
            }}
            disabled={disabled}
          />
        </div>
        <div className="add-question-answers-container">
          {renderAnswers(
            answers,
            id,
            handleAnswerTextChange,
            handleAnswerRightChange,
            handleDeleteAnswer,
            disabled,
            selectionMap?.answers,
          )}
          <div className="add-question-answers-add-container">
            <Button
              type="info"
              small
              textButton="Добавить вариант ответа"
              onClick={() => {
                handleAddAnswer(id);
              }}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestion;
