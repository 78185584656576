import React from 'react';
import Icon from '../icon/Icon';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import './style.css';

const Checkbox = ({
  value = false,
  onClick = () => {},
  disabled = false,
  width = 16,
  containerClassName = '',
  height = 16,
  customSelectionControl,
  selectionColor,
}) => {
  return (
    <SelectableContainer
      customSelectionControl={customSelectionControl}
      selectionColor={selectionColor}
      disabled={disabled}
      className={`${containerClassName} checkbox`}>
      <input
        onClick={disabled ? null : onClick}
        type="checkbox"
        checked={value}
        disabled={disabled}
      />
      <div className="mark-container">
        <Icon
          name="success"
          width={width}
          height={height}
          color={disabled ? 'gray' : 'red'}
        />
      </div>
    </SelectableContainer>
  );
};

export default Checkbox;
