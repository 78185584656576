import React from 'react';
import './style.css';
import Calendar from '../../components/calendar/index';
import CalendarTable from '../../components/calendarTable/index';
import { Modal } from '../../components';
import Select from '../../components/select';
import {
  getTeachersFormattedName,
  getGroupsFormattedName,
  getStudentsFormattedName,
  getGroupTeacherId,
} from '../../core/functions';
import SelectableContainer from '../../components/selectableContainer/SelectableContainer';
import moment from 'moment';
import Button from '../../components/button';
import DateTimePicker from '../../components/dateTimePicker/DateTimePicker';
import { isEqual } from 'lodash';

const CalendarView = props => {
  const reqDisabled = !(
    ((props.targetEvent?.teacherId && !props.targetEvent?.groupId) ||
      props.targetEvent?.groupId) &&
    props.targetEvent?.duration &&
    props.targetEvent?.date
  );
  const renderModalContent = type => {
    switch (type) {
      case 'delete':
        return (
          <div className="delete-lesson-modal">
            <span className="add-lesson-modal-item-last">
              Вы действительно хотите удалить занятие{' '}
              <strong>
                {`${getTeachersFormattedName(
                  props.targetEvent.teacherId
                    ? props.targetEvent.teacherId
                    : getGroupTeacherId(
                        props.targetEvent.groupId,
                        props.groups,
                      ),
                  props.teachers,
                )}`}
                {', '}
                {`${moment(props.targetEvent.date).format(
                  'DD MMMM, dddd',
                )}`}{' '}
                {`${moment(props.targetEvent.date).format('HH:mm')}`}
                {' - '}
                {`${moment(props.targetEvent.date)
                  .add(props.targetEvent.duration, 'm')
                  .format('HH:mm')}`}
                {', '}
                {`${
                  props.targetEvent?.groupId
                    ? getGroupsFormattedName(
                        props.targetEvent.groupId,
                        props.groups,
                      )
                    : props.targetEvent?.studentId
                    ? getStudentsFormattedName(
                        props.targetEvent.studentId,
                        props.students,
                      )
                    : 'Индивидуальное занятие'
                }`}
              </strong>
              ?
            </span>
            <div className="delete-modal-controls-wrapper">
              <Button
                type="text"
                small
                className="delete-modal-go-back-button"
                textButton="Назад"
                onClick={() => props.setShowModal(false)}
              />
              <Button
                loading={props.deleteLoading}
                type="primary"
                small
                textButton="Удалить"
                onClick={props.deleteEvent}
              />
            </div>
          </div>
        );
      case 'new':
        return (
          <>
            {props.isAdmin ? (
              <Select
                containerClass="add-lesson-modal-item"
                direction="bottom"
                value={
                  props.targetEvent?.teacherId
                    ? getTeachersFormattedName(
                        props.targetEvent.teacherId,
                        props.teachers,
                      )
                    : ''
                }
                disabled={!props.isAdmin}
                data={props.teachers}
                search={true}
                onReset={() =>
                  props.setTargetEvent({
                    ...props.targetEvent,
                    teacherId: null,
                    groupId: '',
                  })
                }
                label="Учитель"
                placeholder="Выберите учителя"
                renderOption={el => {
                  return (
                    <div
                      className="option"
                      onMouseDown={() => {
                        props.setTargetEventTeacher(el);
                      }}>
                      {getTeachersFormattedName(el.teacherId, props.teachers)}
                    </div>
                  );
                }}
                extractSearchKey={el => {
                  return getTeachersFormattedName(el.teacherId, props.teachers);
                }}
              />
            ) : null}
            <Select
              containerClass="add-lesson-modal-item"
              direction="bottom"
              value={
                props.targetEvent?.groupId
                  ? `${getGroupsFormattedName(
                      props.targetEvent.groupId,
                      props.groups,
                    )} (Учитель: ${getTeachersFormattedName(
                      props.targetEvent.teacherId,
                      props.teachers,
                    )})`
                  : 'Индивидуальное занятие'
              }
              label="Группа"
              noReset={true}
              data={
                props.targetEvent?.teacherId
                  ? props.groups.filter(
                      el => el.teacherId === props.targetEvent.teacherId,
                    )
                  : props.groups
              }
              search={true}
              onReset={() =>
                props.setTargetEvent({ ...props.targetEvent, groupId: '' })
              }
              placeholder="Выберите группу"
              renderBefore={el => {
                return (
                  <div
                    className="option"
                    onMouseDown={() => {
                      props.setTargetEvent({
                        ...props.targetEvent,
                        groupId: '',
                      });
                    }}>
                    Индивидуальное занятие
                  </div>
                );
              }}
              renderOption={el => {
                return (
                  <div
                    className="option"
                    onMouseDown={() => {
                      props.setTargetEventGroup(el);
                    }}>
                    {`${getGroupsFormattedName(
                      el.groupId,
                      props.groups,
                    )} (Учитель: ${getTeachersFormattedName(
                      el.teacherId,
                      props.teachers,
                    )})`}
                  </div>
                );
              }}
              extractSearchKey={el => {
                return getGroupsFormattedName(el.groupId, props.groups);
              }}
            />
            <Select
              label="Длительность"
              containerClass="add-lesson-modal-item"
              direction="bottom"
              value={
                props.targetEvent?.duration
                  ? props.targetEvent?.duration + ' мин'
                  : ''
              }
              data={['60', '80']}
              search={false}
              onReset={() =>
                props.setTargetEvent({ ...props.targetEvent, duration: null })
              }
              placeholder="Выберите длительность"
              renderOption={el => {
                return (
                  <div
                    className="option"
                    onMouseDown={() => {
                      props.setTargetEvent({
                        ...props.targetEvent,
                        duration: el,
                      });
                    }}>
                    {el + ' мин'}
                  </div>
                );
              }}
            />
            <DateTimePicker
              className="add-lesson-modal-item-last"
              title="Дата начала занятия"
              placeholder="Введите дату начала"
              minDate={moment(new Date()).toISOString()}
              format={'DD MMMM, dd HH:mm'}
              variant="inline"
              wrongTime={props.wrongTime}
              setWrongTime={props.setWrongTime}
              inputProps={{ className: 'date-input' }}
              value={
                props.targetEvent?.date
                  ? moment(props.targetEvent?.date).toISOString()
                  : null
              }
              onChange={date => {
                props.setTargetEvent({
                  ...props.targetEvent,
                  date: date.toISOString(),
                });
              }}
            />
            <Button
              loading={props.createLoading}
              type="primary"
              small
              disabled={reqDisabled || props.wrongTime}
              textButton="Создать"
              className="add-lesson-modal-item"
              onClick={() => {
                props.createEvent();
              }}
            />
            <Button
              type="text"
              small
              textButton="Назад"
              onClick={() => props.setShowModal(false)}
            />
          </>
        );
      case 'change':
        const getStudentValue = () => {
          return props.targetEvent?.type === 'group'
            ? getGroupsFormattedName(props.targetEvent.groupId, props.groups)
            : props.targetEvent?.studentId
            ? getStudentsFormattedName(
                props.targetEvent.studentId,
                props.students,
              )
            : 'Свободно';
        };
        const isPast = moment(new Date()).isAfter(props.targetEvent.date);
        const datePickDisabled = moment(new Date())
          .add(1, 'd')
          .isAfter(props.eventOnChange.date);
        const isFree =
          props.targetEvent.type === 'personal-class' &&
          !props.targetEvent?.studentId === true;
        const isGroup = props.targetEvent.type === 'group';
        return (
          <>
            <Select
              label="Учитель"
              disabled={true}
              containerClass="add-lesson-modal-item"
              direction="bottom"
              value={getTeachersFormattedName(
                props.targetEvent.teacherId
                  ? props.targetEvent.teacherId
                  : getGroupTeacherId(props.targetEvent.groupId, props.groups),
                props.teachers,
              )}
            />
            <Select
              label="Студент(ы)"
              disabled={true}
              containerClass="add-lesson-modal-item"
              direction="bottom"
              value={getStudentValue()}
            />
            <DateTimePicker
              className="add-lesson-modal-item-last"
              disabled={datePickDisabled}
              placeholder="Введите дату начала"
              minDate={
                !isPast && !datePickDisabled
                  ? moment(new Date()).add(1, 'd').toISOString()
                  : null
              }
              title="Дата начала занятия"
              format={'DD MMMM, dd HH:mm'}
              variant="inline"
              inputProps={{ className: 'date-input' }}
              value={
                props.targetEvent?.date
                  ? moment(props.targetEvent?.date).toISOString()
                  : null
              }
              onChange={date => {
                props.setTargetEvent({
                  ...props.targetEvent,
                  date: date.toISOString(),
                });
              }}
            />
            {!isPast ? (
              <div className="add-lesson-modal-item">
                <Button
                  type="primary"
                  disabled={
                    reqDisabled ||
                    isEqual(props.eventOnChange, props.targetEvent)
                  }
                  small
                  loading={props.updateLoading}
                  onClick={props.updateEvent}
                  textButton="Обновить"
                />
              </div>
            ) : null}
            <Button
              type="text"
              small
              textButton="Назад"
              onClick={() => props.setShowModal(false)}
            />
          </>
        );
      default:
        return null;
    }
  };

  const getModalTitle = type => {
    switch (type) {
      case 'new':
        return 'Новое занятие';
      case 'change':
        return 'Просмотр';
      case 'delete':
        return 'Удаление';
      default:
        return 'Error';
    }
  };
  return (
    <div className="calendar-page-container">
      <Modal
        title={getModalTitle(props.modalType)}
        show={props.showModal}
        setShow={props.setShowModal}>
        {renderModalContent(props.modalType)}
      </Modal>
      <Calendar
        onMonthChange={props.onMonthChange}
        onSelect={props.onSelect}
        data={props.data}
      />
      <CalendarTable
        isAdmin={props.isAdmin}
        onPressDelete={event => props.onPressDelete(event)}
        onPressChange={event => props.onPressChange(event)}
        onPressNew={props.onPressNew}
        selectedTeacher={props.selectedTeacher}
        setSelectedTeacher={props.setSelectedTeacher}
        data={props.filteredData}
        displayLoading={props.isLoading}
        isLast={props.isLast}
      />
    </div>
  );
};

export default CalendarView;
