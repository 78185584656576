import React from 'react';
import './style.css';
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import '../../../node_modules/draft-js/dist/Draft.css';
import { useRef } from 'react';
import { EditorContainer, Root, Controls, Button } from './EditorStyled';
import { useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];
const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Monospace', style: 'CODE' },
];

const StyleButton = ({ onToggle, style, label, active }) => {
  const handleToggle = e => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <Button active={active} onMouseDown={handleToggle}>
      {label}
    </Button>
  );
};
const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <Controls>
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </Controls>
  );
};
const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <Controls>
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </Controls>
  );
};
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: 'inherit',
    padding: 2,
  },
};

const getHtmlFromMarkup = markup => {
  const blocksFromHTML = convertFromHTML(markup);
  const state = ContentState?.createFromBlockArray(
    blocksFromHTML?.contentBlocks,
    blocksFromHTML?.entityMap,
  );
  return state
    ? EditorState.createWithContent(state)
    : EditorState.createEmpty();
};

const getMarkupFromHtml = editorState => {
  const editorContent = convertToRaw(editorState.getCurrentContent());
  const markup = Object.values(editorContent.entityMap).find(
    ({ type }) => type === 'LINK',
  )
    ? draftToHtml(
        convertToRaw(
          EditorState.createWithContent(
            convertFromRaw({ ...editorContent, entityMap: {} }),
          ).getCurrentContent(),
        ),
      )
    : draftToHtml(editorContent);
  return markup;
};

const EditorView = props => {
  const [editorState, setEditorState] = React.useState(
    getHtmlFromMarkup(props.value),
  );

  useEffect(() => {
    const markupContent = getMarkupFromHtml(editorState);
    if (props.value !== markupContent) {
      const htmlContent = getHtmlFromMarkup(props?.value);
      setEditorState(htmlContent);
    }
  }, [props.value]);

  useEffect(() => {
    props.onChange(getMarkupFromHtml(editorState));
  }, [editorState]);

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };
  const toggleBlockType = blockType => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  const toggleInlineStyle = inlineStyle => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const editor = useRef();

  return (
    <Root>
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <EditorContainer onClick={() => editor.current.focus()}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          customStyleMap={styleMap}
          placeholder="Текст..."
          readOnly={props.disabled}
        />
      </EditorContainer>
    </Root>
  );
};

export default EditorView;
