import React from 'react';

import Tabs from '../../components/tabs/Tabs';
import { GamesTable, GroupTests } from '../../components';
import SuccessTestTable from '../../components/successTestTable/index';
import GeneralTestTable from '../../components/generalTestsTable/index';
import PaymentsTable from '../../components/PaymentsTable/index';
import TransfersTable from '../../components/transfersTable/index';
import EventsTable from '../../components/eventsTable/index';
import './style.css';
import Button from '../../components/button';
import {
  getGroupsFormattedName,
  getTeachersFormattedName,
} from '../../core/functions';
import GroupsTableContainer from '../../components/groupsTable';
import Loader from '../../components/loader/Loader';
import GameResultTable from '../../components/gameResultsTable/index';

import { ListContainer } from '../../components';
import Select from '../../components/select';
import Icon from '../../components/icon/Icon';
import moment from 'moment';
import NoDataError from '../../components/noDataError/NoDataError';
import { NavLink } from 'react-router-dom';
import tests from '../../services/tests';

const renderGames = () => {};

const View = props => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;

    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.fetchData();
    }
  };

  const groupTabs = [
    { name: 'Тесты', id: 1 },
    { name: 'Игры', id: 2 },
    { name: 'Занятия', id: 3 },
    props.isAdmin ? { name: 'Платежи', id: 4 } : null,
  ].filter(el => el);

  const classTabs = [
    { name: 'Тесты', id: 1 },
    { name: 'Placement tests', id: 6 },
    { name: 'Игры', id: 2 },
    { name: 'Занятия', id: 3 },
    { name: 'Перенесенные занятия', id: 5 },
  ];

  const gameTabs = [
    { name: 'Flash cards', id: 1 },
    { name: 'Match Up', id: 2 },
    { name: 'Anagrams', id: 3 },
    { name: 'Categorize', id: 4 },
  ];

  const renderGames = (tab, props) => {
    let data;
    let type;
    let last;
    let loading;
    let noDataText;
    switch (tab) {
      case 1:
        type = 'flashCards';
        data = props.flashcards;
        last = props.paginationStatus.flashcards.last;
        loading = props.flashcardsLoading;
        noDataText = 'Нет результатов Flashcards';
        break;
      case 2:
        type = 'matchup';
        data = props.matchup;
        last = props.paginationStatus.matchup.last;
        loading = props.matchupLoading;
        noDataText = 'Нет результатов Match Up';
        break;
      case 3:
        type = 'anagrams';
        data = props.anagrams;
        last = props.paginationStatus.anagrams.last;
        loading = props.anagramsLoading;
        noDataText = 'Нет результатов Anagrams';
        break;
      case 4:
        type = 'categorize';
        data = props.categorize;
        last = props.paginationStatus.categorize.last;
        loading = props.categorizeLoading;
        noDataText = 'Нет результатов Categorize';
        break;
      default:
        return;
    }
    return (
      <GameResultTable
        renderEmpty={() => {
          return <NoDataError text={noDataText} />;
        }}
        renderControl={el => {
          return null;
        }}
        type={type}
        tabId={tab}
        last={last}
        loading={loading}
        handleGame={props.handleGame}
        shareGames={props.shareGames}
        data={data}
      />
    );
  };

  const renderUserTab = tab => {
    switch (tab) {
      default:
      case 1:
        return (
          <SuccessTestTable
            renderEmpty={() => {
              return <NoDataError text={'Студент еще не завершил тестов'} />;
            }}
            renderControl={el => {
              return (
                <NavLink
                  to={{
                    pathname: `/results/${el.successTestId}`,
                    state: {
                      tests: props.tests,
                    },
                  }}>
                  <Button
                    type="secondary"
                    small
                    textButton="Просмотр результатов"
                  />
                </NavLink>
              );
            }}
            paginate={handleDataOnBottom}
            last={props.paginationStatus.tests.last}
            loading={props.testsLoading}
            data={props.tests}
            shareTests={props.shareTests}
            handleTest={props.handleTest}
          />
        );
      case 2:
        return (
          <>
            <Tabs
              tabId={props.gamesTabId}
              tabs={gameTabs}
              handleChangeGroup={props.selectGamesTabId}
            />
            {renderGames(props.gamesTabId, props)}
          </>
        );
      case 3:
        return (
          <EventsTable
            type={props.selectedOrganizer.groupId ? 'group' : 'class'}
            renderEmpty={() => {
              return (
                <NoDataError text={'Нет доступных для просмотра занятий'} />
              );
            }}
            paginate={handleDataOnBottom}
            last={props.paginationStatus.events.last}
            loading={props.eventsLoading}
            data={props.events}
          />
        );
      case 4:
        return (
          <PaymentsTable
            renderEmpty={() => {
              return (
                <NoDataError text={'Нет доступных для просмотра занятий'} />
              );
            }}
            paginate={handleDataOnBottom}
            last={props.paginationStatus.payments.last}
            loading={props.paymentsLoading}
            data={props.payments}
            type={'teacher'}
          />
        );
      case 5:
        return (
          <TransfersTable
            renderEmpty={() => {
              return (
                <NoDataError text={'Нет доступных для просмотра занятий'} />
              );
            }}
            paginate={handleDataOnBottom}
            last={props.paginationStatus.transferedEvents.last}
            loading={props.transferedEventsLoading}
            data={props.transferedEvents}
          />
        );
      case 6:
        return (
          <GeneralTestTable
            type="teacher"
            renderEmpty={() => {
              return (
                <NoDataError text={'Студент еще не завершил общих тестов'} />
              );
            }}
            renderControl={el => {
              return el.testObj?.timePassed ? (
                <NavLink
                  to={{
                    pathname: `/general-result/${el.id}`,
                    testAttempt: el.testObj,
                  }}>
                  <Button
                    type="secondary"
                    small
                    textButton="Просмотр результатов"
                  />
                </NavLink>
              ) : null;
            }}
            paginate={handleDataOnBottom}
            last={props.paginationStatus.generalTests.last}
            loading={props.generalTestsLoading}
            data={props.generalTests}
            shareTests={props.shareTests}
            handleTest={props.handleTest}
          />
        );
    }
  };

  const getOrganizerValue = el => {
    if (el) {
      if (props.selectedTeacher) {
        if (el.studentId) {
          return 'Индивидуальное занятие';
        }
      } else {
        if (el.studentId) {
          return `Индивидуальное ${getTeachersFormattedName(
            el.teacherId,
            props.teachers,
          )}`;
        }
      }
      return getGroupsFormattedName(el.groupId, props.organizers);
    }
    return '';
  };
  if (!props.data?.studentId) {
    return <Loader />;
  }

  return (
    <div className="user-page">
      <div className="user-page__header">
        {props.data.avatar ? (
          <div className="teacher-page__avatar">
            <img className="user-avatar" alt="avatar" src={props.data.avatar} />
          </div>
        ) : (
          <div className="teacher-page__no-avatar">
            <div className="user-avatar">
              <Icon name="avatar" width={'100%'} height={'100%'} />
            </div>
          </div>
        )}
        <div className="user-page__info">
          <h2 className="user-page__info-name">{`${props.data.name} ${
            props.data.lastName
          } ${props.data.patronymic ? props.data.patronymic : ''}`}</h2>
          {props.isAdmin && <p>{`Почта: ${props.data.email}`}</p>}
          {props.isAdmin && <p>{`Номер телефона: ${props.data.phone}`}</p>}
          <p>
            {`Присоединился: ${moment(props.data.createdTime).format(
              'DD MMMM YYYY г.',
            )}`}{' '}
          </p>
        </div>
      </div>
      <div className="lesson-organizer-selector">
        {props.isAdmin ? (
          <Select
            direction="bottom"
            containerClass="individual-teacher-select user-teacher-select"
            value={
              props.selectedTeacher
                ? getTeachersFormattedName(
                    props.selectedTeacher,
                    props.teachers,
                  )
                : ''
            }
            data={props.teachers}
            search={true}
            disabled={!props.isAdmin}
            onReset={() => props.setSelectedTeacher()}
            placeholder={'Выберите учителя'}
            renderOption={el => {
              return (
                <div
                  className="option"
                  onMouseDown={() => {
                    props.setSelectedTeacher(el.teacherId);
                  }}>
                  {getTeachersFormattedName(el.teacherId, props.teachers)}
                </div>
              );
            }}
            extractSearchKey={el =>
              getTeachersFormattedName(el.teacherId, props.teachers)
            }
          />
        ) : null}
        <Select
          direction="bottom"
          containerClass="individual-teacher-select user-teacher-select"
          value={getOrganizerValue(props.selectedOrganizer)}
          data={props.organizers}
          search={true}
          width={'300px'}
          renderEmpty={() => (
            <div className="option option-empty">
              Нет доступных организаторов
            </div>
          )}
          onReset={() => props.setSelectedOrganizer(null)}
          placeholder="Выберите организатора занятий"
          renderOption={el => {
            return (
              <div
                className="option"
                onMouseDown={() => {
                  props.setSelectedOrganizer(el);
                }}>
                {getOrganizerValue(el)}
              </div>
            );
          }}
          extractSearchKey={el => getOrganizerValue(el)}
        />
      </div>
      {props.selectedOrganizer ? (
        <div className="user-page-content-container">
          <Tabs
            tabId={props?.tabId}
            tabs={props.selectedOrganizer.groupId ? groupTabs : classTabs}
            handleChangeGroup={props.selectTabId}
          />
          {renderUserTab(props.tabId, props)}
        </div>
      ) : null}
    </div>
  );
};

export default View;
