const SET_USER_DATA = 'SET_USER_DATA';
const LOGOUT = 'LOGOUT';

const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...action.userData,
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export default userReducer;

export const setUserData = userData => {
  return dispatch => {
    dispatch({ type: SET_USER_DATA, userData });
  };
};

export const logOut = () => {
  return dispatch => {
    dispatch({ type: LOGOUT });
  };
};
