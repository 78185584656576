import { createEvent } from '@testing-library/dom';
import { apiGet, apiPost, apiPatch, apiDelete, apiPut } from '../api';

export default {
  async getEvents(fromDate, toDate, teacherId) {
    try {
      const result = await apiGet('/calendar/events', {
        params: { fromDate, toDate, teacherId },
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteEvent(eventId) {
    try {
      const result = await apiDelete('/calendar/event', { eventId });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateEvent(eventId, event) {
    try {
      const result = await apiPut('/calendar/event', { eventId, data: event });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createGroupEvent(date, groupId, duration) {
    try {
      const result = await apiPost('/calendar/event/group', {
        date,
        groupId,
        duration,
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createClassEvent(date, teacherId, duration, price) {
    try {
      const result = await apiPost('/calendar/event/class', {
        date,
        teacherId,
        duration,
        price,
      });
      return result.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
