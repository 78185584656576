import { store } from '../store';
import {
  GamesService,
  GroupsService,
  PersonalsService,
  TeachersService,
  UsersService,
  MaterialsService,
  TestService,
  NotificationsService,
} from '../services';
import { setGroups } from '../store/reducers/groups-reducer';
import { setStudents } from '../store/reducers/students-reducer';
import { setTeachers } from '../store/reducers/teachers-reducer';
import { setPersonals } from '../store/reducers/personals-reducer';
import { setAnagrams } from '../store/reducers/anagrams-reducer';
import { setFlashCards } from '../store/reducers/flashCards-reducer';
import { showErrorNotification } from './errorsController';
import { setMatchup } from '../store/reducers/matchup-reducer';
import { setCategorize } from '../store/reducers/categorize-reducer';
import { setMaterials } from '../store/reducers/materials-reducer';
import { setTests } from '../store/reducers/tests-reducer';
import { setUserData } from '../store/reducers/user-reducer';
import { concatToEnd, concatToStart, incrementNotificationsPage } from '../store/reducers/notifications-reducer';

const dispatch = store.dispatch;

export default class StoreController {
  static init(teacherId, role) {
    const arr = [
      this.getGroups(role === 'teacher' ? teacherId : null),
      this.getPersonals(role === 'teacher' ? teacherId : null),
      this.getUserData(teacherId),
      this.getTeachers(),
      this.getStudents(),
      this.getUnreadNotifications(),
      this.getFirstPageReadNotifications(),
    ];
    return arr;
  }

  static async getUserData(teacherId, n = 0) {
    if (n < 5) {
      try {
        const userData = await TeachersService.getUserData(teacherId);
        dispatch(setUserData(userData));
        return userData;
      } catch (error) {
        return this.getUserData(teacherId, n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при обновлении информации пользователя. Попробуйте позже.',
      );
    }
  }

  static async getUnreadNotifications(teacherId, n = 0) {
    if (n < 5) {
      try {
        const response = await NotificationsService.getUnreadNotifications();
        dispatch(concatToStart(response));
        return response;
      } catch (error) {
        return this.getUnreadNotifications(teacherId, n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при обновлении информации пользователя. Попробуйте позже.',
      );
    }
  }

  static async getFirstPageReadNotifications(teacherId, n = 0) {
    if (n < 5) {
      try {
        const response = await NotificationsService.getNotifications(1);
        dispatch(incrementNotificationsPage());
        dispatch(concatToEnd(response.data));
        return response;
      } catch (error) {
        return this.getFirstPageReadNotifications(teacherId, n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при обновлении информации пользователя. Попробуйте позже.',
      );
    }
  }

  static async getTeachers(n = 0) {
    if (n < 5) {
      try {
        const response = await TeachersService.getTeachers();
        dispatch(setTeachers(response.data));
        return response;
      } catch (error) {
        return this.getTeachers(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении учителей. Попробуйте позже.',
      );
    }
  }

  static async getGroups(teacherId, n = 0) {
    if (n < 5) {
      try {
        const response = await GroupsService.getAllGroups();
        dispatch(
          setGroups(
            teacherId
              ? response.data.filter(el => el.teacherId === teacherId)
              : response.data,
          ),
        );
        return response;
      } catch {
        return this.getGroups(teacherId, n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении групп. Попробуйте позже.',
      );
    }
  }

  static async getStudents(n = 0) {
    if (n < 5) {
      try {
        const response = await UsersService.getUsers();
        dispatch(setStudents(response.data));
        return response;
      } catch {
        return this.getStudents(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении студентов. Попробуйте позже.',
      );
    }
  }

  static async getPersonals(teacherId, n = 0) {
    if (n < 5) {
      try {
        const response = await PersonalsService.getAllPersonals();
        dispatch(
          setPersonals(
            teacherId
              ? response.data.filter(el => el.teacherId === teacherId)
              : response.data,
          ),
        );
        return response;
      } catch {
        return this.getClasses(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении персональных занятий. Попробуйте позже.',
      );
    }
  }

  static async getAnagrams(n = 0) {
    if (n < 5) {
      try {
        const response = await GamesService.getAnagrams();
        dispatch(setAnagrams(response.data));
        return response;
      } catch {
        return this.getAnagrams(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении анаграмм. Попробуйте позже.',
      );
    }
  }

  static async getFlashCards(n = 0) {
    if (n < 5) {
      try {
        const response = await GamesService.getFlashCards();
        dispatch(setFlashCards(response.data));
        return response;
      } catch {
        return this.getFlashCards(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении флэш кард. Попробуйте позже.',
      );
    }
  }

  static async getMatchups(n = 0) {
    if (n < 5) {
      try {
        const response = await GamesService.getMatchups();
        dispatch(setMatchup(response.data));
        return response;
      } catch {
        return this.getMatchups(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении матчапс. Попробуйте позже.',
      );
    }
  }

  static async getCategorize(n = 0) {
    if (n < 5) {
      try {
        const response = await GamesService.getCategorizes();
        dispatch(setCategorize(response.data));
        return response;
      } catch {
        return this.getCategorize(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении категорайз. Попробуйте позже.',
      );
    }
  }

  static async getMaterials(n = 0) {
    if (n < 5) {
      try {
        const response = await MaterialsService.getMaterials();
        dispatch(setMaterials(response.data));
        return response;
      } catch {
        return this.getMaterials(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении материалов. Попробуйте позже.',
      );
    }
  }

  static async getTests(n = 0) {
    if (n < 5) {
      try {
        const response = await TestService.getTests();
        dispatch(setTests(response.data));
        return response;
      } catch {
        return this.getTests(n + 1);
      }
    } else {
      showErrorNotification(
        'Возникла проблема при получении материалов. Попробуйте позже.',
      );
    }
  }
}
