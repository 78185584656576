import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorNotification } from '../../core/errorsController';
import { NotificationsService } from '../../services';
import {
  clearNotifications,
  concatToEnd,
  incrementNotificationsPage,
  readAll,
} from '../../store/reducers/notifications-reducer';

import View from './view';

const Container = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const page = useSelector(state => state.notifications.page);
  const notifications = useSelector(state => state.notifications.data);
  const isLoading = useSelector(state => state.appState.isLoading);
  const unseenedNotificationsAmount = notifications.filter(
    el => el.read === false,
  )?.length;
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const getNotifications = async () => {
    if (!isPaginationLoading && !isLast && !isLoading) {
      setIsPaginationLoading(true);
      try {
        const response = await NotificationsService.getNotifications(page);
        dispatch(incrementNotificationsPage());
        setIsLast(response.last);
        dispatch(concatToEnd(response.data));
      } catch (error) {
        showErrorNotification(error.message);
      }
      setIsPaginationLoading(false);
    }
  };

  const _clearNotifications = () => {
    NotificationsService.deleteNotifications('all');
    dispatch(clearNotifications());
    setIsOpen(false);
  };

  const shouldCloseNotifications = e => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const toggleNotifications = value => {
    setIsOpen(value);
    if (value) {
      setTimeout(() => dispatch(readAll()));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', shouldCloseNotifications);
    return () => {
      document.removeEventListener('mousedown', shouldCloseNotifications);
    };
  }, [containerRef]);

  return (
    <View
      clearNotifications={_clearNotifications}
      containerRef={containerRef}
      unseenedNotificationsAmount={unseenedNotificationsAmount}
      notifications={notifications}
      isOpen={isOpen}
      isLoading={isLoading}
      isLast={isLast}
      isPaginationLoading={isPaginationLoading}
      getNotifications={getNotifications}
      setIsOpen={toggleNotifications}
    />
  );
};

export default Container;
