const SET_MATERIALS = 'SET_MATERIALS';

const initialState = null;

const materialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATERIALS:
      return action.materials;
    default:
      return state;
  }
};

export const setMaterials = materials => {
  return dispatch => {
    dispatch({ type: SET_MATERIALS, materials });
  };
};

export default materialsReducer;
