const SET_TESTS = 'SET_TESTS';

const initialState = null;

const testsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTS:
      return action.tests;
    default:
      return state;
  }
};

export const setTests = tests => {
  return dispatch => {
    dispatch({ type: SET_TESTS, tests });
  };
};

export default testsReducer;
