import React from 'react';
import './style.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import moment from 'moment';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick

const today = moment(new Date()).format('YYYY-MM-DD');

const plugins = [dayGridPlugin, interactionPlugin];
const locales = [ruLocale];

const Calendar = props => {
  return (
    <div className="calendar-container">
      <div className="calendar-wrapper">
        <FullCalendar
          buttonText={{
            today: 'Текущий месяц',
          }}
          unselectAuto={false}
          selectable={true}
          longPressDelay={1}
          events={props.events}
          datesSet={props.onMonthChange}
          height={500}
          contentHeight={400}
          locales={locales}
          plugins={plugins}
          select={props.handleSelect}
        />
      </div>
    </div>
  );
};

export default Calendar;
