import React from 'react';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import './style.css';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';
import TestType from '../../components/create-test/test-type';
import AddQuestion from '../../components/create-test/add-question';
import { LabeledCheckbox, List, Modal } from '../../components';
import Select from '../../components/select';
import Icon from '../../components/icon/Icon';
import Textarea from '../../components/textarea';
import {
  getGroupsFormattedName,
  getStudentsFormattedName,
  getTeachersFormattedName,
} from '../../core/functions';
import DialogModal from '../../components/dialogModal/DialogModal';
import FileInput from '../../components/fileInput/FileInput';
import DateTimePicker from '../../components/dateTimePicker/DateTimePicker';
import Input from '../../components/input/index';
import { AVAILABLE_MOBILE_APP_AUDIO_EXTENSIONS } from '../../core/config';

const TasksView = props => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;

    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.getTests();
    }
  };

  const renderTypedQuestions = (props, type, index) => {
    const subtest = props.subtests[index];
    const questions = subtest.questions.filter(el => el);
    const selectionMap = props.selectionMap[index];
    const isLast = index === props.subtests.length - 1;
    const isFirst = index === 0;
    return (
      <div className="new-test-questions-container">
        <div className="new-test-questions-header">
          <div className="new-test-title-container">
            <input
              type="text"
              class="new-test-title"
              placeholder={`Блок вопросов (${index})`}
              onChange={e =>
                props.handleGeneralTestInfoChange(
                  index,
                  'title',
                  e.target.value,
                )
              }
              disabled={true}
              value={subtest.title}
            />
          </div>
          <div className="swap-block-controls">
            <div
              onClick={() => {
                return isFirst || (props.testInfo.id && props.checkDisabling())
                  ? null
                  : props.swapQuestionBlockUp(index);
              }}
              className={`button-icon-wrapper ${
                isFirst || (props.testInfo.id && props.checkDisabling())
                  ? 'button-icon-wrapper-disabled'
                  : ''
              }`}>
              <Icon name="arrow-top" width={32} height={32} />
            </div>
            <div
              onClick={() => {
                return isLast || (props.testInfo.id && props.checkDisabling())
                  ? null
                  : props.swapQuestionBlockDown(index);
              }}
              className={`button-icon-wrapper ${
                isLast || (props.testInfo.id && props.checkDisabling())
                  ? 'button-icon-wrapper-disabled'
                  : ''
              }`}>
              <Icon name="arrow-bottom" width={32} height={32} />
            </div>
          </div>
          <div
            onClick={() => props.confirmDeleteQuestionBlock(index)}
            className="group-info__deleteQuestion">
            <div className={props.renderDeleteQuestionBlockButtonStyle()}>
              <Icon name="minus" width={36} height={36} color={'#ff6f47'} />
            </div>
          </div>
        </div>
        <div className="new-test-type-container">
          <div className="new-test-type-title">Опции</div>
          <div className="new-test-type-list-container-stupid-paddings">
            <Textarea
              containerClassName="double-size"
              type="text"
              title="Описание"
              class="new-teacher-name"
              maxLength={1000}
              placeholder="Введите описание и цель раздела"
              disabled={
                props.editModa || (props.testInfo.id && props.checkDisabling())
              }
              onChange={e =>
                props.handleGeneralTestInfoChange(
                  index,
                  'description',
                  e.target.value,
                )
              }
              value={subtest.description}
            />
            {subtest.type === 'listening' ? (
              <FileInput
                title="Аудирование"
                maxSize={50}
                className={`new-teacher-name ${
                  props.outlineEmptyFiles
                    ? typeof subtest.file !== 'string' &&
                      !Object.keys(subtest.file)?.length
                      ? 'invalid-field'
                      : ''
                    : ''
                }`}
                placeholder="Добавьте аудиозапись"
                disabled={!'listening' === props.testInfo.type}
                extensions={AVAILABLE_MOBILE_APP_AUDIO_EXTENSIONS}
                handleFileDelete={() =>
                  props.handleGeneralFileChange(index, {})
                }
                handleFileSelect={(blob, fileName) =>
                  props.handleGeneralFileChange(index, { blob, fileName })
                }
                fileAddr={props.data?.passportScan}
              />
            ) : null}
            {/* <LabeledCheckbox
              title="Показывать правильные ответы"
              disabled={
                props.editModa || (props.testInfo.id && props.checkDisabling())
              }
              value={subtest.showRight}
              onClick={() => {
                props.handleGeneralTestInfoChange(
                  index,
                  'showRight',
                  !subtest.showRight,
                );
              }}
            /> */}
            <LabeledCheckbox
              title="Множественный выбор"
              disabled={
                props.editModa || (props.testInfo.id && props.checkDisabling())
              }
              value={subtest.multiplie}
              onClick={() => {
                props.handleGeneralTestInfoChange(
                  index,
                  'multiplie',
                  !subtest.multiplie,
                );
              }}
            />
          </div>
        </div>
        {questions.length
          ? questions.map((item, idx) => (
              <AddQuestion
                selectionMap={selectionMap?.[idx]}
                id={idx}
                key={`answer-${idx}`}
                question={item}
                answers={item.answers}
                removeHandle={props.removeGeneralQuestionHandle.bind(
                  null,
                  index,
                )}
                handleQuestionTextChange={props.handleGeneralQuestionTextChange.bind(
                  null,
                  index,
                )}
                handleDeleteAnswer={props.handleDeleteAnswer.bind(null, index)}
                handleAddAnswer={props.handleAddAnswerGeneral.bind(null, index)}
                handleAnswerTextChange={props.handleGeneralAnswerTextChange.bind(
                  null,
                  index,
                )}
                handleAnswerRightChange={props.handleGeneralAnswerRightChange.bind(
                  null,
                  index,
                )}
                disabled={props.testInfo.id && props.checkDisabling()}
              />
            ))
          : null}
        <Button
          type="info"
          small
          onClick={props.addGeneralQuestionHandle.bind(null, index)}
          textButton="Добавить вопрос"
          disabled={props.testInfo.id && props.checkDisabling()}
        />
      </div>
    );
  };
  const getModalData = type => {
    switch (type) {
      case 'delete_question':
        return {
          title: 'Удаление вопроса',
          text: 'Вы действительно хотите удалить вопрос?',
          callback: props.deleteQuestion,
        };
      case 'delete_subtest_question':
        return {
          title: 'Удаление вопроса',
          text: 'Вы действительно хотите удалить вопрос?',
          callback: props.deleteSubtestQuestion,
        };
      case 'delete_subtest':
        return {
          title: 'Удаление блока вопросов',
          text: 'Вы действительно хотите удалить блок вопросов?',
          callback: props.deleteQuestionBlock,
        };
      case 'delete_question':
        return {
          title: 'Удаление вопроса',
          text: 'Вы действительно хотите удалить вопрос?',
          callback: props.deleteQuestion,
        };
      case 'delete_test':
        return {
          title: 'Удаление теста',
          text: 'Вы действительно хотите удалить тест?',
          callback: props.deleteTest,
        };
    }
  };

  const modalData = getModalData(props.modalType);

  return (
    <div className="tests-container">
      <DialogModal
        title={modalData?.title}
        show={props.showDeleteModal}
        setShow={props.setShowDeleteModal}
        onDelete={() => modalData?.callback()}>
        {modalData ? (
          <span className="add-lesson-modal-item-last">{modalData?.text}</span>
        ) : null}
      </DialogModal>
      <Modal
        show={props.showModal}
        setShow={props.setShowModal}
        title="Привяжите тест">
        {props.isAdmin && (
          <Select
            label="Учитель"
            direction="bottom"
            value={
              props.selectedTeacher
                ? getTeachersFormattedName(
                    props.selectedTeacher,
                    props.teachers,
                  )
                : ''
            }
            data={props.teachers}
            search={true}
            width={'100%'}
            renderEmpty={() => (
              <div className="option option-empty">Нет доступных учителей</div>
            )}
            containerClass="add-lesson-modal-item"
            onReset={() => props.setSelectedTeacher()}
            placeholder={'Выберите учителя'}
            renderOption={el => {
              return (
                <div
                  className="option"
                  onMouseDown={() => {
                    props.setSelectedTeacher(el.teacherId);
                  }}>
                  {getTeachersFormattedName(el.teacherId, props.teachers)}
                </div>
              );
            }}
            extractSearchKey={el =>
              getTeachersFormattedName(el.teacherId, props.teachers)
            }
          />
        )}
        <Select
          label="Занятие"
          direction="bottom"
          value={
            props.selectedOrganizer
              ? `${getStudentsFormattedName(
                  props.selectedOrganizer.studentId,
                  props.students,
                )} (Учитель: ${getTeachersFormattedName(
                  props.selectedOrganizer.teacherId,
                  props.teachers,
                )})`
              : ''
          }
          data={
            props.selectedTeacher
              ? props.organizers.filter(
                  el => el.teacherId === props.selectedTeacher,
                )
              : props.organizers
          }
          search={true}
          width={'100%'}
          renderEmpty={() => (
            <div className="option option-empty">
              Нет доступных групп и персональных занятий
            </div>
          )}
          containerClass="add-lesson-modal-item"
          onReset={() => props.setSelectedOrganizer()}
          placeholder="Выберите группу или персональное занятие"
          renderOption={el => {
            return (
              <div
                className="option"
                onMouseDown={() => {
                  props.setSelectedOrganizer({
                    id: el.classId,
                    studentId: el.studentId,
                    teacherId: el.teacherId,
                  });
                  props.setSelectedTeacher(el.teacherId);
                }}>
                {`${getStudentsFormattedName(
                  el.studentId,
                  props.students,
                )} (Учитель: ${getTeachersFormattedName(
                  el.teacherId,
                  props.teachers,
                )})`}
              </div>
            );
          }}
          extractSearchKey={el =>
            el.groupId
              ? getGroupsFormattedName(el.groupId, props.organizers)
              : getStudentsFormattedName(el.studentId, props.students)
          }
        />
        <DateTimePicker
          className="add-lesson-modal-item"
          title="Дата открытия общего теста"
          placeholder="Выберите дату открытия"
          minDate={new Date()}
          format={'DD MMMM, dd HH:mm'}
          variant="inline"
          wrongTime={props.wrongTime}
          setWrongTime={props.setWrongTime}
          inputProps={{ className: 'date-input' }}
          value={props.selectedDate ? props.selectedDate : null}
          onChange={date => {
            props.setSelectedDate(date);
          }}
        />
        <Button
          small
          textButton="Привязать тест"
          onClick={props.attachTest}
          disabled={
            !(props.selectedOrganizer && props.selectedDate) || props.wrongTime
          }
          loading={props.attachingTestLoading}
        />
      </Modal>
      <List
        renderItem={item => {
          return (
            <NavLink to={`/general-tests/${item.testId}`}>
              <div
                className="item-list-container"
                onClick={() => {
                  props.setNextLinkItem(item);
                }}>
                <div>{item.title}</div>
                <div className="item-creator">
                  {getTeachersFormattedName(item.creatorId, props.teachers)}
                </div>
              </div>
            </NavLink>
          );
        }}
        renderHeader={() => {
          return (
            <NavLink to="/general-tests">
              <div className="add-new-item-button-container">
                <Button
                  small
                  textButton="Добавить тест"
                  onClick={() => props.setNextLinkItem(null)}
                />
              </div>
            </NavLink>
          );
        }}
        list={props.tests}
        onScroll={handleDataOnBottom}
        loading={props.isLoading}
        emptySign="На данный момент нет тестов"
      />
      <div className="new-test-container">
        <div className="new-test-header">
          <div className="new-test-header-left">
            <div className="new-test-title-container">
              <Input
                type="text"
                containerClassName="new-test-title"
                maxLength={200}
                showCounter={true}
                placeholder="Новый тест"
                onChange={e =>
                  props.handleTestInfoChange('title', e.target.value)
                }
                value={props.testInfo.title}
                disabled={props.testInfo.id && props.checkDisabling()}
              />
            </div>
            <div className="new-test-description-container">
              <Textarea
                showCounter={true}
                maxLength={1000}
                className="new-test-description"
                placeholder="Описание"
                onChange={e =>
                  props.handleTestInfoChange('description', e.target.value)
                }
                value={props.testInfo.description}
                disabled={props.testInfo.id && props.checkDisabling()}
              />
            </div>
          </div>
          <div className="new-test-header-right">
            {props.testInfo.id &&
              (props.isAdmin ||
                props.testInfo.creatorId === props.userData.teacherId) && (
                <div className="new-test-cancel-container">
                  <Button
                    type="secondary"
                    small
                    textButton="Удалить тест"
                    disabled={props.deleteTestLoading}
                    loading={props.deleteTestLoading}
                    onClick={() => props.confirmDeleteTest(props.testInfo.id)}
                  />
                </div>
              )}
            <div className="new-test-save-container">
              {props.testInfo.id &&
              (props.isAdmin ||
                props.testInfo.creatorId === props.userData.teacherId) ? (
                <Button
                  small
                  textButton="Обновить"
                  onClick={() => props.updateTest()}
                  loading={props.createTestLoading}
                  disabled={props.createTestLoading}
                />
              ) : (
                !props.testInfo.id && (
                  <Button
                    small
                    textButton="Сохранить"
                    loading={props.createTestLoading}
                    disabled={
                      !(props.testInfo.title && props.subtests.length) ||
                      props.createTestLoading
                    }
                    onClick={() => props.sendGeneralTest()}
                    // disabled={props.saveLoading}
                  />
                )
              )}
              {props.saveLoading && (
                <div
                  className="uploading-progress"
                  style={{ width: `${props.fileLoading}%` }}
                />
              )}
            </div>
            {props.testInfo.id && (
              <div className="new-test-attach-container">
                <Button
                  small
                  textButton="Привязать тест"
                  loading={props.attachingTestLoading}
                  onClick={props.showAttachingTestModal}
                />
              </div>
            )}
          </div>
        </div>
        {props.subtests?.length
          ? props.subtests.map((el, index) => {
              return renderTypedQuestions(props, el.type, index);
            })
          : null}
        <div className="new-test-type-container">
          <div className="new-test-type-title">Добавление блока вопросов</div>
          <div className="new-test-type-list-container-stupid-paddings">
            <Select
              label="Выберите тип блока вопросов"
              containerClass="add-lesson-modal-item"
              direction="top"
              value={testTypeTranslation[props.selectedSubtestType]}
              noReset={true}
              data={[
                'grammar',
                'vocabulary',
                'translation',
                'phonetics',
                'listening',
              ]}
              width={'100%'}
              placeholder="Выберите тип блока вопросов"
              renderOption={el => {
                return (
                  <div
                    className="option"
                    onMouseDown={() => {
                      props.makeEditingMode();
                      props.setSelectedSubtestType(el);
                    }}>
                    {testTypeTranslation[el]}
                  </div>
                );
              }}
              disabled={props.testInfo.id && props.checkDisabling()}
            />
            <Button
              small
              type="info"
              className="add-subtest-button"
              textButton="Добавить блок вопросов"
              onClick={props.addTestToGeneral}
              disabled={props.testInfo.id && props.checkDisabling()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const testTypeTranslation = {
  grammar: 'Грамматика',
  vocabulary: 'Лексика',
  translation: 'Перевод',
  phonetics: 'Фонетика',
  listening: 'Аудирование',
};
export default TasksView;
