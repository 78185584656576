import React from 'react';
import { persistor } from '../../store';
import './style.css';

const ResetRedux = () => {
  return null;
  /*
  return (
    <div className='resetRedux' onClick={async () => {
      localStorage.clear();
      await persistor.purge();
      console.warn(
        '(╯°□°）╯︵ ┻━┻ \nState cleared, Cmd+R to reload the application now',
      );
    }}>Reset redux</div>
  )
  */
};
export default ResetRedux;
