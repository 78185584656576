import React from 'react';
import moment from 'moment';

import './style.css';
import FileDownload from '../../components/fileDownload/FileDownload';

const GeneralResultView = props => {
  return (
    <>
      {Array.isArray(props.test.completedTest) && (
        <>
          {props.test.title && (
            <div className="questions-head-title">
              {props.test.title || 'Teст'}
            </div>
          )}
          {props.test.timePassed && (
            <div className="questions-subtitle">
              <span className="questions-subtitle-indicator">
                Дата прохождения:{' '}
              </span>
              <span className="questions-subtitle-value">
                {moment(props.test.timePassed).format('DD MMMM, HH:mm')}
              </span>
            </div>
          )}
          {props.test.completedTest.map((test, testIndex) => (
            <>
              <div className="questions-title">
                {test?.title || `Teст ${testIndex + 1}`}
              </div>
              {test?.description && (
                <div className="questions-subtitle">{test?.description}</div>
              )}
              {test?.type && (
                <div className="questions-subtitle">
                  <span className="questions-subtitle-indicator">Тип: </span>
                  <span className="questions-subtitle-value">{test?.type}</span>
                </div>
              )}
              {test.file && (
                <div className="questions-subtitle">
                  <FileDownload
                    type="text"
                    title="Файл"
                    placeholder="Выбрать файл"
                    fileAddr={test.file}
                  />
                </div>
              )}
              <div className="questions-container">
                {test?.questions.map(el => {
                  return (
                    <div className="question-container">
                      <div className="question-name">
                        {el.number}. {el.question}
                      </div>
                      {el.answers.map(answer => (
                        <div
                          key={answer.number}
                          className={props.renderAnswerStyle(
                            el.number,
                            answer.number,
                            testIndex,
                          )}>
                          {answer.number}. {answer.answer}
                          {props.renderIcon(
                            el.number,
                            answer.number,
                            testIndex,
                          )}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </>
          ))}
        </>
      )}
    </>
  );
};

export default GeneralResultView;
