import React, { useState } from 'react';
import AuthView from './AuthView';
import firebase from 'firebase/app';
import 'firebase/auth';
import { store } from 'react-notifications-component';
import { setUserData } from '../../store/reducers/user-reducer';
import { useHistory } from 'react-router-dom';
import { TeachersService } from '../../services';
import { useDispatch } from 'react-redux';
import { showErrorNotification } from '../../core/errorsController';

const AuthContainer = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      localStorage.setItem('refreshToken', result.user.refreshToken);
      const token = await firebase.auth().currentUser.getIdToken();
      localStorage.setItem('token', token);
      const userData = await TeachersService.getUserData();
      dispatch(setUserData(userData));
      setLoading(false);
      history.push('/');
    } catch (err) {
      setLoading(false);
      showErrorNotification(err.message);
    }
  };
  return (
    <AuthView
      loading={loading}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      submit={submit}
    />
  );
};

export default AuthContainer;
