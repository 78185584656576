import md5 from 'md5';
import firebase from 'firebase';
import 'firebase/storage';

export default {
  async uploadAvatar(base64, salt) {
    try {
      const imageName = `avatars/${md5(new Date()) + salt}`;
      await firebase
        .storage()
        .ref(imageName)
        .putString(base64, 'data_url', { contentType: 'image/jpg' });
      return firebase.storage().ref(imageName).getDownloadURL();
    } catch (error) {
      return error;
    }
  },
  async uploadFile(blob, addr, salt) {
    try {
      const fileName = `${addr}/${md5(new Date()) + salt}`;
      await firebase
        .storage()
        .ref(fileName)
        .put(blob, { cacheControl: 'public' });
      return firebase.storage().ref(fileName).getDownloadURL();
    } catch (error) {
      return error;
    }
  },
  // async downloadFile(link) {
  //   var xhr = new XMLHttpRequest();
  //   xhr.responseType = 'blob';
  //   xhr.onload = (event) => {
  //     var blob = xhr.response;
  //   };
  //   xhr.open('GET', link);
  //   xhr.send();
  // },
};
