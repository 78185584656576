import { combineReducers } from 'redux';
import userReducer from './user-reducer';
import groupsReducer from './groups-reducer';
import teachersReducer from './teachers-reducer';
import studentsReducer from './students-reducer';
import flashCardsReducer from './flashCards-reducer';
import anagramsReducer from './anagrams-reducer';
import matchupReducer from './matchup-reducer';
import categorizeReducer from './categorize-reducer';
import materialsReducer from './materials-reducer';
import testsReducer from './tests-reducer';
import personalsReducer from './personals-reducer';
import newsReducer from './news-reducer';
import notificationsReducer from './notifications-reducer';
import appStateReducer from './appState-reducer';

const reducers = combineReducers({
  user: userReducer,
  appState: appStateReducer,
  groups: groupsReducer,
  teachers: teachersReducer,
  students: studentsReducer,
  flashCards: flashCardsReducer,
  anagrams: anagramsReducer,
  matchup: matchupReducer,
  categorize: categorizeReducer,
  materials: materialsReducer,
  tests: testsReducer,
  personals: personalsReducer,
  newsAll: newsReducer,
  notifications: notificationsReducer,
});

export default reducers;
