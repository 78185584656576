/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router';

import View from './view';
import { TestService, MaterialsService, StorageService } from '../../services';
import { getMyTests, deleteFile } from '../../core/firebase';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../core/errorsController';
import { getId } from '../../core/functions';
import { setEditing, setScroll } from '../../store/reducers/appState-reducer';

const getSelectionMap = questions => {
  return questions.map(el => {
    return {
      questionSelectionControl: {},
      answers: el.answers.map(item => {
        return {
          inputSelectionControl: {},
          checkboxSelectionControl: {},
        };
      }),
    };
  });
};

const getNewSelection = (toggler, color) => {
  return {
    toggler,
    color,
  };
};

//TODO:
//checks with indication
//length limits

const INITIALS = {
  newTestInfo: {
    title: '',
    description: '',
    date: '',
    type: 'grammar',
  },
  testQuestions: [
    {
      number: '0',
      answers: [
        {
          number: '1',
          answer: '',
          right: false,
        },
        {
          number: '2',
          answer: '',
          right: false,
        },
      ],
    },
    {
      number: '1',
      answers: [
        {
          number: '1',
          answer: '',
          right: false,
        },
        {
          number: '2',
          answer: '',
          right: false,
        },
      ],
    },
  ],
};

const TasksContainer = props => {
  const userData = useSelector(state => state.user);
  const teachers = useSelector(state => state.teachers);
  const organizers = useSelector(state => [
    ...state.groups,
    ...state.personals,
  ]);
  const students = useSelector(state => state.students);
  const isEditing = useSelector(state => state.appState.isEditing);
  const [myTests, setMyTests] = useState([]);
  const [myTestsLoading, setMyTestsLoading] = useState(true);

  const [fileLoading, setFileLoading] = useState(null);
  const [editingMode, setEditingMode] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedOrganizer, setSelectedOrganizer] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [attachingTestLoading, setAttachingTestLoading] = useState(false);
  const [currentTestId, setCurrentTestId] = useState();
  const [file, setFile] = useState(null);
  const [newTestInfo, setNewTestInfo] = useState(
    cloneDeep(INITIALS.newTestInfo),
  );
  const [testQuestions, setTestQuestions] = useState(
    cloneDeep(INITIALS.testQuestions),
  );
  const [selectionMap, setSelectionMap] = useState(
    getSelectionMap(testQuestions),
  );
  const [deletingItem, setDeletingItem] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const [nextLinkItem, setNextLinkItem] = useState(null);
  const [wrongTime, setWrongTime] = useState(false);

  //Pagination
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const isAdmin = useSelector(state => state.user).role === 'admin';
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(true));
  }, []);

  useEffect(() => {
    const id = getId(history.location.pathname);

    if (id) {
      getTestFromLink(id);
    }

    getTests();
    const unsubscribe = history.listen(({ pathname }) => {
      setMyTests(old => {
        openTestToEdit(getId(pathname), old);
        return old;
      });
    });

    return unsubscribe;
  }, []);

  const makeEditingMode = () => {
    if (!isEditing) {
      dispatch(setEditing(true));
    }
  };

  const getTests = async () => {
    if (!isLast && !isLoading) {
      setIsLoading(true);
      try {
        const result = await TestService.getTests(page);
        setPage(old => old + 1);
        setIsLast(result.last);
        setMyTests(old => [...old, ...result.data]);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setIsLoading(false);
    }
  };

  const getTestFromLink = async testId => {
    try {
      const result = await TestService.getTest(testId);
      selectTestToEdit(result);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const selectTestToEdit = test => {
    setCurrentTestId(test.testId);
    setNewTestInfo({
      id: test.testId,
      title: test.title,
      teacherId: test.teacherId,
      date: test.date,
      type: test.type,
      file: test.file,
      files: test.files,
      description: test.description,
      creatorId: test.creatorId,
      showRight: test.showRight,
      multiplie: test.multiplie,
    });
    setSelectionMap(getSelectionMap(test.questions));
    setTestQuestions(test.questions);
  };

  const openTestToEdit = (testId, tests = myTests) => {
    if (testId) {
      const test = tests.find(item => item.testId === testId);

      if (test) {
        selectTestToEdit(test);
        setEditingMode(test.id);
      }
    } else {
      clearState();
    }
  };

  const handleTestInfoChange = (field, value) => {
    makeEditingMode();
    setNewTestInfo({
      ...newTestInfo,
      [field]: value,
    });
  };

  const handleAddAnswer = questionIndex => {
    makeEditingMode();
    if (testQuestions[questionIndex].answers.length < 4) {
      setTestQuestions(old => {
        setSelectionMap(old => {
          return old.map((el, index) => {
            if (index === questionIndex) {
              return {
                ...el,
                answers: [
                  ...el.answers,
                  { inputSelectionControl: {}, checkboxSelectionControl: {} },
                ],
              };
            }
            return el;
          });
        });
        return old.map((el, index) => {
          if (index === questionIndex) {
            return {
              ...el,
              answers: [
                ...el.answers,
                {
                  number: `${el.answers.length + 1}`,
                  answer: '',
                  right: false,
                },
              ],
            };
          }
          return el;
        });
      });
    } else {
      showSuccessNotification('Максимум 4 варианта ответа');
    }
  };

  const handleAnswerTextChange = (e, questionIndex, answerIndex) => {
    makeEditingMode();
    let oldQuestions = testQuestions.slice(0);
    oldQuestions[questionIndex].answers[answerIndex].answer = e.target.value;
    setSelectionMap(old => {
      const temp = [...old];
      temp[questionIndex].answers[answerIndex].inputSelectionControl = null;
      return temp;
    });
    setTestQuestions(oldQuestions);
  };

  const handleAnswerRightChange = (value, questionIndex, answerIndex) => {
    makeEditingMode();
    let oldQuestions = testQuestions.slice(0);
    oldQuestions[questionIndex].answers[answerIndex].right = value;
    if (
      oldQuestions[questionIndex].answers.filter(el => el.right).length > 1 &&
      !newTestInfo.multiplie &&
      value
    ) {
      setNewTestInfo(old => {
        return {
          ...old,
          multiplie: true,
        };
      });
      showWarningNotification('Множественный выбор был включен');
    }
    setSelectionMap(old => {
      const temp = [...old];
      temp[questionIndex].answers = temp[questionIndex].answers.map(el => {
        return {
          ...el,
          checkboxSelectionControl: null,
        };
      });
      return temp;
    });
    setTestQuestions(oldQuestions);
  };

  const addQuestionHandle = () => {
    if (
      !newTestInfo.id ||
      isAdmin ||
      newTestInfo.creatorId === userData.teacherId
    ) {
      makeEditingMode();
      setTestQuestions(old => [
        ...old,
        {
          answers: [
            {
              number: '1',
              answer: '',
              right: false,
            },
            {
              number: '2',
              answer: '',
              right: false,
            },
          ],
        },
      ]);
      setSelectionMap(old => {
        return [
          ...old,
          {
            questionSelectionControl: {},
            answers: [
              {
                inputSelectionControl: {},
                checkboxSelectionControl: {},
              },
              {
                inputSelectionControl: {},
                checkboxSelectionControl: {},
              },
            ],
          },
        ];
      });
    }
  };

  const renderAddQuestionButtonStyle = () => {
    let style = 'group-info__addQuestion';
    if (
      newTestInfo.id &&
      !(isAdmin || newTestInfo.teacherId === userData.teacherId)
    ) {
      style = 'group-info__addQuestion_disabled';
    }
    return style;
  };

  const renderAddQuestionIconStyle = () => {
    let style = 'group-info__addQuestion__icon';
    if (
      newTestInfo.id &&
      !(isAdmin || newTestInfo.teacherId === userData.teacherId)
    ) {
      style = 'group-info__addQuestion__icon_disabled';
    }
    return style;
  };

  const handleDeleteAnswer = (
    index = deletingItem.index,
    subIndex = deletingItem.subIndex,
  ) => {
    setTestQuestions(old => {
      const temp = [...old];
      const tempAnswers = [...old[index].answers];
      if (tempAnswers.length > 2) {
        const newQuestionAnswers = tempAnswers.filter(
          (el, idx) => idx !== subIndex,
        );
        const newSelectionMap = [...selectionMap];
        newSelectionMap[index].answers = newSelectionMap[index].answers.filter(
          (el, idx) => idx !== subIndex,
        );
        setSelectionMap(newSelectionMap);
        temp[index].answers = newQuestionAnswers;
      } else {
        if (tempAnswers[subIndex]?.answer) {
          tempAnswers[subIndex].answer = '';
          tempAnswers[subIndex].right = false;
        }
        temp[index].answers = tempAnswers;
      }
      return temp;
    });
    manipulateDeleteModal(false);
  };

  const deleteQuestion = (index = deletingItem.index) => {
    makeEditingMode();
    setTestQuestions(old => {
      if (old.length > 1) {
        const newQuestions = old.filter((el, idx) => idx !== index);
        const newSelectionMap = selectionMap.filter((el, idx) => idx !== index);
        setSelectionMap(newSelectionMap);
        return newQuestions;
      } else {
        return [
          {
            question: '',
            answers: [
              {
                number: '1',
                answer: '',
                right: false,
              },
              {
                number: '2',
                answer: '',
                right: false,
              },
            ],
          },
        ];
      }
    });
    manipulateDeleteModal(false);
  };

  const checkQuestionEmpty = item => {
    return !(item?.question || item?.answers.some(el => el.answer));
  };

  const removeAnswerHandle = (index, subIndex) => {
    makeEditingMode();
    if (!testQuestions[index].answers[subIndex].answer) {
      handleDeleteAnswer(index, subIndex);
    } else {
      setModalType('delete_answer');
      setDeletingItem({ index, subIndex });
      manipulateDeleteModal(true);
    }
  };

  const removeQuestionHandle = index => {
    makeEditingMode();
    if (checkQuestionEmpty(testQuestions[index])) {
      deleteQuestion(index);
    } else {
      setModalType('delete_question');
      setDeletingItem({ index });
      manipulateDeleteModal(true);
    }
  };

  const handleQuestionTextChange = (e, index) => {
    makeEditingMode();
    e.persist();
    setTestQuestions(old => {
      return old.map((el, idx) => {
        if (idx === index) {
          return {
            ...el,
            question: e?.target?.value,
          };
        }
        return el;
      });
    });
    setSelectionMap(old => {
      return old.map((el, idx) => {
        if (idx === index) {
          return {
            ...el,
            questionSelectionControl: {},
          };
        }
        return el;
      });
    });
  };

  const manipulateDeleteModal = value => {
    setTimeout(() => {
      setShowDeleteModal(value);
      if (!value) {
        setModalType(null);
        setDeletingItem(null);
      }
    }, 0);
  };

  const uploadFile = async (blob, salt, addr) => {
    if (blob) {
      return await StorageService.uploadFile(blob, addr, salt);
    }
    return null;
  };

  const sendTests = async (url = null) => {
    let requestData = {
      title: newTestInfo.title,
      description: newTestInfo.description,
      date: newTestInfo.date,
      showRight: newTestInfo.showRight,
      multiplie: newTestInfo.multiplie,
      questions: testQuestions.map((el, index) => {
        return {
          ...el,
          number: `${index + 1}`,
          answers: el.answers.map((x, idx) => {
            return { ...x, number: `${idx + 1}` };
          }),
        };
      }),
      type: newTestInfo.type,
    };

    if (newTestInfo.type === 'listening') {
      requestData.file = newTestInfo.file;
    }

    if (!checkTestFilled(requestData)) {
      return;
    }

    try {
      if (url) {
        requestData.file = url;
      }
      let resultTest;
      if (newTestInfo.id) {
        resultTest = await TestService.updateTest({
          testId: newTestInfo.id,
          data: requestData,
        });
        showSuccessNotification('Тест обновлен');
        await dispatch(setEditing(false));
      } else {
        resultTest = await TestService.createTest({ data: requestData });
        setMyTests(old => [resultTest, ...old]);
        showSuccessNotification('Тест загружен');
        await dispatch(setEditing(false));
        clearState();
      }
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const checkTestFilled = test => {
    const selectionMapTemp = [...selectionMap];
    let haveErrors = false;
    test.questions.map((el, index) => {
      if (!el.question) {
        showErrorNotification(`Вопрос ${index + 1} не заполнен!`);
        selectionMapTemp[index].questionSelectionControl = getNewSelection(
          'true',
          '#ff6f47',
        );
        haveErrors = true;
      }
      if (el.answers.filter(el => !el.answer).length) {
        showErrorNotification(
          `Вопрос ${index + 1} имеет незаполненные ответы!`,
        );
        el.answers.forEach((item, idx) => {
          if (!item.answer.length) {
            selectionMapTemp[index].answers[idx].inputSelectionControl =
              getNewSelection('true', '#ff6f47');
          }
        });
        haveErrors = true;
      }
      if (!el.answers.filter(el => el.right).length) {
        showErrorNotification(
          `Вопрос ${index + 1} не имеет правильных ответов!`,
        );
        el.answers.map((el, idx) => {
          selectionMapTemp[index].answers[idx].checkboxSelectionControl =
            getNewSelection('true', '#ff6f47');
        });
        haveErrors = true;
      }
    });
    if (haveErrors) {
      setSelectionMap(selectionMapTemp);
      return false;
    }
    return true;
  };

  const saveNewTest = async () => {
    setSaveLoading(true);
    if (newTestInfo.type === 'listening') {
      if (newTestInfo.file && typeof newTestInfo.file !== 'string') {
        newTestInfo.file = await uploadFile(
          newTestInfo.file.blob,
          newTestInfo.file.fileName,
          'audition',
        );
        await sendTests();
      } else if (!newTestInfo.file?.blob && !newTestInfo.file) {
        showErrorNotification('Пожалуйста, добавьте файл для аудирования');
      } else {
        await sendTests();
      }
    } else {
      await sendTests();
    }
    setSaveLoading(false);
  };

  const deleteTestFile = () => {
    makeEditingMode();

    const filePath = `${newTestInfo.type}/${editingMode}/${
      newTestInfo.files[newTestInfo.type]
    }`;

    setSaveLoading(true);

    let requestData = {
      ...newTestInfo,
      questions: testQuestions,
      teacher: userData.teacherId,
      showRight: newTestInfo.showRight,
    };

    requestData.files = {
      [newTestInfo.type]: null,
    };

    deleteFile(
      {
        id: editingMode,
        ...requestData,
      },
      filePath,
      () => {
        showSuccessNotification('Файл упешно удален');
        setNewTestInfo({
          ...newTestInfo,
          files: {
            ...newTestInfo.files,
            [newTestInfo.type]: null,
          },
        });
        setSaveLoading(false);
      },
    );
  };

  const deleteModalOpen = () => {
    setShowDeleteModal(true);
    setModalType('delete_test');
  };

  const deleteTest = async () => {
    try {
      setDeleteLoading(true);
      await TestService.deleteTest(newTestInfo.id);
      setMyTests(old => old.filter(el => el.testId !== newTestInfo.id));
      showSuccessNotification('Тест упешно удален');
      await dispatch(setEditing(false));
      setShowDeleteModal(false);
      history.push('/tests');
    } catch (err) {
      showErrorNotification(err.message);
    }
    setDeleteLoading(false);
    clearState();
  };

  const clearState = () => {
    setEditingMode(false);
    setFile(null);
    setNewTestInfo(cloneDeep(INITIALS.newTestInfo));
    setTestQuestions(cloneDeep(INITIALS.testQuestions));
    setSelectionMap(getSelectionMap(INITIALS.testQuestions));
  };

  const showAttachingTestModal = () => {
    setShowModal(true);
  };

  const attachTest = async () => {
    setAttachingTestLoading(true);
    if (selectedOrganizer.type === 'group') {
      try {
        await TestService.addTestToGroup({
          testId: currentTestId,
          groupId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification('Тест успешно привязан к группе');
        setShowModal(false);
      } catch (err) {
        showErrorNotification('Не удалось привязать тест к группе');
      }
      setAttachingTestLoading(false);
    } else {
      try {
        await TestService.addTestToPersonal({
          testId: currentTestId,
          classId: selectedOrganizer.id,
          date: selectedDate,
        });
        showSuccessNotification(
          'Тест успешно привязан к персональному занятию',
        );
        setShowModal(false);
      } catch (err) {
        showErrorNotification(
          'Не удалось привязать тест к персональному занятию',
        );
      }
      setAttachingTestLoading(false);
    }
  };

  const checkDisabling = () => {
    return !(isAdmin || newTestInfo.teacherId === userData.teacherId);
  };

  return (
    <View
      saveLoading={saveLoading}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={manipulateDeleteModal}
      deleteQuestion={deleteQuestion}
      modalType={modalType}
      removeAnswerHandle={removeAnswerHandle}
      newTestInfo={newTestInfo}
      testQuestions={testQuestions}
      addQuestionHandle={addQuestionHandle}
      removeQuestionHandle={removeQuestionHandle}
      handleQuestionTextChange={handleQuestionTextChange}
      handleAddAnswer={handleAddAnswer}
      handleAnswerTextChange={handleAnswerTextChange}
      handleAnswerRightChange={handleAnswerRightChange}
      myTestsLoading={myTestsLoading}
      myTests={myTests}
      selectionMap={selectionMap}
      openTestToEdit={openTestToEdit}
      fileLoading={fileLoading}
      deleteTestFile={deleteTestFile}
      deleteTest={deleteTest}
      clearState={clearState}
      updateTest={saveNewTest}
      saveNewTest={saveNewTest}
      showAttachingTestModal={showAttachingTestModal}
      attachTest={attachTest}
      showModal={showModal}
      setShowModal={setShowModal}
      teachers={teachers}
      students={students}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={setSelectedTeacher}
      organizers={organizers}
      handleTestInfoChange={handleTestInfoChange}
      selectedOrganizer={selectedOrganizer}
      setSelectedOrganizer={setSelectedOrganizer}
      attachingTestLoading={attachingTestLoading}
      deleteModalOpen={deleteModalOpen}
      deleteLoading={deleteLoading}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      isAdmin={isAdmin}
      checkDisabling={checkDisabling}
      userData={userData}
      isLoading={isLoading}
      handleDeleteAnswer={handleDeleteAnswer}
      getTests={getTests}
      wrongTime={wrongTime}
      setWrongTime={setWrongTime}
      renderAddQuestionButtonStyle={renderAddQuestionButtonStyle}
      renderAddQuestionIconStyle={renderAddQuestionIconStyle}
      setNextLinkItem={setNextLinkItem}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
