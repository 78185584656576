/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { store } from 'react-notifications-component';
import { getId, copyToClipboard } from '../../core/functions';
import View from './view';
import {
  TeachersService,
  MaterialsService,
  StorageService,
  TestService,
  GamesService,
  InvitesService,
} from '../../services';
import { useHistory } from 'react-router-dom';
import { setUserData as updateUserData } from '../../store/reducers/user-reducer';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../core/errorsController';
import { cloneDeep } from 'lodash';
import { setScroll } from '../../store/reducers/appState-reducer';
import { setTeachers } from '../../store/reducers/teachers-reducer';

const paginationInitials = {
  personals: {
    page: 1,
    last: false,
  },
  groups: {
    page: 1,
    last: false,
  },
  materials: {
    page: 1,
    last: false,
  },
  generalTests: {
    page: 1,
    last: false,
  },
  tests: {
    page: 1,
    last: false,
  },
  flashcards: {
    page: 1,
    last: false,
  },
  anagrams: {
    page: 1,
    last: false,
  },
  categorize: {
    page: 1,
    last: false,
  },
  matchup: {
    page: 1,
    last: false,
  },
  // events: {
  //   page: 1,
  //   last: false,
  // },
  // payments: {
  //   page: 1,
  //   last: false,
  // },
};

const educationItem = {
  institutionName: '',
  dyplomaSeries: '',
  dyplomaNumber: '',
  dyplomaScan: '',
};

const TasksContainer = props => {
  const [teacherId, setTeacherId] = useState();
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [avatarUploading, setAvatarUploading] = useState(false);
  const [croppedImage, setCroppedImage] = useState('');
  const [newAvatar, setNewAvatar] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [personals, setPersonals] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tests, setTests] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [flashcards, setFlashcards] = useState([]);
  const [matchup, setMatchup] = useState([]);
  const [anagrams, setAnagrams] = useState([]);
  const [categorize, setCategorize] = useState([]);
  const [generalTests, setGeneralTests] = useState([]);
  const [personalsLoading, setPersonalsLoading] = useState(false);
  const [generalTestsLoading, setGeneralTestsLoading] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [testsLoading, setTestsLoading] = useState(false);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [flashcardsLoading, setFlashcardsLoading] = useState(false);
  const [matchupLoading, setMatchupLoading] = useState(false);
  const [anagramsLoading, setAnagramsLoading] = useState(false);
  const [categorizeLoading, setCategorizeLoading] = useState(false);
  const [paginationStatus, setPaginationStatus] = useState(
    cloneDeep(paginationInitials),
  );

  const [teacherLoading, setTeacherLoading] = useState(true);
  const [teacherUpdateLoading, setTeacherUpdateLoading] = useState(false);
  const [editMode, handleEditMode] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState();
  const [passportScan, setPassportScan] = useState(null);
  const [registrationScan, setRegistrationScan] = useState(null);
  const [convinienceScan, setConvinienceScan] = useState(null);
  const [firstDyplomaScan, setFirstDyplomaScan] = useState(null);
  const [secondDyplomaScan, setSecondDyplomaScan] = useState(null);
  const [adminEdit, setAdminEdit] = useState(false);
  const [invites, setInvites] = useState([]);
  const [teacherEducation, setTeacherEducation] = useState([]);

  const [tabId, setTabId] = useState(1);
  const [gamesTabId, setGamesTabId] = useState(1);

  const history = useHistory();
  const teachers = useSelector(state => state.teachers);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const isAdmin = user.role === 'admin';

  useEffect(() => {
    const id = getId(history.location.pathname);
    setTeacherId(id);
    if (user.teacherId === id || user.role === 'admin') {
      handleEditMode(true);
    }
    if (user.role === 'admin') {
      setAdminEdit(true);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (tabId === 1) {
      dispatch(setScroll(true));
    } else {
      dispatch(setScroll(false));
    }
  }, [tabId]);

  useEffect(() => {
    if (teacherId) {
      getTeacherData(teacherId);
      getCodes(teacherId);
    }
  }, [teacherId]);

  const fetchData = (_tabId = tabId, _gamesTabId = gamesTabId) => {
    switch (_tabId) {
      case 2:
        getGroups();
        break;
      case 3:
        getPersonals();
        break;
      case 4:
        getMaterials();
        break;
      case 5:
        getTests();
        break;
      case 6:
        getGeneralTests();
        break;
      case 7:
        switch (_gamesTabId) {
          case 1:
            getFlashcards();
            break;
          case 2:
            getMatchup();
            break;
          case 3:
            getAnagrams();
            break;
          case 4:
            getCategorize();
            break;
          default:
            return;
        }
        break;
      default:
        return;
    }
  };

  const getCodes = async (_teacherId = userData.teacherId) => {
    if (_teacherId) {
      try {
        const result = await InvitesService.getClassInvites(_teacherId);
        setInvites([...result]);
      } catch (err) {
        showErrorNotification(err.message);
      }
    }
  };

  const generateCode = async () => {
    try {
      const result = await InvitesService.getClassInvite(userData.teacherId);
      showSuccessNotification(`Сгенерирован новый инвайт код: ${result.code}`);
      copyToClipboard(result.code);
      setInvites(old => [...old, result]);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const deleteItem = async () => {
    setDeleteLoading(true);
    try {
      switch (tabId) {
        case 4:
          await MaterialsService.deleteMaterial(deletingItem.materialId);
          setMaterials(old =>
            old.filter(el => el.materialId !== deletingItem.materialId),
          );
          showSuccessNotification('Материал успешно удален');
          break;
        case 5:
          await TestService.deleteTest(deletingItem.testId);
          setTests(old => old.filter(el => el.testId !== deletingItem.testId));
          showSuccessNotification('Тест успешно удален');
          break;
        case 7:
          switch (gamesTabId) {
            case 1:
              await GamesService.deleteGames(1, deletingItem.categoryId);
              setFlashcards(old =>
                old.filter(el => el.categoryId !== deletingItem.categoryId),
              );
              break;
            case 2:
              await GamesService.deleteGames(2, deletingItem.categoryId);
              setMatchup(old =>
                old.filter(el => el.categoryId !== deletingItem.categoryId),
              );
              break;
            case 3:
              await GamesService.deleteGames(3, deletingItem.categoryId);
              setAnagrams(old =>
                old.filter(el => el.categoryId !== deletingItem.categoryId),
              );
              break;
            case 4:
              await GamesService.deleteGames(4, deletingItem.categoryId);
              setCategorize(old =>
                old.filter(el => el.category !== deletingItem.categoryId),
              );
              break;
          }
          showSuccessNotification('Игра успешно удалена');
          break;
        case 6:
          await TestService.deleteGeneralTest(deletingItem.testId);
          setGeneralTests(old =>
            old.filter(el => el.testId !== deletingItem.testId),
          );
          showSuccessNotification('Тест успешно удален');
          break;
      }
      manipulateDeleteModal(false);
    } catch (error) {
      showErrorNotification(error);
    }
    setDeleteLoading(false);
  };

  const selectTabId = _tabId => {
    setTabId(_tabId);
    setGamesTabId(1);
    fetchData(_tabId, 1);
  };

  const selectGamesTabId = _gamesTabId => {
    setGamesTabId(_gamesTabId);
    fetchData(tabId, _gamesTabId);
  };

  useEffect(() => {
    setPaginationStatus(cloneDeep(paginationInitials));
  }, []);

  const handlePaginationStatus = (tab, page = 1, last = false) => {
    setPaginationStatus(old => {
      return {
        ...old,
        [tab]: {
          page: page,
          last: last,
        },
      };
    });
  };

  const confirmDelete = item => {
    setDeletingItem(item);
    manipulateDeleteModal(true);
  };

  const getGroups = async (id = teacherId) => {
    if (id && !paginationStatus.groups.last && !groupsLoading) {
      setGroupsLoading(true);
      try {
        const result = await TeachersService.getGroups(
          id,
          paginationStatus.groups.page,
        );
        setGroups([
          ...groups,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'groups',
          paginationStatus.groups.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setGroupsLoading(false);
    }
  };

  const getPersonals = async (id = teacherId) => {
    if (id && !paginationStatus.personals.last && !personalsLoading) {
      setPersonalsLoading(true);
      try {
        const result = await TeachersService.getPersonals(
          id,
          paginationStatus.personals.page,
        );
        setPersonals([
          ...personals,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'personals',
          paginationStatus.personals.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setPersonalsLoading(false);
    }
  };

  const getMaterials = async (id = teacherId) => {
    if (id && !paginationStatus.materials.last && !materialsLoading) {
      setMaterialsLoading(true);
      try {
        const result = await TeachersService.getMaterials(
          id,
          paginationStatus.materials.page,
        );
        setMaterials([
          ...materials,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'materials',
          paginationStatus.materials.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMaterialsLoading(false);
    }
  };

  const getTests = async (id = teacherId) => {
    if (id && !paginationStatus.tests.last && !testsLoading) {
      setTestsLoading(true);
      try {
        const result = await TeachersService.getTests(
          id,
          paginationStatus.tests.page,
        );
        setTests([
          ...tests,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'tests',
          paginationStatus.tests.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setTestsLoading(false);
    }
  };

  const getGeneralTests = async (id = teacherId) => {
    if (id && !paginationStatus.generalTests.last && !generalTestsLoading) {
      setGeneralTestsLoading(true);
      try {
        const result = await TeachersService.getGeneralTests(
          id,
          paginationStatus.generalTests.page,
        );
        setGeneralTests([
          ...generalTests,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'generalTests',
          paginationStatus.generalTests.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setGeneralTestsLoading(false);
    }
  };

  const getFlashcards = async (id = teacherId) => {
    if (id && !paginationStatus.flashcards.last && !flashcardsLoading) {
      setFlashcardsLoading(true);
      try {
        const result = await TeachersService.getFlashCards(
          id,
          paginationStatus.flashcards.page,
        );
        setFlashcards([
          ...flashcards,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'flashcards',
          paginationStatus.flashcards.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setFlashcardsLoading(false);
    }
  };

  const getAnagrams = async (id = teacherId) => {
    if (id && !paginationStatus.anagrams.last && !anagramsLoading) {
      setAnagramsLoading(true);
      try {
        const result = await TeachersService.getAnagrams(
          id,
          paginationStatus.anagrams.page,
        );
        setAnagrams([
          ...anagrams,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'anagrams',
          paginationStatus.anagrams.page + 1,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setAnagramsLoading(false);
    }
  };

  const getCategorize = async (id = teacherId) => {
    if (id && !paginationStatus.categorize.last && !categorizeLoading) {
      setCategorizeLoading(true);
      try {
        const result = await TeachersService.getCategorize(
          id,
          paginationStatus.categorize.page,
        );
        setCategorize([
          ...categorize,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'categorize',
          paginationStatus.categorize.page,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setCategorizeLoading(false);
    }
  };

  const getMatchup = async (id = teacherId) => {
    if (id && !paginationStatus.matchup.last && !matchupLoading) {
      setMatchupLoading(true);
      try {
        const result = await TeachersService.getMatchup(
          id,
          paginationStatus.matchup.page,
        );
        setMatchup([
          ...matchup,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        handlePaginationStatus(
          'matchup',
          paginationStatus.matchup.page,
          result.last,
        );
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMatchupLoading(false);
    }
  };

  const handleContainerOnBottom = async () => {
    try {
      switch (tabId) {
        case 2:
        default:
          if (paginationStatus.groups.last) {
            return;
          }
          await getGroups();
          break;
        case 3:
          if (paginationStatus.personals.last) {
            return;
          }
          await getPersonals();
          break;
        case 4:
          if (paginationStatus.materials.last) {
            return;
          }
          await getMaterials();
          break;
        case 5:
          if (paginationStatus.tests.last) {
            return;
          }
          await getTests();
          break;
        case 6:
          if (paginationStatus.tests.last) {
            return;
          }
          await getGeneralTests();
          break;
        case 7:
          switch (gamesTabId) {
            case 1:
            default:
              if (paginationStatus.flashcards.last) {
                return;
              }
              await getFlashcards();
              break;
            case 2:
              if (paginationStatus.matchup.last) {
                return;
              }
              await getMatchup();
              break;
            case 3:
              if (paginationStatus.anagrams.last) {
                return;
              }
              await getAnagrams();
              break;
            case 4:
              if (paginationStatus.categorize.last) {
                return;
              }
              await getCategorize();
              break;
          }
          break;
      }
    } catch (error) {
      showErrorNotification('Произошла ошибка при получении данных');
    }
  };

  const getTeacherData = async id => {
    try {
      const result = await TeachersService.getTeacherData(id);
      if (id === user.teacherId) {
        dispatch(updateUserData(result));
      }
      if (result.education) {
        const education = [];
        for (let i = 0; i < 5; i++) {
          if (result.education[i]) {
            education.push(result.education[i]);
          } else {
            education.push(educationItem);
          }
        }
        setTeacherEducation(education);
      } else {
        setTeacherEducation([
          educationItem,
          educationItem,
          educationItem,
          educationItem,
          educationItem,
        ]);
      }
      setUserData(result);
      setTeacherLoading(false);
    } catch (err) {
      showErrorNotification(err.message);
    }
  };

  const handleEducationDelete = (field, index) => {
    const temp = { ...userData };
    if (temp.education?.[index]?.dyplomaScan) {
      temp.education[index].dyplomaScan = '';
    }
    setUserData(temp);
  };

  const handleEducationChange = (field, index, value) => {
    const checkValue = (field, value) => {
      switch (field) {
        case 'dyplomaNumber':
        case 'dyplomaSeries':
          const regex = new RegExp('[^A-Za-zа-яА-Я0-9 ]+', 'gi');
          const result = regex.test(value);
          return !result;
        default:
          return true;
      }
    };
    setTeacherEducation(old => {
      return old.map((el, idx) => {
        if (idx === index) {
          const temp = { ...el };
          if (checkValue(field, value)) {
            temp[field] = value;
          }
          return temp;
        }
        return el;
      });
    });
  };

  const handleTeacherChange = (field, value) => {
    switch (field) {
      case 'teachingExperience':
      case 'translationExperience':
        if (isFinite(value)) {
          const [int, fract = ''] = value.split('.');
          if ((int.length < 3 && fract.length < 3) || value < userData[field]) {
            setUserData(prevState => ({ ...prevState, [field]: '' + value }));
          }
        }
        break;
      case 'shortClassPrice':
      case 'longClassPrice':
        if (
          (value >= 1 && /^[0-9]{1,7}(?:\.[0-9]{0,2})?$/.test(value)) ||
          value === ''
        ) {
          setUserData(prevState => ({ ...prevState, [field]: value }));
        }
        break;
      case 'aboutMyself':
        if (value < 1500) {
          setUserData(prevState => ({ ...prevState, [field]: value }));
        } else {
          setUserData(prevState => ({
            ...prevState,
            [field]: value.slice(0, 1499),
          }));
        }
        break;
      case 'passportNumber':
      case 'passportSeries':
        const regex = new RegExp('[^A-Za-z0-9 ]+', 'gi');
        const result = regex.test(value);
        if (!result) {
          setUserData(prevState => ({ ...prevState, [field]: value }));
        }
        break;
      case 'firstDyplomaNumber':
      case 'secondDyplomaNumber':
        if (isFinite(value)) {
          setUserData(prevState => ({ ...prevState, [field]: value }));
        }
        break;
      default:
        setUserData(prevState => ({ ...prevState, [field]: value }));
        break;
    }
  };

  const onUploadNewAvatar = async () => {
    setAvatarUploading(true);
    try {
      const imageUrl = await StorageService.uploadAvatar(croppedImage);
      const temp = { avatar: imageUrl };
      const response = await TeachersService.updateTeacher(
        temp,
        userData.teacherId,
      );
      await getTeacherData(teacherId);
      setShowModal(false);
    } catch (err) {
      showErrorNotification(err.message);
    }
    setAvatarUploading(false);
  };

  const onDeleteAvatar = async () => {
    setDeleteLoading(true);
    try {
      const temp = { avatar: '' };
      const response = await TeachersService.updateTeacher(
        temp,
        userData.teacherId,
      );
      await getTeacherData(teacherId);
      setShowModal(false);
    } catch (err) {
      setTeacherLoading(false);
      showErrorNotification(err.message);
    }
    setDeleteLoading(false);
  };

  const uploadFile = async (blob, salt, addr) => {
    if (blob) {
      return await StorageService.uploadFile(blob, addr, salt);
    }
    return '';
  };

  const updateTeacher = async () => {
    setUpdateLoading(true);
    try {
      const temp = { ...userData };
      delete temp.role;
      delete temp.teacherId;
      delete temp.createdTime;
      delete temp.registration;
      delete temp.diploma;
      delete temp.deleted;

      if (temp.longClassPrice) {
        if (('' + temp.longClassPrice).endsWith('.')) {
          temp.longClassPrice += '0';
        }
        temp.longClassPrice = +temp.longClassPrice;
      }

      if (temp.shortClassPrice) {
        if (('' + temp.shortClassPrice).endsWith('.')) {
          temp.shortClassPrice += '0';
        }
        temp.shortClassPrice = +temp.shortClassPrice;
      }

      const passportScanUrl = uploadFile(
        passportScan?.blob,
        passportScan?.fileName,
        'passport',
      );

      const registrationScanUrl = uploadFile(
        registrationScan?.blob,
        registrationScan?.fileName,
        'registration',
      );

      const convinienceScanUrl = uploadFile(
        convinienceScan?.blob,
        convinienceScan?.fileName,
        'convinience',
      );

      const scans = await Promise.all([
        passportScanUrl,
        registrationScanUrl,
        convinienceScanUrl,
        ...teacherEducation.map(el => {
          return uploadFile(
            el.dyplomaScan?.blob,
            el.dyplomaScan?.fileName,
            'dyplomas',
          );
        }),
      ]);

      const response = await TeachersService.updateTeacher(
        {
          ...temp,
          passportScan: scans[0] || temp.passportScan,
          registrationScan: scans[1] || temp.registrationScan,
          convinienceScan: scans[2] || temp.convinienceScan,
          education: teacherEducation.map((el, index) => {
            return {
              ...el,
              dyplomaScan:
                scans[index + 3] || temp.education?.[index]?.dyplomaScan || '',
            };
          }),
        },
        userData.teacherId,
      );

      dispatch(
        setTeachers(
          teachers.map(el => {
            if (el.teacherId === response.teacherId) {
              return response;
            } else {
              return el;
            }
          }),
        ),
      );
      showSuccessNotification('Учитель успешно обновлен');
    } catch (err) {
      showErrorNotification(err.message);
    }
    setUpdateLoading(false);
  };

  const manipulateDeleteModal = value => {
    setTimeout(() => {
      setShowDeleteModal(value);
      if (!value) {
        setDeletingItem(null);
      }
    });
  };

  return (
    <View
      paginationStatus={paginationStatus}
      updateLoading={updateLoading}
      updateTeacher={updateTeacher}
      croppedImage={croppedImage}
      setCroppedImage={setCroppedImage}
      newAvatar={newAvatar}
      adminEdit={adminEdit}
      deletingItem={deletingItem}
      avatarUploading={avatarUploading}
      setAvatarUploading={setAvatarUploading}
      setNewAvatar={setNewAvatar}
      onUploadNewAvatar={onUploadNewAvatar}
      onDeleteAvatar={onDeleteAvatar}
      deleteLoading={deleteLoading}
      setPassportScan={setPassportScan}
      setConvinienceScan={setConvinienceScan}
      setRegistrationScan={setRegistrationScan}
      setFirstDyplomaScan={setFirstDyplomaScan}
      setSecondDyplomaScan={setSecondDyplomaScan}
      data={userData}
      confirmDelete={confirmDelete}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={manipulateDeleteModal}
      handleTeacherChange={handleTeacherChange}
      tabId={tabId}
      gamesTabId={gamesTabId}
      setGamesTabId={selectGamesTabId}
      user={user}
      setShowModal={setShowModal}
      showModal={showModal}
      handleEditMode={handleEditMode}
      handleTab={selectTabId}
      editMode={editMode}
      personals={personals}
      personalsLoading={personalsLoading}
      groups={groups}
      groupsLoading={groupsLoading}
      generalTests={generalTests}
      generalTestsLoading={generalTestsLoading}
      tests={tests}
      testsLoading={testsLoading}
      materials={materials}
      materialsLoading={materialsLoading}
      flashcards={flashcards}
      flashcardsLoading={flashcardsLoading}
      matchup={matchup}
      matchupLoading={matchupLoading}
      anagrams={anagrams}
      deleteItem={deleteItem}
      anagramsLoading={anagramsLoading}
      categorize={categorize}
      invites={invites}
      getCodes={getCodes}
      education={teacherEducation}
      copyToClipboard={copyToClipboard}
      handleEducationChange={handleEducationChange}
      generateCode={generateCode}
      categorizeLoading={categorizeLoading}
      teacherLoading={teacherLoading}
      teacherUpdateLoading={teacherUpdateLoading}
      handleContainerOnBottom={handleContainerOnBottom}
      handleEducationDelete={handleEducationDelete}
      isAdmin={isAdmin}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
