import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { store } from 'react-notifications-component';
import { logOut } from '../../store/reducers/user-reducer';

import UserBlockView from './UserBlockView';
import { showErrorNotification } from '../../core/errorsController';

const UserBlockContainer = props => {
  const [displayUserMenu, setDisplayUserMenu] = useState(false);
  const history = useHistory();
  const userData = useSelector(state => state.user);

  const handleMenu = value => {
    setDisplayUserMenu(value);
  };

  const navigateTo = () => {
    history.push(`/teachers/${userData.teacherId}`);
  };

  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        props.logOut();
      })
      .catch(function (error) {
        showErrorNotification(error.message);
      });
  };

  return (
    <UserBlockView
      displayUserMenu={displayUserMenu}
      navigateTo={navigateTo}
      handleMenu={handleMenu}
      userData={userData}
      logOut={logOut}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut()),
});

export default connect('', mapDispatchToProps)(UserBlockContainer);
