import React from 'react';
import './style.css';
import Icon from '../icon/Icon';
import moment from 'moment';
import ButtonIcon from '../button-icon';

const PushContainer = props => {
  return (
    <div
      ref={props.notificationRef}
      className={`push-notification-wrapper ${
        props.unseen ? 'push-notification-unseen' : ''
      }`}>
      <div className="notification-avatar">
        {props.avatar ? (
          <img
            src={props.avatar}
            className="notification-avatar"
            alt="account"
          />
        ) : props.icon ? (
          <Icon name={props.icon} width={'100%'} height={'100%'} />
        ) : (
          <Icon name="avatar" width={'100%'} height={'100%'} />
        )}
      </div>
      <div className="notification-content">
        <div className="notification-content-header">
          <p className="text notification-title">{props.title}</p>
          <ButtonIcon name="close" onClick={props.onDelete} />
        </div>
        <p className="text notification-text">{props.text}</p>
        <p className="text notification-date">
          {moment(props.date).format('DD MMMM, dddd, в HH:mm')}
        </p>
      </div>
    </div>
  );
};

export default PushContainer;
