import React from 'react';
import Loader from '../loader/Loader';
import './style.css';

const ProgressCard = ({
  amount,
  description = 'statistics',
  loading = false,
  size = 'half',
  postfix = '',
}) => {
  const getSizeClasses = () => {
    switch (size) {
      case 'full':
        return 'progress-card-full';
      case 'half':
        return 'progress-card-half';
      case 'third':
        return 'progress-card-third';
      default:
        return '';
    }
  };

  const getLoaderSizeClass = () => {
    switch (size) {
      case 'full':
        return 'progress-card-loader-full';
      case 'half':
        return 'progress-card-loader-half';
      case 'third':
        return 'progress-card-loader-third';
    }
  };

  return (
    <div className={`progress-card ${getSizeClasses()}`}>
      <div className="block">
        {loading ? (
          <Loader
            color={'black'}
            className={`progress-card-loader ${getLoaderSizeClass()}`}
          />
        ) : (
          <p className="number">{amount + postfix}</p>
        )}
        <span className="title">{description}</span>
      </div>
    </div>
  );
};

export default ProgressCard;
