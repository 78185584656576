import React from 'react';
import { useSelector } from 'react-redux';

import View from './view';

const Container = ({
  type = 'group',
  renderEmpty = () => {},
  renderControl,
  data = [],
  last = false,
  loading = false,
  paginate = () => {},
}) => {
  const groups = useSelector(state => state.groups);
  const teachers = useSelector(state => state.teachers);

  return (
    <View
      type={type}
      groups={groups}
      teachers={teachers}
      renderControl={renderControl}
      last={last}
      paginate={paginate}
      loading={loading}
      renderEmpty={renderEmpty}
      data={data}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default Container;
