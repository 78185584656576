import React, { useEffect } from 'react';
import ReactNotification from 'react-notifications-component';

import './style.css';
import Menu from '../../components/menu/Menu';
import EditorView from '../../components/editor/Editor';
import Container from '../../components/container';
import { Route, Redirect, Switch, Prompt } from 'react-router-dom';
import DashboardContainer from '../../modules/dashboard/DashboardContainer';
import HeaderContainer from '../../modules/header/HeaderContainer';
import {
  Groups,
  Teacher,
  User,
  Users,
  PersonalClasses,
  PersonalClass,
  AddGroup,
  AddPersonal,
  Invites,
  Materials,
  Group,
  Tests,
  Teachers,
  Games,
  Calendar,
  Results,
  GeneralTests,
  News,
  GeneralResult,
  AboutUs,
} from '../../modules';
import Loader from '../../components/loader/Loader';
import DialogModal from '../../components/dialogModal/DialogModal';

const MainView = props => {
  if (!props.inited) {
    return <Loader />;
  }

  return (
    <div
      className={`main-container ${
        props.appState?.isScrollable ? 'scrollable' : 'non-scrollable'
      }`}>
      <HeaderContainer />
      <Container>
        <div
          className={`main-wrapper ${
            props.appState?.isScrollable ? 'scrollable' : 'non-scrollable'
          }`}>
          <Menu />
          <Prompt
            when={props.appState.isEditing}
            message={({ pathname }) => {
              props.setNextLocation(pathname);
              props.setShowModal(true);
              return false;
            }}
          />
          <DialogModal
            title="Предупреждение"
            show={props.showModal}
            setShow={props.setShowModal}
            okText={'Принять'}
            onDelete={props.confirmRedirect}>
            <p className="modal-message">
              Вы действительно хотите уйти? Все несохраненные изменения бyдут
              потеряны.
            </p>
          </DialogModal>
          <div className="content">
            <Switch>
              <Route path="/dashboard" render={() => <DashboardContainer />} />
              <Route exact path="/groups" render={() => <Groups />} />
              <Route
                exact
                path="/personal"
                render={() => <PersonalClasses />}
              />
              <Route path="/groups/:id" render={() => <Group />} />
              <Route path="/personal/:id" render={() => <PersonalClass />} />
              <Route path="/materials/:id?" render={() => <Materials />} />
              <Route path="/calendar" render={() => <Calendar />} />
              <Route path="/tests/:id?" render={() => <Tests />} />
              <Route
                path="/general-tests/:id?"
                render={() => <GeneralTests />}
              />
              <Route path="/about-us" render={() => <AboutUs />} />
              <Route path="/games/:id?" render={() => <Games />} />
              <Route path="/teachers/:id" render={() => <Teacher />} />
              <Route exact path="/teachers" render={() => <Teachers />} />
              <Route path="/users/:id" render={() => <User />} />
              <Route exact path="/users" render={() => <Users />} />
              <Route exact path="/invites" render={() => <Invites />} />
              <Route exact path="/results/:id" render={() => <Results />} />
              <Route path="/news" render={() => <News />} />
              <Route
                exact
                path="/general-result/:id"
                render={() => <GeneralResult />}
              />
              <Route>
                <Redirect to="/groups" />
              </Route>
            </Switch>
          </div>
        </div>
      </Container>
      <ReactNotification />
    </div>
  );
};

export default MainView;
