import React from 'react';
import Dropdown from 'react-dropdown';
import { NavLink } from 'react-router-dom';

import './style.css';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';
import TestType from '../../components/create-test/test-type';
import AddQuestion from '../../components/create-test/add-question';
import { LabeledCheckbox, List, Modal } from '../../components';
import Select from '../../components/select';
import Icon from '../../components/icon/Icon';
import Textarea from '../../components/textarea';
import {
  getGroupsFormattedName,
  getStudentsFormattedName,
  getTeachersFormattedName,
} from '../../core/functions';
import DialogModal from '../../components/dialogModal/DialogModal';
import FileInput from '../../components/fileInput/FileInput';
import DateTimePicker from '../../components/dateTimePicker/DateTimePicker';
import teachersReducer from '../../store/reducers/teachers-reducer';
import Input from '../../components/input/index';
import { AVAILABLE_MOBILE_APP_AUDIO_EXTENSIONS } from '../../core/config';

const TasksView = props => {
  const handleDataOnBottom = async e => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;

    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      await props.getTests();
    }
  };

  const renderQuestions = props => {
    return props.testQuestions.length
      ? props.testQuestions.map((item, index) => (
          <AddQuestion
            id={index}
            key={`answer-${index}`}
            question={item}
            answers={item.answers}
            removeHandle={props.removeQuestionHandle}
            selectionMap={props.selectionMap?.[index]}
            handleDeleteAnswer={props.removeAnswerHandle}
            handleQuestionTextChange={props.handleQuestionTextChange}
            handleAddAnswer={props.handleAddAnswer}
            handleAnswerTextChange={props.handleAnswerTextChange}
            handleAnswerRightChange={props.handleAnswerRightChange}
            disabled={props.newTestInfo.id && props.checkDisabling()}
          />
        ))
      : null;
  };

  const getModalData = type => {
    switch (type) {
      case 'delete_test':
        return {
          title: 'Удаление теста',
          text: 'Вы действительно хотите удалить тест?',
          callback: props.deleteTest,
        };
      case 'delete_question':
        return {
          title: 'Удаление вопроса',
          text: 'Вы действительно хотите удалить вопрос?',
          callback: props.deleteQuestion,
        };
      case 'delete_answer':
        return {
          title: 'Удаление ответа',
          text: 'Вы действительно хотите удалить ответ?',
          callback: props.handleDeleteAnswer,
        };
      case 'delete_subtest_question':
        return {
          title: 'Удаление вопроса',
          text: 'Вы действительно хотите удалить вопрос?',
          callback: props.deleteSubtestQuestion,
        };
      case 'delete_subtest':
        return {
          title: 'Удаление блока вопросов',
          text: 'Вы действительно хотите удалить блок вопросов?',
          callback: props.deleteQuestionBlock,
        };
    }
  };

  const modalData = getModalData(props.modalType);

  return (
    <div className="tests-container">
      <DialogModal
        title={modalData?.title}
        show={props.showDeleteModal}
        setShow={props.setShowDeleteModal}
        loading={props.deleteLoading}
        onDelete={() => {
          return modalData?.callback();
        }}>
        {modalData ? (
          <span className="add-lesson-modal-item-last">{modalData?.text}</span>
        ) : null}
      </DialogModal>
      <Modal
        show={props.showModal}
        setShow={props.setShowModal}
        title="Привяжите тест">
        {props.isAdmin && (
          <Select
            label="Учитель"
            direction="bottom"
            containerClass="add-lesson-modal-item"
            value={
              props.selectedTeacher
                ? getTeachersFormattedName(
                    props.selectedTeacher,
                    props.teachers,
                  )
                : ''
            }
            width={'100%'}
            data={props.teachers}
            search={true}
            renderEmpty={() => (
              <div className="option option-empty">Нет доступных учителей</div>
            )}
            onReset={() => props.setSelectedTeacher()}
            placeholder={'Выберите учителя'}
            renderOption={el => {
              return (
                <div
                  className="option"
                  onMouseDown={() => {
                    props.setSelectedTeacher(el.teacherId);
                    props.setSelectedOrganizer();
                  }}>
                  {getTeachersFormattedName(el.teacherId, props.teachers)}
                </div>
              );
            }}
            extractSearchKey={el =>
              getTeachersFormattedName(el.teacherId, props.teachers)
            }
          />
        )}
        <Select
          label="Группа/занятие"
          direction="bottom"
          containerClass="add-lesson-modal-item"
          value={
            props.selectedOrganizer
              ? `${
                  props.selectedOrganizer.type === 'group'
                    ? getGroupsFormattedName(
                        props.selectedOrganizer.id,
                        props.organizers,
                      )
                    : getStudentsFormattedName(
                        props.selectedOrganizer.studentId,
                        props.students,
                      )
                } (Учитель: ${getTeachersFormattedName(
                  props.selectedOrganizer.teacherId,
                  props.teachers,
                )})`
              : ''
          }
          data={
            props.selectedTeacher
              ? props.organizers.filter(
                  el => el.teacherId === props.selectedTeacher,
                )
              : props.organizers
          }
          search={true}
          width={'100%'}
          renderEmpty={() => (
            <div className="option option-empty">
              Нет доступных групп и персональных занятий
            </div>
          )}
          onReset={() => props.setSelectedOrganizer()}
          placeholder="Выберите группу или персональное занятие"
          renderOption={el => {
            return (
              <div
                className="option"
                onMouseDown={() => {
                  props.setSelectedOrganizer({
                    id: el.groupId || el.classId,
                    type: el.groupId ? 'group' : 'class',
                    studentId: el.studentId,
                    teacherId: el.teacherId,
                  });
                  props.setSelectedTeacher(el.teacherId);
                }}>
                {`${
                  el.groupId
                    ? getGroupsFormattedName(el.groupId, props.organizers)
                    : getStudentsFormattedName(el.studentId, props.students)
                } (Учитель: ${getTeachersFormattedName(
                  el.teacherId,
                  props.teachers,
                )})`}
              </div>
            );
          }}
          extractSearchKey={el =>
            el.groupId
              ? getGroupsFormattedName(el.groupId, props.organizers)
              : getStudentsFormattedName(el.studentId, props.students)
          }
        />
        <DateTimePicker
          className="add-lesson-modal-item-last"
          title="Дата открытия материала"
          placeholder="Выберите дату открытия"
          minDate={new Date()}
          format={'DD MMMM, dd HH:mm'}
          variant="inline"
          wrongTime={props.wrongTime}
          setWrongTime={props.setWrongTime}
          inputProps={{ className: 'date-input' }}
          value={props.selectedDate ? props.selectedDate : null}
          onChange={date => {
            props.setSelectedDate(date);
          }}
          disabled={props.newTestInfo.id && props.checkDisabling()}
        />
        <Button
          small
          textButton="Привязать тест"
          onClick={props.attachTest}
          disabled={
            !(props.selectedOrganizer && props.selectedDate) || props.wrongTime
          }
          loading={props.attachingTestLoading}
        />
      </Modal>
      <List
        renderItem={item => {
          return (
            <NavLink to={`/tests/${item.testId}`}>
              <div
                className="item-list-container"
                onClick={() => {
                  props.setNextLinkItem(item);
                }}>
                <div>{item.title}</div>
                <div className="item-creator">
                  {getTeachersFormattedName(item.teacherId, props.teachers)}
                </div>
              </div>
            </NavLink>
          );
        }}
        renderHeader={() => {
          return (
            <NavLink to="/tests">
              <div className="add-new-item-button-container">
                <Button
                  small
                  textButton="Добавить тест"
                  onClick={() => props.setNextLinkItem(null)}
                />
              </div>
            </NavLink>
          );
        }}
        list={props.myTests}
        loading={props.isLoading}
        onScroll={handleDataOnBottom}
        emptySign="У Вас еще нет сохраненных тестов"
      />
      <div className="new-test-container">
        <div className="new-test-header">
          <div className="new-test-header-left">
            <div className="new-test-title-container">
              <Input
                showCounter={true}
                type="text"
                containerClassName="new-test-title"
                placeholder="Новый тест"
                maxLength={200}
                onChange={e =>
                  props.handleTestInfoChange('title', e.target.value)
                }
                value={props.newTestInfo.title}
                disabled={props.newTestInfo.id && props.checkDisabling()}
              />
            </div>
            <div className="new-test-description-container">
              <Textarea
                showCounter={true}
                className="new-test-description"
                placeholder="Описание"
                maxLength={1000}
                onChange={e =>
                  props.handleTestInfoChange('description', e.target.value)
                }
                value={props.newTestInfo.description}
                disabled={props.newTestInfo.id && props.checkDisabling()}
              />
            </div>
          </div>
          <div className="new-test-header-right">
            {props.newTestInfo.id &&
              (props.isAdmin ||
                props.newTestInfo.teacherId === props.userData.teacherId) && (
                <div className="new-test-cancel-container">
                  <Button
                    type="secondary"
                    small
                    textButton="Удалить тест"
                    disabled={props.saveLoading}
                    onClick={props.deleteModalOpen}
                  />
                </div>
              )}
            <div className="new-test-save-container">
              {props.newTestInfo.id &&
              (props.isAdmin ||
                props.newTestInfo.teacherId === props.userData.teacherId) ? (
                <Button
                  small
                  textButton="Обновить"
                  onClick={() => props.updateTest()}
                  disabled={props.saveLoading}
                />
              ) : (
                !props.newTestInfo.id && (
                  <Button
                    small
                    textButton="Сохранить"
                    onClick={() => props.saveNewTest()}
                    disabled={props.saveLoading || !props.newTestInfo.title}
                  />
                )
              )}
              {props.saveLoading && (
                <div
                  className="uploading-progress"
                  style={{ width: `${props.fileLoading}%` }}
                />
              )}
            </div>
            {props.newTestInfo.id && (
              <div className="new-test-attach-container">
                <Button
                  small
                  textButton="Привязать тест"
                  loading={props.attachingTestLoading}
                  onClick={props.showAttachingTestModal}
                />
              </div>
            )}
          </div>
        </div>

        <div className="new-test-type-container">
          <div className="new-test-type-title">Опции</div>
          <div className="new-test-type-list-container-stupid-paddings">
            <FileInput
              title="Аудирование"
              class="new-teacher-name"
              disabledText="Недоступно для данного типа"
              placeholder="Добавьте аудиозапись"
              disabled={
                props.newTestInfo.type !== 'listening' ||
                (props.newTestInfo.id && props.checkDisabling())
              }
              extensions={AVAILABLE_MOBILE_APP_AUDIO_EXTENSIONS}
              handleFileDelete={() => props.handleTestInfoChange('file', {})}
              handleFileSelect={(blob, fileName) =>
                props.handleTestInfoChange('file', { blob, fileName })
              }
              fileAddr={
                typeof props.newTestInfo.file === 'string'
                  ? props.newTestInfo.file
                  : null
              }
            />

            <LabeledCheckbox
              title="Показывать правильные ответы"
              disabled={
                props.editModa ||
                (props.newTestInfo.id && props.checkDisabling())
              }
              value={props.newTestInfo.showRight}
              onClick={() => {
                props.handleTestInfoChange(
                  'showRight',
                  !props.newTestInfo.showRight,
                );
              }}
            />
            <LabeledCheckbox
              title="Множественный выбор"
              disabled={
                props.editModa ||
                (props.newTestInfo.id && props.checkDisabling())
              }
              value={props.newTestInfo.multiplie}
              onClick={() => {
                props.handleTestInfoChange(
                  'multiplie',
                  !props.newTestInfo.multiplie,
                );
              }}
            />
          </div>
        </div>
        <div className="new-test-type-container">
          <div className="new-test-type-title">Раздел языка</div>
          <div className="new-test-type-list-container">
            <TestType
              text="Грамматика"
              type="grammar"
              active={props.newTestInfo.type === 'grammar'}
              onStateChange={type => {
                props.handleTestInfoChange('type', type);
              }}
              disabled={props.newTestInfo.id && props.checkDisabling()}
            />
            <TestType
              text="Лексика"
              type="vocabulary"
              active={props.newTestInfo.type === 'vocabulary'}
              onStateChange={type => {
                props.handleTestInfoChange('type', type);
              }}
              disabled={props.newTestInfo.id && props.checkDisabling()}
            />
            <TestType
              text="Аудирование"
              type="listening"
              active={props.newTestInfo.type === 'listening'}
              onStateChange={type => {
                props.handleTestInfoChange('type', type);
              }}
              disabled={props.newTestInfo.id && props.checkDisabling()}
            />
            <TestType
              text="Перевод"
              type="translation"
              active={props.newTestInfo.type === 'translation'}
              onStateChange={type => {
                props.handleTestInfoChange('type', type);
              }}
              disabled={props.newTestInfo.id && props.checkDisabling()}
            />
            <TestType
              text="Фонетика"
              type="phonetics"
              active={props.newTestInfo.type === 'phonetics'}
              onStateChange={type => {
                props.handleTestInfoChange('type', type);
              }}
              disabled={props.newTestInfo.id && props.checkDisabling()}
            />
          </div>
        </div>
        <div className="new-test-questions-container">
          <div className="new-test-questions-header">
            <div className="new-test-questions-header-left">
              <div className="new-test-questions-title">Вопросы</div>
            </div>
            <div
              onClick={props.addQuestionHandle}
              className={props.renderAddQuestionButtonStyle()}>
              <img
                className={props.renderAddQuestionIconStyle()}
                src={require('../../assets/pictures/plus.svg')}
                alt="Новый вопрос"
              />
            </div>
          </div>
          {renderQuestions(props)}
        </div>
      </div>
    </div>
  );
};

export default TasksView;
