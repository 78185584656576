import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import { getStudentsFormattedName } from '../../core/functions';
import moment from 'moment';

const getStatus = status => {
  switch (status) {
    case 'pending':
      return 'Обрабатывается';
    case 'created':
      return 'Не оплачено';
    case 'failed':
      return 'Неудачно';
    case 'confirmed':
      return 'Оплачено';
    case 'canceled':
      return 'Отмена';
    default:
      return status;
  }
};

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      {/* <NavLink to={`users/${data.id}`} className="box box-1">{data.id}</NavLink> */}
      <div className="box box-2">
        <div>c {moment(data.fromDate).format('DD MMMM YYYY г.')}</div>
        <div>по {moment(data.toDate).format('DD MMMM YYYY г.')}</div>
      </div>
      <div className="box box-3">
        {getStudentsFormattedName(data.studentId, props.students)}
      </div>
      <div className="box box-4"> {data?.value + ' RUB'}</div>
      <div className="box box-5">
        {data.capturedPayment?.captured_at
          ? moment(data.capturedPayment?.captured_at).format('DD MMMM YYYY г.')
          : '-'}
      </div>
      <div className="box box-6">
        {data.status ? getStatus(data.status) : '-'}
      </div>
      {/* <div className="box box-7">
        {props.renderControl ? (
          props.renderControl(data)
        ) : (
          <Button
            type={data.active ? 'secondary' : 'primary'}
            small
            textButton={data.active ? 'Удалить' : 'Добавить'}
            onClick={() => {
              props.deleteTest(data.test);
            }}
          />
        )}
      </div> */}
    </div>
  );

  return (
    <>
      <div onScroll={props.paginate} className="table group-payments-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Период</div>
          <div className="box box-3">Студент</div>
          <div className="box box-4">Сумма</div>
          <div className="box box-5">Дата оплаты</div>
          <div className="box box-6">Статус</div>
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((paymentWrapper, index) => (
                <TableLine
                  key={paymentWrapper.payment?.id}
                  index={index}
                  {...paymentWrapper}
                />
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
      {props.type !== 'teacher' ? (
        <div className="controls-container">
          <Button
            type="secondary"
            textButton="Удалить оплату"
            onClick={() => {
              props.deletePayment();
            }}
          />
          <Button
            type="primary"
            textButton="Выставить оплату"
            onClick={() => {
              props.newPayment();
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default UsersTableView;
