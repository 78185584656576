import React from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import './style.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import { LabeledCheckbox } from '../../components';
import { getTeacherBirthBoundry } from '../../core/functions';
import DatePicker from '../../components/datePicker/DatePicker.jsx';

const Registration = props => {
  return (
    <div className="loginContainer">
      <div className="half left">
        <Logo />
        <div className="registration__form__container">
          <div className="loginTitle">Введите адрес электронной почты</div>
          <Input
            type="name"
            title="Адрес электронной почты"
            className="registration-input"
            value={props.email}
            placeholder="email@service.com"
            onChange={e => props.setEmail(e.target.value)}
          />
          <Button
            loading={props.loading}
            disabled={!props.email}
            className="centered"
            onClick={() => props.sendRestoreEmail()}
            textButton="Восстановить"
          />
          <NavLink to="/auth" className="moveToRegistration">
            Вернуться к авторизации
          </NavLink>
        </div>
      </div>
      <div className="half right">
        <div
          style={{
            backgroundImage: `url('${require('../../assets/pictures/authPicture.png')}')`,
          }}
          alt="Языковая студия Nota5"
        />
      </div>
    </div>
  );
};

export default Registration;
