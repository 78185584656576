import React from 'react';
import { useSelector } from 'react-redux';

import View from './view';

const Container = ({
  renderEmpty = () => {},
  deleteTest = () => {},
  renderControl,
  data = [],
  type,
  last = false,
  loading = false,
  paginate = () => {},
  newPayment = () => {},
  deletePayment = () => {},
}) => {
  const students = useSelector(state => state.students);

  return (
    <View
      students={students}
      renderControl={renderControl}
      last={last}
      type={type}
      paginate={paginate}
      newPayment={newPayment}
      loading={loading}
      renderEmpty={renderEmpty}
      deleteTest={deleteTest}
      data={data}
      deletePayment={deletePayment}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default Container;
