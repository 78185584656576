import React from 'react';
import './style.css';
import Icon from '../icon/Icon';
const ButtonIcon = props => {
  const renderIcon = () => {
    switch (props.name) {
      default:
        return <Icon name={props.name} />;
    }
  };
  return (
    <div className="button-icon-container">
      <button onClick={props.onClick}>{renderIcon()}</button>
    </div>
  );
};

export default ButtonIcon;
