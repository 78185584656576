import React from 'react';

import './style.css';
import TeachersTableContainer from '../../components/teachersTable/TeachersTableContainer';
import { NavLink } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import DialogModal from '../../components/dialogModal/DialogModal';

const TeachersView = props => {
  if (!props.teachers.length) {
    return <Loader />;
  }

  return (
    <>
      <DialogModal
        setShow={props.setShowModal}
        show={props.showModal}
        title="Удаление учителя"
        loading={props.deleteTeacherLoading}
        onDelete={props.deleteTeacher}>
        <span className="add-lesson-modal-item-last">
          Вы действительно хотите удалить учителя{' '}
          <strong>
            {props.deletingTeacher?.name} {props.deletingTeacher?.lastName}
          </strong>
          ?
          <br />
          <em>
            *Удаление приведет к потере всех материалов, тестов, игр и данных об
            этом учителе
          </em>
        </span>
      </DialogModal>
      <div className="teachers-header">
        <NavLink to="/invites" className="button-add-group">
          Сгенерировать инвайт код
        </NavLink>
      </div>
      <TeachersTableContainer
        teachers={props.teachers}
        deleteTeacher={teacher => props.confirmDelete(teacher)}
      />
    </>
  );
};

export default TeachersView;
