import React from 'react';

import View from './view';

const Container = ({
  renderEmpty = () => {},
  loading = false,
  data = [],
  last = false,
  renderControl,
  type,
  paginate = () => {},
  shareStudents = () => {},
}) => {
  return (
    <View
      paginate={paginate}
      type={type}
      renderEmpty={renderEmpty}
      loading={loading}
      last={last}
      renderControl={renderControl}
      data={data}
      shareStudents={shareStudents}
    />
  );
};

export default Container;
