import React, { useRef, useEffect } from 'react';
import { showErrorNotification } from '../../core/errorsController';
import Icon from '../icon/Icon';
import SelectableContainer from '../selectableContainer/SelectableContainer';
import './style.css';

const FileInput = ({
  placeholder = '',
  externalRef = {},
  disabled = false,
  disabledText = '',
  title = '',
  className = '',
  fileAddr = '',
  extensions = [],
  maxSize = '',
  handleFileSelect = () => {},
  handleFileDelete = () => {},
}) => {
  const uploader = useRef(null);

  const toggleUploader = () => {
    uploader.current.click();
  };

  const unsetFileSelector = () => {
    uploader.current.value = '';
  };

  useEffect(() => {
    externalRef.current = uploader.current;
  }, [externalRef]);

  const checkExtensions = fileName => {
    if (extensions.length) {
      const fileExtension = fileName.split('.').pop();
      if (!fileExtension) {
        showErrorNotification(
          'Произошла ошибка, проверьте расширение загружаемого файла',
        );
        unsetFileSelector();
        return false;
      }
      if (!extensions.includes(fileExtension)) {
        showErrorNotification(
          'Пожалуйста, загрузите файл требуемого расширения, доступные расширения: ' +
            extensions.join(', '),
        );
        unsetFileSelector();
        return false;
      }
    }
    return true;
  };

  const checkMaxSize = file => {
    if (maxSize) {
      if (file.size > maxSize * 1024 * 1024) {
        showErrorNotification(
          'Превышен максимальный размер файла, пожалуйста, загрузите файл размером до ' +
            maxSize +
            ' МБ',
        );
        unsetFileSelector();
        return false;
      }
    }
    return true;
  };

  const getBlob = async (file, name) => {
    const _blob = new Blob([file]);
    const _name = file.name;
    if (checkExtensions(_name) && checkMaxSize(file)) {
      handleFileSelect(_blob, _name);
    }
  };

  return (
    <div>
      {title && <div className="inputTitle">{title}</div>}
      <SelectableContainer
        disabled={disabled}
        className={`input-container ${className}`}>
        <div
          className={`upload-area ${!disabled ? 'pointer' : ''}`}
          onClick={!disabled ? toggleUploader : null}>
          {!disabled ? (
            <div className="file-control-wrapper">
              <div className="file-control">
                <Icon name="upload" color="black" width={24} height={24} />
              </div>
            </div>
          ) : null}
          <div className="file-name input">
            {uploader.current?.files?.[0]?.name ? (
              <span>{uploader.current.files[0].name}</span>
            ) : fileAddr ? (
              <span>Доступно для скачивания</span>
            ) : disabled ? (
              <span className="file-input-placeholder">
                {disabledText ? disabledText : 'Нет данных'}
              </span>
            ) : (
              <span className="file-input-placeholder">{placeholder}</span>
            )}
          </div>
        </div>
        <input
          ref={uploader}
          type="file"
          accept={
            extensions.length ? extensions.map(el => '.' + el).join(',') : null
          }
          className="file-input"
          onChange={e => {
            const file = e.target.files[0];
            getBlob(file, file.name);
          }}
        />
        {fileAddr ? (
          <a href={fileAddr}>
            <div className="file-control-wrapper">
              <div className="file-control">
                <Icon name="download" color="black" width={24} height={24} />
              </div>
            </div>
          </a>
        ) : null}
        {fileAddr || uploader.current?.files?.[0]?.name ? (
          <div
            onClick={() => {
              uploader.current.value = '';
              handleFileDelete();
            }}
            className="file-control-wrapper">
            <div className="file-control">
              <Icon name="trash" color="black" width={24} height={24} />
            </div>
          </div>
        ) : null}
      </SelectableContainer>
    </div>
  );
};

export default FileInput;
