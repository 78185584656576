/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { GroupsService, PersonalsService, UsersService } from '../../services';
import { getId } from '../../core/functions';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { showErrorNotification } from '../../core/errorsController';
import { cloneDeep } from 'lodash';

import View from './view';
import { setScroll } from '../../store/reducers/appState-reducer';

const paginationInitials = {
  tests: {
    page: 1,
    last: false,
  },
  generalTests: {
    page: 1,
    last: false,
  },
  flashcards: {
    page: 1,
    last: false,
  },
  anagrams: {
    page: 1,
    last: false,
  },
  categorize: {
    page: 1,
    last: false,
  },
  matchup: {
    page: 1,
    last: false,
  },
  transferedEvents: {
    page: 1,
    last: false,
  },
  events: {
    page: 1,
    last: false,
  },
  payments: {
    page: 1,
    last: false,
  },
};

const TasksContainer = props => {
  const teachers = useSelector(state => state.teachers);
  const students = useSelector(state => state.students);

  const [tests, setTests] = useState([]);
  const [flashcards, setFlashcards] = useState([]);
  const [matchup, setMatchup] = useState([]);
  const [anagrams, setAnagrams] = useState([]);
  const [categorize, setCategorize] = useState([]);
  const [events, setEvents] = useState([]);
  const [transferedEvents, setTransferedEvents] = useState([]);
  const [payments, setPayments] = useState([]);
  const [generalTests, setGeneralTests] = useState([]);

  const [transferedEventsLoading, setTransferedEventsLoading] = useState(false);
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [generalTestsLoading, setGeneralTestsLoading] = useState(false);
  const [testsLoading, setTestsLoading] = useState(false);
  const [flashcardsLoading, setFlashcardsLoading] = useState(false);
  const [matchupLoading, setMatchupLoading] = useState(false);
  const [anagramsLoading, setAnagramsLoading] = useState(false);
  const [categorizeLoading, setCategorizeLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const loggedTeacher = useSelector(state => state.user);
  const isAdmin = loggedTeacher.role === 'admin';
  const history = useHistory();
  const [selectedTeacher, setSelectedTeacher] = useState(
    isAdmin ? null : loggedTeacher.teacherId,
  );
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [tabId, setTabId] = useState(1);
  const [gamesTabId, setGamesTabId] = useState(1);
  const [organizers, setOrganizers] = useState([]);
  const [selectedOrganizer, setSelectedOrganizer] = useState(null);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [filteredOrganizerData, setFilteredOrganizerData] = useState(true);
  const [personalLoading, setPersonalLoading] = useState(true);
  const [TargetService, setTargetService] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const [paginationStatus, setPaginationStatus] = useState(
    cloneDeep(paginationInitials),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getUserLessonsOrganizer(userId);
  }, [userId]);

  useEffect(() => {
    setUserId(getId(history.location.pathname));
    setUserData(
      students.find(el => el.studentId === getId(history.location.pathname)),
    );
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setScroll(false));
  }, []);

  const updatePaginationStatus = async (key, last) => {
    setPaginationStatus(old => {
      return {
        ...old,
        [key]: {
          page: paginationStatus[key].page + 1,
          last: last,
        },
      };
    });
  };

  const getTests = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
  ) => {
    if ((_targetId && !paginationStatus.tests.last && !testsLoading) || force) {
      setTestsLoading(true);
      try {
        const result = await _TargetService.getCompletedTests(
          _targetId,
          userData.studentId,
          force ? 1 : paginationStatus.tests.page,
        );
        setTests(
          force
            ? result.data.map(item => {
                return { ...item, ...item.test };
              })
            : [
                ...tests,
                ...result.data.map(item => {
                  return { ...item, ...item.test };
                }),
              ],
        );
        updatePaginationStatus('tests', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setTestsLoading(false);
    }
  };

  const getPayments = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
  ) => {
    if (
      (_targetId && !paginationStatus.payments.last && !paymentsLoading) ||
      force
    ) {
      setPaymentsLoading(true);
      try {
        const result = await _TargetService.getStudentPayments(
          _targetId,
          userData.studentId,
          force ? 1 : paginationStatus.payments.page,
        );
        setPayments(
          force
            ? result.data.map(item => {
                return { ...item, ...item.test };
              })
            : [
                ...payments,
                ...result.data.map(item => {
                  return { ...item, ...item.test };
                }),
              ],
        );
        updatePaginationStatus('payments', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setPaymentsLoading(false);
    }
  };

  const getGeneralTests = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
  ) => {
    if (
      (_targetId &&
        !paginationStatus.generalTests.last &&
        !generalTestsLoading) ||
      force
    ) {
      setGeneralTestsLoading(true);
      try {
        const result = await _TargetService.getCompletedGeneralTests(
          _targetId,
          userData.studentId,
          force ? 1 : paginationStatus.generalTests.page,
        );
        setGeneralTests(
          force
            ? result.data.map(item => {
                return { ...item, ...item.test };
              })
            : [
                ...generalTests,
                ...result.data.map(item => {
                  return { ...item, ...item.test };
                }),
              ],
        );
        updatePaginationStatus('generalTests', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setGeneralTestsLoading(false);
    }
  };

  const getFlashcards = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
  ) => {
    if (
      (_targetId && !paginationStatus.flashcards.last && !flashcardsLoading) ||
      force
    ) {
      setFlashcardsLoading(true);
      try {
        const result = await _TargetService.getCompletedFlashcards(
          _targetId,
          userData.studentId,
          paginationStatus.flashcards.page,
        );
        setFlashcards([
          ...flashcards,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        updatePaginationStatus('flashcards', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setFlashcardsLoading(false);
    }
  };

  const getMatchup = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
  ) => {
    if (
      (_targetId && !paginationStatus.matchup.last && !matchupLoading) ||
      force
    ) {
      setMatchupLoading(true);
      try {
        const result = await _TargetService.getCompletedMatchup(
          _targetId,
          userData.studentId,
          paginationStatus.matchup.page,
        );
        setMatchup([
          ...matchup,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        updatePaginationStatus('matchup', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setMatchupLoading(false);
    }
  };

  const getAnagrams = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
  ) => {
    if (
      (_targetId && !paginationStatus.anagrams.last && !anagramsLoading) ||
      force
    ) {
      setAnagramsLoading(true);
      try {
        const result = await _TargetService.getCompletedAnagrams(
          _targetId,
          userData.studentId,
          paginationStatus.anagrams.page,
        );
        setAnagrams([
          ...anagrams,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        updatePaginationStatus('anagrams', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setAnagramsLoading(false);
    }
  };

  const getCategorize = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
  ) => {
    if (
      (_targetId && !paginationStatus.categorize.last && !categorizeLoading) ||
      force
    ) {
      setCategorizeLoading(true);
      try {
        const result = await _TargetService.getCompletedCategorize(
          _targetId,
          userData.studentId,
          paginationStatus.categorize.page,
        );
        setCategorize([
          ...categorize,
          ...result.data.map(item => {
            return { ...item, has: true, active: true };
          }),
        ]);
        updatePaginationStatus('categorize', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setCategorizeLoading(false);
    }
  };

  const getEvents = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
    _selectedOrganizer = selectedOrganizer,
  ) => {
    if (
      (_targetId && !paginationStatus.events.last && !eventsLoading) ||
      force
    ) {
      setEventsLoading(true);
      try {
        const ids = _selectedOrganizer?.classId
          ? {
              teacherId: _selectedOrganizer.teacherId,
              studentId: userData.studentId,
            }
          : {
              groupId: _selectedOrganizer.groupId,
            };
        const result = await _TargetService.getEvents(
          ids,
          force ? 1 : paginationStatus.events.page,
        );
        setEvents(
          force
            ? result.data.map(item => {
                return { ...item };
              })
            : [
                ...events,
                ...result.data.map(item => {
                  return { ...item };
                }),
              ],
        );
        updatePaginationStatus('events', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setEventsLoading(false);
    }
  };

  const getTransferedEvents = async (
    _TargetService = TargetService,
    _targetId = targetId,
    force = false,
    _selectedOrganizer = selectedOrganizer,
  ) => {
    if (
      (_targetId &&
        !paginationStatus.transferedEvents.last &&
        !transferedEventsLoading) ||
      force
    ) {
      setTransferedEventsLoading(true);
      try {
        const ids = {
          oldTeacherId: _selectedOrganizer.teacherId,
          studentId: userData.studentId,
        };
        const result = await _TargetService.getTransferedEvents(
          ids,
          force ? 1 : paginationStatus.transferedEvents.page,
        );
        setTransferedEvents(
          force
            ? result?.data.map(item => {
                return { ...item };
              })
            : [
                ...transferedEvents,
                ...result?.data.map(item => {
                  return { ...item };
                }),
              ],
        );
        updatePaginationStatus('transferedEvents', result.last);
      } catch (err) {
        showErrorNotification(err.message);
      }
      setTransferedEventsLoading(false);
    }
  };

  const getUserLessonsOrganizer = (id = userId) => {
    if (id) {
      try {
        const groups = UsersService.getUserGroups(id);
        const personals = UsersService.getUserPersonals(id);
        Promise.all([groups, personals])
          .then(values => {
            const temp = values
              .map(el => el.data)
              .flat()
              .reverse();
            setOrganizers(temp);
            filterOrganizerData(selectedTeacher, temp);
          })
          .catch(error => showErrorNotification(error.message));
        setGroupsLoading(false);
      } catch (err) {
        showErrorNotification(err.message);
      }
    }
  };

  const fetchData = (
    _tabId = tabId,
    _gamesTabId = gamesTabId,
    force = false,
    _TargetService,
    _targetId,
    _selectedOrganizer,
  ) => {
    switch (_tabId) {
      case 1:
        getTests(_TargetService, _targetId, force);
        break;
      case 2:
        switch (_gamesTabId) {
          case 1:
            getFlashcards(_TargetService, _targetId, force);
            break;
          case 2:
            getMatchup(_TargetService, _targetId, force);
            break;
          case 3:
            getAnagrams(_TargetService, _targetId, force);
            break;
          case 4:
            getCategorize(_TargetService, _targetId, force);
            break;
          default:
            return;
        }
        break;
      case 3:
        getEvents(_TargetService, _targetId, force, _selectedOrganizer);
        break;
      case 4:
        getPayments(_TargetService, _targetId, force);
        break;
      case 5:
        getTransferedEvents(
          _TargetService,
          _targetId,
          force,
          _selectedOrganizer,
        );
        break;
      case 6:
        getGeneralTests(_TargetService, _targetId, force);
        break;
      default:
        return;
    }
  };

  const clearTables = () => {
    setTests([]);
    setAnagrams([]);
    setCategorize([]);
    setMatchup([]);
    setFlashcards([]);
    setEvents([]);
    setGeneralTests([]);
    setTransferedEvents([]);
    setPayments([]);
  };

  const selectTabId = _tabId => {
    setTabId(_tabId);
    setGamesTabId(1);
    fetchData(_tabId, 1);
  };

  const selectGamesTabId = _gamesTabId => {
    setGamesTabId(_gamesTabId);
    fetchData(tabId, _gamesTabId);
  };

  const selectTeacher = teacherId => {
    if (teacherId) {
      filterOrganizerData(teacherId);
    } else {
      filterOrganizerData(null);
    }
    setSelectedTeacher(teacherId);
    setSelectedOrganizer(null);
  };

  const selectOrganizer = el => {
    setSelectedOrganizer(el);
    if (el?.teacherId) {
      filterOrganizerData(el.teacherId);
    } else {
      filterOrganizerData();
    }
    if (el) {
      clearTables();
      const _TargetService = el.groupId ? GroupsService : PersonalsService;
      const _targetId = el.groupId ? el.groupId : el.classId;
      setTargetService(_TargetService);
      setTargetId(_targetId);
      setTabId(1);
      setGamesTabId(1);
      setSelectedTeacher(el.teacherId);
      const renewedPaginationStatus = cloneDeep(paginationInitials);
      setPaginationStatus(renewedPaginationStatus);
      setTimeout(
        () => fetchData(1, gamesTabId, true, _TargetService, _targetId, el),
        0,
      );
    } else {
      setTabId(1);
    }
  };

  const filterOrganizerData = (
    teacherId = selectedTeacher,
    data = organizers,
  ) => {
    if (teacherId) {
      setFilteredOrganizerData(data?.filter(el => el.teacherId === teacherId));
    } else {
      setFilteredOrganizerData(data);
    }
  };

  return (
    <View
      organizers={filteredOrganizerData}
      teachers={teachers}
      selectTabId={selectTabId}
      gamesTabId={gamesTabId}
      selectGamesTabId={selectGamesTabId}
      selectedOrganizer={selectedOrganizer}
      setSelectedOrganizer={selectOrganizer}
      selectedTeacher={selectedTeacher}
      setSelectedTeacher={selectTeacher}
      data={userData}
      groupsLoading={groupsLoading}
      personalLoading={personalLoading}
      tests={tests}
      tabId={tabId}
      fetchData={fetchData}
      eventsLoading={eventsLoading}
      events={events}
      transferedEvents={transferedEvents}
      transferedEventsLoading={transferedEventsLoading}
      generalTests={generalTests}
      generalTestsLoading={generalTestsLoading}
      payments={payments}
      paymentsLoading={paymentsLoading}
      flashcards={flashcards}
      flashcardsLoading={flashcardsLoading}
      matchup={matchup}
      matchupLoading={matchupLoading}
      categorize={categorize}
      categorizeLoading={categorizeLoading}
      anagrams={anagrams}
      anagramsLoading={anagramsLoading}
      testsLoading={testsLoading}
      paginationStatus={paginationStatus}
      isAdmin={isAdmin}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
