import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import { ruDateTimeFormat } from '../../core/functions';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      {/* <NavLink to={`general-tests/${data.testObj.testId}`} className="box box-1">{data.id}</NavLink> */}
      <NavLink
        to={`/general-tests/${data.testObj.test.testId}`}
        className="box box-2">
        {data.title}
      </NavLink>
      <NavLink
        to={`/general-tests/${data.testObj.test.testId}`}
        className="box box-3">
        {moment(data.date).format('DD MMMM YYYY, HH:mm')}
      </NavLink>
      <NavLink
        to={`/general-tests/${data.testObj.test.testId}`}
        className="box box-4">
        {data.passedTime
          ? moment(data.passedTime).format('DD MMMM YYYY, HH:mm')
          : '-'}
      </NavLink>
      {/* <NavLink
        to={`/general-tests/${data.testObj.testId}`}
        className="box box-5">
        {data.mark ? data.mark : '-'}
      </NavLink> */}
      <div className="box box-6 center">
        {props.renderControl ? (
          props.renderControl(data)
        ) : (
          <Button
            type={data.active ? 'secondary' : 'primary'}
            small
            textButton={data.active ? 'Удалить' : 'Добавить'}
            onClick={() => {
              props.deleteTest(data.test);
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div onScroll={props.paginate} className="table general-tests-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Название</div>
          <div className="box box-3">Дата начала</div>
          <div className="box box-4">Дата прохождения</div>
          {/* <div className="box box-5">Оценка</div> */}
          <div className="box box-6">Управление</div>
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((wrapper, index) => {
                return (
                  <TableLine
                    index={index}
                    title={wrapper.test.title}
                    createdTime={wrapper.test.createdTime}
                    passedTime={wrapper.timePassed}
                    mark={wrapper.mark}
                    date={new Date(wrapper.timestampDate?._seconds * 1000)}
                    testObj={wrapper}
                  />
                );
              })
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
      {props.type !== 'teacher' && props.data?.length ? (
        <Button
          disabled={!props.data.filter(el => !el.active).length}
          type="primary"
          textButton="Удалить"
          onClick={() => {
            props.shareTests();
          }}
        />
      ) : null}
    </>
  );
};

export default UsersTableView;
