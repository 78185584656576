import React from 'react';

import { NavLink } from 'react-router-dom';
import './style.css';
import Button from '../button';
import Loader from '../loader/Loader';
import { ruDateTimeFormat } from '../../core/functions';
import moment from 'moment';

const UsersTableView = props => {
  const TableLine = data => (
    <div
      className={`table-line ${
        data.index % 2 === 1 ? 'table-line_white' : 'table-line_gray'
      }`}>
      {/* <NavLink to={`users/${data.id}`} className="box box-1">{data.id}</NavLink> */}
      <NavLink to={`/tests/${data.id}`} className="box box-2">
        {data.title}
      </NavLink>
      <NavLink to={`/tests/${data.id}`} className="box box-3">
        {data.type}
      </NavLink>
      <NavLink to={`/tests/${data.id}`} className="box box-4">
        {data.showRight ? 'да' : 'нет'}
      </NavLink>
      <NavLink to={`/tests/${data.id}`} className="box box-5">
        {data.multiplie ? 'да' : 'нет'}
      </NavLink>
      <NavLink to={`/tests/${data.id}`} className="box box-6">
        {moment(data.date || data.createdTime).format('YYYY.MM.DD, HH:mm')}
      </NavLink>
      <div className="box box-7">
        {props.renderControl ? (
          props.renderControl(data)
        ) : (
          <Button
            type={data.active ? 'secondary' : 'primary'}
            small
            textButton={data.active ? 'Удалить' : 'Добавить'}
            onClick={() => {
              props.deleteTest(data.test);
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div onScroll={props.paginate} className="table group-tests-table">
        <div className="table-header table-line">
          {/* <div className="box box-1">ID</div> */}
          <div className="box box-2">Название</div>
          <div className="box box-3">Тип</div>
          <div className="box box-4">Показывать верные</div>
          <div className="box box-5">Мн. выбор</div>
          <div className="box box-6">Дата начала</div>
          <div className="box box-7">Управление</div>
        </div>
        <div className="table-list">
          {props.data?.length
            ? props.data.map((test, index) => (
                <TableLine
                  key={test.testId}
                  index={index}
                  id={test.testId}
                  test={test}
                  active={test.active}
                  title={test.title}
                  type={test.type}
                  showRight={test.showRight}
                  createdTime={test.createdTime}
                  date={test.date}
                  multiplie={test.multiplie}
                  has={test.has}
                  handleTest={props.handleTest}
                />
              ))
            : null}
          {props.last && !props.data?.length ? props.renderEmpty() : null}
          {props.loading ? <Loader className="table-loader" /> : null}
        </div>
      </div>
      {props.type !== 'teacher' && props.data.length ? (
        <Button
          disabled={!props.data.filter(el => !el.active).length}
          type="primary"
          textButton="Удалить"
          onClick={() => {
            props.shareTests();
          }}
        />
      ) : null}
    </>
  );
};

export default UsersTableView;
