const SET_STUDENTS = 'SET_STUDENTS';

const initialState = null;

const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENTS:
      return action.students;
    default:
      return state;
  }
};

export const setStudents = students => {
  return dispatch => {
    dispatch({ type: SET_STUDENTS, students });
  };
};

export default studentsReducer;
