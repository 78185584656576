import React from 'react';
import './style.css';
import Loader from '../loader/Loader';

const List = ({
  list = [],
  loading = true,
  emptySign = '',
  renderItem = () => {},
  renderHeader = () => {},
  onScroll = () => {},
}) => {
  return (
    <div className="items-list-container" onScroll={onScroll}>
      {list.length ? (
        [
          renderHeader(),
          <div className="list-item-container">
            {list.map(item => {
              return renderItem(item);
            })}
          </div>,
          loading ? <Loader className="pagination-loader" /> : null,
        ]
      ) : (
        <div className="items-list-empty-container">
          <img
            src={require('../../assets/pictures/tests-list.svg')}
            alt={'Список пуст'}
          />
          {loading && <Loader />}
          {!loading && <div className="items-list-empty-sign">{emptySign}</div>}
        </div>
      )}
    </div>
  );
};

export default List;
